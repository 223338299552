@import '../../../variables';

.kpiWidgetPanelContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .actionContainer {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .kpiPresentContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .kpiActionContainer {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
        }

        .checkboxRow {
            display: flex;
            font-size: 12px;
            margin: 0 0 20px 0;
            .text {
                margin-left: 12px;
            }
        }
    }

    .content {
        opacity: 0.7;
        width: 100%;

        .descriptionContainer {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .descriptionDesign {
                font-size: 12px;
            }
        }
    }
}

.title {
    @include widgetTitle();
}

.description {
    font-size: 14px;
}

.seperator {
    padding: 10px 0 0 0;
    border-top: 1px solid $textbox-border-color;
}

.editSettings {
    display: flex;
    flex-direction: column;
    gap: 10px;
}