@import '../../../variables';

.buttonFrame {
    border: solid 1px;
    padding: 0 10px;
    line-height: 24px;
    cursor: pointer;
}

.detailsText {
    background-color: $widget-elements-white-backgroung;
    padding-right: 5px;
}

.arrowUp {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
}

.arrowDown {
    transition: transform 0.2s linear;
}

.button {
    height: 26px;
    max-height: 26px;
    font-size: 13px;
    cursor: default;
}
