.fullImageDialog{
  max-width: 80vw;
  height: 80vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
}

.thumbnailContainer {
  cursor: pointer;
  position: relative;
  width: auto;
  height: 40px;
}

.thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
}

.fullImage {
  height: auto;
}

.moreIcon {
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;

  background: white;
  padding: 2px;
  border-radius: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.thumbnailContainer:hover .moreIcon {
  display: block;
}