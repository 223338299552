@import '../../../variables';

.internal {
  background-color: $internal-border-color;
  color: black;
}

.text {
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-align: center;
}