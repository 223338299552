@import '../../variables';

.container {
    margin-top: 230px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.title {
    font-size: 28px;
    font-family: $main-Font;
    font-weight: bold;
}

.subtitle {
    margin-top: 30px;
}

.button {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}

.conclusion {
    margin-top: 20px;
    color: $description-grey;
}
