@import '../../../variables';

.container {
    color: $primary-branded-color;
    border: 1px dashed $primary-branded-color;
    font-size: 14px;
    font-weight: 600;
    width: 157px;
    height: 37px;
    font-family: $main-Font;
    border-radius: 18.5px;
    background: $widgets-header-color;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hasFollowers {
        border-style: solid;
        background: $followers-button-color_with-followers;
        color: $text-color;
        border-color: $text-color;
    }

    .bellIcon {
        width: 12px;
        height: 16px;
        margin-right: 10px;
        fill: $primary-branded-color;
    }

    &:hover {
        transition: all 0.2s ease-in;
        cursor: pointer;
        border-color: $all-accounts-hover-border-color;
        color: $all-accounts-hover-border-color;
        background: $all-accounts-hover-color;

        .bellIcon {
            transition: fill 0.2s ease-in;
            fill: $all-accounts-hover-border-color;
        }
    }
}

.tooltip {
    white-space: pre;
}

.dialogContent {
    .description {
        @include description();
        color: $subtext-grey;
    }

    .link {
        color: $primary-branded-color;
        font-weight: 600;
        text-decoration: none;
        font-size: 12px;
        padding-bottom: 10px;
    }

    .input {
        padding-top: 10px;
    }
}
