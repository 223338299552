@import '../../../variables';

.wrapper {
    // FloatingWindow has wrapper with position: absolute !important;
    position: fixed !important;
    width: fit-content;
    z-index: 100;
    left: 87px;
    height: fit-content;
    margin-top: unset;
    border-radius: 10px;

    &::before {
        top: 25px;
        left: -15px;
        border-bottom: 10px solid $menu-color;
        transform: rotate(270deg);
    }

    &.openFromBottom {
        margin-top: -65px;

        &::before {
            bottom: 25px;
            top: unset;
        }
    }
}

.main {
    height: fit-content;
    background-color: $menu-color;
    padding: 0 5px;
    border-radius: 6px;
}

.childrenContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 9px 0;
}

.itemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 6px;
    height: 24px;
    text-decoration: none;

    &:hover {
        background-color: $item-hover-background-color;
    }

    &.isSelected {
        background-color: $main-color;
    }
}

.imageLogo {
    width: 16px;
}

.title {
    font-size: 12px;
    color: $item-title-color;
    white-space: nowrap;

    &.isSelected {
        font-weight: 600;
    }
}
