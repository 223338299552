@import '../../../../variables';
@import '../../../../aiVariables';

.AIDialogHeader {
    @extend %AI_TEXT;
    gap: 14px;
    display: flex;
    align-items: center;
}

.AIDialog {
    width: 548px;
    padding: 26px 30px 30px 26px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.AITranslationAgreementContainer {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    flex-direction: row;
    cursor: pointer;

    .checkbox {
        margin-top: 3px;
    }
}