@import '../../variables';

.main {
    max-height: 450px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow-y: auto;
    padding: 10px 23px 30px 23px;
    cursor: auto;
}

.wrapper {
    background-color: white;
    position: absolute !important;
    cursor: unset;

    &.masked {
        z-index: 1000;
    }
}

.mask {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 500;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.arrowDown {
    @include arrowDown();
}

.arrowUp-center {
    @include arrowUp();
    &::before {
        right: 50%;
    }
}

.arrowUp-right {
    @include arrowUp();
    &::before {
        right: 10%;
    }
}

.arrowUp-side {
    @include arrowUp();
    &::before {
        bottom: 217px;
        right: 0;
        border-bottom: 10px solid $main-color;
        transform: rotate(90deg);
        margin-right: -12px;
    }
}
