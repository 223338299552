@import '../../../variables';
@import '../overviewVariables';

.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 30px;
    border-bottom: 1px solid $LIGHT-GREY-BORDER;
}

.sectionContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: $medium-laptop) {
        gap: 10px;
    }
}

.margin {
    margin-left: 10px;
}

.divider {
    border-left: 1px solid $separator-color;
    height: 19px;
}

.accountsFilterDivider {
    margin-left: 20px;
}

.confetti {
    margin-left: 30px;

    @media (max-width: $laptop) {
        margin-left: -5px;
    }

    @media (max-width: $small-laptop) {
        display: none;
    }
}

.name {
    color: $main-color;
}