@import '../../variables';

.limit {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  position: absolute;
  top: calc(100% + 2px);
  color: $field-color;
}

.wiggle {
  animation: wiggle 1s linear infinite;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-3deg);
  }
  20% {
    transform: rotateZ(2deg);
  }
  25% {
    transform: rotateZ(-1deg);
  }
  30%, 100% {
    transform: rotateZ(0deg);
  }
}