@import '../../../variables';

.container {
    @include flexRow;
    margin-top: 20px;
}

.rightButton {
    background-color: $main-color;
}
