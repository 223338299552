@import '../../../variables';
.container {
    transition: all 0.2s ease-in;
    margin: -25px 0px 6px 61px;
    .arrow {
        margin-left: 10px;
        cursor: pointer;
        &.arrowButtonActive {
            transform: rotate(180deg);
            transition: all 0.2s linear;
        }
    }
    .countReplies {
        color: $primary-branded-color;
        font-size: 11px;
        font-weight: bold;
        cursor: pointer;
    }
    .replyContainer {
        position: relative;
        display: flex;
        width: 95%;
        &:hover {
            .actionIcon {
                transition: all 0.2s ease-in;
                opacity: 1;
            }
        }
        .actionContainer {
            position: absolute;
            right: 20px;
            top: 15px;
        }

        .internalLabel {
            position: absolute;
            top: 15px;
            right: 45px;
        }
        .comment {
            position: relative;
            right: 18px;
            margin-top: 10px;
            width: 100%;
            &:hover {
                transition: all 0.2s ease-in;
                background-color: $placeholder-content-background;
                border-radius: 4px;
            }
        }
        .actionIcon {
            opacity: 0;
            height: 13px;
            width: 13px;
            margin-right: 5px;
            cursor: pointer;
        }

        &.internal {
            .comment {
                border-radius: 4px;
                background: $internal-data-background-color;
            }
            &:hover {
                [class*='internalLabel'] {
                    opacity: 0;
                }
            }
        }
    }
    .csmWidth {
        width: 99%;
    }
}
