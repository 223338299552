@import '../../../../variables';

.container {
    @include horizontalCenter();
    display: flex;
    padding-top: 70px;
    width: 100%;
}

.title {
    font-family: $title-Font;
    font-weight: normal;
    font-stretch: normal;
    font-size: 28px;
    width: 576px;
    text-align: center;
}

.description {
    @include description();
    margin: 10px 0 28px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .message {
        margin-bottom: 10px;
    }
}

.highlight {
    color: $primary-color;
    font-weight: 600;
    cursor: pointer;
}

.button {
    max-width: 160px;
    height: 37px;
    margin: 45px 0;
    font-size: 14px;
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 685px;
    justify-content: center;

    .tag {
        margin-right: 22px;
        margin-top: 22px;
    }
}
