@import '../../../variables';
@import './KitGalleryVariables';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  background-image: linear-gradient(to bottom, #fff 0%, #f5f5f5 100%);
  padding: 20px 27px 0;
  height: calc(100% - #{$header-height} - 1px);
  box-sizing: border-box;
}

.header {
  text-align: center;
}

.title {
  color: $TEXT_COLOR;
  font-family: $main-Font;
  font-size: 22px;
  font-weight: 600;
}

.subtitle {
  color: $TEXT_COLOR;
  font-family: $main-Font;
  font-size: 12px;
}

.tags {
  @extend %TAGS;
  gap: 10px;
  padding-bottom: 5px;
}

.tag {
  @extend %TAG;
  cursor: pointer;
  transition: color ease-in-out 200ms, background-color ease-in-out 200ms;

  &:not(.selectedTag):hover {
    color: $main-color;
    background-color: rgba($SELECTED_TAG_BG_COLOR, 0.5);
  }
}

.selectedTag {
  @extend %TAG;
  color: $main-color;
  background-color: $SELECTED_TAG_BG_COLOR;
}

.templates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(283px, 1fr));
  gap: 20px;
  padding: 25px 10px;
  width: 100%;
  overflow: auto;
}