@import '../../../../../variables';

.container {
    padding: 5px 28px;
    height: calc(100% - 15px);
    overflow: auto;
    width: 100%;

    .actionsContainer {
        display: flex;
        justify-content: space-between;
    }

    .actions {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
        margin-bottom: 10px;

        &.spaced {
            justify-content: space-between;
        }
    }

    .separator {
        @include taskActionSeparator();
    }
    .description {
        width: 100%;
        display: flex;
        gap: 10px;

        &.vertical {
            flex-direction: column;
        }

        &.horizontal {
            flex-direction: row;
        }
    }

    .datePickerContainer {
        display: flex;
        align-items: center;
        &.mobile {
            width: 100%;
        }
    }

    .datePicker {
        display: flex;
        align-items: center;
    }

    .relativeDatesLabel {
        font-size: 13px;
    }

    .relativeDateInput {
        min-width: 137px;
    }

    .taskOwner {
        font-size: 13px;
        height: 33px;
    }

    .tagsWrapper {
        display: flex;
        align-items: center;
    }
}

.descriptionWrapper {
    padding: 0 5px 5px 5px;
}
