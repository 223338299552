.content {
  width: 1000px;
  padding: 0 30px;
  font-size: 14px;
  max-height: 75vh;
  overflow: scroll;
}

.toggleContainer {
  margin: 20px 0 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .toggleTitle {
    font-size: 14px;
    margin-left: 10px;
  }
}

.statusTitle {
  font-size: 12px;
  margin-top: 10px;
}

.accountsFieldsFilterSelector{
    margin-bottom: 30px;
}

.customerActionsContainer{
    margin-left: 25px;
}