@import '../../variables';

.container {
    @include widgetPanel();
    width: 0;
    min-width: 0;
    transition: width 0.1s ease-in-out;
    &.open {
        transition: width 0.1s ease-in-out;
        width: $configuration-panel-width;
        min-width: $configuration-panel-width;
    }
}
