@import '../../../variables';
@import '../../../aiVariables';

.container {
  background-color: #fff;
  padding: 8px 11px 10px;
  border: 1px solid #eff0f6;
  border-radius: 11px;
  margin-top: 10px;
}

.disabled {
  pointer-events: none;
}

.widgetDetails {
  display: flex;
  align-items: center;
  box-shadow: unset;
  background-color: unset;
  width: auto;
  gap: 15px;
  font-family: $main-Font;

  * {
    padding: unset;
  }

  img {
    padding: unset;
  }

  &:hover {
    background-color: unset;
  }
}

.widgetDescription {
  color: $subtext-light-grey;
}


.creationOptions {
  display: flex;
  gap: 17px
}

.option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 123px;
  border-radius: 11px;
  font-family: $main-Font;

  span {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.fromScratchOption) {
    background-color: $widget-entity-light-grey-background;

    span {
      @extend %AI_TEXT;
    }

    &:hover {
      background-color: unset;
      @extend %AI_COLOR_BACKGROUND;

      span {
        background: unset;
        color: white;
        -webkit-text-fill-color: unset;
      }

      .optionIcon:not(.uploadIcon) * {
        fill: white;
      }

      .uploadIcon * {
        stroke: white;
      }
    }
  }
}

.optionIcon {
  width: 37px;
  height: 35px;
}

.fromScratchOption {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23CCCCCCFF' stroke-width='2' stroke-dasharray='5%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 11px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover * {
    opacity: 0.6;
  }
}