@import '../../variables';

.root {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    > * {
        width: 100%;
    }

    & > [class*='loader'] {
        position: absolute;
        right: 35px;
        z-index: 1;
    }
}

.container {
    padding: 10px;
}

:global(.MuiOutlinedInput-input) {
    &.input {
        display: flex;
        padding: 5px 20px;
        font-family: $main-Font !important;
    }

    &.inputMulti {
        display: flex;
        height: fit-content;
        padding: 7px 20px;

        .valueContainer {
            flex-wrap: wrap;
        }
    }
}

:global(.MuiInputLabel-outlined) {
    &.label {
        transform: translate(20px, 13px) scale(1);
        font-family: $main-Font !important;
        font-size: 14px;
    }
}

:global(.MuiFormLabel-root.Mui-error) {
    &.label {
        color: $textbox-hover-border-color;
    }
}

:global(.MuiPaper-root) {
    z-index: 1;
}

.inputRoot {
    width: 100%;
}

.valueContainer {
    display: flex;
    flex: 1;
    align-self: center;
    overflow: hidden;
    align-items: center;
    color: black;

    div {
        font-family: $main-Font;
    }
}

.chip {
    margin: 5px 2px;
    font-family: $main-Font;
}

.chipFocused {
    background-color: white;
}

.noOptionsMessage {
    padding: 10px 5px;
}

.singleValue {
    font-size: 16px;
    font-family: $main-Font;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.paper {
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    left: 0;
    right: 0;
}

.divider {
    height: 50px;
}

.dropdown {
    z-index: 100;
    min-width: fit-content;
    max-width: $max-select-option-width;
}

.optionLabel {
    font-family: $main-Font;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.textInput {
    p {
        font-size: 14px;
    }

    input {
        font-size: 14px;
    }

    input::placeholder {
        font-size: 14px;
    }
}

.loader {
    margin-left: -60px;
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
}

.error {
    font-size: 10px;
    font-weight: 600;
    color: #ee3543;
}

.helpTextTooltip {
    min-width: unset;
}