@import '../../../../../../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    align-items: center;

    .title {
        font-size: 18px;
        font-weight: bold;
        color: $text-color;
    }

    .imageLogo {
        margin: 20px 0;
    }
}
