@import '../../variables';

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;

    .action {
        height: 20px;
        width: 20px;
        align-self: flex-end;
    }
    .loaderOverlay {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(
            red($widget-container-background-color),
            green($widget-container-background-color),
            blue($widget-container-background-color),
            0.8
        );
        z-index: 20;
    }
}

.dialogContainer {
    width: 80vw;
}
.renderContainer {
    width: 100%;
    height: 100%;
    display: flex;

    &.hideGridLines {
        :global(.dhx_grid-cell) {
            border: 0 !important;
        }
    }
}

.disabled {
    pointer-events: none;
}

.outOfSyncMessageContainer {
    height: 100px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    background: $widget-container-background-color;

    .title {
        @include title();
    }

    .description {
        @include description();
    }

    .button {
        padding: 0 30px;
    }
}
