@import '../../../variables';

.calculateLink {
  cursor: pointer;
  margin-top: 25px;
  font-size: 12px;
  font-weight: 600;
  color: $primary-color;
}

.activateButton {
  min-width: 146px;
}