@import '../../../../../variables';

.container {
    padding: 5px 30px;
    height: calc(100% - 15px);
    overflow: auto;
    width: 100%;

    .actionsContainer {
        display: flex;
        justify-content: space-between;
    }

    .actions {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
        margin-bottom: 10px;

        &.spaced {
            justify-content: space-between;
        }
    }

    .taskDurationContainer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;

        .durationText {
            font-size: 12px;
        }
    }

    .separator {
        @include taskActionSeparator();
    }

    .description {
        width: 100%;
        display: flex;
        gap: 10px;

        [class*='DraftEditor-root'] {
            min-height: 160px;
            max-height: unset;
        }

        &.vertical {
            flex-direction: column;
        }

        &.horizontal {
            flex-direction: row;

            [class*='DraftEditor-root'] {
                height: calc(100% - 70px);
            }
        }
    }

    .descriptionItemWrapper {
        display: flex;
        width: 100%;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        > * {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 100%;
        }
    }

    .updateViaApi {
        width: 130px;
    }

    .datePicker {
        display: flex;
        align-items: center;
    }

    .relativeDatesLabel {
        font-size: 13px;
    }

    .relativeDateInput {
        min-width: 137px;
    }

    .taskOwner {
        font-size: 13px;
        height: 33px;
    }

    .actionTitle {
        font-size: 12px;
        font-weight: 600;
        padding-right: 15px;
    }

    .tagsWrapper {
        display: flex;
        align-items: center;
    }

    .attachActionsWrapper {
        gap: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;

        [class*='separator'] {
            margin: 0 10px;
        }
    }

    .layoutLine {
        padding: 10px 0;
        display: flex;
        align-items: flex-start;
    }

    .additionalTaskDataContainer {
        position: relative;
        display: flex;
        width: 100%;

        .removeImageAndEmbedIcon {
            position: absolute;
            padding: 5px;
            top: 10px;
            right: 10px;
            opacity: 0;
        }

        &:hover {
            .removeImageAndEmbedIcon {
                opacity: 1;
                transition: opacity 0.2s ease-in;
            }
        }
    }

    .customerActions {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        padding-top: 25px;
        border-top: 1px solid $separator-color;
        align-items: flex-start;
        width: 100%;
    }

    .image {
        max-height: 500px;
        max-width: 500px;
        object-fit: contain;
    }

    .actionButtonSettings {
        display: flex;
        padding-top: 20px;
    }

    .files {
        display: flex;
        align-items: center;
    }

    .reminder {
        border-radius: 5px;
        height: 33px;
        width: 33px;
    }
}

.attachFileButton {
    padding: 8px;
    gap: 10px;
    width: fit-content;
    flex-wrap: nowrap;
    display: inline-flex;
}
