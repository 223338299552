@import '../../../../variables';

.questionEditor {
    width: 100%;
    background-color: $widget-container-background-color;
}

.questionRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    justify-content: center;
}

.questionRowEditMode {
    width: 50%;
}

.question {
    text-align: center;
}

.question *:not(h3) {
    font-size: 16px;
}