@import '../../../variables';

.attachmentContainer {
    margin: 5px 26px 18px 20px;
}

.addAttachmentContainer {
    margin: 5px 26px 20px;
    width: fit-content;
    display: flex;
}

.addAttachmentButton{
    @include actionButton();
    padding: 10px 5px;
}

.attachmentText {
    margin-left: 10px;
    font-size: 12px;
}
