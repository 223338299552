.dialogTitle {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    height: 16px;
    width: 16px;

    Circle {
      fill: #00a63b
    }
  }
}

.dialogBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 26px 26px 30px;
  white-space: break-spaces;
}