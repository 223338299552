@import '../../variables';

.container {
    input {
        @include textField;
    }

    input[type='submit'] {
        width: auto;
        border-radius: 50px;
        margin: 20px auto;
    }
}
