.container {
    align-items: center;
    display: flex;
    height: 67px;
    min-height: 67px;
    background-color: white;
    box-sizing: border-box;
    width: inherit;
    border-bottom: 1px solid #e9e8ed;
    margin: 0 25px;

    &.sticky {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

.container.noMargins {
    margin: 0;
    width: 291px;
}

.divider {
    background-color: #e9e8ed;
    height: 1px;
    margin-right: 25px;
    margin-left: 25px;
    width: inherit;
}
