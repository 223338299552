@import '../../../../variables';

.title {
    width: 424px;
    margin-top: 43px;
}
.uploadPhoto {
    margin-top: 22px;
}

.category {
    display: flex;
    margin-top: 22px;
}

.uploadButton {
    margin-top: 10px;
}

.categoryCheckbox {
    margin-right: 5px;
}

.uploadImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 50%;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.removeBtn {
    color: $delete-or-remove-color;
}
