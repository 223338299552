@import '../../../../variables';

$pending-verification-bg: #fcf4d8;
$pending-verification-bg-hover: #ebf8ef;
$verified-bg: $success-color;

.container {
  display: inline-block;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 163px;
    height: 25px;
    z-index: 0;
    background-color: transparent;

    &:hover {
      width: 163px;
    }
  }

  &:hover {
    .pendingVerification {
      background-color: $pending-verification-bg-hover;

      .icon {
        .verifiedIcon {
          display: block;

          circle {
            fill: $verified-bg;
          }
        }

        .pendingIcon {
          display: none;
        }
      }

      .label::before {
        content: 'Mark as verified'
      }
    }
  }
}

.widgetVerification {
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  padding: 4px 10px;
  box-sizing: border-box;
  height: 20px;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.1s ease-in;
}

.icon {
  display: flex;
  height: 14px;
}

.label {
  font-size: 12px;
  font-family: $main-Font;
}

.pendingVerification {
  background-color: $pending-verification-bg;

  .label::before {
    content: 'Pending verification'
  }
}

.verified {
  background-color: $verified-bg;

  .icon {
    .verifiedIcon {
      display: block;
    }
    .pendingIcon {
      display: none;
    }
  }

  .label::before {
    content: 'Verified'
  }
}

.verifiedIcon {
  display: none;
}