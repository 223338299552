@import '../../../variables';
@import '../../../aiVariables';

.AIDialogHeader {
    @extend %AI_TEXT;
    gap: 14px;
    display: flex;
    align-items: center;
}

.AIDialog {
    width: 605px;
    padding: 26px 30px 30px 26px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}