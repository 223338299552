@import '../../../../variables';
@import '../TemplateModal.module';

.enableSearchContainer {
    @include flexRow();

    .enableSearch {
        @include description(14px);
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
}
