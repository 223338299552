@import '../../variables';

.sideBarMain {
    width: $sidebar-main-width;
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: column;
    background-color: $sidebar-color;
    padding: 15px 0px 14px 0px;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 110;

    .container {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.buttonLink {
    text-decoration: none;

    .upgradeButton {
        background-color: $main-color;
        padding: 5px 4px;
        font-size: 12px;
        height: fit-content;
    }
}

.sideBarMain::-webkit-scrollbar {
    display: none;
}

.spacing {
    margin-bottom: 30px;
}

.iconTabMain {
    position: relative;
    margin-bottom: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: inherit;
}

.linkLogo {
    margin-bottom: 30px;
    display: block;
    width: 100%;
    position: relative;
    .oval {
        width: 5px;
        height: 5px;
        border: solid 1px #0c0c0c;
        background-color: #ffd030;
        border-radius: 50%;
        position: absolute;
        right: 18px;
        top: 2px;
    }
    .logo {
        // composes: spacing;
        width: 37px;
        height: 37px;
        cursor: pointer;
        &.selected {
            border: solid 2px #ffffff;
            border-radius: 50%;
        }
        border: 2px solid transparent;
    }
}

.icon {
    width: 25px;
    // opacity: 0.7;
}

.highlight {
    & .title {
        opacity: 1;
        font-weight: 700;
    }
    & .icon {
        opacity: 1;
    }
    & .iconWrapper {
        background-color: #4f4f4f80;
        border-radius: 17px;
    }
}

.iconWrapper {
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
}

.selected {
    & .iconWrapper {
        background-color: #4f4f4f;
        border-radius: 17px;
    }
    & .title {
        opacity: 1;
        font-weight: 700;
    }
    & .icon {
        opacity: 1;
    }
}

.title {
    // opacity: 0.7;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.notificationsDot::after {
    content: ' \25CF';
    font-size: 20px;
    color: $notification-icon-color;
    position: absolute;
    left: 41px;
    top: 7px;
}

.selectedInd {
    border-right: 5px solid white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: 0px;
    height: 0px;
    transform: translateY(-10px);
}

.iconTabWrapper {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: flex-end;
}

.betaIcon {
    position: absolute;
    bottom: 38px;
    left: 38px;
}

.sideBarWithTrial {
    position: unset;
}

.tabsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
