@import '../../../../../variables';

.addWidget {
    background-color: $icon-add-section-color;
    font-family: $main-Font;
    height: fit-content;
    &:hover {
        background-color: #ebebeb;
    }
}
