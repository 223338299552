.submitButton {
    display: flex;
    justify-content: center;
}

.modalContent {
    display: flex;
    flex-direction: column;
}

.infoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.info {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.taskPreview {
    display: flex;
    gap: 6px;
    padding-bottom: 20px;
    .title {
        font-weight: 600;
    }
}

.loader {
    height: 100px;
}
