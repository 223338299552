@import '../../../variables';
.container {
    border-radius: 275px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #000000;
    height: fit-content;
    display: flex;
    padding: 2px 15px;
    align-items: center;
    margin-top: -8px;
    position: absolute;
    right: 0px;
    max-width: 40%;
    width: fit-content;

    .textArea {
        margin-left: 7px;
        background-color: #000000;
        border: none;
        color: #ffffff;
        font-size: 12px;
    }

    .closeIcon {
        padding: 5px;
        cursor: pointer;
    }

    .text {
        color: #ffffff;
        font-family: $main-Font;
        font-size: 12px;
    }
}

.templateMode {
    width: 25%;
}
