.contentLayout {
    width: 600px;
    height: fit-content;
    overflow: auto;
    max-height: 500px;
    .dialogContent {
        width: auto;
    }
}

.header {
    width: 550px;
}
