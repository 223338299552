@import '../../../../../variables';

.menuContainer {
    position: relative;
    .accountActionsMenuButton {
        margin-left: auto;
        &:hover {
            background-color: $gray-action-button-menu-hover-color;
        }
    }

    .archivedDialogContainer {
        position: absolute;
        right: 0;
    }

    .presentationFloatingWindow {
        width: 331px;
        z-index: 100;
        top: 55px;
        right: 0;
    }
}
