@import '../../../../variables';

.container {
    width: calc(100% - 90px);
    height: 100%;
    min-height: 510px;
    display: grid;
    position: relative;
    grid-template-columns: 1fr 5fr;
    padding: 75px 0 75px 30px;

    .dialogTitle {
        position: absolute;
        top: 0;
        left: -30px;
        max-height: 74px;
        border-bottom: 1px solid $separator-color;
        width: calc(100% + 60px);
        font-size: 16px;
        font-weight: 600;
        padding-left: 60px;
        height: 44px;
    }

    .dialogSubTitle {
        display: flex;
        font-size: 14px;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .errorContainer {
        border: 1px $error-message-color solid;
        background: rgba($error-message-color, 0.25);
        border-radius: 5px;
    }

    .subject {
        min-height: 30px;
        max-height: 30px;
        margin: -5px 0 10px;
        padding-bottom: 5px;

        .subjectSeparator {
            margin: 3px 0;
        }
        .errorContainer {
            width: calc(100% - 15px);
        }
    }

    .rowTitle {
        color: $text-color;
        font-weight: 600;
        font-size: 14px;
        &.subjectTitle {
            padding-top: 14px;
        }
    }

    .textEditContainer {
        height: 30px;
        display: inherit;
    }

    .checkboxOption {
        display: flex;
        gap: 8px;
        width: 200px;
    }

    .checkboxArea {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 16px;
        padding-bottom: 20px;
    }

    .followersButton {
        margin-top: 0;
    }

    .content {
        grid-column-start: 2;
    }

    .intro {
        max-height: 140px;
    }

    .footer {
        max-height: 85px;
    }

    .buttonsArea {
        position: absolute;
        bottom: 0;
        right: -90px;
        height: 60px;
        padding-right: 90px;
        padding-bottom: 10px;
        background: $placeholder-content-background;
        width: calc(100% + 120px);

        .buttons {
            display: flex;
            justify-content: flex-end;
            padding-top: 16px;
            gap: 20px;
        }
    }

    .emailContent {
        padding: 15px 0;
        min-height: 280px;
        height: fit-content;
        max-height: 315px;
        transition: all 0.2s ease-in;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .contactsSelect {
        [class*='valueContainer'] {
            max-height: 80px;
            overflow: auto;
        }

        &.errorHighlight {
            [class*='MuiInputBase-input'] {
                background: rgba($error-message-color, 0.25);
                border: 1px solid $error-message-color;
                border-radius: 5px;
            }
        }
    }

    .ctaButton {
        height: 60px;
        max-height: 60px;
        min-height: 60px;
        margin-bottom: 5px;
        margin-top: -10px;

        .ctaSeparator {
            margin-bottom: 5px;
        }
    }

    .widgetsSelect {
        &.errorContainer {
            [class*='placeholder'] {
                color: $error-message-color;
            }
        }
    }
}
