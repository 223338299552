@import '../../variables';

$actions-separator-color: $textbox-border-color;

.toggle {
    display: flex;
    margin-right: 10px;
    gap: 10px;
    &.isLeftCollapse {
        position: absolute;
        left: 0px;
        top: 6px;
        width: 16px;
    }
}

.actionsContainer {
    border-right: 1px solid $actions-separator-color;
    height: 11px;
    display: flex;
    align-items: center;
    padding: 3px 15px 0 0;
}

.toggleOn {
    background-image: url('../../assets/icons/toggle-on.svg');
    width: 40px;
    height: 22px;
}

.contentContainer {
    transition-duration: 200ms;
    overflow: hidden;
    height: auto;
}

.headerContainer {
    width: 100%;
}

.collapsed {
    overflow: hidden;
}

.arrow {
    transition: transform 200ms;
}

.collapsedArrow {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.toggleOff {
    width: 40px;
    height: 22px;
    background-image: url('../../assets/icons/toggle-off.svg');
}

.actionContainer hr {
    border: none;
    border-top: 1px dotted #979797;
    height: 1px;
    width: auto;
    flex-grow: 1;
    margin: 10px;
}

.container .textField {
    margin-top: 13px;
    width: inherit;
    height: 40px;
}

.actionContainer {
    width: inherit;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.container {
    width: 100%;
}

.input {
    padding-top: 10px;
}

.collapsed {
    height: 0px;
}
