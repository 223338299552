@import '../../variables.scss';

$footer-font-size: 10px;
$footer-background-color: #f6f7fa;
$footer-color: #6f7282;
$empty-state-color: #6b6b6b;
$date-color: #6b6b6b;
$signal-hover-background-color: #f3f3f3;
$success-opacity: 0.4;
$warning-background-color: #fbe5c5;
$warning-hover-background-color: #efdabb;
$danger-background-color: #f8aeb4;
$danger-hover-background-color: #eca5ab;
$success-background-color: $success-color;
$success-hover-background-color: #91d0a8;

.signalsContainer {
    position: relative;

    .signalBtn {
        height: 33px;
        padding: 8px;

        @media (max-width: $small-laptop) {
            padding: 5px;
        }

        &.warning {
            background-color: $warning-background-color;
            &:hover {
                background-color: $warning-hover-background-color;
            }
        }

        &.danger {
            background-color: $danger-background-color;
            &:hover {
                background-color: $danger-hover-background-color;
            }
        }

        &.success {
            background-color: $success-background-color;
            &:hover {
                background-color: $success-hover-background-color;
            }
        }
    }

    .signalLabel {
        @include textualButtonLabel;
        gap: 2px;

        @media (max-width: $small-laptop) {
            .label {
                display: none;
            }
        }

        .icon {
            display: flex;
            align-items: center;
            margin-bottom: 3px;
        }
    }

    .signalsListContainer {
        width: 405px;
        left: -176px;
        z-index: 100;
        top: 37px;
        border-radius: 8px 8px 0 0;

        &:before {
            right: 50%;
        }

        [class*='main'] {
            padding: unset;
            border-radius: 8px 8px 0 0;
        }

        .list {
            display: flex;
            flex-direction: column;
        }

        .singleSignalContainer {
            margin: 5px 10px;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 5px;

            &:hover {
                background-color: $signal-hover-background-color;
                border-radius: 5px;
            }

            .icon {
                flex: 0 0 10%;
                display: flex;
                justify-content: center;
                margin-top: 5px;
            }

            .content {
                font-size: 12px;
                flex: 0 0 75%;
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: flex-start;

                &.success {
                    opacity: $success-opacity;
                    text-decoration: line-through;
                }

                .information {
                    vertical-align: middle;
                    display: inline-flex;
                    align-items: center;
                    margin: 3px 10px 0 0;
                    flex: 0 0 4%;
                }
            }

            .date {
                font-size: 12px;
                color: $date-color;
                flex: 1 1 10%;

                &.opacity {
                    opacity: $success-opacity;
                }
            }
        }

        .emptyState {
            height: 60px;
            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                color: $empty-state-color;
                font-size: 12px;
            }
        }

        .footer {
            position: absolute;
            bottom: -15px;
            font-size: $footer-font-size;
            width: 100%;
            height: 16px;
            border-radius: 0 0 8px 8px;
            background-color: $footer-background-color;
            color: $footer-color;

            .text {
                text-align: center;
            }
        }
    }
}

.skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;

    .single {
        display: flex;
        align-items: center;
    }

    .title {
        height: 10px !important;
    }

    .paragraph {
        margin-top: 15px !important;
        li {
            height: 10px !important;
        }
    }
}
