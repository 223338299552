@import '../../../variables';
@import './TemplateModal.module';

.container {
    @include flexRow();
}

.checkboxTitle {
    @include description(14px);
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.infoIcon {
    margin-left: $info-icon-margin-left;
}
