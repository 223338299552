@import '../../../../../../variables';

.date {
    display: flex;
    align-items: center;
    color: $subtext-color;
}

.brakeLine {
    flex-direction: column;
    align-items: flex-start;
}

.clockIcon {
    position: absolute;

    @media (max-width: $mobile) {
        padding-left: 0px;
    }
}

.dayAndMonth {
    margin-right: 10px;
}

.houresContainer {
    display: flex;
    position: relative;

    .houresText {
        padding-left: 20px;
    }
}

.dot {
    font-weight: bold;
}
