@import '../../variables';
.pdf {
    .milestones {
        overflow: hidden;
    }
}

.milestonesContainer {
    display: flex;
}

.milestones {
    display: flex;
    padding-bottom: 55px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative;
}

.milestonesMobile {
    max-width: 100%;
    overflow-y: auto;
    display: block;
    padding: 10px 0 0;
    margin-top: 0;
    overflow-x: hidden;
}

.timelineBased {
    font-size: 14px;
    font-weight: 600;
    margin-top: 27px;
    margin-bottom: 11px;
}

.infoIcon {
    margin-left: 15px;
}

.content {
    @include widgetDescriptionPanel;
}

.layoutOptions {
    @include flexRow;
    gap: 10px;
}

.autocompleteDropdownRTL {
    @include autocompleteDropdownRTL;
}

.title {
    @include widgetTitle;
    margin: 18px 0 10px;
}

.addMilestoneButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-width: 100px;
    max-width: 100px;

    .addIcon {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}