.widgetPanelContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .titleWithIconsContainer {
        display: flex;
        flex-direction: column;

        .iconsContainer {
            display: flex;
            flex-direction: row;
            gap: 7px;
            flex-wrap: wrap;
        }
    }
}
