@import '../../../../variables';

.tableTitle {
    color: $subtext-color;
    font-size: 14px;
    font-family: $main-Font;
}

.container {
    :global(.MuiToolbar-gutters) {
        padding-left: 0;
        padding-right: 0;
    }
}
