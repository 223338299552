@import '../../variables';
.container {
    height: 20px;
    width: 20px;
    position: relative;
    z-index: 5;
    .button {
        cursor: pointer;
    }

    .pickerWrapper {
        position: absolute;
        overflow: hidden;
        z-index: 5;
        height: 0;
        width: 0;
        transition: height 0.2s ease-in-out, width 0.2s ease-in-out;

        &.isOpen {
            height: 320px;
            width: 280px;
            transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
            &.withValue {
                height: 346px;
            }
        }

        .removeButton {
            height: 24px;
            width: 278px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: 600;
            border: 1px solid $separator-color;
            color: $placeholder-editor-color;
            background-color: $selected-item-background;
            z-index: 6;
        }
    }
}
