.dateFieldAccountDetails {
    margin-top: 19px;

    input {
        font-size: 12px;
    }
}

.richTextEdit {
    width: 400px;
}

.dialogContent {
    padding: 0 20px 20px 20px;
}

.dialogHeader {
    border-bottom: unset;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.closeIcon {
    height: 12px;
    width: 12px;
}

.input {
    margin-left: unset;
}

.editButton {
    margin-left: -10px !important;
}

.inputEditMode {
    width: 180px !important;
}