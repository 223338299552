@import '../../../../variables';

.toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0 !important;
    margin-bottom: 10px;
    margin-top: 10px;
    max-height: 40px;
}

.tableToolbarRoot {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: unset !important; // Override Material-UI
}

.syncIcon {
    width: 18px;
    height: 18px;
    fill: $sync-icon-table-widget;
}

.sync {
    cursor: pointer;
    margin-left: 20px;
}

.sync {
    &.updating {
        animation: rotation 1s infinite ease;
    }
}
