@import '../../variables';

.collapseHeader {
    display: flex;
    cursor: pointer;
}

.content {
    margin-left: 10px;
}

.subtitle{
    font-size: 12px;
    color: $subtext-light-grey;
    font-weight: normal;
    padding-left: 10px;
}