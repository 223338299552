@import '../../../variables';

.permissionsError {
    display: flex;
    font-size: 12px;
    color: $error-message-color;
    text-align: left;
}

.errorText {
    width: 75%;
}

.reconnectBtn {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    height: 100%;
    align-items: flex-end;
}
