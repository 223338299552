@import '../../../variables';

.header {
    font-size: 16px;
    font-weight: 600;
}

.content {
    width: 550px;
}

.dialogContent {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .subTitle {
        font-size: 14px;
    }
}

.container {
    margin-top: 20px;
}

.manageKeysLink {
    color: $primary-color;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
}

.rightButton {
    background-color: $main-color;
}

.noKeyOption {
    border: green;
    color: green;
}
