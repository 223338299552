@import '../../variables';

.container {
    background-image: url('../../assets/images/4.png');
    background-repeat: repeat-y;
    background-size: cover;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 67px);
    overflow: auto;
    @media (max-width: $mobile) {
        padding-bottom: 170px;
        height: fit-content;
    }
}

.tracker {
    margin-top: 30px;
}
.title {
    @include pageTitle();
    font-family: $secondary-font;
    margin-top: 70px;
    @media (max-width: $mobile) {
        margin-top: 40px;
        .container {
            padding-bottom: 30px;
        }
    }
}

.semiTitle {
    @include pageTitle();
    font-family: $main-Font;
    margin-top: 70px;
    font-weight: bold;
    @media (max-width: $mobile) {
        margin-top: 40px;
        .container {
            padding-bottom: 30px;
        }
    }
}
