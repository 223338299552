.updateFieldContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  margin-left: unset;
  width: 100%;
  gap: 10px;

  .inputContainer {
    margin-left: unset;
  }

  .inputContent {
    margin-left: unset;
  }

  .refreshHub {
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
    font-size: 14px;

    .icon {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}