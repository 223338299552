@import '../../../variables';

.button {
    margin-left: 5px;
    &.disabled {
        cursor: default;
    }
    &.withLabel {
        border-radius: 5px;
        min-width: 0;
        margin-left: 0;
        height: 33px;
        width: fit-content;
        &:hover {
            background: $separator-color;
            transition: background-color 0.2s ease-in;
        }
    }
}

.labelWrapper {
    justify-content: flex-start;
    max-width: 160px;
    width: 100%;
    gap: 8px;
    display: flex;
    overflow: hidden;
    min-width: 0;
    margin: 3px 5px 0 5px;
    align-items: center;
    > * {
        flex-shrink: 0;
    }

    .label {
        min-width: 0;
        max-width: 100px;
        width: 100%;
        overflow: hidden;
        display: inline-block;
        @include ellipsis();
    }
}

.labelTooltip {
    max-height: 20px;
}

.specificOwnerButton {
    display: inline-flex;
    align-items: center;
    min-width: fit-content;
    div,
    span {
        min-width: fit-content;
        width: initial;
    }
    > div {
        min-width: 40px;
    }
}
