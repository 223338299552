@import './variables';
@font-face {
    font-family: 'Quincy CF';
    src: url('assets/Connary Fagen - Quincy CF Regular.otf') format('opentype');
}
@font-face {
    font-family: 'OggRoman';
    src: local('OggRoman'), url('./fonts/Ogg-Roman.ttf') format('truetype');
}
html,
body,
#root,
.App {
    height: 100%;
}

body {
    margin: 0;
    font-family: $main-Font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.systemFont {
    font-family: $main-Font;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.opacity07 {
    opacity: 0.7;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-track {
    margin: 5px 0px;
    transition: background-color 1000ms ease-in-out;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
}

div::-webkit-scrollbar-track {
    background-color: transparent;
}

div:hover::-webkit-scrollbar-track {
    background-color: transparent;
}

div::-webkit-scrollbar-thumb {
    background-color: transparent;
}

div:hover::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
}

textarea::-webkit-scrollbar-track {
    background-color: transparent;
}

textarea:hover::-webkit-scrollbar-track {
    background-color: transparent;
}

textarea::-webkit-scrollbar-thumb {
    background-color: transparent;
}

textarea:hover::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
}

/************************************** standard splitted screen to left panel and right content - BEGIN *********************************/

// please avoid using this class, instead use 'configurationPanel' mixin
.leftPanelContainer {
    width: 345px;
    min-width: 345px;
    position: sticky;
    @include configurationPanel();
}

.leftPanelContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 67px);
    top: 67px;
    align-items: center;
}

.rightPanelContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 25px 25px 25px;
    position: relative;
    max-width: calc(100% - 395px);
}

.leftPanel {
    min-width: 304px;
    width: 304px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 67px);
    top: 67px;
    position: sticky;
    padding-left: 27px;
    z-index: 100;
    margin-right: 28px;
}

.rightPanel {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 20px;
    position: relative;
    overflow: hidden;
}

.flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flex-column-center {
    @extend .flex-column;
    justify-content: center;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.flex-row-end {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.flex-spaceBetween {
    justify-content: space-between;
}

/************************************** standard splitted screen to left panel and right content - END   *********************************/

.spaceFromSideBar {
    padding-left: 27px;
}

h1 {
    font-size: 50px;
}

h3 {
    font-size: 21px;
}

h4 {
    font-family: $main-Font;
    font-weight: bold;
    font-size: 18px;
}

input {
    font-family: inherit;
}

input:focus {
    outline: none;
}

textarea {
    resize: none;
    outline: none;
    overflow: hidden;
    font-family: $main-Font;
}

.divider {
    border-left: 1px solid #e9e8ed;
    margin-right: 28px;
    height: 19px;
}

.pointer {
    cursor: pointer;
}

.grab {
    cursor: grab;
}

.hidden {
    visibility: hidden;
}

.hidden-display {
    display: none;
}

.opacityHidden {
    opacity: 0;
}

.heightHidden {
    height: 0px;
}

.H2_Onboarding_Badges {
    font-size: 20px;
    line-height: 1;
    color: #000000;
}

.H1_Onboarding_Title {
    font-family: $title-Font;
    font-size: 60px;
    text-align: center;
    color: #000000;
    margin-top: 70px;
}

.Onb_PrimaryButtonXL_Label {
    font-size: 20px;
    color: #ffffff;
}

.PrimaryandSecondaryButtons_S_Label {
    font-size: 14px;
    color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .H1_Onboarding_Title {
        font-family: $title-Font;
        font-size: 34px;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        margin-top: 60px;
        padding: 0 20px;
    }
}

.H3_Onboarding_Text {
    font-size: 16px;
    color: #000000;
}

.Error-Message {
    font-size: 10px;
    font-weight: 600;
    color: #ee3543;
}

.Warning-Color {
    color: #ee3543;
}

.H4_Onb_TextField_Regular {
    font-size: 20px;
    color: #000000;
}

.H6_Onb_TextField_Input {
    font-size: 18px;
    color: #000000;
}

.H5_Onb_TextField_SelectedLabel {
    /* opacity: 0.7; */
    font-size: 12px;
    color: #000000;
}

.H10_AccountHeader {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #000000;
}

.H11_AccountStatus {
    font-size: 12px;
    color: #000000;
}

.AccountDetails_Title {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
}

.Special_Loading_L {
    font-family: $title-Font;
    font-size: 74px;
    color: #000000;
}

.Special_Loading_S {
    font-family: $title-Font;
    font-size: 28px;
    font-weight: bold;
    color: #000000;
}

.H12_AccountDetails {
    font-size: 14px;
    color: #000000;
}

.H9_AccountName {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.WidgetTitle {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.TextField_App_Label_Writting {
    font-size: 10px;
    opacity: 0.7;
    color: #000000;
}

.H14_Tabs_Regular {
    font-size: 14px;
    color: #000000;
}

.WidgetMenuCTA_Primary {
    font-size: 12px;
}

.widgetHeader div.DraftEditor-root {
    min-height: 20px;
    background-color: white;
    border-radius: 8px;
}

.widgetSubtitle div.DraftEditor-root {
    min-height: 20px;
    background-color: white;
    border-radius: 8px;
}

.kpiWidget div.DraftEditor-root {
    min-height: 22px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
}

.milestone div.DraftEditor-root {
    min-height: 22px;
    background-color: white;
    overflow: unset;
    max-width: 120px;
    width: 120px;
}

.contact div.DraftEditor-root {
    min-height: 93px;
    background-color: white;
    border-radius: 8px;
}

.multiContacts div.DraftEditor-root {
    height: 100%;
    min-height: 93px;
    background-color: white;
    border-radius: 8px;
}

.survey div.DraftEditor-root {
    min-height: 60px;
}

.contact div.public-DraftEditor-content {
    max-height: 93px;
}

.Drop-down-link {
    font-size: 12px;
    font-weight: 600;
    color: $primary-color;
}

.milestone .public-DraftEditorPlaceholder-root {
    padding-left: 0;
    text-align: center;
    width: 100%;
    color: #000000;
    font-size: 14px;
}

.milestone .subtitle .public-DraftEditorPlaceholder-root {
    font-size: 12px;
    color: #545454;
}

.milestone .public-DraftEditor-content {
    text-align: center;
    padding-left: 0;
}

.customData div.DraftEditor-root {
    color: #000000;
    font-size: 14px;
    min-height: 22px;
}

.customData .public-DraftEditorPlaceholder-root {
    color: #919191;
}

.meetings div.DraftEditor-root {
    max-height: 100%;
    height: 100%;
}

.relatedNotes div.DraftEditor-root {
    max-height: 100%;
}
/*.kpiWidget .public-DraftEditorPlaceholder-root {*/

/*    position: absolute;*/

/*    left: 124px;*/

/*}*/

div.DraftEditor-root {
    max-height: 400px;
    min-height: 100px;
    min-height: 125px;
    overflow-y: auto;
    position: relative;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
    padding-left: 3px;
}

.public-DraftEditorPlaceholder-root {
    opacity: 0.7;
    position: absolute;
    padding-left: 5px;
}

.TextStyle {
    font-family: $title-Font;
    font-size: 28px;
    font-weight: bold;
    color: #000000;
}

.widget {
    padding: 25px 25px 25px;
    /* box-shadow: 0 2px 15px 0 rgba(162, 162, 162, 0.5); */
    background-color: $widget-container-background-color;
    height: fit-content;
    position: relative;
    border-radius: 6px;
    /* margin-bottom: 34px; */
}

.info {
    font-size: 12px;
    color: #000000;
}

.edit [class*='widget_']:not([class*='aiBorder_']) {
    border: solid 2px $primary-color !important;
}

.WidgetDescription {
    font-size: 12px;
    color: #000000;
}

.H8_Compliance {
    font-size: 12px;
    color: #5c5a5a;
}

.Title-Regular_Bold {
    font-family: $title-Font;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}

.WidgetMenuCTA_Secondary-Centered-3 {
    font-size: 14px;
    text-align: center;
    color: #000000;
}

.A_S {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.42;
    color: $primary-color;
}

.H1_Onboarding_Title_Mobile {
    font-family: $title-Font;
    font-size: 34px;
}

.H1_Onboarding_Title_Mobile-3 {
    font-family: $title-Font;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.54;
    letter-spacing: -0.2px;
    text-align: center;
    color: #000000;
}

.CustomerActionLink {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}

.MeetingDate {
    font-size: 12px;
    font-weight: 500;
    color: #545454;
}

.MuiSnackbar-anchorOriginTopRight {
    right: 40px !important;
}

.GoogleButton {
    font-family: 'Roboto';
}

@media (max-width: $mobile) {
    .widget {
        padding: 25px 20px 22px;
    }
}

li.dhx_menu-item--separator {
    display: none;
}
