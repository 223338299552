@import '../../../../variables';

.personaTag {
  font-family: $main-Font;
  font-size: 8px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
  height: 14px;

  svg {
    height: 8px;
    width: 8px;
  }

  span {
    display: inline-block;
    margin-left: 4px;
    padding-top: 1px;
  }
}