@import '../../variables';

.container {
    padding: 0 15px;
    height: 100%;

    .header {
        height: 46px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $separator-color;
        margin-bottom: 5px;
        > span {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .title {
            @include title();
            font-weight: bold;
            font-size: 16px;
        }

        .actionItem {
            padding-left: 10px;
        }
    }
    .detachedOrder {
        @include description();
        border-bottom: 1px solid $separator-color;
        padding: 12px 0;
        margin-bottom: 5px;
        span {
            color: $primary-branded-color;
            &:hover {
                cursor: pointer;
            }
        }

        .infoIcon {
            margin-left: 5px;
        }
    }
}
