@import '../../variables';

$padding-width: 100px;

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: visible;
    @include hideScrollbar();
    height: 80px;
    padding-top: 20px;
    align-items: flex-start;
}

.stepsContainer {
    position: relative;
    display: flex;
    width: 100%;
    overflow: visible;
    justify-content: space-between;
    align-items: center;
}

@mixin progressBarLine {
    height: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: width 0.3s ease-in;
    background: $steps-line-color;
}

.progressBar {
    @include progressBarLine();
    z-index: 1;
    transition: width 0.3s ease-in;
    display: flex;
    justify-content: flex-end;

    &.background {
        z-index: 0;
        width: 100%;
    }
}

.centeredIconWrapper {
    overflow: visible;
    width: 0;
    height: 0;
    z-index: 2;
}

.currentStep {
    margin-left: -25px;
    margin-top: -25px;
    inset: 0;
    z-index: 2;
    animation: fadeInWithDelay 0.3s linear;
}

.doneStep {
    z-index: 2;
}

.notStartedStep {
    z-index: 2;
}

.arrow {
    margin-bottom: 22px;
}

.titleWrapper {
    z-index: 2;
}

.iconWrapper {
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepTitle {
    position: absolute;
    margin: auto;
    display: inline-block;
    width: 100%;
    max-height: 56px;
    text-overflow: ellipsis;
    overflow: hidden;
    top: 0;
}

.componentWithTooltip {
    position: relative;
}

.componentWithTitle {
    position: relative;

    .stepTitle {
        @include description();
        justify-content: center;
        margin-top: 28px;
        display: inline-block;
        width: 100%;
        text-align: center;

        &.bold {
            font-weight: 600;
        }

        &.faded {
            opacity: 0.7;
        }
    }
}

.stepsWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    height: 100px;
    > * {
        width: 100%;
    }
}
