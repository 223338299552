@import '../../variables';
@import '../../aiVariables';

[role="button"] {
    transition: background-color 0.2s ease-in;
}

.button_normal {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 37px;
    font-size: 14px;
    box-sizing: border-box;
    cursor: pointer;
    &.rounded {
        border-radius: 100px;
    }
    &.slightlyRounded {
        border-radius: 4px;
    }
    &.flatLeft {
        border-radius: 0 64px 64px 0;
    }
}

.button_large {
    @extend .button_normal;
    font-size: 20px;
    height: 50px;
}

.button_slim {
    @extend .button_normal;
    height: 24px;
}

.white_gray {
    background-color: #ffffff;
    color: black;
    border: solid 1px #dedede;
    &:hover {
        border: solid 1px #dedede;
        background-color: #ebebeb;
    }
}

.transparent_icon {
    min-width: 37px;
    max-width: 37px;
    background-color: transparent;
    color: black;
    &:hover {
        background-color: #ebebeb;
    }
}

.primary {
    background-color: $primary-color;
    color: white;
    &:hover {
        background-color: $primary-color;
    }
}

.icon {
    min-width: 37px;
    background-color: #ffffff;
    color: black;
    &:hover {
        background-color: #ebebeb;
    }
}

.gray {
    background-color: #edeced;
    border-radius: 12px;

    &:hover {
        background-color: $gray-action-button-menu-hover-color;
    }
}

.white {
    background-color: #ffffff;
    color: black;
    border: solid 1px black;
    &:hover {
        background-color: #ebebeb;
    }
    &:active {
        background-color: #dddddd;
    }
}

.black {
    background-color: black;
    color: white;
    border: solid 1px black;
    &:hover {
        background-color: #000000d9;
    }
    &:active {
        background-color: #000000b3;
    }
}
.purple {
    background-color: $main-color;
    color: white;
    border: solid 1px $main-color;
    &:hover {
        background-color: $main-color;
    }
    &:active {
        background-color: $main-color;
    }
}
.red {
    background-color: $delete-or-remove-color;
    color: white;
    border: solid 1px $delete-or-remove-color;
    &:hover {
        background-color: $delete-or-remove-color;
    }
    &:active {
        background-color: $delete-or-remove-color;
    }
}
.clear_with_pink_borders {
    border: solid 2px $primary-color;
    &:hover {
        background-color: #ebebeb;
    }
}

.clear_with_borders {
    border: solid 1px black;
    &:hover {
        background-color: #ebebeb;
    }
}

.pink {
    background-color: pink;
    color: white;
}

.pink_vivid {
    background-color: $primary-color;
    color: white;
}

.blue {
    background-color: #4285f4;
    color: white;
}

.ai {
    background-color: #edeced;
    transition: background-color 0.2s ease-in-out;

    path {
        fill: #000;
    }

    &:hover {
        @extend %AI_COLOR_BACKGROUND;
        color: white;

        path {
            fill: #fff;
        }
    }
}

.button:hover {
    opacity: 0.85;
    cursor: pointer;
}

.button:active {
    opacity: 0.7;
}

.button:disabled {
    opacity: 0.2;
}

.disabled {
    opacity: 0.2;
    // background-color: #000000;
    pointer-events: none;
}

.loading {
    pointer-events: none;
}

// .black {
//     border: 1px solid #f8c52c;
//     background-color: black;
//     width: 99px;
//     height: 25px;
// }
.yellowLabel {
    color: #f8c52c;
    font-size: 9px;
}

input[type='text']:disabled {
    background: #ffffff;
}

.submit {
    color: inherit;
    background-color: inherit;
    border-radius: inherit;
    font: inherit;
    cursor: inherit;
}
