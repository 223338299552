@import '../../../../variables';

.pinnedAssetsList {
  margin: 0 3px 0 5px;
  padding-bottom: 7px;
  border-bottom: 1px solid $separator-color;
}

.assetsList {
  margin: 0 3px 0 20px;
}

.groupedAssets {
  margin: 10px 0 0 -20px;
}

.groupedAssetsHeader {
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid $divider-color;
  color: $subtext-light-grey;
  padding-bottom: 4px;
  margin-top: 20px;
  margin-left: 5px;

  svg rect {
    fill: $subtext-light-grey;
  }
}

.groupedAssetsTitle {
  font-size: 12px;
}

.groupedAssetsContent {
  margin: 0;
}

// To align with rest of assets and have enough space for the selected indication
.groupedAssetsListMargin {
  margin-left: 20px;
}

.pinnedAssetsListMargin {
  margin-left: 15px;
}