@import '../../../../variables';

.addSection {
    padding-top: 15px;
    font-size: 14px;
    font-family: $add-field-kpi-font-family;
    cursor: pointer;
    color: $subtext-color;
    border-top: 1px solid $section-kpi-seperator;
}
