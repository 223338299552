@import '../../variables';

$WIDGET-BACKGROUND: #000;
$SECTION-BACKGROUND: #666;
$NOT-STARTED-TASK-BACKGROUND: $task-status-not-started-background;
$NOT-STARTED-TASK-FONT-COLOR: $task-status-not-started-color;
$OPEN-TASK-BACKGROUND: $task-status-in-progress-background;
$OPEN-TASK-FONT-COLOR: #1a8ec6;
$INTERNAL-TASK-BACKGROUND: $internal-data-background-color;
$INTERNAL-TASK-FONT_COLOR: $internal-data-border-color;
$COMPLETED-TASK-BACKGROUND: $task-status-completed-background;
$COMPLETED-TASK-FONT-COLOR: #0dab45;

.gantt {
  width: 100%;
  height: 100%;

  * {
    // --font-family is a variable set in the element style.
    font-family: var(--font-family);
    font-size: 12px;
  }
}

.ganttItem {
  border: none;
  border-radius: 5px;

  & > * {
    cursor: default;
  }

  [class*='gantt_task_content'] {
    @include ellipsis;
    font-size: 12px;
    font-weight: normal;
  };
}

.sectionItem {
  background-color: $SECTION-BACKGROUND;
}

.widgetItem {
  background-color: $WIDGET-BACKGROUND;
}

.notStartedTask {
  background-color: $NOT-STARTED-TASK-BACKGROUND;
  [class*='gantt_task_content'] {
    color: $NOT-STARTED-TASK-FONT-COLOR;
  }
}

.openTask {
  background-color: $OPEN-TASK-BACKGROUND;
  [class*='gantt_task_content'] {
    color: $OPEN-TASK-FONT-COLOR
  };
}

.completedTask {
  background-color: $COMPLETED-TASK-BACKGROUND;

  [class*='gantt_task_content'] {
    color: $COMPLETED-TASK-FONT-COLOR;
    text-decoration: line-through;
  }
}

.hiddenTask {
  background-color: $INTERNAL-TASK-BACKGROUND;
  border: 1px solid $INTERNAL-TASK-FONT_COLOR;
  [class*='gantt_task_content'] {
    color: #000;
  };
}

.completedGridTask {
  text-decoration: line-through;
  opacity: 0.2;
}