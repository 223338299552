.plus {
    margin: 0px 20px;
    opacity: 0.7;
    font-size: 22px;
    font-weight: 600;
}

.accountLogo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    min-width: 25px;
    border: 1px solid #e9e8ed;
    background-color: white;
    // position: absolute;
    &.hidden {
        opacity: 0.2;
    }
    &:hover:not(.customer) {
        opacity: 0.5;
        cursor: pointer;
    }
}

.edit {
    opacity: 0;
    position: relative;
    z-index: 4;
    margin-left: 7px;
    &:hover {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
    }
}
