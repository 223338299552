@import '../../../../../../variables';

$resource-background-color: #ffffff;

.container {
    display: grid;
    grid-template-columns: $shared-material-width-label $shared-material-width-body $shared-material-width-button;
    width: 100%;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07);
    height: auto;
    align-items: center;
    border-radius: 7px;
    text-align: left;
    min-width: 485px;
    flex: 1 1;
    min-height: 63px;
    cursor: pointer;
    background-color: $resource-background-color;

    @media (max-width: $mobile) {
        grid-template-columns: none;
        position: relative;
        grid-gap: 10px;
        padding: 15px;
        height: fit-content;
        width: fit-content;
        min-width: 90%;
        flex: auto;
        align-items: start;
    }

    .boxTitleViewMode {
        font-size: 14px;
        color: $text-color;
        font-weight: bold;

        @media (max-width: $mobile) {
            max-width: 75%;
        }
    }

    .resourceAction {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        .actionButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 4px;
            border: 1px solid;

            &.linkUrl {
                text-decoration: none;
                @include sharedWidgetBtn;

                @media (max-width: $mobile) {
                    margin-right: 5px;
                }
            }

            &.downloadFile {
                cursor: pointer;
                @include sharedWidgetBtn;

                @media (max-width: $mobile) {
                    margin-right: 5px;
                }
            }
        }

        @media (max-width: $mobile) {
            position: absolute;
            top: 13px;
            right: 17px;
            padding: 3px 0 3px 8px;
            justify-content: flex-end;
        }
    }
}

.details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
    width: 100%;
    word-break: break-all;

    @media (max-width: $mobile) {
        padding-top: 5px;
    }
}

.details:only-child {
    gap: unset;
}

.boxDescription {
    color: $share-material-description-color;
    font-size: 13px;
}