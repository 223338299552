@import '../../../../variables';

.testConnection {
    font-size: 14px;
    @include horizontalCenter();

    img {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $primary-color;
    font-weight: 600;
    width: 200px;
}

.success {
    color: $text-color;
}

.error {
    color: $error-message-color;
}

.statusContainer {
    font-size: 14px;
}
