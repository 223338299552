.textEditor {
    z-index: 2; // keep text editor's toolbar above text inputs
    :global(.DraftEditor-root) {
        min-height: 20px;
        height: fit-content;
    }
}

.text {
    font-size: 14px;
}
