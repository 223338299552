.csLoader {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    min-width: 50px;
}

.dark {
    color: #fff;
}

.light {
    color: #4e4e4e;
}

.innerLoader {
    width: inherit;
    text-align: center;
    min-width: 50px;

    & label {
        font-size: 20px;
        opacity: 0;
        display: inline-block;
    }

    & label:nth-child(6) {
        -webkit-animation: lol 3s infinite ease-in-out;
        animation: lol 3s infinite ease-in-out;
    }

    & label:nth-child(5) {
        -webkit-animation: lol 3s 100ms infinite ease-in-out;
        animation: lol 3s 100ms infinite ease-in-out;
    }

    & label:nth-child(4) {
        -webkit-animation: lol 3s 200ms infinite ease-in-out;
        animation: lol 3s 200ms infinite ease-in-out;
    }

    & label:nth-child(3) {
        -webkit-animation: lol 3s 300ms infinite ease-in-out;
        animation: lol 3s 300ms infinite ease-in-out;
    }

    & label:nth-child(2) {
        -webkit-animation: lol 3s 400ms infinite ease-in-out;
        animation: lol 3s 400ms infinite ease-in-out;
    }

    & label:nth-child(1) {
        -webkit-animation: lol 3s 500ms infinite ease-in-out;
        animation: lol 3s 500ms infinite ease-in-out;
    }
}

@keyframes lol {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(30px);
    }
}

@-webkit-keyframes lol {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-30px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(30px);
    }
}
