@import '../../../variables';

.headerContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  cursor: pointer;

  .title {
    font-size: 14px;
    color: $subtext-color;
  }
}

.borderHeader {
  border-bottom: 1px dashed #979797;
  padding-bottom: 20px;
}

.arrowIcon {
  transition: transform ease-in-out 300ms;
}

.expandedArrow {
  transform: rotate(0deg);
}

.collapsedRightArrow {
  transform: rotate(90deg);
}

.collapsedUpArrow {
  transform: rotate(180deg);
}

.collapsibleContentWrapper {
  display: flex;
  flex-direction: column;
}

.collapsed {
  display: none;
  animation: collapseAnimation ease-in-out 300ms;
}

.expanded {
  animation: expandAnimation ease-in-out 300ms;
}

$initial-margin-top: -300px;
@keyframes expandAnimation {
  0% {
    margin-top: $initial-margin-top;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes collapseAnimation {
  0% {
    margin-top: 0;
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    margin-top: $initial-margin-top;
    opacity: 0;
  }
}