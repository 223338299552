@import '../../../../variables';

.lastParagraph {
    padding-bottom: 40px;
}

.link {
    margin-top: 10px;
    display: block;
}

.switchBack {
    color: $primary-color;
    text-decoration: none;
    font-weight: 600;
}

.space {
    padding-left: 5px;
}

.rules {
    margin-top: 20px;
    margin-bottom: 20px;
}
