@import '../../../../variables';

.container {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 6px);
    height: fit-content;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 2px;

    @media (max-width: $mobile) {
        width: 100%;
    }

    &.internal {
        background: $internal-data-background-color;
    }

    .titleRow {
        display: flex;
        flex-direction: row;
        min-height: 32px;
        height: fit-content;

        width: 100%;

        gap: 6px;

        .wrappableOnSmallResolution {
            align-items: center;
            display: flex;
            width: 100%;
            @media (max-width: $laptop) {
                flex-wrap: wrap;
            }
        }

        .taskValue {
            display: flex;
            width: 100%;
            align-items: center;

            @media (max-width: $laptop) {
                &.multiStatus {
                    flex-wrap: wrap;
                }
                flex-shrink: 0;
            }

            .emojiWrapper {
                display: flex;
                align-items: center;
                padding: 0 3px;
                position: relative;
                top: 1px;
            }
        }

        .taskAdditionalData {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: fit-content;
            flex-shrink: 0;
            gap: 4px;

            @media (max-width: $laptop) {
                width: calc(100% - 66px);
                justify-content: flex-start;
                gap: 10px;
                padding-bottom: 5px;
                padding-left: 60px;
                height: auto;

                &.multiStatus {
                    padding-left: 145px;
                    width: calc(100% - 152px);
                }
            }

            @media (max-width: $mobile) {
                flex-wrap: wrap;
                width: calc(100% - 30px);
                justify-content: flex-start;
                padding-left: 0;
                &.multiStatus {
                    padding-left: 0;
                    width: 100%;
                }
            }
        }

        @media (max-width: $mobile) {
            display: block;
            width: calc(100% - 30px);
            align-items: start;
        }
    }

    .reorderIndicator {
        fill: $description-grey;
        cursor: pointer;
        width: 6px;
        margin-right: 5px;
    }

    .editActionButton {
        width: 13px;
        height: 16px;
        cursor: pointer;
        margin: 0 4px;
        @include taskActionButton();

        &.deleteIcon {
            @include deleteButton();
        }
    }

    .editItem,
    .editActionButton {
        opacity: 0;
        transition: opacity 0.2s ease-in;

        &.shouldShow {
            opacity: 1;
        }
    }

    &:hover {
        .editItem,
        .editActionButton {
            opacity: 1;
            transition: opacity 0.2s ease-in;
        }

        .additionalTaskActions {
            opacity: 1;
        }
    }

    [class*='commentsIcon'] {
        @include taskActionButton();
        height: 12px;
        width: 18px;
        padding: 6px 3px 2px 8px;

        @media (max-width: $mobile) {
            padding: 6px 3px 2px 8px;
        }
    }
}

.valueInput {
    padding: 0;
}

.taskActions {
    margin-top: 3px;
    display: flex;
    align-items: center;
    color: $text-color;
    @media (max-width: $laptop) {
        padding-top: 0;
    }
}

.taskActionsSpace {
    margin-top: 3px;
}

.taskTags {
    color: $text-color;
    height: 26px;
    margin-top: 2px;

    @media (max-width: $mobile) {
        margin-left: 28px;
    }
}

.separator {
    height: 16px;
    border-left: 1px $widget-container-border-color solid;
}

.taskOwnerContainer {
    display: contents;

    @media (max-width: $mobile) {
        display: flex;
    }
}

.taskOwnerWrapper {
    display: flex;
    align-items: center;

    &.editableOwner {
        @include taskActionButton();
    }

    height: 22px;
    width: 30px;
    margin: 0 5px;

    .taskOwner {
        height: 0; // remove hover circle background on task owner button
        margin: 0;
        padding: 0;
        width: fit-content;
        display: flex;

        img {
            width: 16px;
            min-width: 16px;
            height: 16px;
            min-height: 16px;
        }

        [class*='hiddenParticipantsCount'] {
            width: 16px;
            min-width: 16px;
            height: 16px;
            min-height: 16px;
            font-size: 8px;
            line-height: 14px;
        }
    }

    @media (max-width: $mobile) {
        margin-left: -11px;
    }
}

.actionButtonContainer {
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;

    &.actionButtonCustomer {
        margin-top: 0;
    }

    @media (max-width: $laptop) {
        margin-left: 5px;
    }

    .actionButton {
        margin: 0;

        div:first-of-type {
            min-height: 24px;
            height: 24px;
            min-width: 20px;
            width: fit-content;
            padding: 0 5px;
            margin: 0;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            flex-wrap: nowrap;
            white-space: nowrap;
            font-size: 14px;

            @media (max-width: $mobile) {
                [class*='buttonLabel'] {
                    max-width: 100%;
                }
            }
        }
    }
}

.taskStatus {
    display: contents;

    @media (max-width: $mobile) {
        display: block;

        [class*='statusMenu'] {
            margin-left: 30px;
        }
    }
}

.date {
    cursor: pointer;
    width: fit-content;
    margin-right: 5px;

    > div {
        max-height: 25px;
    }

    [class*='clearButton'] {
        top: 4px;
    }
}

.relativeDatesPreview,
.fixedDatesPreview {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    [class*='emptyLabel'] {
        min-width: 55px;
    }

    &.alignStartDate {
        [class*='datePreviewContainer'] {
            padding-right: 28px;
        }
    }
}

.relativeDatesPreview {
    margin-right: 10px;
}

.relativeDate {
    display: flex;
    gap: 7px;
    cursor: pointer;
    font-size: 14px;
}

.commentsButton {
    position: unset;
    visibility: visible;
    padding: 5px 7px 4px;
    margin: 0 5px 0 0;

    @media (max-width: $mobile) {
        position: absolute;
        right: -5px;
        top: 4px;
    }
}

.pointerCursor {
    cursor: pointer;
}

.completedTaskWithStrike {
    @include completedTaskTextWithStrike();
}

.completedTaskNoStyle {
    @include completedTaskTextNoStyle();
}

.dateClassNameCompletedTask {
    color: $text-color;
}

.collapseButton {
    height: 6px;
    width: 18px;
    padding: unset;
}

.linePrefix {
    width: 57px;
    padding-right: 5px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    height: 30px;

    @media (max-width: $mobile) {
        align-items: center;
        height: 32px;
        width: 40px;
    }

    .collapseButtonPlaceholder {
        min-height: 12px;
    }

    .collapseButtonContainer {
        position: relative;
        top: -2px;
    }

    &.subTask {
        width: unset;
    }

    &.multiStatus {
        width: 143px;
        @media (max-width: $mobile) {
            width: 30px;
        }
    }
}

.createdByTaskIndication {
    position: absolute;
    right: -6px;
    top: 5px;
}

.customerEdit {
    margin-left: -10px;
    padding-right: 10px;
}

.datesSeparator {
    padding: 3px 6px 5px;
}

.inputContainer {
    position: relative;
    display: flex;
    width: 100%;

    @media (max-width: $mobile) {
        width: calc(100% - 40px);

        [class*='displayValue'] {
            width: calc(100% - 15px);
            flex-wrap: wrap;
            line-height: 32px;
            gap: 5px;
            padding-top: 0;
            @media (max-width: $laptop) {
                > :first-child {
                    width: 100%;
                    display: flex;
                }
                gap: 0;
                grid-gap: 0;
            }

            @media (max-width: $mobile) {
                > :first-child {
                    line-height: 20px;
                    padding: 6px 0;
                }
            }
        }
    }

    .expandIcon {
        position: absolute;
        right: 3px;
        top: 3px;
        padding: 5px;
        height: 15px;
        width: 15px;
        @include taskActionButton();
    }
}

.inlineTaskActions {
    display: flex;
    align-items: center;
    padding: 0 5px;
    z-index: 5;
    align-self: flex-start;

    @media (max-width: $mobile) {
        align-self: baseline;
        padding-top: 2px;
        padding-left: 0;
    }
}

@mixin elementsBelowTask {
    margin-bottom: 5px;

    @media (max-width: $mobile) {
        margin-left: 47px;
        width: calc(100% - 87px);
    }
}

@mixin elementsBelowTaskCheckbox {
    @include elementsBelowTask();
    margin-left: 60px;
    width: calc(100% - 160px);
}

@mixin elementsBelowTaskMultiStatus {
    @include elementsBelowTask();
    margin-left: 145px;
    width: calc(100% - 302px);
}

.descriptionWrapper {
    @include elementsBelowTaskCheckbox();

    &.multiStatusDescription {
        @include elementsBelowTaskMultiStatus();
    }

    &.editable {
        cursor: pointer;

        &:first-child {
            padding: 0 1px;
            border: 1px solid transparent;
        }

        &:hover {
            &:first-child {
                border: 1px $separator-color dashed;
            }
        }
    }
}

.robotIconTooltip {
    margin-top: 7px;
}

.robotIconWrapper {
    padding-top: 2px;
}

.robotIcon {
    width: 21px;
    margin-top: -3px;
    height: 18px;
    flex-shrink: 0;
    padding: 0 5px;
}

.mobileActionsRow {
    display: flex;
    align-items: center;
    margin-left: 47px;

    justify-content: space-between;
    width: calc(100% - 15px);
    flex-shrink: 0;

    .mobileTaskOwnerAndTags {
        display: flex;
        align-items: center;
    }
}

.mobileActionsRowRevert {
    flex-direction: row-reverse;
}

.taskDescriptionMobile {
    color: $share-material-description-color;
}

.statusAndTitleLine {
    display: flex;
    width: 100%;
    align-items: baseline;
}

.hide {
    display: none;
}

.description {
    padding: 0 5px 5px 5px;
}

.textSuffix {
    height: 100%;
}

.editBox,
.taskTitle {
    min-height: 25px;
}

.insightsContainer {
    align-self: flex-start;
}

.additionalTaskActionsAligner {
    width: 35px;
}

.animate {
    transition: background 0.3s;
}