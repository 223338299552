@import '../../variables';

.navigationButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0 60px;

    & .button {
        cursor: pointer;
        display: flex;
        gap: 15px;
        align-items: center;
        fill: $placeholder-color;
    }
}

.rtl {
    flex-direction: row-reverse;
}

.prefix {
    color: $navigation-buttons-prefix-color;
}

.buttonLabel {
    display: flex;
    gap: 10px;
    align-items: center;
}

.navigationLabel {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
}

.arrowButton {
    width: 14px;
    height: 14px;
    border: 1px solid transparent;
    border-radius: 20px;
}

.previousButton {
    transform: rotate(180deg);
}

.borderBetweenButtons {
    border-bottom: 1px solid $widget-container-border-color;
    flex: 1;
}

.buttons {
    padding: 6px 15px;
    border: 1px solid $steps-line-color;
}

.arrow-buttons {
    border: none;
}

.arrow-textAndArrow,
.arrow-bottomBorder {
    padding: 6px;
    border: 1px solid $widget-container-border-color;
}

@media only screen and (max-width: $mobile) {
    .navigationButtons {
        padding: 0 0 30px;
    }

    .arrowButton {
        width: 20px;
        height: 20px;
    }

    .buttonLabel {
        display: none;
    }

    .borderBetweenButtons {
        border: none;
    }
}
