@import '../../variables';
.headerOnboarding {
    height: 65px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid #ebebeb;
}

.logIn {
    font-size: 16px;
    font-weight: 600;
    margin-left: 7px;
}
.logIn a:hover {
    text-decoration: none;
}
.logIn a {
    color: $primary-color;
    text-decoration: none;
}

.logo {
    line-height: 1;
    flex-grow: 2;
}

@media only screen and (max-width: 600px) {
    .alreadyHaveAccount {
        visibility: hidden;
        display: none;
    }
}

.loginEUButton {
    background-color: $login-button-color;
    height: 37px;
    margin-left: 20px;
    
    .loginEULabelButton {
        font-size: 14px;
        text-align: center;
        display: block;
    }
}
