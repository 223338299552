@import '../../variables';

.container {
    input {
        z-index: 1;
        background: none;
    }

    .checkbox {
        width: 17px;
        height: 17px;
        border-radius: 3px;
        border: solid 1px #cccccc;
        background-color: #ffffff;
        position: absolute;

        &:hover {
            border: solid 1px $textbox-hover-border-color;
        }
    }
}

.disabled {
    pointer-events: none;
}

.checkbox:after {
    content: '';
    position: absolute;
    display: none;
}

.container input:checked ~ .checkbox:after {
    display: block;
}

.container .checkbox:after {
    left: 6px;
    top: 0;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(42deg);
}

.container input:checked ~ .checkbox {
    border-radius: 3px;
    opacity: 1;
    color: white;
}

.container {
    display: block;
    position: relative;
    cursor: pointer;
    clear: both;
    width: 18px;
    height: 18px;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    color: white;
}
