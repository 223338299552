@import '../../variables';

.grouping {
  display: flex;
  align-items: center;
  gap: 10px;
}

.groupingHeadline {
  @include description();
}