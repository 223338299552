@import '../../../variables';

.anchor {
    @include inheritWrappedStyle();
    position: relative;

    .negativeTopOffset {
        position: absolute;
        top: -10px;
    }

    .highlightOverlay {
        position: absolute;
        inset: 2px 4px;
        margin: auto;
        opacity: 0;
        transition: opacity 0.5s ease-in, inset 0.5s ease-in;
        pointer-events: none;
        border-radius: 3px;
        padding: 3px 2px;
        z-index: 1000;
        &.visible {
            inset: -2px 1px;
            transition: opacity 0.4s ease-in, inset 0.2s ease-in;
            opacity: 0.2;
        }
    }
}
