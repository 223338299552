@import '../../../variables';

$row-background-color: #f8f9fb;
$upcoming-row-background-color: #f1ebff;
$laptop: 1439px;

.row {
  display: flex;
  padding: 9px 10px;
  background-color: $row-background-color;
  border-radius: 6px;
  margin-bottom: 4px;
  align-items: center;

  @media (max-width: $laptop) {
    padding: 9px 8px;
  }

  @media (max-width: $mobile) {
    padding: 9px 20px 9px 8px;
  }

  &.upcomingMeeting {
    background-color: $upcoming-row-background-color;
  }
}

.accountLogoColumn {
  height: fit-content;
  margin-right: 15px;
  min-width: 40px;

  @media (max-width: $laptop) {
    margin-right: 10px;
  }
}

.infoColumn {
  min-width: 255px;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  margin: 0 15px 5px 0;

  &.isRoomy {
    min-width: 45%;
  }

  @media (max-width: $medium-laptop) {
    min-width: 180px;
    margin-right: 0;
  }

  @media (max-width: $mobile) {
    min-width: 170px;
  }

  .upcomingTitle {
    margin-bottom: 4px;
    color: $main-color;
    font-size: 10px;
    font-weight: bold;
  }

  .meetingTitle {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 4px;

    .recurring {
      margin-left: 10px;
    }
  }

  .dateContainer {
    display: flex;
    color: $subtext-grey;
    font-size: 12px;


    @media (max-width: $medium-laptop) {
      flex-direction: column;
    }


    [class*="houresContainer"] {
      align-items: center;

      svg {
        path {
          stroke: $main-color;
        }
      }
    }
  }
}

.participantsColumn {
  width: 100%;
  margin-right: 15px;

  @media (max-width: $laptop) {
    margin-right: 8px;
  }
}

.videoColumn {
  width: fit-content;
  margin-right: 20px;

  &.recording {
    margin-right: 6px;
  }

  @media (max-width: $laptop) {
    display: none;
  }
}

.actionsColumn {

  .actionButton {
    height: 26px;
    padding: 0 9.5px;

    &.clear {
      border-color: $main-color;
      color: $main-color;

      &:hover {
        background-color: unset;
      }

      svg {
        path {
          stroke: $main-color;
        }
      }
    }
  }
}

@mixin logo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: solid 1px $separator-color;
}

.upcomingMeeting {
  .accountLogo, .defaultAccountLogo {
    border: solid 2px $main-color;
  }
}

.accountLogo {
  background-color: white;
  @include logo;
}

.defaultAccountLogo {
  @include logo;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  font-size: 27px;
}