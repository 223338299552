@import '../../../variables';
@import '../../../aiVariables';

.loadingDialog {
    height: 200px;
    width: 400px;
}

.loadingDialogHeader {
    border: none;
    height: 15px;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: calc(100% - 40px);
    padding: 0 30px;
    box-sizing: border-box;
    overflow: hidden;
}

.starsAnimation {
    width: 70px;
    object-fit: contain;
}

.loadingText {
    font-size: 14px;
    color: $subtext-light-grey;
}

.loader {
    border: 1px solid #eaeaea;
    border-radius: 100px;
    width: 100%;
    height: 10px;
    display: flex;
    background-color: #fff;
    overflow: hidden;

    .progress {
        background-color: $main-color;
        background-image: -moz-linear-gradient(-45deg,
                rgba(0, 0, 0, 0.3) 0px,
                rgba(0, 0, 0, 0.3) 20px,
                rgba(0, 0, 0, 0.0) 20px,
                rgba(0, 0, 0, 0.0) 40px,
                rgba(0, 0, 0, 0.3) 40px,
                rgba(0, 0, 0, 0.3) 60px); /* FF3.6-15 */
        background-image: -webkit-linear-gradient(-45deg,
                rgba(0, 0, 0, 0.3) 0px,
                rgba(0, 0, 0, 0.3) 20px,
                rgba(0, 0, 0, 0.0) 20px,
                rgba(0, 0, 0, 0.0) 40px,
                rgba(0, 0, 0, 0.3) 40px,
                rgba(0, 0, 0, 0.3) 60px); /* Chrome10-25,Safari5.1-6 */
        background-image: linear-gradient(135deg,
                rgba(0, 0, 0, 0.3) 0px,
                rgba(0, 0, 0, 0.3) 20px,
                rgba(0, 0, 0, 0.0) 20px,
                rgba(0, 0, 0, 0.0) 40px,
                rgba(0, 0, 0, 0.3) 40px,
                rgba(0, 0, 0, 0.3) 60px); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        background-size: 60px 20px;
        animation: animatedBackground 40s linear infinite;
        border-radius: 100px;
        transition: width 0.4s ease-in;
    }
}

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -2000px 0;
    }
}