@import '../../../variables';

.container {
    @include horizontalCenter();
    height: fit-content;
    padding-top: 118px;
}

.view {
    display: flex;
    flex-direction: column;
    width: 100%;
}
