.container {
    display: flex;
    flex: 100%;
    flex-direction: column;
    height: 100%;

    .content {
        display: flex;
        margin: 30px auto 0;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
}
