.container {
  cursor: pointer;

  &:hover {
    .pinHover {
      display: block;
    }
    .pinIcon {
      display: none;
    }
  }
}

.pinHover {
  display: none;
}
