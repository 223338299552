@import '../../variables';

.container {
    position: absolute;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background: $widget-container-background-color;
    z-index: 1000;
    overflow: hidden;

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 30%;

        .title {
            @include onboardingTitle();
            font-size: 30px;
            font-weight: bold;
            width: 370px;
            margin-bottom: 10px;
        }

        .subtitle {
            font-size: 14px;
            text-align: center;
        }
    }
}
