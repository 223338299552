.ldsRipple {
    display: inline-block;
    position: relative;
}

.ldsRipple div {
    position: absolute;
    border: 4px solid rgb(192, 190, 190);
    opacity: 1;
    border-radius: 50%;
    animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldsRipple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes ldsRipple {
    0% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
