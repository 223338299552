.container {
    display: flex;
    height: inherit;
    flex: 1;

    .iframeContaienr {
        overflow: hidden;
        height: inherit;
        flex: 1;
    }

    .iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
