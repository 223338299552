@import '../../../../../variables';

.previewBtn {
    height: 33px;
    padding: 15px;

    @media (max-width: $small-laptop) {
        padding: 10px;
    }
}

.previewButtonLabel {
    @include textualButtonLabel;

    @media (max-width: $small-laptop) {
        .label {
            display: none;
        }
    }
}
