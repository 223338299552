@import '../../../../../variables';

.container {
    display: flex;
    flex-direction: row;

    .title {
        flex: 1 1 max-content;
        max-width: 40%;
    }

    .value {
        font-size: 14px;
        display: flex;
        text-align: start;
        justify-content: space-between;
        word-break: break-word;
    }
}

.editIcon {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.emptyStateContainer {
    display: flex;
    min-width: 300px;
    max-width: 300px;
}

.emptyStateDesc {
    font-size: 14px;
}