@import '../../variables';
.button {
    border-radius: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 200px;
    height: 50px;
    outline: 0;
    font-family: $main-Font;
}

//.button:hover {
//    opacity: 0.85;
//    cursor: pointer;
//}
//.button:active {
//    opacity: 0.7;
//}
//
//.button:disabled {
//    opacity: 0.2
//}
.pink {
    background-color: pink;
}

.black {
    border: 1px solid #f8c52c;
    background-color: black;
    width: 99px;
    height: 25px;
}

.yellowLabel {
    color: #f8c52c;
    font-size: 9px;
}

.boxInner {
    padding: 15px 25px 30px;
    text-align: left;
    font-size: 14px;
    line-height: 1.5;
    color: #4f4f4f;
}

.boxTop {
    position: relative;
    z-index: 0;
}

.dotmenu {
    position: absolute;
    z-index: 0;
    right: -19px;
    top: -20px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background: url(../../assets/icons/icon-menu.svg) center top -1px / 20px auto no-repeat;
}

.dotmenu:hover {
    background-color: #eee;
}

.boxRow {
    margin: 10px 0 0;
}

.txtInput {
    border: 1px solid #e4e4e4;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    color: #545454;
    // transition: all .3s ease-in-out;
}

//.txtInput:focus {
//    border-style: dashed;
//    border-color: #ccc;
//}
textarea.txtInput {
    //height: auto;
    //resize: none;
    //transition: box-shadow 0.5s ease;
    //display: block;
}

.boxTitle {
    //font-size: 14px;
    font-weight: 500;
    color: #0e0e0e;
    padding: 0;
    margin: 0;
}

.textareaFontSize {
    font-size: 14px;
}

.white {
    border: 1px solid #000;
    border-radius: 64px;
    width: auto;
    height: 37px;
    padding: 5px 20px;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: normal;
    transition: all 0.3s ease-in-out;
}

//.white:hover {
//    background-color: #000000;
//    color: #fff;
//}
.btncol {
    flex: 3;
    display: flex;
    justify-content: flex-end;
}

.btncolRight {
    margin: 0 0 0 15px;
}

.filesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bottomMargin {
    margin-bottom: 0px;
    font-size: 10px;
    font-family: $main-Font;
}

.boxContent {
    padding: 0px 15px 0;
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    margin: 10px 0 0;
}

.boxContentViewMode {
    border-radius: 6px;
    margin: 10px 0 16px;
}

.boxMiddle {
    display: flex;
    text-align: right;
    overflow: hidden;
    border-bottom: 2px solid #d9d9d9;
    padding: 10px 0 10px;
}

.linkbox {
    overflow: hidden;
    position: relative;
    z-index: 0;
    padding: 0 100px 0 0;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    min-width: 100%;

    &.fullWidth {
        padding: 0;
    }
}

.txtlink {
    width: 100%;
    height: 37px;
    box-sizing: border-box;
    border: 2px solid #d9d9d9;
    border-radius: 64px 0 0 64px;
    float: left;
    padding: 5px 10px 5px 15px;
    font-family: $main-Font;

    &.fullWidth {
        border-radius: 64px;
    }
}

.addCodeContainer {
    position: absolute;
    right: 0;
    top: 0;
}

.boxBottom {
    clear: both;
    padding: 0px 0 0;
    text-align: center;
    font-family: $main-Font;
}

.descriptionTitle {
    font-size: 14px;
    color: #000;
    font-family: $main-Font;
}

.logosExamples {
    margin-bottom: 30px;
    margin-top: 10px;
}

.embedContainer {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: center;
    width: inherit;
}

.icembedContent {
    padding: 45px 0 0;
    background: url(../../assets/icons/icon-embed-content.svg) center top/auto 35px no-repeat;
    margin: 26px 0 0;
}

.dropDownMenu {
    position: absolute;
    left: 157px;
    top: 10px;
}

.boxFoot {
    background-color: #f9f2d8;
    color: #000;
    padding: 5px 15px;
    //margin: 30px 0 0;
    border-radius: 0 0 6px 6px;
    //margin: 15px -17px -17px;
    margin: 30px -18px -2px;
    box-sizing: border-box;
    font-family: $main-Font;
    font-size: 12px;
}

.colrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -6px 15px;
}

.colwidget {
    text-align: left;
    font-size: 13px;
    max-width: 224px;
    margin: 0 6px 12px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    padding: 20px 10px 10px;
    border-top: 10px solid #94dbe4;
    position: relative;
    z-index: 0;
    margin-top: 7px;
}

.linklgnd {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    text-transform: uppercase;
    background: #333;
    height: 20px;
    padding: 0 5px;
    border-radius: 2px;
    text-decoration: none;
    display: block;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: -14px;
}

.linkLabel {
    background: #459939;
}

.fileLabel {
    background-color: #b3523d;
}

.linklgnd:hover {
    //background-color: #ed8795;
}

.colwidget .dotmenu {
    top: 0px;
    right: 8px;
    background-color: #fff;
    z-index: 20;
}

.colwidget .dotmenu:hover {
    background-color: #fff;
}

.colwidget .boxRow {
    margin-bottom: 15px;
    color: #545454;
}

.colwidget .boxTitle {
    color: #545454;
    //font-size: 14px;
    padding: 0 5px;
    font-weight: 500;
    margin: 0;
    line-height: 1.25;
}

.colwidget .mtitle::after {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background: #ededed;
    margin: 20px 0 -5px;
}

.colwidget .txtDescription {
    font-size: 13px;
    line-height: 1.5;
}

.linktxt {
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 153px;
    font-family: $main-Font;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 153px;
}

.borderBox {
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    width: 153px;
}

//.optionalItem {
//    white-space: nowrap;
//    overflow: hidden;
//    text-overflow: ellipsis;
//    width: 153px;
//    /* align-self: flex-start;
//    width: 70px; */
//}
.authorName {
    font-size: 11px;
    color: #878787;
    margin: 10px 0 10px;
    display: block;
}

.ibxwidover {
    background: rgba(0, 200, 169, 0.8);
    position: absolute;
    z-index: 5;
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.iconImg {
    background: url(../../assets/icons/icon-image.svg) left center no-repeat;
    padding: 0 0 0 25px;
}

@media only screen and (max-width: 1200px) {
    .colwidget {
        max-width: 30%;
    }
}

@media only screen and (max-width: 1500px) {
    //.boxInner {
    //    padding-left: 15px;
    //    padding-right: 15px;
    //}
    textarea.txtInput {
        //min-height: 65px;
    }
    .boxMiddle {
        //text-align: center;
        //display: flex;
        //flex-direction: column-reverse;
        //align-items: center;
    }
    .btncol {
        //float: none;
        //margin: 0 0 15px 0;
        //width: 100%;
        //display: block;
    }
    .boxBottom {
        padding-bottom: 0;
    }
    //.btnupload {
    //    margin: 0 auto;
    //}
    .colwidget {
        // max-width: 47%;
    }
}

@media only screen and (max-width: $mobile) {
    .colwidget {
        max-width: 100%;
    }
}

.container iframe,
.embedContainer iframe {
    max-width: 100%;
}

.container.pdf iframe {
    zoom: 1.6;
}
