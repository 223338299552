@import '../../../../variables';

.templatePermissionsContainer {
    @include description(14px);
    margin-top: 30px;
}

.row {
    margin-top: 10px;

    .templatePermissions {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.permissionLabel {
    @include description(14px);
}