.dataSourcesCombo {
    margin-top: 10px;
}

.learnMore {
    color: #0b3ff2;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
}
