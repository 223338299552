@import '../../variables';

.imageUploaderContainer {
    margin-top: 20px;
    flex-grow: 1;
    min-width: 48%;
}

.hasImage {
    opacity: 0;
    &:hover {
        opacity: 1;
    }
}

.empty {
    .overlay {
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }
}

.uploaderText {
    margin-top: 11px;
    font-family: $main-Font;
    font-size: 12px;
    span {
        background: white;
        width: fit-content;
        height: 20px;
        border-radius: 2px;
        padding: 2px 5px;
        margin: -2px 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageUploader {
    min-height: 155px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    position: relative;

    &:hover {
        .upload {
            opacity: 1;
        }
        .overlay {
            background-color: white;
            background-color: rgba(11, 63, 242, 0.3);
            border-radius: 3px;
            border: solid 2px $primary-color;
            opacity: 1;
            margin-top: -4px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 10;
}

.overlay {
    position: absolute;
    background-color: white;
    background-color: rgba(11, 63, 242, 0.3);
    border-radius: 3px;
    border: solid 2px $primary-color;
    opacity: 1;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
}
