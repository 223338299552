@import '../../../variables';

.labeledCheckbox {
    display: flex;
    gap: 11px;
    font-size: 12px;
    color: $subtext-grey;
    padding: 7px 0;
}

.countLabel {
    font-size: 12px;
    color: $subtext-grey;
}

.freezeCountInput {
    padding-left: 30px;
}

.description {
    @include description();
}

.title {
    @include title();
    font-size: 14px;
    margin-bottom: 10px;
}

.layoutConfig {
    margin-top: 14px;
}

.userSettingsConfig {
    padding-top: 30px;
}
