@import '../../../variables';

$NEVER-ACCESSED: #8e969d;
$OVER-60-DAYS: #f96c6a;
$OVER-30-DAYS: #ffda6c;
$LAST-30-DAYS: #8ee563;

$BATTERY-BORDER-COLOR: #333;
$BATTERY-BODY-BORDER-RADIUS: 15px;

@mixin batteryPart($color) {
    $lighterColor: lighten($color, 5%);
    background: repeating-linear-gradient(35deg, $color, $color 10px, $lighterColor 10px, $lighterColor 20px);
}

@mixin accountLogoBorder($color) {
    border: 2px solid $color;
}

.container {
    overflow: hidden;
}

.content {
    display: flex;
    gap: 30px;
    height: 100%;
    overflow: auto;
}

.skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeletonRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skeletonGroup {
    width: 30%;
    display: flex;
    gap: 20px;
}

// TABLE HEADER STYLES

.headerContainer {
    display: flex;
    align-items: center;
}

.header {
    padding: 0 5px;
    font-size: 14px;
    width: fit-content;
    border-radius: 2px;
}

.headerTooltip {
    white-space: break-spaces;
    text-align: center;
}

.infoIcon {
  margin-bottom: 1px;
  margin-left: 10px;
}

.neverAccessed {
    background-color: $NEVER-ACCESSED;
}

.over60Days {
    background-color: $OVER-60-DAYS;
}

.over30Days {
    background-color: $OVER-30-DAYS;
}

.last30Days {
    background-color: $LAST-30-DAYS;
}

.neverAccessed,
.over60Days {
    color: #fff;
}
.over30Days,
.last30Days {
    color: #000;
}

// TABLE STYLES

.table {
    margin-top: 10px;
    height: 92%;

    div {
        height: 95%;
    }
}
.headerRow > span {
    font-family: $main-Font;
}

.logo-neverAccessed {
    @include accountLogoBorder($NEVER-ACCESSED);
}

.logo-over60Days {
    @include accountLogoBorder($OVER-60-DAYS);
}

.logo-over30Days {
    @include accountLogoBorder($OVER-30-DAYS);
}

.logo-last30Days {
    @include accountLogoBorder($LAST-30-DAYS);
}

// BATTERY STYLES

.battery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
}

.accountNameColumn {
    @media (max-width: $laptop) {
        max-width: 180px;
    }

    [class*='Title'] {
        font-size: 12px;
    }
}

.enlargedAccountNameColumn {
    width: 180px;
    max-width: 180px;

    @media (min-width: $desktop) {
        width: 330px;
        max-width: 330px;
    }
}

.batteryTip {
    width: 33px;
    height: 12px;
    background-color: $BATTERY-BORDER-COLOR;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.batteryBody {
    height: calc(100% - 14px);
    width: 79px;
    border: 2px solid $BATTERY-BORDER-COLOR;
    border-radius: $BATTERY-BODY-BORDER-RADIUS;
    padding: 1px;

    display: flex;
    flex-direction: column;

    & .selected {
        border: 2px solid $main-color;
    }

    & > div {
        cursor: pointer;
        border: 2px solid white;

        &:hover {
            transition: opacity 0.1s ease-in-out;
            opacity: 0.85;
        }
    }

    & > div:first-of-type {
        border-top-left-radius: $BATTERY-BODY-BORDER-RADIUS;
        border-top-right-radius: $BATTERY-BODY-BORDER-RADIUS;
    }

    & > div:last-of-type {
        border-bottom-left-radius: $BATTERY-BODY-BORDER-RADIUS;
        border-bottom-right-radius: $BATTERY-BODY-BORDER-RADIUS;
    }

    @media (min-width: $laptop) {
        width: 100px;
    }
}

.battery-neverAccessed {
    @include batteryPart($NEVER-ACCESSED);
}
.battery-over60Days {
    @include batteryPart($OVER-60-DAYS);
}
.battery-over30Days {
    @include batteryPart($OVER-30-DAYS);
}
.battery-last30Days {
    @include batteryPart($LAST-30-DAYS);
}
