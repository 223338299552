@import '../../../../../variables';

.assetContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  gap: 10px;
  border-radius: 4px;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    background-color: $asset-hover-background-color;

    .assetSettingsIcon, .unpinnedAssetActions, .hoveringPin {
      opacity: 1;
      transition: opacity 0.2ms ease-in;
    }
  }
}

.assetDetails {
  display: flex;
  align-items: baseline;
  width: calc(100% - 27px);
}

.editableAssetDetails {
  width: calc(100% - 41px);
}

.assetItem {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
}

.assetName {
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.bolderAsset {
  font-weight: bold;
  color: $primary-branded-color;
  display: list-item;
}

.internalAssetItem {
  width: calc(100% - 37px);
}

.internal {
  background-color: $internal-data-background-color;
  width: 34px;
  height: 13px;
  font-size: 9px;
  padding: 0 3px;
  border-radius: 25%;
  align-self: center;
  margin-top: -1px;
}

.assetActions {
  display: flex;
  gap: 7px;
  margin-top: 1px; // Better alignment with asset name
}

.assetSettingsIcon {
  opacity: 0;
  background-color: unset;
  transition: display 0.2ms ease-in;
  cursor: pointer;
}

.hoveringPin {
  opacity: 0;
}

.unpinnedAssetActions {
  opacity: 0;
  transition: opacity 0.2ms ease-in;
}

.assetSharedLinkIcon, .invisibleSharedLinkIcon {
  padding: 0 6px;
}

.invisibleSharedLinkIcon {
  opacity: 0;
  pointer-events: none;
}

.assetSharedLinkIcon {
  border-radius: 100px;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: darken($asset-hover-background-color, 10%);
  }
}