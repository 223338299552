@import '../../variables';

.buttonAction {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .buttonActionInput {
        @include ellipsis;
        font-family: $main-Font;
    }

    .label {
        font-family: $main-Font;
        font-size: 14px;
    }

    .buttonActionDescription {
        font-size: 12px;
        color: $action-button-description;
        font-family: $main-Font;
        margin-top: 10px;
    }

    .buttonLink {
        font-family: $main-Font;
    }

    .editActionContent {
        display: flex;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;
        color: $primary-branded-color;
        font-size: 14px;
        align-items: center;
        font-family: $main-Font;

        .editPen {
            fill: $primary-branded-color;
        }
    }

    .widgetChosen {
        background-color: $main-background-color;
        border-radius: 5px;
    }
}

.updateFieldContainer {
    margin-top: 0 !important;
}

.editEmbedContent {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
