$DARK-LOGIN-BUTTON-COLOR: #4c4c4c;
$DARK-BACK-BUTTON-BORDER-COLOR: #e6e6e6;

%LOGIN_BUTTON {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 120px;
    height: 37px;
    padding: 11px 0;
    cursor: pointer;
    font-size: 14px;
    box-sizing: border-box;
    transition: background-color 0.2s ease-in-out;

    overflow: hidden;
    position: relative;

    span,
    svg {
        z-index: 20;
    }
}

.black {
    border: 1px solid #f8c52c;
    background-color: black;
    width: 99px;
    height: 25px;
}

.avatar {
    width: 25px;
    height: 25px;
    border-radius: 12px;
}

.welcomeText {
    font-size: 12px;
    line-height: 2.22;
    color: #ffffff;
    margin-right: 10px;
}

.divider {
    width: 1px;
    height: 37px;
    opacity: 0.2;
    background-color: #ffffff;
    margin: 0 20px;
}

.leftSide {
    display: flex;
    align-items: center;
}

.rightSide {
    display: flex;
    align-items: center;

    .searchIconBackground {
        margin-right: unset;
    }
}

.companyLogo {
    height: 25px;
}

.yellowLabel {
    font-size: 12px;
}

.creatorHeaderMain {
    display: flex;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
    padding: 9px 18px;
    background-color: black;
    justify-content: space-between;
}

.hubNotificationDot::before {
    content: ' \25CF';
    font-size: 20px;
    color: #fe5e5b;
    z-index: 1;
    position: absolute;
    left: 19px;
    top: -2px;
}

.hidden {
    opacity: 0;
}

.lightDivider {
    border-left: 1px solid #fff;
}

.darkDivider {
    border-left: 1px solid $DARK-BACK-BUTTON-BORDER-COLOR;
}

.darkLoginButton {
    @extend %LOGIN_BUTTON;
    color: #fff;
    background-color: $DARK-LOGIN-BUTTON-COLOR;

    &:hover {
        background-color: lighten($DARK-LOGIN-BUTTON-COLOR, 10%);

        &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    .loginAnimationEffect {
        background: #fff;
    }
}

.lightLoginButton {
    @extend %LOGIN_BUTTON;
    color: #000;
    background-color: #fff;

    &:hover {
        background-color: darken(#fff, 10%);

        &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    .loginAnimationEffect {
        background: $DARK-LOGIN-BUTTON-COLOR;
    }
}

.loginAnimationEffect {
    content: '';
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    animation-name: buttonAnimation;
    -webkit-animation-name: buttonAnimation;
    animation-duration: 4s;
    -webkit-animation-duration: 4s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes buttonAnimation {
    0% {
        left: -75px;
    }
    25% {
        left: 120%;
    }
    100% {
        left: 120%;
    }
}

.headerButtons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
}
