@import '../../variables';

.content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;

    [class*='sharedHub'] {
        height: 100%;
        inset: 0;
    }

    .body {
        height: 100%;
        max-width: 100vw;
        width: 100%;
    }
}

@mixin absoluteNavigation {
    position: absolute;
    display: flex;
}

.defaultLayout {
    .content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        .header {
            flex-shrink: 0;
            height: $title-widget-height;
            top: 0;
            left: 0;
            z-index: 101;
            [class*='navComponent'] {
                position: absolute;
                top: $title-widget-height;
            }
        }

        .body {
            padding-top: $navigation-bar-height;
            min-height: calc(100% - #{$navigation-bar-height} - #{$title-widget-height});
            height: 100%;
            position: relative;
            &.noPadding {
                padding-top: 0;
            }
        }

        .navigation {
            @include absoluteNavigation();
            top: $title-widget-height;
            left: 0;
            width: 100%;

            &.alignTop {
                top: 0;
            }

            [class*='navComponentContainer'] {
                > * {
                    width: calc(65% + 100px);
                }
            }
            flex-direction: column;
            flex-shrink: 0;
            justify-content: center;
        }
    }
}

.menuLayout {
    .content {
        max-width: 100vw;
        display: flex;
        flex-direction: row;
    }

    .navigation {
        @media (max-width: $mobile) {
            z-index: 99;
            @include absoluteNavigation();
        }
    }
}

[class*='noHeader'] {
    .navigation {
        @media (max-width: $mobile) {
            z-index: 102;
        }
    }
}

.customerHubContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        height: 100%;

        .body {
            .navigationBarPadding {
                padding-top: $navigation-bar-height;
            }

            &.noPadding {
                padding-top: 0;
            }
        }
    }
    .header {
        display: flex;
        height: 55px;
        width: 100%;
        flex-shrink: 0;
    }
    [class*='main'] {
        display: flex;
        [class*='mainContent'] {
            min-width: 65vw;
            align-items: center;

            @media (max-width: $mobile) {
                min-width: $mobile-widget-content-max-width;
            }
        }

        @media (max-width: $mobile) {
            width: 100%;
            min-width: calc(100% - 60px);
        }
    }
}

.content {
    .body {
        &.noPadding {
            padding-top: 0;
        }
    }
}
