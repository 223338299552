@import '../../../../variables';
@import '../../../../aiVariables';

%ACTIONS_CONTAINER {
    display: flex;
    gap: 10px;
    align-items: center;
}

.actionsContainer {
    @extend %ACTIONS_CONTAINER;
}

.actionsContainerKitGallery {
    @extend %ACTIONS_CONTAINER;
    gap: 25px;
}

.divider {
    height: 18px;
    border-right: 1px solid $divider-color;
}

.translateMenu {
    display: flex;
    align-items: center;
    gap: 10px;
}

.previewButtonLabel {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        fill: white;
    }
}

.kitSettingsIcon {
    width: 16px;
}

.kitSettingsLabel {
    gap: 8px;
}

.minimapLabel {
    gap: 6px;
}

.kitSettingsLabel,
.minimapLabel {
    padding: 8px 15px;
    display: flex;
    align-items: center;

    span {
        @media (max-width: $medium-laptop) {
            display: none;
        }
    }

    @media (max-width: $medium-laptop) {
        width: 37px;
        height: 37px;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.previewButton:hover {
    background-color: $purple-action-button-menu-hover-color;
}

.personizationTokenPopover {
    top: 44px;
    right: -165px;
}

@container kit-actions (width < 700px) {
    .kitSettingsLabel {
        width: 37px;
        height: 37px;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: none;
        }
    }
}