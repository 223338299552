@import '../../../variables';
.description {
    opacity: 0.7;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.helpLink {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $primary-color;
    text-decoration: none;
}

.closeButton {
    margin-bottom: -5px;
    display: flex;
    justify-content: flex-end;
}

.editWidgetPanelContainer {
    width: 100%;
}

.embedContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
