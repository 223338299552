@import '../../../../variables';

.container {
    margin-top: 20px;
}

.separator {
    height: 1px;
    margin: 0 0 10px 20px;
    border-bottom: 1px solid $separator-color;
}

.addTagLink {
    color: $primary-color;
    cursor: pointer;
}

.rightButton {
    background-color: $main-color;
}

.circle {
    @include colorCircle();
}

.colorMenuItem {
    display: flex;
    align-items: center;
}

.deleteMenuItem {
    color: $error-message-color;
}
