@import '../../../../../variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {
  @include widgetTitle();
}

.infoIcon {
  margin: 0 10px 0 5px;
  width: 14px;
}

.editIcon {
  cursor: pointer;
}