@import '../../../../../variables';

.container {
    @include horizontalCenter();
}

.dataContainer {
    @include horizontalCenter();
    margin-top: 30px;
}

.input {
    margin-top: 20px;
    display: flex;
    align-items: center;

    input {
        width: 291px;
    }
}

.withInfo {
    padding-left: 20px;
}

.info {
    width: 13px;
    height: 12px;
    margin: 14px 0 14px 6px;
}

.testConnection {
    color: $primary-color;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }
}
