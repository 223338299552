@import '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .customerCheckBoxContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .recordsButton {
            margin-top: 14px;
        }

        .buttonLabelTextField {
            margin-top: 11px;
        }

        .checkboxRow {
            display: flex;
            font-size: 12px;
            margin: 0;
            .text {
                margin-left: 12px;
            }
        }
    }

    .comboBoxGroupContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .tableColumnContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .addColumn {
            padding-top: 10px;
            border-top: 1px solid $add-column-border-top-color;
            cursor: pointer;
        }

        .columns {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
}

.customerPermissionsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.settingsLabel {
    font-size: 12px;
    margin-left: 30px;
}