.leftContainer {
    flex: 2;

    .accountsContainer {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        padding-top: 1px;
        overflow-y: auto;
        overflow-x: hidden;
        flex: 2;
        max-height: 450px;
        margin-bottom: 15px;
        padding-left: 5px;
    }

    .newAccount {
        padding: 10px 30px;
        margin-top: 15px;
    }
}

.rightContainer {
    border-left: dashed 1px #cfcfcf;
    flex: 1;
    padding-left: 45px;

    .crmButton {
        margin-top: 20px;

        .crmButtonLabel {
            display: flex;
            align-items: center;

            .crmButtonImg {
                margin-right: 5px;
            }
        }
    }
}

.hide {
    display: none;
}
