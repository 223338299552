@import '../../../../../../variables';

$file-input-border: $widget-container-border-color;

.dialog {
  width: 517px;
  padding: 26px 26px 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    width: auto;
  }

  .loader {
    margin: 26px auto;
  }

  .fileInput {
    width: 100%;

    &:nth-child(n+2) {
      border-top: 1px dashed $file-input-border;
      padding-top: 30px;
      margin-top: 20px;
    }
  }

  .textField {
    margin-bottom: 10px;
  }
}