@import '../../../variables';

.container {
    overflow-x: hidden;
    background-image: url('../../../assets/images/4.png');
    background-repeat: repeat-y;
    background-size: cover;

    @media (max-width: $mobile) {
        height: calc(100% - 62px - 40px); // header height + padding bottom
        justify-content: space-between;
    }

    .tracker {
        margin-top: 30px;
        @media (max-width: $mobile) {
            display: none;
        }
    }

    .inputsContainer {
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 22px;

        @media (max-width: $mobile) {
            width: 310px;
        }
    }

    .title {
        @include pageTitle();
        font-family: $main-Font;
        font-weight: bolder;
    }

    .mainTitle {
        @include pageTitle();
        font-family: $secondary-font;
        font-weight: normal;
    }

    .mainTitle:first-of-type {
        margin-top: 70px;

        @media (max-width: $mobile) {
            margin-top: 40px;
            line-height: 1.2;
            padding: 0px 5px;
        }
    }

    .title:first-of-type {
        margin-top: 70px;

        @media (max-width: $mobile) {
            margin-top: 40px;
        }
    }

    .content {
        @include center();
        height: fit-content;
        @media (max-width: $mobile) {
            padding: 0px 5px 40px 15px;
        }
    }

    .subtitle {
        font-size: 14px;
        margin-top: 74px;
        color: $branding-subtitle-color;
        @media (max-width: $mobile) {
            display: none;
        }
    }

    .textFieldTitle {
        font-size: 18px;
        margin-top: 33px;

        @media (max-width: $mobile) {
            font-size: 18px;
            margin-top: 0;
            text-align: center;
            padding: 30px;
        }
    }

    .eaNote {
        margin-top: 33px;
    }

    .logos {
        margin-top: 32px;
        margin-bottom: 62px;
        @media (max-width: $mobile) {
            display: none;
        }
    }
}

.nextButton {
    @include mobileButton();
    font-size: 14px;
    @media (max-width: $mobile) {
        bottom: 30px;
        height: 40px;
    }
}

.loader {
    height: 100%;

    @media (max-width: $mobile) {
        height: 85%;
    }
}

.buttonWrapper {
    @media (max-width: $mobile) {
        height: 100px;
        width: 100%;
        position: absolute;
        bottom: 0;
        @include centeredStickyElement();
        box-shadow: -3px 0px 11px 0 rgba(0, 0, 0, 0.2);
        padding-top: 10px;
        background: $widget-container-background-color;
    }
}
