/*Old CSS*/

@import '../../variables';

.container {
    margin: 20px 0 0 0;
}

.nwidget {
    border-radius: 8px;
    padding: 15px;
    font-size: 13px;
    line-height: 1.4;
    color: #000;
    .content {
        height: 100%;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 180px;
    }
    .wdescrow {
        min-height: 35px;
        height: 100%;
    }
    &.noPointerEvents {
        pointer-events: none;
    }
}

.overlayText {
    position: absolute;
    top: 60%;
    z-index: 1;
    opacity: 0.7;
    /* left: 45%; */
    justify-content: center;
    width: 100%;
    max-width: calc(100% - 50px);
    text-align: center;
    display: inline-block;
}

.blurOverlay {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    opacity: 0.4;
    // opacity: 0.2;
}

.topRow {
    padding: 0 0 10px;
    display: flex;
    flex-direction: row;
}

.avatarCircleContainer {
    border-radius: 100%;
    width: 55px;
    height: 55px;
    //border: 2px solid #70cfba;
    padding: 2px;
    box-sizing: border-box;
}

.imageContainer {
    border-radius: 50%;
    object-fit: cover;
}

.gravatarContainer {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //background: #3c6061;
}

.gravatarContainer img {
    max-width: 100%;
}

.formFieldsContainer {
    flex: 1;
    padding: 0 0 0 15px;
}

.fieldSetContainer {
    padding: 0;
    margin: 0;
    border: none;
}

.widedtxt .wtxtname {
    height: 26px;
    padding: 2px 5px;
}

.widedtxt .wtxtdesc {
    //font-size: 12px;
    height: 22px;
    padding: 2px 5px;
}

.nameText {
    font-size: 14px;
    font-weight: 900;
    margin: 0;
    padding: 0;
    color: #000;
}

.roleText {
    font-size: 12px;
}

.wdescrow {
    padding: 0 0 2px;
    margin-bottom: 10px;
}

.wdescrow div p {
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 14px;
}

.widedtxt {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    margin: 0 0 5px 0;
    padding: 5px;
    font-size: 13px;
    line-height: 1.3;
}

textarea.widedtxt {
    height: 46px;
}

.wdfoot {
    border-top: 1px solid #dfdfdf;
    padding: 10px 0 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.wdfoot > a {
    min-width: 30px;
    display: flex;
    justify-content: center;
    border-radius: 100%;
}

@media (max-width: $mobile) {
    .wdfoot > a {
        margin: 0 0 0 20px;
        width: 28px;
        border-radius: 100%;
    }
}

.linkedToAccountOwner {
    font-family: $main-Font;
    font-size: 14px;
    vertical-align: middle;
}

.linkedToAccountOwnerDescription {
    font-family: $main-Font;
    font-size: 12px;
    color: $description-grey;
    margin: 18px;
}

.vIcon {
    vertical-align: middle;
    padding-right: 10px;
}

.linkedField {
    top: 50%;
}
