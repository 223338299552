@import '../../variables';

.container {
    @include actionButton();
    height: 16px;
    width: 20px;
    padding: 5px;

    .arrow {
        &.asc {
            transform: rotateZ(180deg);
            transition: transform 0.4s ease-in-out;
        }

        &.desc {
            transform: rotateZ(360deg);
            transition: transform 0.4s ease-in;
        }
    }
}
