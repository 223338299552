@import '../../_variables.scss';

.container {
    border-top: 1px solid #e9e8ed;
    padding-top: 22px;
    margin-top: 20px;
}

.oval {
    width: 7px;
    height: 7px;
    background-color: #13c32c;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
}

.connectCalendarButton {
    margin-top: 8px;
    font-size: 12px;
}

.connectionIcon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.customerMeeting {
    margin-top: 20px;
    padding: 2px 0;
    @include widgetDescriptionPanel;
}

.title {
    @include title();
}

.subTitle {
    @include smallTitle();
    margin-top: 10px;
}

.calendarText {
    margin-bottom: 11px;
}

.customerWarning {
    background-color: $internal-data-background-color;
    padding: 2px 0;
    width: fit-content;
    margin-bottom: 22px;
}
