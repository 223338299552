@import '../../../variables';

.widgetPanelContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 20px;
}

.description {
    @include descriptionWithOpacity(14px);
}

.subtitle {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 16px;
}

.inputField {
    margin-bottom: 22px
}

.questionsContainer {
    margin-bottom: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.questionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover, &.selected {
        .questionIndex, .questionTitleWrapper {
            color: $primary-branded-color;
            font-weight: 600;
        }

        .questionContainer {
            background-color: #dee8fb;
        }

        .questionTypeIcon rect, .questionTypeIcon path {
            fill: $primary-branded-color;
        }
    }
}

.questionIndex {
    margin-right: 8px;
    min-width: 13px;
}

.questionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 5px;
    border-radius: 4px;
    margin-right: 7px;
}

.questionTypeIcon {
    margin-right: 5px;
    width: 14px;
}

.questionTitleWrapper {
    width: 140px;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
}

.questionTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addQuestionButton {
    cursor: pointer;
    color: $primary-branded-color;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
}

.followUpQuestionTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.followUpQuestionTitle {
    width: 65px;
    font-size: 14px;
    color: $widget-container-font-color;
}

.answerChoiceLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.rangeDropDown {
    width: 84px;
}

.rangeText {
    color: #000;
}

.followUpQuestionEditor {
    [class*='DraftEditor-root'] {
        min-height: 90px;
        max-height: 90px;
    }
}

.deleteNotification {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    svg {
        fill: white;
    }
}

.reorderDialog {
    width: 400px;
}

.separator {
    padding: 10px 0 0 0;
    border-top: 1px solid $textbox-border-color;
}

.customActionsContainer {
    padding: 20px 30px;
    max-width: 625px;

    .checkboxRow {
        display: flex;
        font-size: 14px;
        margin: 0 0 2px 0;

        .text {
            margin-left: 12px;
        }
    }

    .customActionDescription {
        margin-left: 30px;
        color: $subtitle-info-color;
    }

    a {
        color: $primary-branded-color;
    }
}

.emptyState {
    p:first-child {
        margin-bottom: 20px;
    }
}

.automationContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: relative;
    margin-top: 10px;

    .robotIcon {
        margin-right: 10px;
    }

    .oval {
        width: 12px;
        height: 12px;
        position: relative;
        bottom: 5px;
        background-color: $primary-branded-color;
        border-radius: 50%;
        text-align: center;
        font-size: 8px;
        color: $font-color-in-main-color;
    }
}
