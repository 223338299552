.row {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.infoIcon {
    margin-left: 5px;
}

.widgetTitle {
    margin-left: 15px;
}
