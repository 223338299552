.addRecordButtonContainer {
    display: flex;
    justify-content: center;
    width: auto;
}

.addRecordButtonLabel {
    padding: 0 35px;
}

.addRecordButton {
    margin-top: 36px;
    border-color: transparent;
    max-width: 500px;
    min-width: 120px;
}
