@import '../../../../../variables';
@import '../../../../../aiVariables';

.editMeetingDialogContainer,
.editMeetingDialogContainerSansIntercom {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    .textBoxContainer {
        height: unset;
    }

    & > div {
        flex: 1;
        height: 50%;
    }
    .itemsEditPanel,
    .summaryEditPanel,
    .internalEditPanel {
        height: calc(91%);
    }

    .splitedToTwo {
        .editMeetingTextBoxContainer {
            height: calc(100% - 35px);
        }
    }

    .internalEditPanel {
        margin: unset;

        .editMeetingTextBoxContainer {
            height: calc(100% - 45px);
        }

        @media (max-width: $desktop) {
            height: calc(87%);
        }
    }

    .editPanel:not(.internalEditPanel) {
        height: calc(100% - 20px);
    }

    .editMeetingTextBoxContainer {
        .textEditor {
            :global(.DraftEditor-root) {
                min-height: calc(100% + 20px);
                max-height: calc(100% + 20px);
                overflow-y: auto;
            }
        }
    }
}

.textBoxContainer {
    width: 100%;
    height: fit-content;
    position: relative;

    [class*='DraftEditor-root'] {
        max-height: none;
        min-height: 105px;
        overflow-y: visible;
    }

    .noteContainer {
        display: flex;
        width: fit-content;
        height: 21px;
        z-index: 10;
        align-items: center;
        font-family: $main-Font;
        background-color: $internal-data-background-color;
        font-size: 12px;
        position: relative;
        padding: 0 5px;
        margin: -10px auto;
        border-radius: 25px;
        border: solid 1px $internal-border-color;
        .noteText {
            padding: 0 5.5px;
        }
    }
}

.editPanel {
    width: 100%;
    display: flex;

    .splitedToTwo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .paperAirplaneIcon {
        z-index: 100;
    }
}

.internalEditPanel {
    border-radius: 3px;
    border: solid 1px $internal-border-color;
    background: $textbox-backgrount-color;
    margin-bottom: 15px;
    padding-bottom: 2px;
}

.summaryEditPanel {
    border-radius: 3px;
    border: solid 1px $textbox-border-color;
    background: $textbox-backgrount-color;
    margin-left: 10px;
    max-width: calc(50% - 10px);
    position: relative;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.highlightedSummary {
    @include AI_CONTENT_WRAPPER($textbox-backgrount-color, 3px);
    height: 100%;
}

.aiTooltip {
    bottom: calc(100% - 3px);
}

.itemsEditPanel {
    border-radius: 3px;
    border: solid 1px $textbox-border-color;
    background: $textbox-backgrount-color;
    margin-right: 10px;
    max-width: calc(50% - 10px);
}

.editPanelsTitle {
    width: 50%;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 10px;
    color: $subtext-color;
}

.relatedNotes{
    margin-top: -23px;
}

.placeholder {
    color: $subtext-light-grey;
    font-size: 12px;
    margin-top: 10px;
    padding-left: 7px;
}

.topPanelsPlaceholder {
    color: $subtext-light-grey;
    font-size: 12px;
    margin-top: 3px;
    padding-left: 7px;
}

.relatedNotesWrapper {
    height: fit-content;
    position: relative;
    margin-top: 10px;
    min-height: 100px;
}

.editMeetingTextBoxContainer {
    .textEditor {
        margin-bottom: unset;
        :global(.DraftEditor-root) {
            min-height: 95px;
            overflow-y: auto;
            max-height: 90px;
        }

        .textSnippetIconClassName{
            margin-top: -10px;
        }
    }
}

.addRelatedBtn {
    position: absolute;
    right: 10px;
    bottom: 6px;

    @media (max-width: $mobile) {
        bottom: 42px;
    }
}

.addInternalNotesBtn {
    height: 22px;
    margin-top: 10px;
    padding: 0 10px;
    font-family: $main-Font;
}

.hidden {
    display: none;
}

@media (max-width: calc(#{$laptop} - 1px)) {
    .internalNotesContainer {
        display: block;
        margin-top: 40px;
    }

    .addInternalNotesBtn {
        display: none;
    }
}

@media (max-width: $mobile) {
    .editMeetingContainer {
        display: flex;
        flex-direction: column;
        gap: 75px;
    }
}
