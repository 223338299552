@import '../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.testBtn {
    font-size: 12px;
    color: $primary-branded-color;
}

.testBtnContainer {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: fit-content;
    height: 30px;
    gap: 5px;
}

.errorText {
    display: inline-block;
    font-size: 12px;
    color: $error-message-color;
    text-align: left;
}
