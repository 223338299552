@import '../../../variables';

.dialogTitleContainer {
    display: flex;
    gap: 10px;
    align-items: center;

    .betaIcon {
        margin-left: 15px;
    }
}

.dialogBody {
    display: flex;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.dialogBodyLeft {
    width: 350px;
    padding: 20px 25px 30px;

    [class*='DraftEditorPlaceholder'] {
        font-size: 14px;
        pointer-events: none;
    }

    .explainer {
        font-size: 12px;
        color: $subtitle-info-color;
        padding-bottom: 20px;
    }

    .freeTextInput {
        padding: 5px 10px;
        min-height: 157px;
        max-height: 392px;

        [class*='DraftEditor-root'] {
            min-height: 135px;
            max-height: 370px;
            color: $freestyle-texteditor-color;
            caret-color: $freestyle-texteditor-color;
        }
    }

    .freeTextError {
        border: 1px solid $notification-icon-color;
    }

    .freeTextErrorText {
        color: $notification-icon-color;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: -18px;
    }

    .additionalInputsContainer {
        margin-top: 30px;

        .inputsTitle {
            font-size: 12px;
            font-weight: 600;
        }

        .inputs {
            display: flex;
            gap: 10px;
            margin-top: 10px;
        }
    }
}

.dialogSubmitButton {
    padding: 0 17px;
    width: fit-content;
}

.regenerateButton {
    display: flex;
    margin-right: auto;
    gap: 10px;
    cursor: pointer;
    align-items: center;

    .regenerateText {
        font-size: 14px;
    }
}

.dialogBodyRight {
    width: 500px;
    border-left: 1px solid $separator-color;
    justify-content: center;
    padding: 0 15px 0 25px;
}

// EMPTY/LOADING/ERROR STATES
.loadingContentAnimation {
    height: 172px;
}

.requestErrorText {
    font-size: 14px;
    font-weight: 600;
    color: $notification-icon-color;
    text-align: center;
    position: absolute;
    margin-top: 107px;
    margin-left: 6px;
}

.resultErrorState {
    opacity: 0.15;
}

.emptyStateText,
.loadingStateText {
    font-size: 14px;
    color: $subtitle-info-color;
    text-align: center;
}

// GENERATED RESULTS STATE

.generatedResultsContainer {
    min-height: 393px;
    max-height: 600px;
    padding-top: 20px;
}

.suggestedText {
    font-size: 12px;

    .boldText {
        font-weight: bold;
    }
}

.generatedContent {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow: auto;
    max-height: 562px;
    gap: 10px;
    padding-right: 10px;

    &:last-of-type::after {
        padding-top: 10px;
        content: '';
    }
}

@media (max-width: $laptop) {
    .dialogBodyLeft {
        .freeTextInput {
            max-height: 314px;

            [class*='DraftEditor-root'] {
                max-height: 292px;
            }
        }
    }

    .generatedContent {
        max-height: 500px;
    }
}

.animateResult {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.disabledResults {
    opacity: 0.3;
    pointer-events: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-22 14:50:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@mixin fadeInTop {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-top {
    @include fadeInTop();
}
@keyframes fade-in-top {
    @include fadeInTop();
}
