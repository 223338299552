@import '../../../variables';

.container {
    min-height: 100px;
    margin: 20px;
    min-width: 100%;
    max-width: 100%;

    .steps {
        display: flex;
        max-width: $kit-steps-width;
        max-height: 120px;
        min-height: 105px;
        position: relative;
        overflow: scroll;
        float: left;

        .stepContainer {
            display: flex;
            height: fit-content;
            flex-direction: column;
            align-items: center;
            margin: 0 2px;
            padding: 8px 16px 0 10px;
            border-radius: 5px 5px 0 0;
            &:hover {
                background-color: #ebebeb;

                .step:not(.editStep) .pen {
                    visibility: visible;
                }
            }

            .step {
                display: flex;
                align-items: flex-start;
                cursor: pointer;
                margin-bottom: 11px;
                position: relative;
                width: fit-content;

                &.selected {
                    color: $main-color;
                }

                .count {
                    font-size: 43px;
                    font-weight: 200;
                    margin-right: 20px;
                    line-height: 1;
                }

                .titleContainer {
                    display: inline-block;
                    min-width: 100px;
                    max-width: 180px;

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        overflow: auto;
                    }

                    .description {
                        opacity: 0.7;
                        @include smallTitle();
                        line-height: 1.25;
                    }

                    .titleTextArea {
                        height: 23px;
                        font-size: 12px;
                        margin-bottom: -5px;
                    }

                    .descriptionTextArea {
                        height: 18px;
                        padding: 0 3px;
                        font-size: 10px;
                    }

                    .valueHelp {
                        margin: 3px 0 -1px;
                        opacity: 0.7;
                        @include smallTitle();
                    }
                }

                .pen {
                    visibility: hidden;
                    align-self: start;
                    margin-left: 10px;
                }
            }

            .selectedStep {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                bottom: 0px;
            }
        }
    }

    .plus {
        float: left;
        width: 20px;
        padding: 20px;
        padding-top: 30px;
        flex: 1 0 auto;
        border-left: solid 1px $separator-color;
        margin: 10px 20px;

        .pointer {
            cursor: pointer;
        }
    }
}
.editContainer {
    min-width: 180px;
}

.deleteBtn {
    height: 16px;
    width: 16px;
    &:hover {
        cursor: pointer;
        fill: red;
        transition: fill 0.1s ease-in;
    }
}

.containerBtn {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 7px;
}

.checkBtn {
    height: 16px;
    width: 16px;
}

.viewDesign {
    padding-top: 10px;
}

.editDesign {
    padding-top: 2px;
}

.context {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.leftColorBtn {
    color: red;
}
