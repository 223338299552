@import '../../../variables';
@import './KitGalleryVariables';

.content {
  width: 800px;
  max-height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 26px;
  box-sizing: border-box;
  background-image: url('../../../assets/images/color-swirl.png');
  background-repeat: no-repeat;
  background-position: top 0 left 30%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.topHalf, .bottomHalf {
  display: flex;
  gap: 20px;
}

.bottomHalf {
  justify-content: space-between;
}

.textContainer {
  flex: 1;
  font-size: 14px;

  ul {
    list-style-image: url('../../../assets/images/star-logo.png');
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, $separator-color 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 25px 1px;
  background-repeat: repeat-x;
}

.image {
  width: 532px;
  height: 306px;
}

.info {
  color: #000000;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.subtitle {
  font-size: 14px;
}

.tags {
  @extend %TAGS;
  flex-wrap: wrap;
}