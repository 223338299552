@import '../../../../_variables.scss';
.container {
    padding: 0 15px;
    margin-bottom: 55px;
    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }
}

.notesTitle {
    margin-top: 36px;
    margin-bottom: 12px;
}

.title {
    @include titleBold();
    font-family: $main-Font;
    margin-top: 36px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 18px;
}

.bottomBorder {
    border-bottom: 1px solid $separator-color;
    padding-bottom: 22px;
}

.notes {
    color: $bullet-color;
    font-size: 12px;
}

.buttonToLeft {
    justify-content: flex-start;
}
