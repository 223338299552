@import '../../../../variables';

.textArea {
    height: fit-content;
    min-height: 20px;
    overflow: auto;
    font-size: 13px;

    [class*='DraftEditor-root'] {
        min-height: 30px;
        padding-top: 5px;
    }
}

.textAreaWrapper {
    transition: min-height 0.3s linear;
    min-height: 20px;
    overflow: hidden;

    &.editing {
        min-height: 30px;
        transition: min-height 0.3s linear;
    }
    [class*='editButton'] {
        width: 30px;
        height: 30px;
    }
}

.placeholder {
    color: $placeholder-color;
    padding: 5px;
    font-size: 13px;
}

.displayValue {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: fit-content;
    font-size: 14px;
    color: $subtext-color;
}
