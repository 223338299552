@import '../../variables';

.addNewRecordContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .customerAction {
        display: flex;
        flex-direction: row;
        gap: 2px;

        .text {
            margin-left: 10px;
        }
    }

    .addNewRecordActions {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .dialogButtonTitle {
            font-size: 14px;
            cursor: pointer;
            font-weight: 600;
            color: $primary-color;
        }

        .recordsButton {
            font-size: 14px;
            font-weight: 600;
            color: $text-color;
        }

        .addRecordLabel {
            font-size: 12px;
            color: $text-color;
        }
    }
}
