@import '../../../../../variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    @include widgetPanelConfigurationName;
    margin-bottom: 5px;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkboxRow {
    display: flex;
    font-size: 12px;

    .text {
        margin-left: 12px;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.checkbox {
    display: flex;
    flex-shrink: 0;
}
