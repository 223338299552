@import '../../variables';

.mainClass {
    width: 224px;
    right: 300px;
    z-index: 100;
    top: 190px;
    background-color: $main-color;
    border-radius: 10px;
    position: relative;
}

.title {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.text {
    margin-top: 4px;
    font-size: 12px;
    color: white;
}

.happyGuy {
    position: absolute;
    right: 115px;
    z-index: 100;
    top: -90px;
}
