@import '../../../../variables';

.toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actions {
    min-width: fit-content;
}

.tableToolbarRoot {
    padding-left: 0 !important;
}

.syncIcon {
    width: 18px;
    height: 18px;
    fill: $sync-icon-table-widget;
}

.sync {
    cursor: pointer;
    margin-left: 20px;
}

.sync {
    &.updating {
        animation: rotation 1s infinite ease;
    }
}
