@import '../../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    align-items: center;
}

.textAreaContainer {
    width: 100%;
}

.questionEditor {
    width: 100%;
    background-color: $widget-container-background-color;
}

.disabled {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;

    [role='toolbar'] {
        display: none;
    }
}

.submitButton {
    border: 0;
    padding: 9px 35px;
}