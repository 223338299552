@import '../../../taskVariables';

.container {
    padding: 26px;

    .embed {
        margin: 0;
    }
}

.dialogContent {
    overflow-y: auto;
}

.button {
    @extend %TASK_CTA;
}