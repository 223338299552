.container {
    background-color: #f7f7f7;
    border-radius: 5px;
    position: absolute;
    border: solid 1px #d9d9d9;
    height: 100%;
    width: calc(100% - 2px);
    top: 3px;

    .header {
        display: flex;
    }

    .title {
        display: flex;
        justify-content: center;
        flex: 1;
        margin: 3px 0;
    }

    .close {
        margin-left: auto;
        display: flex;
        padding: 6px;
        cursor: pointer;
    }

    .body {
        display: flex;
        overflow-y: scroll;
        height: calc(100% - 25px);
    }
}
