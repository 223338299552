@import '../../variables';

.surveyContainer {
    padding: 20px 0 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.viewModeReducedPadding {
    padding-top: 10px;
}

.followUpQuestionContainer {
    @include center;
    gap: 20px;
    padding-top: 10px;
}

.responseEditor {
    width: 50%;
    min-height: 60px;
    background-color: $widget-container-background-color;
}

.submitButtonContainer {
    @include center;
    padding-top: 20px;
}

.submitButton {
    border: 0;
    padding: 9px 35px;
}

.submittedSurvey {
    @include center;
    height: 95px;
}