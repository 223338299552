.selectObjectDialog {
  padding: 26px 30px 30px 26px;
}

.objectSelect {
  padding-bottom: 5px; // Completes the 20px gap between the object select and fields selector
  font-size: 14px;
  display: flex;
  gap: 5px;
}

.bold {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}