@import '../../../../variables';

.singleMeetingContainer {
    max-width: calc(100% - 20px);

    .rows {
        display: flex;
        border-radius: 6px;
        flex-direction: column;
        width: 100%;
        margin-bottom: 5px;
        padding: 8px 10px;
        background-color: $widget-entity-light-grey-background;
    }
}

.editMeetingWrapper {
    margin-left: $margin-left-meeting-details;
    margin-top: 10px;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.paper {
    height: 100%;
}

.infoContentWrapper {
    padding-bottom: 20px;
    margin-left: $margin-left-meeting-details;
    margin-top: 5px;

    .infoContainer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        &.expand {
            height: calc(100vh - 290px);
        }

        .infoContent > * {
            font-size: 12px;
        }

        .titleViewer {
            color: $subtitle-info-color;
            margin-bottom: 5px;
            font-size: 12px;
        }
    }
}

.revealOnHover {
    opacity: 0;
}

.hidden {
    background-color: $internal-data-background-color !important;
}

.additionalMeetingContent {
    margin-left: $margin-left-meeting-details;
}

.filesContainer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.filesSumContainer {
    display: flex;
    gap: 5px;
    align-items: center;

    .filesSum {
        cursor: pointer;
        text-decoration-line: underline;
        font-size: 12px;
        color: $subtext-color;
    }
}