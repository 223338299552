.contactDetailsDialog {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    width: 100%;
}
.fieldWrapper {
    display: flex;
}

.contactWidgetPanelContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.description {
    opacity: 0.7;
}
