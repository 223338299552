@import '../../../../variables';

.container {
    @include svgButton;

    border-radius: 8px;
    padding: 30px 40px 40px;
    width: 200px;
    background-color: $widget-container-background-color;
    text-align: center;
    height: 280px;
    position: relative;
    cursor: pointer;

    .title {
        font-family: $main-Font;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.27;
        text-align: center;
        color: $template-title-color;
        margin-top: 20px;
    }

    .subTitle {
        margin-top: 15px;
        font-family: $main-Font;
        font-size: 12px;
        line-height: 1.5;
        color: $template-title-color;
        display: block;
    }

    .selectButton {
        margin-top: 30px;
        background-color: $main-color;
        border: unset;
        padding: 15px 30px;
        font-size: 16px;
        position: absolute;
        bottom: 28px;
    }

    @media (max-width: $mobile) {
        min-height: 300px;
        padding-bottom: 30px;
        margin-bottom: 20px;
        .selectButton {
            bottom: 28px;
        }
    }
}
