@import '../../variables';

$off-indicator-color: #a0a0a0;

.container {
    width: 100%;
    height: 23px;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    position: relative;
    align-items: center;
    border: 1px solid #a0a0a0;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;

    &:hover {
        background-color: #e4e4e4;
    }

    &[data-value="true"] {
        .optionIndicator {
            background-color: $primary-branded-color;
            left: 50%;
        }
    }

    &[data-value="false"] {
        .pinkIndicator {
            background-color: $primary-branded-color;
        }

        .normalIndicator {
            background-color: $off-indicator-color;
        }
    }
}

.optionIndicator {
    width: 50%;
    position: absolute;
    height: 100%;
    z-index: 2;
    border-radius: 15px;
    transition: background-color 200ms ease-in-out, left 200ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.85;
    }
}

.pinkIndicator {
    background-color: $primary-branded-color;
}

.normalIndicator {
    background-color: $off-indicator-color;
}

.selected {
    &.optionContainer {
        color: white;
    }
}

.optionContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    min-width: 50%;
    max-width: 50%;
    position: relative;
    transition: color 200ms ease-in-out;
    z-index: 5;
}

.offOption {
    width: 60%;
    position: absolute;
    height: 100%;
    background-color: $primary-color;
    z-index: 2;
    border-radius: 15px;

    &:hover {
        opacity: 0.85;
    }
}

.disabled {
    opacity: 0.3;
}

.disabled:hover {
    cursor: not-allowed;
}
