@import '../../../../variables';

.containerBox {
    @include center();
    margin: 10px 0;
    font-family: $main-Font;
    font-size: 12px;
    color: $widget-container-font-color;
    border: 1px dashed $widget-container-border-color;
    border-radius: 4px;
    background-color: $widget-container-background-color;
}
