@import '../../../../variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    .title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin: 20px 0 3px;
    }

    .subtitle {
        font-size: 12px;
        color: #000000;
        opacity: 0.7;
        margin-bottom: 7px;
    }

    .checkboxRow {
        display: flex;
        font-size: 12px;
        margin: 0 0 20px 0;
        .text {
            margin-left: 12px;
        }
    }
}
