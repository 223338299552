@import '../../../../../variables';

.container {
    @include center();
    width: 100%;
    height: 100%;
    label {
        background: white;
    }
}

.error {
    color: $error-message-color;
    font-weight: 600;
    font-size: 12px;
    width: 394px;
}

.content {
    width: 306px;
    margin-top: 40px;

    > div {
        margin-bottom: 22px;
        &:last-of-type {
            margin-bottom: 10px;
        }
    }
}

.loader {
    position: fixed;
    top: 0;
    height: 100vh;
}

.inputDescription {
    @include description();
    padding-bottom: 10px;
    opacity: 0.7;
}

.input {
    width: 306px;
    input {
        height: 20px;
        padding: 10px;
        width: 306px;
    }
}
