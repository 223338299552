$section-category-bg-color: #f2f2f2;
$section-category-hover-bg-color: #dadada;

.wrapper {
  padding: 0 10px 0 20px;
}

.category {
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 2px;
  background-color: $section-category-bg-color;
  max-width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in-out;
  color: #000 !important; // Overrides section hover changing color to branded
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: $section-category-hover-bg-color;
  }
}

.notClickable {
  pointer-events: none;
}