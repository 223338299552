.customAction {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.content {
    max-height: 400px;
    overflow: scroll;
    width: 550px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.customActionName {
    font-size: 14px;
}
