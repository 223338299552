.inputArea {
    text-align: start;
    margin-bottom: 30px;

    &.disabled .inputLabel {
        opacity: 0.2;
    }
}

.inputLabel {
    font-size: 12px;
}

.additionalObjectInputArea {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info {
    margin: 0 10px 0 5px;
    width: 14px;

    &.disabled {
        opacity: 0.2;
    }
}

.disabled {
    pointer-events: none;
}