@import '../../variables';
@import '../../components/Task/TaskMultiStatusSelector/TaskMultiStatusSelect.module.scss';

.main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    padding-left: 30px;
    @media (max-width: $mobile) {
        padding-left: 14px;
        .commentsIcon {
            opacity: 1;
            visibility: visible;
            position: absolute;
            right: -40px;
        }
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.02);

        .commentsIcon {
            opacity: 1;
        }
    }
}
.numberOfCommentsContainer {
    display: flex;
    margin-bottom: 1px;
}
.numberOfComments {
    margin-right: 5px;
    position: relative;
    top: -3px;
}
.mobileBtnContainer {
    display: flex;
    flex-direction: row;
}
.customer {
    width: calc(100% + 7px);
}

.tools {
    display: flex;
    position: relative;
    align-items: center;
    margin: 3px 10px 0 0;
    &.listItem {
        margin: 0 3px 0;
    }
    height: 22px;
}

.leftHeader {
    position: relative;
    height: 22px;
    display: flex;
    align-items: center;
    overflow: visible;
}

.taskTitle {
    @include flexRow();
    font-size: 14px;
    line-height: 24px;
    padding-left: 6px;
    width: 100%;
    @media (max-width: $mobile) {
        position: relative;
        align-items: flex-start;
        display: block;
    }
    &.placeholder {
        color: #919191;
    }

    .taskActionsData {
        align-items: center;
        display: flex;
        @media (max-width: $mobile) {
            margin-top: 17px;
        }

        .customerIndication {
            position: absolute;
            right: -15px;
            top: 4px;
            margin-right: 12px;
        }
    }
}

.contentEditor {
    color: #545454;
    width: 100%;
    background-color: #ffffff;
}

.taskDescription {
    font-size: 14px;
    padding-left: 6px;
    color: #545454;
}

.titleContainer {
    display: flex;
    width: -webkit-fill-available;
    :global(.DraftEditor-root) {
        min-height: 22px;
        height: fit-content;
    }
}

.customerTitleContainer {
    display: flex;
    width: 100%;
}

.completed {
    & .taskDescription,
    .emoji,
    .header > span:first-child,
    .taskActionsData {
        opacity: 0.2;
        text-decoration: line-through;
    }
}

.emoji {
    margin-right: 4px;
    display: flex;
    align-items: center;
}

.emojiPickerContainer {
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 100;
}

.fileInput {
    display: none;
}

.dashMiddleLine {
    height: 1px;
    margin: 0 15px;
    opacity: 0.5;
    border-bottom: dashed 1px #979797;
    flex-grow: 1;
}

.oval {
    width: 24px;
    height: 24px;
    background-color: $primary-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    block-size: 23px;
}

.ovalDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #000;
}

.taskHeader {
    display: flex;
    width: 100%;
    align-items: center;
}

.mobileTaskButton {
    @media (max-width: $mobile) {
        justify-content: initial;
        margin-top: 14px;
        margin-right: 10px;
    }
}

.taskContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: $mobile) {
        display: block;
    }
}

.taskContentUnder {
    width: 100%;
}

.tasksDivider {
    border-top: solid 1px #d9d9d9;
    margin-top: 5px;
    padding-bottom: 8px;
    display: none;
}

.taskImage {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    margin: 5px;
    max-width: #{'min(50%, 500px)'};
    max-height: 500px;
    object-fit: contain;
    height: fit-content;
    @media (max-width: $mobile) {
        margin-right: 56%;
        max-width: 100%;
        height: auto;
    }
}

.taskImageCenter {
    margin-top: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 500px;
    object-fit: contain;
    height: fit-content;
}

.dueAfterTextBox {
    width: 145px;
    margin-left: 10px;
}

.statusMenu {
    @extend %TASK_MULTI_SELECT;
    // default colors for custom statuses
    background-color: #c3e5f6;
    color: #0885c1;

    svg g {
        fill: #0885c1;
    }

    &.notStarted {
        background-color: $task-status-not-started-background;
        color: $task-status-not-started-color;
        svg g {
            fill: $task-status-not-started-color;
        }
    }

    &.inProgress {
        background-color: $task-status-in-progress-background;
        color: $task-status-in-progress-color;
        svg g {
            fill: $task-status-in-progress-color;
        }
    }

    &.completed {
        background-color: $task-status-completed-background;
        color: $task-status-completed-color;
        svg g {
            fill: $task-status-completed-color;
        }
    }

    span {
        @include ellipsis;
        width: 100%;
        margin-right: 3px;
    }
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .circle {
        @include colorCircle();

        &.notStarted {
            background-color: $task-status-not-started-background;
        }

        &.inProgress {
            background-color: $task-status-in-progress-background;
        }

        &.completed {
            background-color: $task-status-completed-background;
        }
    }
}

.dateText {
    min-width: fit-content;
    font-size: 14px;
    margin-left: 20px;
}

.fileUploading {
    display: flex;
    margin-right: 10px;

    .message {
        margin-left: 10px;
        font-family: $main-Font;
        font-size: 12px;
        color: #5c5a5a;
    }
}

.actionDateFieldContainer {
    margin-left: 10px;
}

/***************** COMMENTS STYLES *****************/

.commentsIcon {
    display: flex;
    opacity: 0;
    padding: 7px 3px 5px 8px;
    margin: 0 15px;
    cursor: pointer;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -70px;
    border-radius: 3px;
    height: 12px;
    width: 18px;

    &:hover {
        background-color: $all-accounts-hover-color;
    }

    &.alwaysShow {
        opacity: 1;
    }
}

.taskCommentsHeader,
.taskCommentsBody {
    width: 100%;
}

.taskCommentsHeader {
    padding-top: 10px;
    height: 51px;
}

.taskCommentsBody {
    flex: 1;
    overflow: auto;
}

.dialogCommentsBody {
    max-height: $comments-dialog-height;
}

.taskCommentsFooter {
    border-top: 1px solid $separator-color;
    border-bottom: 1px solid $separator-color;
    width: calc(100% - 50px);
    bottom: 0;

    [class*='newCommentContainer'] {
        margin-top: 20px;
    }
}

.taskOwnerIcon {
    margin-left: 20px;
    @media (max-width: $mobile) {
        margin-left: 0px;
    }
}
.endDateMobile {
    margin-right: 10px;
}
/***************************************/

.header {
    display: flex;
    flex-direction: row;
    gap: 30px;
    min-height: 24px;
    max-width: calc(100% - #{$comment-icon-width});
    overflow: visible;
    position: relative;
    @media (max-width: $mobile) {
        position: unset;
        flex-direction: column;
        width: 100%;
        gap: 0px;
        max-width: 100%;
    }
}

.nonCollapsibleItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.iconLeftMargin {
    margin-left: 3px;
}

.iconRightMargin {
    margin-left: 10px;
}

.robotIcon {
    position: absolute;
    left: -45px;
    top: 2px;
}

.toggleCollapsibleAreaBox {
    align-items: center;
    margin-left: 10px;
    @media (max-width: $mobile) {
        margin-left: 0px;
    }
}

.mobileAttachFile {
    margin-top: 14px;
}

.emptyCommentsIcon {
    padding-bottom: 4px;
}

.taskVisibilityMenuItem {
    display: flex;
    align-items: center;
    .isHiddenForced {
        opacity: 0.2;
        cursor: default;
        margin-right: 14px;
    }
}
