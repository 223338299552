.title {
    width: 424px;
    margin-top: 43px;
}
.uploadPhoto {
    margin-top: 22px;
}

.category {
    display: flex;
    margin-top: 22px;
}

.uploadButton {
    margin-top: 10px;
}

.categoryCheckbox {
    margin-right: 5px;
}
