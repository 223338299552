@import '../../variables';

.title {
  @include widgetTitle();
}

.infoIcon {
  margin-left: 5px;
}

.columns {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 5px 0 10px;
}

.addColumn {
  font-size: 12px;
  font-weight: 600;
  color: $primary-color;
  padding-top: 10px;
  border-top: 1px solid $add-column-border-top-color;
  cursor: pointer;
}

.addIcon {
  fill: $primary-color;
  margin-right: 10px;
}