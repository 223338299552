.container {
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    padding-left: 5px;
    width: auto;
}

.dateFieldContainer {
    width: 100%;
}

.startDateFieldContainer {
    margin-right: 15px;
}

.contactDropDownInput {
    margin-top: 15px;
}
