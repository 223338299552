@import '../../variables';

%tableHeaderHover {
    :global(.MuiTableSortLabel-root) {
        &:hover {
            color: inherit;
        }
        &:focus {
            color: inherit;
        }
    }
    :global(.MuiTableSortLabel-active) {
        color: inherit !important;
    }
}

.container {
    padding-top: 10px;

    &.reducedContainerPadding {
        padding: 0;
    }

    .massageNoConnection {
        background-color: white;
    }
    .messageContainer {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        @include smallTitle;

        .customerNoteContainer {
            margin-top: 10px;
            align-items: center;
            display: flex;
            flex-direction: column;

            .note {
                background-color: $internal-data-background-color;
                padding: 2px;
            }
        }
    }

    @extend %tableHeaderHover;
}

.filterTableTitle {
    @include widgetTitle();
    margin-bottom: 10px;
    display: flex;
}
.filterIcon {
    margin-left: 5px;
    margin-right: 10px;
    width: 14px;
    margin-top: 5px;
}
.editIcon {
    cursor: pointer;
}

.loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
