.navigationAction {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .description {
        opacity: 0.7;
        width: 100;
    }
}
