@import '../../../variables';

.suggestionsFloatingWindow {
    width: 345px;
    position: absolute;
    top: 25px;
    left: 5px;
    z-index: 100;
}

.closeIcon {
    z-index: 10;
}

.childrenClassName {
    min-height: 110px;
    max-height: $word-wand-suggestion-floating-window-height;
    height: auto;
    border-radius: 5px;
    padding-left: 10px;
    overflow: unset;
    padding: 10px 10px 0 10px;

    &.modalDesign {
        position: fixed;
        background-color: white;
        height: auto;
        max-height: $word-wand-suggestion-floating-window-height;
        width: 327px;
    }

    &.smallFloatingWindow {
        min-height: 55px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    max-height: $word-wand-suggestion-floating-window-height;

    .title {
        opacity: 0.5;
        width: fit-content;
        font-family: $main-Font;
    }

    .error {
        font-family: $main-Font;
        color: $error-message-color;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        padding-bottom: 20px;

        .retry {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .suggestions {
        overflow-y: auto;
        max-height: $word-wand-suggestion-floating-window-height;
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: auto;
        padding-bottom: 20px;

        .replaceBtn {
            height: fit-content;
        }

        .choice {
            font-size: 14px;
        }
    }
}

.emptyState {
    font-family: $main-Font;
    opacity: 0.5;
    width: fit-content;
    padding-bottom: 20px;
}
