@import '../../../variables';

.actionButtonContainer {
    display: flex;
    flex-direction: column;

    .textFieldContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .buttonAction {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .buttonActionDescription {
                font-size: 12px;
                color: $action-button-description;
            }
        }

        .updateFieldContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .refreshHub {
                display: flex;
                flex-direction: row;
                gap: 15px;
                cursor: pointer;
                font-size: 14px;

                .icon {
                    vertical-align: middle;
                    margin-left: 5px;
                }
            }
        }
    }

    .textImageRadio {
        font-size: 12px;
    }

    .linkTo {
        font-size: 12px;
    }

    .editEmebedContent {
        display: flex;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;
        color: $primary-branded-color;
        font-size: 14px;
        align-items: center;

        .editPen {
            fill: $primary-branded-color;
        }
    }
}

.updateFieldContainer {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    margin-left: unset;
    width: 100%;
    gap: 15px;

    .inputContainer {
        margin-left: unset;
    }

    .inputContent {
        margin-left: unset;
    }
}

.buttonActionInput {
    @include ellipsis;
}
