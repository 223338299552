@import '../../../variables';

.divider {
    border-bottom: 1px solid #e9e8ed;
    margin: 30px 0;
}

.widgetPanelContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 173px;

    .description {
        opacity: 0.7;
        margin-bottom: 25px;
    }

    .actionsPanelContainer {
        display: flex;
        flex-direction: column;
        gap: 36px;

        .row {
            display: flex;
            flex-direction: row;
            gap: 5%;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.content {
    @include widgetDescriptionPanel;
}

.checkboxContainer {
    display: flex;
    gap: 10px;
    font-size: 12px;

    .checkbox {
        flex-shrink: 0;
    }
}

.title {
    @include widgetTitle();
    margin-bottom: 10px;
}

.recordingToggleContainer {
    margin-bottom: 10px;
}
