.content {
    width: 1000px;
    padding: 0 30px;
    font-size: 14px;
    max-height: 65vh;
    overflow: scroll;

    .header {
        margin: 26px 0 20px;
    }

    .visibilityRulesCriteria {
        margin-left: 27px;

        .section {
            margin-bottom: 18px;

            .personaSelector {
                display: flex;
                align-items: center;

                .helpText {
                    height: 15px;
                }
            }
        }
    }

    .accountsFieldsFilterSelector {
        margin-bottom: 30px;
    }
}

.extraRulesSection {
    margin: 0 0 20px 27px;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}