@import '../../../../variables';

.insightsMode {
    background-color: $primary-branded-color;
    color: white;

    .fullText {
        @media (max-width: $laptop) {
            display: none;
        }
    }

    .shortText {
        display: none;

        @media (max-width: $laptop) {
            display: block;
        }
    }
}

.text {
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
}
