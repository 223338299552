@import '../../../variables';

.container {
    @include center();
    text-align: center;
    margin-top: 50px;
}

.title {
    font-family: $title-Font;
    font-size: 60px;
}

.description {
    margin-top: 22px;
    font-size: 24px;
}

.filter {
    margin-left: 5px;
    cursor: pointer;
    border-bottom: 1px solid;
}
