@import '../../variables';

.chartsContainer {
    position: relative;
}

.chartCanvas {
    width: 100%;
    padding-top: 2px;
}

.invisible {
    visibility: hidden;
}

// Occupies the space of chart canvas during loading (since it needs to be in DOM and setting it's height to 0 causes weird bugs)
.skeleton {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addMetric {
    border-top: 1px solid $add-column-border-top-color;
    padding: 10px 0;
    cursor: pointer;
}

.editIcon {
    cursor: pointer;
    padding: 5px 0;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .aggregationContainer {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: space-between;
    }

    .categoryContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.showAxisCheckbox {
    display: flex;
    font-size: 14px;
    margin-top: 10px;
    gap: 10px;
}

.chartContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.valueYContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nameConfiguration {
    @include widgetPanelConfigurationName();
    margin-bottom: 10px;
}

.valueYTitle {
    @include widgetPanelConfigurationName();
    margin-top: 10px;
}

.sortByContainer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 40px; // add space for the intercom
}

.editModalContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.editModalLabel {
    @include smallTitle();
}

.editModalInputTitle {
    font-weight: bold;
    font-size: 14px;
}