@import '../../../variables';

.container {
    margin-right: 10px;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;

    .tag {
        padding: 0 5px;
        font-size: 10px;
        height: 14px;
        display: flex;
        align-items: center;
        border-radius: 2px;
        margin-left: 5px;
        white-space: nowrap;
    }

    .tooltip {
        font-size: 10px;
        margin-left: 10px;
        cursor: default;
    }
}
