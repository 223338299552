.container {
    display: flex;
    justify-content: space-between;
    .radioButtonsContainer {
        margin-top: 20px;
        width: 100%;

        .radioButton {
            margin-bottom: 75px;
        }
    }
    .templatesListContainer {
        width: 100%;
        margin-top: 10px;
        flex-direction: column;
        display: flex;
        gap: 6px;
        > * {
            width: 100%;
        }
    }
    .disabled {
        opacity: 0.3;
    }
}

.dropDown {
    margin-bottom: 10px;
    margin-left: 20px;
}

.checkbox {
    margin-top: 20px;
    display: flex;
}

.errorMessage {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;
}
