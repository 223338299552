@import '../../variables';

.main {
    display: flex;
    flex-direction: column;
    flex: 100%;
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: relative;
    .hub {
        overflow: auto;
        flex: 1;
        height: 100%;
        z-index: 0;
        display: flex;
        flex-direction: column;
        outline: none;

        &:not(.slides) {
            .mainContent {
                padding: 20px 63px 30px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                flex: 1;
            }
        }
    }

    [class*='creatorHeaderMain'] {
        min-height: $title-widget-height;
        max-height: $title-widget-height;
        display: flex;
        align-items: center;
        padding: 0 18px;
    }

    .hubContent {
        display: flex;
        flex-direction: column;

        &.menuLayout {
            flex-direction: row;
        }

        border-radius: 0 0 10px 10px;
        position: absolute;
        top: $browser-header-height + $title-widget-height + 1px;
        height: calc(100% - 1px - #{$browser-header-height} - #{$title-widget-height});
        width: 100%;
        overflow: hidden;
    }
}

.slides {
    display: flex;
    justify-content: center;

    @media (min-width: $mobile) {
        transform: scale(1.15);
    }

    .widgetsPresentationMode {
        @include slidesMainContent();
    }
}

.closingSlide * {
    @include closingSlide();
}

.creatorPageMain {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.accountPageTitle {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
}

.addBtn {
    margin: 30px 0 100px 0;

    cursor: pointer;
    width: max-content;

    &:hover {
        // -webkit-box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.3);
        // -moz-box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.3);
        // box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.3);
        // border-radius:25px;
        opacity: 0.85;
    }
}

.addButtonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.dropSpot {
    transition: opacity, height 200ms;
    width: 100%;
}

.middleDropSpot {
    margin-bottom: 15px;
}

.dotBackground {
    @include internalWidgetBackground();
}

.widgetWrapper {
    width: 100%;
}

.widgetSpacer {
    margin-bottom: 15px;
}

.mainContent {
    @include navigationBarPadding();
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
}

.widgetsTitleContainer {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    align-items: center;

    .greyNote {
        width: fit-content;
        padding: 1px 4px;
        height: 16px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff4d;
    }
}

.archiveWidgetsTitle {
    width: 100%;
    padding: 1px 4px;
    height: 16px;
    border-radius: 2px;
    font-size: 12px;
    color: #000000;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    background-color: #ffffff4d;
    margin-bottom: 35px;
}

.disabledDiv {
    // width: 100%;
    // height: 100%;
    // position: absolute;
    opacity: 0.2;
    background-color: black;
    z-index: 5;
}

.whiteNoteWarning {
    font-family: $main-Font;
    padding: 0 10px;
    width: fit-content;
    height: 16px;
    background-color: #ffffff;
    color: #ee3543;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    margin: 0 auto;
    border-radius: 2px;
}

.redNoteWarning {
    font-family: $main-Font;
    padding: 0 10px;
    width: fit-content;
    height: 17px;
    color: #ffffff;
    background-color: #ee3543;
    font-size: 12px;
    font-weight: 600;
    margin: 0 auto;
    border-radius: 2px;
    position: sticky;
    bottom: 0;
    z-index: 14;

    svg {
        fill: #ffffff;
        margin: 0 21px;
    }
}

.archiveWidgets {
    opacity: 0.5;
}

.archiveWidgetsTitle hr {
    border: none;
    border-top: 1px dotted #979797;
    height: 1px;
    width: auto;
    flex-grow: 1;
    margin: 10px;
    width: inherit;
}

.seriesTemplateTitle {
    display: flex;
    align-items: center;

    .arrow {
        margin-left: 5px;
        background-color: transparent;

        &:hover {
            background-color: #ebebeb;
        }
    }
}

.navComponentContainer {
    padding-top: 5px;
    top: 0;
    z-index: 11;
    overflow-y: visible;
    display: flex;
    justify-content: space-evenly;
    height: $navigation-bar-component-without-padding;

    &.hidden {
        display: none;
    }

    .arrowBtn {
        svg {
            height: 16px;
        }
    }

    .navComponent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 50px;
        border-bottom: 1px solid #d1d1d1;
    }

    .navBar {
        position: relative;
        top: 2px;
        width: fit-content;
        max-width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        overflow-x: scroll;
        @include hideScrollbar();

        a {
            transition: all 0.2s ease-in-out;
        }

        .selectedTab {
            font-weight: bold;
        }

        &.navBarBackgroundStyle {
            top: -2px;

            .navItemBackgroundStyle {
                font-size: 14px;
                padding-bottom: 0;
                height: 21px;
                line-height: 1px;
            }

            .selectedTab {
                padding: 4px 15px;
                margin: 0 5px;
                border-bottom: 0;
            }
        }

        .addSection {
            margin: 0 12px 0 12px;
        }
    }
}

.activeNavigationTab {
    font-weight: bold;
}

.customerViewSourceCompactNavigation {
    margin-bottom: 0;
}

.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin addButton() {
    position: absolute;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
}

@mixin addButtonOnHover() {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s ease-in;
}

.navContainer {
    position: relative;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .plusIcon {
        opacity: 0;
    }

    &:hover .plusIcon {
        transition: opacity 0.1s ease-in;
        opacity: 1;
    }

    .leftPlusIcon {
        left: -17px;
    }

    .rightPlusIcon {
        right: -17px;
    }

    .plusIconBorderStyle {
        top: 8px;
    }

    .leftPlusIcon,
    .rightPlusIcon {
        @include addButton();
    }

    &:hover {
        .leftPlusIcon,
        .rightPlusIcon {
            @include addButtonOnHover();
        }
    }
}

.spacing {
    margin-left: 17px;

    &:last-of-type {
        margin-right: 17px;
    }
}

.widgetsPresentationMode {
    max-width: $widget-content-max-width;
    margin: auto;
    padding: 0 63px 0;

    @media only screen and (max-width: $mobile) {
        max-width: $mobile-widget-content-max-width;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.navigationBarPresentationMode {
    max-width: $navigation-bar-max-width;
    margin: auto;
}
