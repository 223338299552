@import '../../variables';
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    width: 94px;
}

.iconBox {
    width: 100%;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
}

.selected {
    border-radius: 5px;
    border: solid 2px $primary-branded-color;
    background-color: #ffffff;
}

.radioSelected {
    width: 18px;
    height: 18px;
    margin-top: -10px;
    background-image: url('../../assets/icons/pinkbullet.svg');
}

.checkboxSelected {
    width: 16px;
    height: 16px;
    margin-top: -10px;
    background-image: url('../../assets/icons/checkbox-selected.svg');
}
