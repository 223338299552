@import '../../variables';

.emptyKitHeader {
    @include title;
    font-size: 16px;
    line-height: 1.5;
}

.subtitle {
    @include description();
    margin-bottom: 24px;
}

.chooseOrDragTitle {
    @include description();
    margin-top: 40px;
}

.container {
    @media (max-width: $laptop) {
        [class*='title'] {
            font-size: 22px;
        }
        [class*='subTitle'] {
            font-size: 12px;
        }

        [class*='selectButton'] {
            bottom: 20px;
        }
        min-height: 300px;
        margin-right: 0px;
        padding: 0px;
    }
}

.dropSpotMain {
    border-radius: 6px;
    border: dashed 2px $primary-color;
    background-color: #fafafa;
    transition: height 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    flex: 1 1;
}

.basicEmptyState {
    border-radius: 6px;
    border: dashed 2px $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 50%;
    height: 50%;
    margin-top: 100px;

    .startFromStratch {
        transition: opacity ease-in-out 200ms;

        &:hover {
            opacity: 0.7;
        }
    }
}

.startFromStratch {
    background-color: $start-from-stratch-button-color;
}

.startFromStratchLabel {
    color: $start-from-stratch-label-color;
    font-family: $main-Font;
}

.widgetsInlineMenu {
    margin: unset;
    flex: 1;
}
