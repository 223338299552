.contactCardMain {
    width: 241px;
    /* height: 72px; */
    border-radius: 5px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 11px;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.container {
    display: flex;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    margin-right: 12px;
}

.initials {
    composes: avatar;
    text-align: center;
    border: 1px #bcbcc0 solid;
}

.col {
    display: flex;
    flex-direction: column;
    justify-content: sp;
}

.form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textField {
    margin-top: 22px;
    width: 100%;
}

.menuContainer {
    margin-left: auto;
}
