@import '../../../../variables';

.subHeader {
    display: flex;
    width: 100%;
    align-items: center;
    height: 67px;
    justify-content: flex-end;
    gap: 5px;
    flex: 1 1;

    .presentationMenuTopWithTrialBanner {
        top: $header-height-with-trial-banner;
    }

    .shareMenu {
        @include shareMenu();
        top: $header-height;
    }

    .shareMenuWithTrialBanner {
        @include shareMenu();
        top: $header-height-with-trial-banner;
    }

    .divider {
        height: 18px;
        border-right: 1px solid $divider-color;
    }

    div.signals {
        left: -237px;

        &::before {
            right: 35%;
        }
    }
}
