.container {
}

.closeIcon {
    text-align: right;
    margin-top: 2px;
    margin-right: 10px;
}

.button {
    width: 135px;
    height: 37px;
    border-radius: 20px;
    border: solid 1px #000000;
}

.leftButton {
    width: fit-content;
    margin-right: 25px;
}

.delete {
    color: #ee3543;
}

.input {
    padding-top: 10px;
}

.raw {
    display: flex;
    justify-content: space-between;
}
.field {
    width: 201px;
}
