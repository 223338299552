@import '../../../variables';

$title-padding: 10px 0;

.titlesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(.slides) {
        .title {
            font-size: 22px;
            font-weight: 500;
            display: flex;

            @media (max-width: $mobile) {
                font-size: 16px;
            }
        }

        .subtitle {
            color: $placeholder-color;
            font-size: 14px;

            @media (max-width: $mobile) {
                font-size: 12px;
            }
        }

        &.titleViewModeContainer {
            .title {
                padding: $title-padding;
            }
        }

        &.titleEditModeContainer {
            padding: $title-padding;
            .title {
                color: unset !important;
            }
        }
    }
}

@media (max-width: $mobile) {
    .titlesContainer:not(.pdf):not(.slides) {
        .title {
            font-size: 20px;
        }
        .subtitle {
            font-size: 14px;
        }
    }
}

.descriptionContainer {
    flex-flow: row;
}

.widgetHeaderTitleAddition {
    font-size: 12px;
    margin: auto 20px;
    font-weight: normal;
}

.textEditor {
    :global(.DraftEditor-root) {
        min-height: 20px;
        height: fit-content;
    }
}
