@import '../../../variables';
@import '../overviewVariables';

.container {
    height: calc(50% - 10px);
    border: 1px solid #eff0f6;
    border-radius: 20px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    flex: 1 1 calc(50% - 20px);
    overflow: hidden;
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.titleContainer {
    align-items: center;
    display: flex;
}

.icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background-color: $ICON-BACKGROUND-COLOR;
    border-radius: 50%;
    padding: 6px;
    overflow: unset;
}

.title {
    @include widgetTitle;
}

.cta {
    font-size: 14px;
    width: fit-content;
    border: 1px solid $border-gray;
    border-radius: 4px;
    padding: 0 10px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.contentContainer {
    padding: 0 20px;
    height: inherit;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
}
