@import '../../variables';

.poweredByEverAfter {
    @media only screen and (max-width: $mobile) {
        display: none;
    }

    position: absolute;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    right: 20px;
    bottom: 25px;
    border-radius: 5px;
    padding: 10px 0;
    height: 130px;
    width: 35px;
    display: flex;

    .poweredBy {
        font-family: $main-Font;
        color: $watermarks-color;
        font-size: 10px;
        padding-bottom: 16px;
        align-self: center;
    }

    .everafterLogo {
        transform: rotate(90deg);
    }
}

.poweredByEverAfter:hover {
    background-color: $all-accounts-hover-color;
    cursor: pointer;
}
