@import '../../variables';

.mainContentContainerEdit {
    display: flex;
    flex-direction: row;
    text-align: center;
    box-sizing: border-box;
    padding: 10px 0;
    margin: 0 -20px;
}

.contentContainerView,
.contentContainerViewLine {
    display: flex;
    text-align: center;
    box-sizing: border-box;
}

.contentContainerView {
    flex-direction: row;
}

.contentContainerViewLine {
    flex-direction: column;
    gap: 20px;
}

.columnContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 40px;

    &.editLinkedFields {
        cursor: pointer;
    }

    &::after {
        content: '';
        display: block;
        width: 2px;
        height: 90%;
        position: absolute;
        z-index: 0;
        top: 5%;
        right: 0;
        background: #e9e8ed;
    }
    &:last-child::after {
        display: none;
    }
    :global([aria-controls='simple-menu']) {
        background-color: white;
        font-family: $main-Font;
    }
}

.kpiValue {
    color: #59c5aa;
    font-weight: 700;
    padding: 20px 0 20px;
    max-height: 50px;
    overflow: hidden;
    .kpiField,
    .kpiFieldView {
        height: 50px;
        font-size: inherit;
        font-weight: bold;
        box-sizing: border-box;
    }
    .kpiField {
        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            opacity: 1;
        }
    }
    &.compact {
        font-size: 20px;
    }
}

.pdf {
    .kpiValueView {
        font-size: 18px;
    }
}

.kpiValueView {
    color: #59c5aa;
    font-weight: 700;
    padding: 0 0 10px;
    display: flex;
    flex-direction: column;
    &.compact {
        font-size: 20px;
    }
}

.editPenContainer {
    display: flex;
    justify-content: flex-end;
}

.helperTextIcon {
    margin-left: 10px;
    min-width: 12px;
}
.kpiDescription {
    font-size: 14px;
    color: #545454;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        justify-content: center;
        align-items: center;
    }
    div {
        margin: 0;
        padding: 0;
        font-size: inherit;
    }
    a {
        text-decoration: underline;
    }
}

.kpiField {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    vertical-align: middle;
    &:focus {
        outline: none;
    }
}

.inptitle {
    font-size: 24px;
    color: #000;
    font-weight: bold;
}

.columnContainer .kpiField {
    text-align: center;
}

.addField {
    margin-left: 15px;
    font-size: 12px;
    color: $primary-color;
    height: 20px;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600;
}

.emptyStateContainer {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptyStateValue {
    margin-right: 3px;
    font-size: 14px;
}

.emptyStateDesc {
    font-size: 14px;
}

.sectionsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    .sectionWrapper {
        &:first-of-type {
            padding-top: 10px;
        }

        .sectionTitle {
            font-size: 14px;
            border-bottom: 0.5px solid $section-kpi-seperator;
            margin-bottom: 20px;
            text-align: left;
            padding-bottom: 3px;
            color: $subtext-light-grey;
            font-family: $section-title-kpi-font-family;
            font-weight: bold;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .sections {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .sectionHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .sectionTitle {
                    height: 35px;
                    font-size: 16px;
                    color: $subtitle-info-color;
                    flex: 1 1 90%;

                    :global(.DraftEditor-root) {
                        min-height: 35px;
                        max-height: 35px;

                        :global(.public-DraftEditorPlaceholder-root) {
                            margin: 8px 8px 8px 10px;
                        }

                        :global(.DraftEditor-editorContainer) {
                            margin: 8px 0 0 9px;
                        }
                    }

                    &.value {
                        color: $text-color;
                    }
                }
            }
        }
    }
}

.formEditModeContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(220px, 1fr)) 120px;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
    align-items: center;
    overflow: visible;
    padding-bottom: 10px;

    &.customerReadOnly {
        grid-template-columns: repeat(3, minmax(220px, 1fr)) 20px;
    }

    .formEditModeRow {
        word-wrap: break-word;
        overflow: hidden;
        cursor: pointer;
    }

    .fieldTitle {
        font-family: $main-Font;
        color: $field-name-color;
        font-size: 10px;
    }
}

@media (max-width: $mobile) {
    .contentContainerView:not(.pdf) {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        &.kpiDescription:last-child {
            background-color: red;
        }
        .kpiValueView {
            padding-top: 7px;
            padding-bottom: 10px;
            font-size: 34px;
        }
        .noDescription {
            padding-bottom: 30px;
        }
        .columnContainer {
            padding: 0;
            border-bottom: 1px solid #e9e8ed;
            &::after {
                display: none;
            }
        }
        .columnContainer:last-child {
            border-bottom: none;
        }
        .kpiDescription {
            padding-bottom: 30px;
            margin-top: 5px;
        }
        .noTitle {
            margin-top: 0;
        }
    }

    .sectionsContainer {
        gap: 20px;
    }

    .columnContainer:last-of-type {
        .kpiDescription {
            padding-bottom: 15px;
        }
    }
}

.text {
    font-size: 14px;
}
