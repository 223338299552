.calendarIconContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding-bottom: 3px;
}

.iconLabel {
    font-size: 14px;
    padding-right: 13px;
    margin-top: 2px;
}

.meetingsIcon {
    width: 16px;
}

.textFieldContainer {
    position: relative;
}

.resetIcon {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 8px;
    z-index: 1;
}
