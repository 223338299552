@import '../../../../variables';

.title {
    @include title();
    font-size: 14px;
}

.infoIcon {
    margin-left: 5px;
}
