@import '../../../../../variables';

$hide-participants-on-csm: 1170px;
$hide-join-button-on-csm: 1222px;
$hide-meeting-recording-on-csm: 1360px;

$hide-participants-on-shared-view: $medium-laptop;
$hide-join-button-shared-view: 1274px;

$participants-max-users-width: 78px;

.row {
    display: flex;

    &.expand {
        height: calc(100vh - 260px);
    }

    .detailsRow {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        @media (max-width: $mobile) {
            flex-direction: row;
            align-items: flex-end;
            width: 100%;

            &.withLinkRecording {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .columnOne {
            width: 255px;
            height: fit-content;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 5px;

            @media (max-width: $mobile) {
                position: relative;
                width: 100%;
                padding-right: 5px;
                flex: 1 1;
            }

            .meetingTitle {
                width: fit-content;
                font-size: 14px;
                font-weight: 600;
                color: $text-color;

                .recurring {
                    margin-left: 6px;
                }
            }

            .upcomingMeeting {
                font-size: 11px;
                margin-bottom: 3px;
            }
        }
    }

    .detailsBtn {
        display: flex;
        align-items: center;
    }
}

.recapLabelWrapper {
    display: inline-block;
}

.dontShow {
    opacity: 0;
}

.expandButtons {
    display: inline-flex;
    align-items: center;
    flex: 1 1;
    justify-content: flex-end;

    @media (max-width: $mobile) {
        &.withLinkRecording {
            justify-content: space-between;
            width: 100%;
        }
    }
}

.dateAndTimeContenier {
    display: flex;
    width: fit-content;
    font-size: 12px;
    font-family: $date-and-time-font-family;
    color: $subtext-light-grey;
    margin-top: 4px;
}

.pastMeeting {
    opacity: 0.5;
}

.divider {
    margin-right: 10px;

    @media (max-width: $mobile) {
        display: none;
    }
}

.participants {
    width: $participants-max-users-width;
    display: inline-flex;
    height: fit-content;
    margin-left: 25px;
    margin-right: 30px;
}

.participantsCostumerView {
    @media (max-width: $hide-participants-on-shared-view) {
        display: none;
    }
}

.participantsCsmView {
    @media (max-width: $hide-participants-on-csm) {
        display: none;
    }
}

.joinWrapper {
    display: flex;
    place-items: center;
}

.joinWrapperCsmView {
    @media (max-width: $hide-join-button-on-csm) {
        display: none;
    }
}

.joinWrapperCostumerView {
    @media (max-width: $hide-join-button-shared-view) {
        display: none;
    }
}

.meetingRecording {
    display: flex;
}

.meetingRecordingCsmView {
    @media (max-width: $hide-meeting-recording-on-csm) {
        display: none;
    }
}

.brakeLine {
    flex-direction: column;
    align-items: flex-start;
}

.space {
    margin-top: 12px;
}
