.customerActionsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .label {
    font-size: 14px;
    font-weight: 600;
  }

  .explanationTextLabel {
    font-size: 12px;
  }

  .disableMode {
    opacity: 0.5;
  }
}
