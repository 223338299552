@import '../../variables';

.notificationsDot {
    border-radius: 100px;
    width: 16px;
    height: 12px;
    padding: 1px;
    background: $notification-icon-color;
    z-index: 100000;
    top: -2px;
    right: -4px;
    color: #ffff;
    text-align: center;
    position: absolute;
    font: 10px Arial, sans-serif;
}
