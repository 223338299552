@import '../../variables';

.participants {
    display: flex;
    height: fit-content;
    > * {
        height: fit-content;
        display: inline-flex;
    }
}

.participant {
    display: flex;
    align-items: center;

    &.internalBorder {
        > span {
            transition: all 1ms ease-in;
            outline: 2px solid $internal-data-border-color;
            border-radius: 50%;
        }
    }
}

.avatarContainer {
    display: inline-flex;
    margin-left: -5px;
}
.avatar {
    min-width: 21px;
    min-height: 21px;
    max-width: 21px;
    max-height: 21px;
    border-radius: 50%;
    margin-bottom: 0;
}

.restOfParticipants {
    font-family: $main-Font;
    font-size: 12px;
    line-height: 22px;
    margin-left: -5px;
    font-weight: 500;
    color: $avatar-icon-font-color;
    background-color: $avatar-icon-background-color;
    display: flex;
    justify-content: center;
}

.hiddenParticipantsCount {
    font-size: 9px;
    font-family: $main-Font;
    background-color: $avatar-icon-background-color;
    color: $avatar-icon-font-color;
    border-radius: 50%;
    height: 21px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    line-height: 19px;
    align-items: flex-end;
}
