@import '../../variables';

.header {
  height: 67px;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;

    @media (min-width: $medium-laptop) {
      margin-right: 15px;
    }
  }

  .accountFilter {
    flex-grow: 0;
  }
}

.divider {
  @extend %divider;
}