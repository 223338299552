@import '../../../../../../../variables';

.type {
    margin: auto;
    position: relative;
    @media (max-width: $mobile) {
        margin: unset;
        position: absolute;
        top: 19px;
        left: 16px;
    }

    .accountLogo {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        position: absolute;
        top: -17px;
        left: -10px;
    }

    .label {
        border-radius: 40px;
        padding: 4px 7px;
        font-size: 12px;
        font-weight: bold;
        width: 25px;
        text-align: center;

        &.fileLabel {
            background-color: rgba(171, 194, 100, 0.25);
            color: $share-material-file-label-color;
        }

        &.linkLabel {
            background-color: rgba(35, 99, 230, 0.1);
            color: $share-material-link-label-color;
        }

        @media (max-width: $mobile) {
            visibility: hidden;
        }
    }
}

.tooltip {
    text-align: center;
}