@import '../../../../variables';

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .description {
        @include description();
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .maxHeightInput {
        margin-left: 25px;
    }
}
