@import '../../../../variables';

.dialogContent {
    width: 335px;
    overflow-x: hidden;
}

.optionsTitle {
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
}

.addValueBtn {
    justify-content: left;
    margin-left: 10px;
}

.optionsContainer {
    margin-top: 30px;
}

.workspaceIdContainer {
    font-weight: bold;
    color: $text-color;
    margin-top: 55px;
    margin-bottom: 6px;
}

.text {
    @include description();
}

.selectedDefaultFields {
    margin-top: 20px;
    display: flex;
    font-size: 12px;
    align-items: baseline;

    & .boldText {
        font-weight: bold;
        padding-right: 12px;
        border-right: 1px solid $field-selector-border-color;
        margin-right: 10px;
    }
}

.fieldsRow {
    max-width: 60%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.description {
    margin-top: 26px;
    font-size: 14px;
    padding: 0 26px;
}
