.dialogBody {
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 20px;
  width: 480px;
  max-height: 65vh;
  overflow: auto;
  box-sizing: border-box;
}

.inputContainer {
  textarea {
    // --font-family is a variable set in the element style.
    // This is done since textarea has a font-family set in index.scss and none of the props in input affect the element directly.
    font-family: var(--font-family);
  }
}