.container {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding: 22px 18px;

  .title {
    font-size: 17px;
    font-weight: bold;
  }

  .description {
    font-size: 12px;
  }
}