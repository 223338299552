@import '../../../../variables';
.container {
    display: flex;
    padding: 10px 15px 20px;
    flex-direction: column;
    border-bottom: 1px solid $separator-color;

    .title {
        @include title();
        font-size: 16px;
        padding: 10px 0;
    }
}

.subtitle {
    margin-top: 10px;
    margin-bottom: 20px;
    opacity: 0.7;
}

.lightUserCurrentKit {
    @include ellipsis();
    font-size: 12px;
    width: 202px;
}

.add {
    color: $primary-color;
    font-weight: 600;
    padding-right: 80px;

    svg {
        margin-right: 8px;
    }

    .iconPink {
        filter: invert(86%) sepia(37%) saturate(6950%) hue-rotate(305deg) brightness(106%) contrast(98%);
        margin: 0 10px 0 5px;
    }
}

.info {
    height: 12px;
    width: 12px;
}

.header {
    display: flex;
    gap: 10px;
    align-items: center;
}

.templateMenuItemContiner {
    display: flex;
    width: 100%;

    .templateName {
        flex: 90%;
    }

    .templateUsage {
        justify-items: flex-end;
        padding-left: 50px;
    }
}
