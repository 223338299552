%regular_text {
  font-size: 12px;
  color: #000;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.subtitle {
  @extend %regular_text;
}

.rolesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.roleLine {
  @extend %regular_text;
  display: flex;
  gap: 5px;
  align-items: center;
}

.roleValue {
  font-weight: bold;
}