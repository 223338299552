@import '../../../variables';

$max-body-height-progress-status: 600px;
$max-body-height-milestones-status: 520px;

.addSectionButtonContainer {
    border-top: 1px solid $widget-container-border-color;
    width: 100%;
    margin-top: 13px;
    padding-top: 12px;

    .addSectionButton {
        display: flex;
        justify-content: flex-start;
        width: 140px;
        @include addTaskItemButton();
    }
}

.anchorHighlight {
    width: calc(100% - 10px);
    height: calc(100% - 6px);
    border-radius: 0;

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.section {
    padding-bottom: 10px;

    &:last-of-type {
        padding-bottom: 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    position: relative;

    @media (max-width: $mobile) {
        width: 100%;
    }

    .header {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        height: fit-content;

        @media (max-width: $mobile) {
            width: calc(100% - 20px);
        }
    }

    .loader {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        background: white;
        z-index: 10;
        max-height: -webkit-fill-available;
        display: flex;
        justify-content: center;
        align-items: center;

        .loadingLogo {
            height: 67px;
            width: 67px;
        }
    }

    .body {
        flex-direction: column;
        flex-shrink: 1;
        overflow: auto;
        display: flex;

        @media (max-width: $mobile) {
            overflow: hidden;
        }
    }

    .progressStatusBarBody,
    .milestoneStatusBarBody {
        overflow: auto;

        @media (max-width: $mobile) {
            max-height: unset;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        height: fit-content;
    }
}

.editableSectionWrapper {
    min-height: 5px;
}

.overflowVisible {
    > div:last-of-type {
        overflow: visible !important;
    }
}

.widgetContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div:last-of-type {
        display: flex;
        flex-direction: column;
        width: auto;
        height: 100%;
        flex-shrink: 1;
        overflow: auto;
    }

    @media (max-width: $mobile) {
        overflow: overlay !important;
        max-height: unset !important;

        &::-webkit-scrollbar-thumb,
        div::-webkit-scrollbar-thumb {
            background-color: transparent !important;
        }

        [class*='titleContainer'] {
            padding: 20px 5px 0 10px;
        }

        [class*='widgetContent'] {
            padding: 0 5px 20px 10px;
        }

        [class*='collapsedWidgetTitleContainer'] {
            padding-bottom: 20px;
        }
    }
}

.tasksCompleted {
    color: $checked-tag-color;

    .vIcon {
        vertical-align: middle;
        padding-right: 10px;
    }
}

.tasksTitleAddition {
    display: flex;
    align-items: center;
    gap: 20px;
    white-space: nowrap;
    flex: 1 1;
    justify-content: space-between;
}

.divider {
    border-left: 1px solid $separator-color;
    height: 19px;
}

.widgetTitle {
    display: flex;
    align-items: center;
}

.widgetHeaderTitleAddition {
    flex: 1 1;
}

.leftSideAdditional {
    display: flex;
    gap: 10px;
    align-items: center;
}

div.titleAdditionHeader {
    padding-top: unset !important; // remove padding from widget title
}

.sectionContainer {
    margin-bottom: 5px;
}

.taskPlaceholder {
    height: 32px;
}
