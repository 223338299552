@import '../../../../../variables';
@import '../Tasks.module';

.fieldUpdatedContainer {
  border-radius: 2px;
  background-color: rgba(223, 225, 227, 0.2);
  margin: 10px 0;
  font-size: 10px;
  color: $collaborators-info-color;
  padding: 2px 5px;

  .title {
    font-weight: bold;
  }
}

.fieldsList {
  display: flex;
  flex-direction: column;
  margin: 5px 0 15px 15px;
  gap: 5px;

  .field {
    display: list-item;

    .fieldValue {
      color: black;
      font-weight: bold;
      margin-left: 5px;

      p {
        font-size: 10px;
      }
    }

    .plaintextValue{
      white-space: pre-line;
      margin-left: 0;
    }
  }
}

.regularContainerMargin {
  @include elementsBelowTaskCheckbox();
}

.multiStatusContainerMargin {
  @include elementsBelowTaskMultiStatus();
}