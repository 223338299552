@import '../../_variables.scss';

.arrowButton {
    background-color: unset;
    svg {
        height: 11px;
        width: 8px;
        @media (max-width: $mobile) {
            height: 22px;
            width: 12px;
        }
    }
    visibility: hidden;
}

.visible {
    visibility: visible;
}
