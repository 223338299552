.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
    max-width: 385px;
}

.description {
    font-size: 14px;
}

.saveButton {
    font-size: 16px;
}
