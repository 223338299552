@import '../../../../../variables';

.commentIcon {
  width: 20px;
  height: 20px;
}

.numberOfComments {
  margin-right: 7px;
}
.numberOfCommentsMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.commentsIcon {
  padding: 6px 2px 0 8px;
  box-shadow: none !important;
  margin-top: 6px !important;
  cursor: pointer;
  text-align: center;
}

.csmCommentsIcon {
  margin-bottom: 0 !important;
  border: 2px solid transparent !important;

  &:hover {
    border: solid 2px $primary-branded-color !important;
  }
}

.customerCommentsIcon {
  &:hover {
    box-shadow: 0 1px 5px 0 rgba(162, 162, 162, 0.5) !important;
  }
}

.firstCommentAnimation {
  -webkit-animation: swing 0.8s ease-in-out both;
  animation: swing 0.8s ease-in-out both;
  transition: border 0.2ms ease-in-out;
}

@mixin swingAnimation {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  @include swingAnimation();
}

@-webkit-keyframes swing {
  @include swingAnimation
}

.noComments {
  opacity: 0;
}