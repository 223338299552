@import '../../../variables';

.label {
    @include midTitle();
}

.accountDropDownContainer {
    display: flex;
    flex-grow: 2;
}

.accountDropDownContainer:hover {
    cursor: pointer;
}

.dropDownArrow {
    margin-left: 7px;
}

.customViewOption {
    display: flex;
    align-items: center;
    min-width: 150px;
    width: fit-content;
    max-width: 600px;
    height: 100%;
    justify-content: space-between;
}

.reorderViews {
    font-weight: 600;
    color: $primary-branded-color;
}