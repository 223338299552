@import '../../../../../variables';

.infoTextParagraph {
    @include description();
    max-width: 400px;
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
}

.container {
    @include horizontalCenter();
    height: fit-content;
}
