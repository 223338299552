@import '../../../variables';
.container {
    padding: 0 15px;
    border-radius: 6px;
    margin: 10px 0 0;
    &.dashed {
        border: 2px dashed #d9d9d9;
        padding-bottom: 10px;
    }
}

.templateContainer {
    display: flex;
    min-height: 149px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
    margin-top: 14px;
    font-size: 12px;
    flex-direction: column;

    .icon {
        margin-top: 22px;
        margin-bottom: 6px;
    }
    .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 7px;
    }
    .footerText {
        font-size: 14px;
        margin-bottom: 31px;
    }
}

.emptyContainer {
    display: flex;
    min-height: 149px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 12px;
    flex-direction: column;
    padding-top: 35px;
    .content {
        max-width: 692px;
        text-align: center;
    }
}

.emptyMeetingsMarginContainer {
    margin-top: 14px;
}

.calendarImage {
    margin-bottom: 16px;
}

.notConnectedWithMeetings {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 32px;

    .text {
        text-align: center;
        width: 360px;
        font-size: 12px;
        margin-bottom: 6px;
    }
}

.description {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    text-align: center;
}

.meetingsPreviewImg {
    margin-top: 34px;
    margin-bottom: 35px;
    background-color: white;
}

.connectButton {
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
}
.readMoreText {
    margin-top: 34px;
    margin-bottom: 22px;
}

.toolbar {
    display: flex;
    flex-direction: row-reverse;
}

.connectCalendarFooter {
    @include center;

    .connectCalendarText {
        font-size: 12px;
    }
}

@media (max-width: $mobile) {
    .container {
        padding: 0;
    }
    .meetingsDataContainer {
        padding-left: 5px;
    }
}
