@import '../../../variables';
@import '../../Task//TaskMultiStatusSelector/TaskMultiStatusSelect.module.scss';

$hourglass-background: $task-status-not-started-background;
$hourglass-color: $task-status-not-started-color;

.hourglassContainer {
    height: 100%;
    display: flex;
    align-items: center;
}

.statusBtn {
    @extend %TASK_MULTI_SELECT;
    background-color: $hourglass-background;
    color: $hourglass-color;
    height: 22px;
    cursor: pointer;
    place-content: center;
}

.hourglassIcon {
    height: 15px;
    width: 16px;
}

.statusBtnSpace {
    margin-left: 3px;
}
