.content {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;

    .description {
        font-size: 14px;
    }
}

.confirmationDescription {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
