@import '../../../../variables';

.container {
    border: 1px solid $task-plan-empty-state-border-color;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    font-family: $main-Font;

    .description {
        font-size: 14px;
        font-weight: bold;
    }

    .footer {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}
