@import '../../../../variables';

$section-seperator: #d1d1d1;
$completed-section-color: #989898;

.container {
    display: flex;
    flex-direction: column;

    &.additionalCollapseContainer {
        gap: 10px;
    }

    .actionsContainer {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .sectionContainer {
        display: flex;
        flex-direction: column;
        background: $widget-container-background-color;

        &.seperator {
            border-bottom: 1px solid $section-seperator;
        }

        .actions {
            display: flex;
            align-items: center;
            opacity: 0;
            transition: opacity 0.2s ease-in;

            &:not(.withValue) {
                padding-left: 5px;
                margin: 0 5px;
            }

            &.withValue {
                opacity: 1;
            }
        }

        .relativeDate {
            display: flex;
            gap: 7px;
            cursor: pointer;
            font-size: 14px;
        }

        .datesContainer {
            color: $text-color;
            display: flex;
            align-items: center;
            height: 20px;
            padding-left: 5px;

            &.completedSection {
                color: $completed-section-color;
            }
        }

        &:hover {
            .actions {
                opacity: 1;
                transition: opacity 0.2s ease-in;
            }
        }

        @media (max-width: $mobile) {
            width: calc(100% - 10px);
        }
    }
}

.automationIcon {
    padding-right: 5px;
}

.alignToCsmViewTaskDatesWithRobotIcon {
    padding-right: 36px;
}

.alignToCustomerViewTaskDatesWithRobotIcon {
    padding-right: 31px;
}

.alignToCsmViewTaskDates {
    padding-right: 52px;
}

.alignToCustomerViewTaskDates {
    padding-right: 46px;
}

.datesSeparator {
    padding: 0 6px 0 11px;
}

.actionButton {
    @include taskActionButton();
    height: 13px;
    width: 16px;
    padding: 4px;
}

.separator {
    height: 16px;
    border-left: 1px $widget-container-border-color solid;
    margin: 0 5px;
}

.datePicker {
    display: flex;
    align-items: center;
}

.sectionDisplayValue {
    display: flex;
    align-items: center;
    height: fit-content;
}

.title {
    @include title();
    font-size: 16px;
}

.deleteIcon {
    width: 16px;
    height: 18px;
    padding: 4px;
    @include deleteButton();
}

.internalNotesContainer {
    background-color: $internal-data-background-color;
    margin: 5px 0;
}

.internalNotesEditor {
    [class*='textArea'] {
        background-color: $internal-data-background-color;
    }

    background-color: $internal-data-background-color;
}

.confirmTitle {
    min-width: 400px;
    @include ellipsis();
}

.tasks {
    background: $widget-container-background-color;
}

.editDialogHeader {
    display: flex;
    border-bottom: 1px $separator-color solid;
    padding: 15px 20px;
    align-items: center;
    gap: 5px;
}

.titleDialogInput {
    width: 100%;
    margin-right: 20px;
    background: $widget-container-background-color;
    max-height: unset;
}

.datesDialogContainer {
    display: flex;
    gap: 15px;
}

.editableItem {
    cursor: pointer;
}

.descriptionAreaWrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-right: 5px;

    &.collapsable {
        padding-left: 20px;
    }
}

.inlineSectionActions {
    padding-left: 8px;

    [class*='inlineEditButton'] {
        height: 13px;
        width: 18px;
        padding: 4px;
    }
}

.updateOnCompletionIcon {
    cursor: default;
}

.clickableTextarea {
    cursor: pointer;
    padding: 6px;
    margin: 1px;

    &:hover {
        margin: 0;
        border: 1px dashed $separator-color;
    }
}

.internal {
    background: $internal-data-background-color;
}

.inputContainer {
    position: relative;
    display: flex;
    width: 100%;

    &.editableInputContainer {
        min-height: 35px;
    }

    .expandIcon {
        position: absolute;
        right: 3px;
        top: 3px;
        padding: 5px;
        height: 15px;
        width: 15px;
        @include taskActionButton();
    }

    .title {
        font-weight: 900;
    }
}

.addTaskButton {
    @include addTaskItemButton();
    margin: 8px 0;
    width: 120px;

    &.collapsable {
        margin: 8px 8px;
    }
}

.collapseButton {
    width: 14px;
    min-width: fit-content;
    padding: 5px;
}

.tasksCompleted {
    color: $checked-tag-color;
    padding-left: 10px;
    cursor: pointer;

    .text {
        font-size: 14px;
    }

    .vIcon {
        vertical-align: middle;
        padding-right: 5px;
    }
}

.taskSectionStatus {
    padding-left: 10px;
    font-size: 14px;
    cursor: pointer;
}

.completedSection {
    color: $completed-section-color;
}

.taskPlaceholder {
    height: 32px;
}