@import '../../variables';

.customerMessageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 450px;
    font-size: 28px;
    font-family: $title-Font;
    margin-top: 230px;
}

.title {
    text-align: center;
    margin-top: 58px;
}
