@import '../../../variables';

.editBox {
    position: relative;
    cursor: pointer;
    min-height: 30px;
    border: 1px dashed transparent;

    &.editing {
        cursor: initial;
    }

    &:hover {
        border: 1px dashed $separator-color;
        .editButton {
            opacity: 1;
            transition: opacity 0.1s linear;
        }
    }

    .editButton {
        width: 40px;
        height: 20px;
        aspect-ratio: 1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.1s linear;
    }

    .fixedEditButton {
        position: absolute;
        height: 40px;
        right: 0;
        top: 0;
    }
}
