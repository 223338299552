@import '../../_variables.scss';
.divider {
    height: 1px;
    background-color: #edecf0;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tasksContainer {
    flex-wrap: wrap;
    min-height: 15px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    @media (max-width: $mobile) {
        position: relative;
        left: -13px;
    }
}

.dateText {
    min-width: fit-content;
    font-size: 14px;
    margin: 0 0 0 20px;
}

.editDaysPlaceholder {
    width: 170px;
    display: flex;
    align-items: center;

    .meetingIcon {
        width: 16px;
    }
}

.editDaysTextBox {
    width: 170px;
    margin-left: 10px;
}

.customerAddTask {
    font-size: 14px;
}

.dateFieldContainer {
    margin-left: 20px;
}

.twoColumns {
    width: 45%;

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.oneColumnTransparent {
    width: calc(100% - 75px);
    padding: 0 25px;
}

.oneColumnRegular {
    width: 100%;
}

.taskWrapper {
    margin-top: 10px;
    padding: 3px 3px 3px 0px;
}

:global(.flex-row) {
    &.hidden {
        display: none;
        padding: 0;
        margin: 0;
    }
}

.internalNotes {
    font-size: 14px;
    background-color: $internal-data-background-color;
    color: $placeholder-color;
    padding: 0 6px;
    white-space: pre-line;
}

.description {
    font-size: 14px;
    color: $placeholder-color;
    white-space: pre-line;
}

.transparentStyle {
    padding: 0 25px;
}

.singleAddTask {
    font-family: $main-Font;
    cursor: pointer;
    font-size: 12px;
    padding-left: 30px;
}
