@import '../../../variables';

.searchContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .content {
        @include widgetDescriptionPanel;
    }

    .searchFieldContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .widgetTitle {
            @include widgetTitle;
        }

        .information {
            @include widgetInformationPanel;
        }
    }
}
