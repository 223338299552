@import '../../variables';

.iconWrapper {
    @include iconWrapperBorderColor();
    background-color: white;
}

.context {
    display: flex;
    flex-direction: column;
    gap: 14px;
}
