@import '../../variables';

.shareBtn {
    height: 33px;

    &:hover {
        background-color: $purple-action-button-menu-hover-color;
    }
}

.sharedButtonLabel {
    @include textualButtonLabel;
    svg {
        height: 14px;
        width: 12.5px;
        fill: white;
    }
}
