@import '../../_variables.scss';
@import './WorkspaceSettings/FieldsSettings/FieldsSettings.module.scss';

.container {
    display: flex;
    flex: 100%;
    flex-direction: column;
    width: 100%;
}

.mainContent {
    margin: 0 135px 68px;

    & hr {
        margin-top: 61px;
        border: none;
        border-top: 1px solid $separator-color;
    }
}

.workspaceIdContainer {
    font-weight: bold;
    color: $text-color;
    margin-top: 55px;
    margin-bottom: 6px;
}

.emailTemplateContaner {
    font-size: 14px;
    max-width: 800px;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    padding: 10px;
    white-space: pre-wrap;

    .grey {
        color: #757575;
    }
}

.additionalSettingsRow {
    margin-bottom: 18px;
}

.externalStorageLink {
    color: $primary-branded-color;
}

.supportText {
    font-weight: bold;
    font-style: italic;
}

.permissionsText {
    width: 161px;
    padding-right: 20px;
}

.oval {
    width: 7px;
    height: 7px;
    background-color: #cbcbcb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;

    &.on {
        background-color: #13c32c;
    }
}

.flexBetween {
    width: 100%;
    justify-content: space-between;
}

.crmIntegrationRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .label {
        display: flex;
        align-items: center;
    }

    .buttonsArea {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .buttonRow {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
        }
    }
}

.additionalObjectsRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.field {
    margin-top: 20px;
    margin-bottom: 15px;
}

.text {
    @include description();
}

.settingTextContainer {
    max-width: 850px;
}

.grayText {
    @include description(12px, #5c5a5a);
}

.additionalObject {
    margin: 0 0 15px 15px;
    display: flex;
    align-items: center;

    .text {
        margin-right: 10px;
    }

    .trash,
    .edit,
    .duplicate,
    .sync {
        height: 13px;
        cursor: pointer;
        margin-left: 20px;
        transition: opacity 0.3s ease-in;
        opacity: 0;

        svg {
            height: 13px;
            vertical-align: top;
        }
    }

    .options {
        display: flex;
    }

    .sync.updating {
        animation: rotation 1s infinite ease;
        opacity: 1;
    }

    &:hover .options {
        > * {
            opacity: 1;
        }
    }
}

.editIntegration {
    margin-left: 10px;
}

.editHs {
    margin-left: 100px;
}

.integrationSectionTitle {
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 40px;
}

.tableauButtons {
    margin-left: 292px;
}

.businessContent {
    margin-top: 20px;
}

.addTableau {
    margin-top: 10px;
}

.tableauEdit {
    margin-right: 10px;
}

.configurationLabel {
    min-width: fit-content;
    padding-right: 18px;
}

.providerSelect {
    max-width: 291px;
}

.providerOption {
    width: 229px;
    height: 24px;
    display: flex;
    align-items: center;

    > * {
        height: 22px;
        padding-right: 10px;
    }

    > *:first-child {
        width: 16px;
        height: 16px;
    }
}

.teamMembersLink {
    color: $primary-color;
    margin-left: 5px;
    font-weight: bold;
}

.defaultViewSettings {
    padding-right: 20px;
}

.integrationsDocsLink {
    color: $primary-color;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 50px;
    display: block;
    width: fit-content;
}

.learnMoreHs {
    color: $primary-color;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
    display: block;
}

.row {
    display: flex;
    align-items: center;
}

.column {
    > * {
        margin-bottom: 18px;
    }
}

.hidden {
    visibility: hidden;
}

.title {
    font-size: 12px;
    margin-bottom: 20px;
}

.integrationContainerMargin {
    margin-bottom: 20px;
}

.sharedPageAccessInfo {
    width: 140px;
}

.zapierSubtext {
    color: $primary-color;
}

.bigLineSpacing {
    line-height: 3.2;
}

.infoTitle {
    font-size: 14px;
    font-weight: bold;
    color: $text-color;
}

.customSenderName {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;

    .header {
        font-weight: bold;
    }
}

.emailSenderDescription {
    font-size: 12px;
}