@import '../../../../variables';

.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        margin: 15px 0 0 0;
    }
}
