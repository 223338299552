@import '../../../../variables';

.msgCommon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.noAccountsUpperMsg {
    @extend .msgCommon;
    margin-top: 20px;
}

.noAccountsMainMsg {
    @extend .msgCommon;
    margin-top: 55px;
    font-family: $secondary-font;
    font-size: 60px;

    .textRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $secondary-font;
        text-align: center;
    }
}

.noAccountsBottomMsg {
    @extend .msgCommon;
    margin-top: 22px;
    font-size: 24px;
}

.smallTextRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.newAccountBtn {
    margin-top: 40px;
}
