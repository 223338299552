@import '../../variables';

.container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slidesMode {
    @media (min-width: $mobile) {
        transform: scale(1.15);
    }
}

.accountPageTitle {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 23px;
    width: 100%;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-color;
    border-radius: 4px;
}

.widgetWrapper {
    width: 100%;
}

.mainContent {
    @include navigationBarPadding();

    width: -webkit-fill-available;
    align-items: flex-start;
    max-width: $widget-content-width;
    max-height: $widget-content-height-without-hub-layout;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile) {
        max-width: calc(100% - 60px);
    }
}

@media (min-width: $mobile) {
    .mainContent:not(.mainContentSlidesMode) {
        padding: 20px 63px 0;
    }
}

.mainContentSlidesMode {
    margin: auto;
    align-items: center;

    @include slidesMainContent();
}

.closingSlide * {
    @include closingSlide();
}

.navigationBar {
    max-height: $widget-content-height-customer-hub-with-tabs;
}

.noNavigationBar {
    max-height: $widget-content-height-customer-hub;
}

.contact {
    margin: 0 15px;
    flex: 1 1;
}
.widget {
    width: 100%;
    position: relative;
    max-width: $widget-max-width;

    &:hover {
        [class*='noNotes'],
        [class*='noComments'] {
            opacity: 1;
            transition: opacity 100ms ease-in-out;
        }
    }

    &:not(:hover) {
        [class*='customerCommentsIcon'] {
            border: unset !important;
        }
    }
}

.widgetSlidesMode {
    margin: auto;
    overflow: hidden auto;

    @media (max-width: $mobile) {
        max-width: $mobile-widget-content-max-width;
        overflow: hidden overlay;
    }
}

@media (max-width: $mobile) {
    .mainContent:not(.mainContentSlidesMode) {
        margin: 70px 20px 30px 20px;
    }

    .mainContent {
        max-height: calc(100vh - 155px);
        padding: 0;
        min-width: calc(100% - 60px);
        [class*='collaboration'] {
            right: 4px;
            flex-direction: row;
            gap: 15px;
            top: -40px;
            width: unset;
        }
        [class*='customerCommentsIcon'] {
            margin: 0 !important;
        }
    }
    .contact {
        margin: 0;
    }
}

.loaderScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}
.loaderHeader {
    width: 100%;
    height: 55px;
    background: black;
    padding: 9px 18px;
}

.avatarSkeleton {
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }
}

.loader {
    min-width: 831px;
    @media (max-width: $mobile) {
        min-width: 90%;
    }
    width: 60%;
    padding: 41px 75px 36px 66px;
    .title {
        h3 {
            height: 8px;
        }
    }

    .skeletonTemplate {
        justify-content: space-evenly;
        display: flex;
        flex-direction: column;
    }

    .widgetSkeletonWrapper {
        > * {
            height: 100%;
            width: 100%;
            padding: 37px 37px 37px 26px;
        }

        &.upperSkeleton {
            height: 114px;
            padding-top: 35px;
        }

        margin-top: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        border: 1px solid #e9e9e9;
        width: 100%;
        height: 159px;
        h3 {
            border-radius: 4px;
            height: 8px;
            margin: 0;
        }
        li {
            border-radius: 4px;
            height: 8px;
        }
        .rectangle {
            > div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }
            h3 {
                width: 70%;
                height: 85px;
                border-radius: 0;
            }
        }
    }
}

.sharedHub {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: fit-content;
    min-height: 100vh;
}

@mixin withHeader() {
    overflow: auto;
}

.withHeaderCompactNavBar {
    @include withHeader;
    min-height: calc(#{$customer-hub-with-header} - #{$navigation-bar-height});
}

.withoutHeaderCompactNavBar {
    @include withHeader;
    min-height: calc(100vh - #{$navigation-bar-height});
}

.withHeaderWithoutNavBar {
    @include withHeader;
    min-height: $customer-hub-with-header;
}

.navComponent {
    height: $navigation-bar-component-without-padding;
    width: 100%;
    > * {
        width: calc(#{$widget-content-width} + 100px);
        @media (max-width: $mobile) {
            width: 100%;
        }
    }
}

.navComponentLayout {
    width: 100%;
}

.loadingWidgets {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ownerLink {
    position: absolute;
    bottom: 0;
    right: 10px;
    height: 20px;
    width: 135px;
    background-color: $owner-link-background-color;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    font-family: $main-Font;
}

.noHeader {
    display: flex;
    position: absolute;
    right: 1%;
    top: 8px;
    z-index: 101; // 100 from CustomerHubLayout .header // should be less than 1000 for the dialog overlay
    justify-content: flex-end;
    @media (max-width: $mobile) {
        width: 100%;
    }

    .searchBtnWithoutHeader [class*='searchWindow'] {
        @media (max-width: $mobile) {
            right: 0%;
        }
    }

    .headlessSearchIconClassName{
        margin-right: 0;
    }
}

.notificationBtnWithHeader {
    position: absolute;
    right: 25px;
    top: 9px;
    z-index: 101; // 100 from CustomerHubLayout .header // should be less than 1000 for the dialog overlay
}

.navigationWithAdditionalAssets {
    [class*='navComponentContainer'] {
        > * {
            width: 60% !important;
        }
    }
}

// Custom loader styles

.customLoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 30px;
}

.customLoader {
    width: 100%;
    max-width: 300px;
    height: auto;
}