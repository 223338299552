@import '../../../../../../../../variables';

.title {
    font-size: 18px;
    font-weight: bold;
    color: $text-color;
}

.description {
    color: $text-color;
    font-size: 14px;
    margin: 20px 0 40px;
    max-width: 500px;
}

.imageLogo {
    margin: 20px 0;
}
