.accountColumnContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    .accountLogo {
        flex-shrink: 0;
        border-radius: 20px;
        border: solid 1px #e9e8ed;
        height: 25px;
        width: 25px;

        &.error {
            display: none;
        }
    }

    .defaultLogo {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 20px;
        text-align: center;
    }
}
