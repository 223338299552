@import '../../../variables';

.addButton {
    color: $primary-color;
    font-family: $main-Font;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.addButtonIcon {
    fill: $primary-color;
    padding-right: 10px;
}
