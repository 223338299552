@import '../../../_variables.scss';

.accountCardMain {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 268px;
    cursor: pointer;
    height: fit-content;
    margin-right: 21px;
    margin-bottom: 21px;
    position: relative;
}

.contactCardMain {
    width: 241px;
    height: 52px;
    border-radius: 5px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.mainContainer {
    border-radius: 10px;
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.logoContainer {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #e9e8ed;
    border-radius: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #ffffff;
}

.cardContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
}

.moreContainer {
    display: flex;
    flex-direction: row-reverse;
    height: 10px;
}

.accountCardMenu {
    position: absolute;
    right: 0;
    z-index: 1;

    &:hover ~ .cardContainer {
        background-color: $account-card-hover-background-color;
    }
}

.contentCardsContainer {
    margin: 20px;
}

.cardContainer {
    display: flex;
    width: inherit;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    height: 204px;
    flex-direction: column;
    &:hover {
        background-color: #f3f3f3;
    }
    position: relative;
}

.button {
    width: 97px;
    display: flex;
    height: 37px;
    align-items: center;
    margin-top: 20px;
    border-radius: 25.5px;
    justify-content: center;
    background-color: #ffffff;
    border: solid 1px #000000;
    margin-bottom: 30px;
}

.companyDetailsContainer {
    width: 153px;
}

.optionalItem {
    display: flex;
    /* align-self: flex-start;
  width: 70px; */
}

.optionalItemValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 153px;
}

.optionalItemsWrapper {
    width: 153px;
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
    opacity: 0.7;
    justify-content: center;
}

.dotMargin {
    margin-left: 3px;
    margin-right: 3px;
}

.avatar {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.defaultIcon {
    line-height: 55px;
    font-size: 40px;
    color: white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eeeeee;
}

.hiddenIcon {
    visibility: hidden;
    pointer-events: none;
}

.logoImg {
    border-radius: 50%;
    height: 100%;
}
