@import '../../../../../_variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .personizationTokenPopover {
        top: 44px;
        right: -18px;
    }

    .content {
        display: flex;
        margin: 30px auto;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
}

.title {
    font-size: 18px;
    font-weight: bold;
    color: $text-color;
    margin-top: 18px;
}

.imageLogo {
    margin: 20px 0;
}

.description {
    margin: 20px 0 40px;
}

.formContainer {
    margin-top: 20px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .useForSelectInput {
        margin-top: 15px;
    }

    .allowBulkCheckboxContainer, .delayRequestCheckboxContainer, .lazyLoadCheckboxContainer {
        align-items: center;
        width: inherit;
        margin-top: 15px;
        font-size: 14px;

        & > div {
            width: fit-content;
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 5px;
        }
    }

    .allowBulkCheckbox, .delayRequestCheckbox, .lazyLoadCheckbox {
        margin-right: 20px;
        width: unset;
        display: flex;
        align-items: center;
    }

    .delayRequestInput {
        margin-top: 10px;
    }

    .bulkFieldSelect {
        margin-top: 15px;
        width: inherit;
    }

    .bulkTipContainer {
        display: flex;
        align-items: flex-start;
        margin-top: 15px;
    }

    .customObjectDropDown {
        margin-top: 15px;
    }

    .saveButton {
        margin-top: 30px;
    }
}

.link {
    color: #0b3ff2;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}

.textFieldTitle {
    margin: 30px 0 10px;
    width: 100%;
    text-align: left;
    opacity: 0.8;
}

.urlCombo {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    width: 100%;

    .method {
        width: 15%;
        margin-right: 10px;
    }

    .url {
        width: 85%;
    }
}

.tabs {
    margin-bottom: 10px;
    border-bottom: 1px solid $separator-color;
}

.bodyInputModeToggle {
    font-size: 12px;
    cursor: pointer;
    color: $primary-color;
    width: 100%;
}

.authSection {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .authSectionHeader {
        font-size: 18px;
        font-weight: bold;
        color: $text-color;
        margin-bottom: 20px;
    }

    .fieldContainer {
        margin-top: 15px;
        width: 100%;

        .textFieldInfo {
            text-align: left;
            font-size: 12px;
            color: $placeholder-color;
            margin-left: 5px;
        }
    }

    .redirectUrlContainer {
        margin-top: 15px;
        display: flex;
        font-size: 12px;
        border: 1px solid $textbox-border-color;
        border-radius: 5px;
        width: 100%;

        .innerContent {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .placeholder {
                font-size: 10px;
                opacity: 0.7;
                margin-top: 3px;
                display: flex;

                & > div {
                    margin-right: 5px;
                }

                svg {
                    opacity: 0.7;
                    margin-top: 1px;
                }
            }

            & > span {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                color: $text-color;
            }

            .copy {
                height: 15px;
            }
        }
    }

    .button {
        margin-top: 15px;
    }
}

.testAccountsSection {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .header {
        font-size: 18px;
        font-weight: bold;
        color: $text-color;
        margin-bottom: 20px;
    }

    .button {
        margin-top: 15px;
    }

    .shouldShowSelectAccountWarning {
        border: 1px solid $error-message-color;
    }

    .error {
        color: $error-message-color;
        font-weight: bold;
        margin-top: 10px;
    }

    .resultCount {
        font-size: 12px;
        color: $subtext-light-grey;
        margin-top: 5px;
    }

    .fieldsContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin-top: 20px;

        .fieldsTitle {
            font-weight: bold;
            padding: 3px 10px;
            margin-bottom: 5px;
        }

        .separator {
            border-right: 1px solid $field-selector-border-color;
            margin: 0 10px 5px 0;
            height: 18px;
        }

        .field {
            margin: 0 5px 5px 0;
            padding: 3px 10px;
            border-radius: 4px;
            background-color: $billing-feature-almost-white;
            cursor: pointer;
        }

        .selectedField {
            background-color: $primary-branded-color;
            color: white;
        }
    }

    .fieldsLinkContainer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        font-size: 12px;
    }

    .selectAll {
        color: $primary-branded-color;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        margin-top: 5px;
    }

    .fieldCount {
        font-size: 12px;
        color: $subtext-light-grey;
        text-decoration: underline;
        cursor: pointer;
    }
}

.responseParseToggle {
    font-size: 12px;
    text-align: left;
    cursor: pointer;
    color: $primary-color;
    margin-bottom: 15px;
    width: 100%;
}

.dataSourceContainer {
    margin-top: 20px;
}

.settingsContainer {
    padding: 15px 20px 30px 25px;
    min-width: 580px;

    .settingsRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.restApiEntriesTitle {
    padding-top: 20px;
    color: $subtext-light-grey;
    border-bottom: 1px solid $separator-color;
    font-size: 14px;
}

.restApiEntriesContainer {
    padding-top: 20px;
    max-height: 15vh;
    overflow: auto;
}

.text {
    font-size: 14px;
}

.dataSource {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .edit,
    .duplicate,
    .delete {
        transition: opacity 0.2s ease-in-out;
        visibility: hidden;
        opacity: 0;
        height: 13px;
        cursor: pointer;
        margin-left: 20px;

        svg {
            height: 13px;
            vertical-align: top;
        }
    }

    &:hover {
        .edit,
        .duplicate,
        .delete {
            visibility: visible;
            opacity: 1;
        }
    }
}

.actionButtons {
    margin-left: auto;
    display: flex;
    margin-right: 50px;
}

.additionalObjectRecordDropDown {
    margin-top: 15px;
}

.fieldDialogContainer {
    padding: 15px 20px 30px 25px;
    min-width: 580px;
}