.backgroundImage {
    position: absolute;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    background-attachment: fixed;
}

.headerImage {
    position: absolute;
    height: 200px;
    z-index: -1;
}

.footerImage {
    position: absolute;
    bottom: 0px;
    height: 200px;
    z-index: -1;
}
