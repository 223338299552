@import '../../variables';

.container {
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    white-space: nowrap;

    input {
        padding: 8px 10px 6px 10px;
        width: 90px;
    }

    .clearButton {
        width: 8px;
        height: 8px;
        margin: 4px 0;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        padding: 3px;
        background: $separator-color;
        border-radius: 50%;
        transition: opacity 0.2ms ease-in;
    }

    &.editable {
        .clearButton {
            position: absolute;
            right: 2px;
            top: 2px;
            width: 10px;
            height: 10px;
            margin: 0;
        }
    }

    &:hover {
        .clearButton {
            opacity: 1;
            transition: opacity 0.2ms ease-in;
        }
    }

    .hidden {
        opacity: 0;
        width: 0;
    }

    .emptyLabel {
        width: fit-content;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        top: 0;
        left: 0;
        padding: 0 8px;
        border-radius: 5px;
        cursor: pointer;
        height: 33px;
        &:hover {
            background: $separator-color;
            transition: background-color 0.2s ease-in;
        }
    }

    .editable {
        cursor: pointer;
    }
}

.datePreviewContainer {
    font-size: 14px;
    min-width: 53px;
    width: fit-content;
    padding: 4px 6px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    .datePreview {
        display: flex;
        width: fit-content;
        flex-wrap: nowrap;
    }
}

.overdue {
    color: $error-message-color;
}

.disabledPickersText {
    font-size: 13px;
}

.startEndContainer {
    display: flex;
    align-items: center;
}

.datesSeparator {
    font-size: 14px;

    &.disabled {
        padding: 0 5px;
    }
}
