.container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    .textField {
        margin: 0 15px;
    }
    .alignRight {
        justify-content: flex-end;
        display: flex;
        flex: 1;
    }
}
