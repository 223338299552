.container {
  display: flex;
  flex-direction: column;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.imagesRow {
  gap: 5px;
  margin-top: 10px;
}

.otherFiles {
  margin-left: -5px;
}