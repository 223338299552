@import '../../../../../_variables.scss';

.settingsContainer {
    margin-top: 20px;

    .link {
        color: $primary-color;
    }
}

.customObjectDropDown {
    margin-top: 15px;
}

.customObjectRecordDropDown {
    margin-top: 15px;
}

.actionButtons{
    margin-left: auto;
    display: flex;
    margin-right: 50px;

}

.settingsDialogContainer {
    padding: 15px 20px 30px 25px;
    min-width: 625px;
    max-height: 65vh;
    overflow: scroll;

    .settingsRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .text {
        font-size: 14px;
    }

    .link {
        color: $primary-color;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .personizationTokenPopover {
        top: 44px;
        right: -18px;
    }
    
    .content {
        display: flex;
        margin: 30px auto;
        text-align: center;
        flex-direction: column;
        align-items: center;

        .imageLogo {
            margin: 20px 0;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            color: $text-color;
            margin-top: 18px;
        }
    }
}

.formTitle {
    @include title();
}

.formContainer {
    margin-top: 20px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titleTextArea {
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        text-align: left;
        padding: 7px 14px;
        height: 72px;
        min-height: 0;
        overflow: auto;

        :global(.public-DraftEditorPlaceholder-root) {
            color: $placeholder-color;
        }

        > * {
            min-height: 0;
        }
    }

    .useForSelectInput {
        margin-top: 15px;
    }

    .saveButton {
        margin-top: 30px;
    }
}

.formattingCheckbox {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;

    .checkboxText {
        margin-left: 13px;
    }
}

.testAccountsSection {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .header {
        font-size: 18px;
        font-weight: bold;
        color: $text-color;
        margin-bottom: 20px;
    }

    .button {
        margin-top: 15px;
    }

    .warning {
        border: 1px solid $error-message-color;
    }

    .error {
        color: $error-message-color;
        font-weight: bold;
        margin-top: 10px;
    }

    .success {
        display: flex;
        align-items: center;
        margin: 20px 0;
        font-size: 12px;

        svg {
            margin-right: 10px;
        }
    }

    .accountUser {
        margin-top: 20px;
    }
}

.autoCompleteDropdown > div {
    max-height: 130px;
    height: auto;
    overflow: auto;
}
