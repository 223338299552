@import '../../variables';

.description {
    opacity: 0.7;
    width: 100%;
}

.editElemensContainer {
    display: flex;
    height: 100%;

    .textEditorContainer {
        height: inherit;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        min-width: 48%;

        .contentEditor {
            color: #545454;
            width: 100%;

            .contentViewer {
                color: #545454;
                width: 100%;
            }
        }

        &:not(.reducedMarginTop) {
            .contentEditor {
                margin-top: 10px;

                .contentViewer {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile) {
    .editElemensContainer:not(.pdf) {
        flex-direction: column;

        .row {
            flex-direction: column;
        }
        .rowReverse {
            flex-direction: column;
        }
        .contentViewer {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
