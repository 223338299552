@import '../../../../../variables';

.defaultValue {
    margin: 15px 0 30px 10px;

    .description {
        color: $subtitle-info-color;
        font-size: 12px;
    }
}

.dropdown {
    div {
        max-height: 130px;
    }
}
