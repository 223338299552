@import '../../variables';
.container {
    height: 48px;
    width: 981px;
    position: fixed;
    bottom: 26px;
    left: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    z-index: 10;
    
    @media (max-width: $laptop) {
        left: 15%;
    }
}

.rightButton {
    height: 25px;
    margin-left: 10px;
}

.leftButton {
    height: 25px;
}
