.valueContainer {
    width: 130px;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    padding-left: 5px;
}

.noValueContainer {
    width: max-content;
}
