@import '../../../variables';

.container {
    display: flex;
    flex: 100%;
    flex-direction: column;
    height: 100%;

    .headerButtons {
        margin-left: auto;
        display: flex;

        .headerButton {
            margin-left: 20px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        margin-top: 55px;
        margin-left: 135px;
        margin-right: 135px;

        h3 {
            font-size: 18px;
            margin-bottom: 4px;
        }

        p {
            font-size: 14px;
            margin-bottom: 20px;

            &.intro {
                font-size: 12px;
                opacity: 0.7;
            }

            &.assetTitle {
                font-weight: bold;
                margin-bottom: 0;
            }
        }

        .priorityHeader {
            display: flex;

            .priority {
                margin-right: 10px;
            }
        }

        .priorityCell {
            color: $priority-cell;
        }
    }
}

.dialogContent {
    width: 460px;
    height: 177px;
}

.description {
    display: flex;
    flex-direction: column;
}
