@import '../../../_variables.scss';

$info-icon-margin-left: 10px;

.button {
    width: 135px;
    height: 37px;
    border-radius: 20px;
    border: solid 1px #000000;
}

.leftButton {
    width: fit-content;
    margin-right: 25px;
}

.delete {
    color: #ee3543;
}

.input {
    padding-top: 10px;
}

.subTitle {
    display: block;
    margin-top: 10px;
}

.confirmDeleteMessage {
    padding: 25px;
    font-size: 12px;
    color: #000000;
    white-space: nowrap;
}

.dialogBody {
    padding: 0 25px 25px;
    max-width: 455px;
    max-height: 70vh;
    overflow: scroll;
}

.dialogContent {
    margin-top: 20px;
}

.folderSelect {
    margin-top: 22px;
    height: 40px;
    font-size: 14px;
}

.templateToggleOptions {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.leadingKitToggle {
    @include flexRow;
    margin-top: 20px;
}

.verificationToggle {
    @include description(14px);
    @include flexRow;
    gap: 10px;
}

.leadingKitText {
    @include description(14px);
    margin-left: 10px;
}

.divider {
    border-top: 1px solid $divider-color;
}