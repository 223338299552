@import '../../../../variables';

.dialogContainer,
.dialogContainerWithIntercom {
    width: 100vw;
    max-width: 100vw !important;
    margin: 20px !important;
}

.dialogContainer {
    height: calc(100vh - 40px);
    max-height: 100vh !important;
}

.dialogContainerWithIntercom {
    height: calc(90vh - 30px);
    margin-top: -64px !important;
}

.row {
    display: flex;
    &.expand {
        height: 60vh;
    }
    .columnOne {
        display: flex;
        min-height: 62px;
        height: 62px;
        flex-direction: column;
        justify-content: center;
        flex: 2;
        .meetingTitle {
            font-size: 14px;
            font-weight: 900;
            color: $text-color;
            .recurring {
                margin-left: 14px;
            }
        }
        .dataLine {
            display: flex;
            align-items: center;
            .paticipants {
                margin-left: 15px;
            }
        }
    }
    .arrow {
        display: flex;
        align-items: center;
        margin-right: 8px;
        cursor: pointer;
        .editIcon {
            margin-right: 30px;
        }
    }
    .join {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #4a4a4a;
        flex: 2;
    }
    .addItemsText {
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
    }
    .editPanel {
        display: flex;
        width: 50%;
        &.singleColumn {
            width: 95%;
        }
        .splitedToTwo {
            flex-direction: column;
            width: 100%;
        }
        .paperAirplaneIcon {
            z-index: 100;
        }
        .sendIcon {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: -24px;
            padding-top: 8px;
            padding-right: 10px;
            z-index: 100;
        }
    }
}

.right {
    width: 100%;
    height: 100%;
    padding-left: 5px;
    position: relative;
    .noteContainer {
        font-family: $main-Font;
        width: fit-content;
        height: 21px;
        background-color: $internal-data-background-color;
        font-size: 12px;
        z-index: 10;
        position: relative;
        padding: 0 5px;
        margin: -10px auto;
        display: flex;
        align-items: center;
        .noteText {
            padding: 0 5.5px;
        }
    }
}

.dialogContent {
    display: flex;
    align-items: center;
}

@media (max-width: $mobile) {
    .paticipants {
        visibility: hidden;
        display: none;
    }
    .columnOne {
        flex: none;
        min-width: 70%;
    }
    .arrow {
        margin: 0;
        justify-content: flex-end;
        width: 100%;
    }
    .meetingTitle {
        font-size: 13px;
    }
    .join {
        margin-left: 15px;
        width: 15%;
    }
    .addItemsText {
        font-size: 12px;
    }
}

.dialogTitle {
    padding: 16px 24px 0px !important;
}
