@import '../../../../variables';

.wrapper {
    &:hover .resizerWrapper * {
        opacity: 1;
    }
}

.container {
    font-family: $main-Font;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 15px;
    position: relative;
    overflow: hidden scroll;
    padding-bottom: 5px;
    overflow-anchor: none;
}

.assetSettings {
    position: absolute;
    right: 10px;

    &:hover {
        background-color: $asset-hover-background-color;
        border-radius: 50%;
    }
}

.headline {
    margin-right: 2px;
    font-weight: bold;
    position: sticky;
    top: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.resizer {
    cursor: row-resize;
    border-bottom: 1px solid $widget-container-border-color;
    padding-top: 5px;
}