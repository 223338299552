$tip-background-color: #fff5cc;
$tip-text-color: #5d5d5d;

.tipContainer {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $tip-background-color;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $tip-text-color
}

.tipIcon {
    min-width: 18px;
}

.tipText {
    margin-left: 10px;
}