@import '../../../../variables';

.kpiValue {
    color: $kpi-value-color;
    font-weight: 700;
    max-height: 50px;
    overflow: hidden;
    .kpiField,
    .kpiFieldView {
        font-size: inherit;
        font-weight: bold;
        box-sizing: border-box;
    }
    .kpiField {
        width: 100%;
        border: 1px solid #d9d9d9;
        vertical-align: middle;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            opacity: 1;
        }
    }
    &.compact {
        font-size: 20px;
    }
}

.plaintextarea {
    font-size: 14px;
}