.avatarContainer {
    margin-right: 18px;
    width: 21px;
}

.icon {
    font-family: AppleColorEmoji;
    font-size: 35px;
    margin-bottom: 5px;
}

.notificationEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}
