@import '../../../../variables';

.icon {
    height: 59px;
    width: 160px;
    padding-bottom: 18px;
    font-size: 18px;
}

.title {
    @include title();
    max-width: 400px;
    text-align: center;
}

.container {
    @include center();
}
