@import '../../variables';

.undoIcon {
    border-radius: 20px;
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: $gray-action-button-menu-hover-color;
        transition: background-color 0.1s linear;
    }
}
