@import '../../../variables';

.container {
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 50;
}

.allKits {
    @include textFieldInput;
    cursor: pointer;
    width: 39px;
    padding-left: 7px;
    border-radius: 3px;
    &:hover {
        background-color: $all-accounts-hover-color;
    }
}

.kitDetails {
    max-width: 60vw;
    display: flex;
    align-items: center;

    @media (max-width: $desktop) {
        max-width: 55vw;
    }

    @media (max-width: $laptop) {
        max-width: 45vw;
    }
}

.kitDetailsWithVerification {
    max-width: 53vw;

    @media (max-width: $desktop) {
        max-width: 47vw;
    }

    @media (max-width: $laptop) {
        max-width: 43vw;
    }

    @media (max-width: $medium-laptop) {
        max-width: 40vw;
    }

    @media (max-width: $small-laptop) {
        max-width: 37vw;
    }
}

.kitFolderName {
    cursor: pointer;
    margin: 0 10px;
    padding: 0 8px;
    font-size: 18px;

    &:hover {
        background-color: $all-accounts-hover-color;
        border-radius: 3px;
    }
}

.kitName {
    cursor: initial;
    font-weight: bold;
    margin-left: 18px;
    font-size: 18px;
}

.kitFolderName, .kitName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subHeader {
    display: flex;
    align-items: center;
    flex: 1;

    .hr {
        height: 20px;
        border-right: solid 1px #e9e8ed;
        margin: 0 30px 0 18px;
    }

    .modeSwitcher {
        font-size: 14px;
        font-weight: bold;
        color: $primary-color;
        cursor: pointer;

        .arrow {
            fill: $primary-color;
            margin-left: 7px;
        }
    }
}

.actionsHeader {
    justify-content: flex-end;
    container-name: kit-actions;
    container-type: inline-size;
    flex: 2;
}

.headerContainer {
    padding-right: 13px;
}

.automatedIcon {
    margin-left: 25px;
}

.automatedIconTooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.minimapButton {
    padding: 8px 15px;
    display: flex;
    gap: 6px;
    align-items: center;
}