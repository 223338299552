@import '../../../../variables';

.scorecardContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media (max-width: $mobile) {
        flex-direction: column;
    }
}

.customerEdit {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
