@import '../../variables';

%DISABLED {
    pointer-events: none;
    opacity: 0.2;
}

.fieldSelectorContainer {
    display: flex;
    gap: 10px;
    position: relative;
    padding-top: 50px;
}

.searchFieldsInput {
    position: absolute;
    top: 0;
}

.arrows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & svg {
        width: 15px;
        height: 15px;
        padding: 10px;
        cursor: pointer;
    }

    & .arrowWithLine {
        height: 20px;
    }
}

.disabled {
    @extend %DISABLED;

    svg {
        @extend %DISABLED;
    }
}

.arrowWrapper {
    height: 35px;
    width: 35px;
}

.addFieldArrowIcon {
    transform: rotate(-90deg);
}

.removeFieldArrowIcon {
    transform: rotate(90deg);
}

.movePositionUpArrowIcon,
.movePositionFirstArrowIcon {
    transform: rotate(180deg);
}

.arrowButtonsSeparator {
    border-bottom: 1px solid $separator-color;
    width: 20px;
    align-items: center;
}

.fieldsPanel {
    width: 86%;
    min-width: 250px;
    height: 278px;
    padding: 0 10px 10px;
    border: 1px solid $field-selector-border-color;
    border-radius: 6px;
    overflow: auto;
}

.fieldsPanelLabel {
    @include description(12px, $subtitle-color);
    @include sticky();
    padding: 10px 0;
    background-color: $main-background-color;
    z-index: 1;
}

.fields {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.fieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .editIcon {
        opacity: 0;
        transition: opacity 0.1s ease-in;
    }

    [class*='editableTag'] {
        opacity: 1;
        transition: opacity 0.1s ease-in;
    }

    &:hover {
        .editIcon {
            opacity: 1;
        }

        [class*='editableTag'] {
            opacity: 0;
        }
    }
}

.field,
.selectedField {
    padding: 5px 10px;
    width: calc(100% - 20px);
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.field {
    background-color: $placeholder-content-background;
    color: $text-color;
}

.selectedField {
    background-color: $main-color;
    color: $font-color-in-main-color;
}

@media only screen and (max-height: 700px) {
    .fieldsPanel {
        height: 150px;
    }
}

.fieldsDropArea {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    gap: 5px;
}


.fieldLabel {
    display: flex;
    align-items: center;
    min-height: 21px;
}