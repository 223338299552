@import '../../../../../../variables';
@import '../../../../../../aiVariables';

$top-border-color: #ebebeb;
$text-color: #707383;

.aiContentWrapper {
  width: calc(100% - 85px);
  margin-top: 10px;
  padding-top: 3px;
  border-top: 1px solid $top-border-color;
}

.aiContent {
  padding-top: 7px;
}

.aiIconsWrapper {
  position: relative;
}

.aiTextIcon {
  position: absolute;
  top: calc(100% + 2px)
}

.internalIcon {
  position: absolute;
  top: -7px;
  left: 15px;
}

.textOpener {
  @extend %AI_TEXT;
  font-size: 12px;
  margin: 0 5px 0 15px;
}

.aiTextWrapper {
  line-height: 1.43;
}

.aiText {
  margin-top: 9px;
  font-family: $main-Font;
  font-size: 12px;
  color: $text-color;
}

.animationWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.animation {
  position: absolute;
  left: 100px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 100px;
}

.button {
  height: 22px;
  padding: 2px 10px;
  border-radius: 100px;
  margin-top: 7px;
}

.buttonLabel {
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: $main-Font;
  font-size: 12px;
}

.loading {
  @extend %AI_COLOR_BACKGROUND;
  color: white;

  path {
    fill: #fff;
  }
}