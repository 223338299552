@import '../../../variables';

.singleEventMain {
    @include notificationEvent();
    cursor: pointer;
    flex-direction: column;
}

.message {
    width: 180px;
}

.dateContainer {
    color: #545454;
}

.messageContainer {
    display: flex;
}

.userName {
    font-style: italic;
    font-size: 10px;
    color: $user-name-text-color;
}

.eventData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.container {
    position: relative;
}