@import '../../../../variables';

$logo-border: #e5e3ea;

.container {
  display: flex;
  align-items: center;
}

.text {
  font-size: 14px;
  color: $subtext-color;
}

.accounts {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;

  @media (max-width: $medium-laptop) {
    margin-left: 10px;
    & a:nth-last-child(-n+2) {
      display: none;
    }
  }

  @media (max-width: $small-laptop) {
    & a:nth-last-child(-n+3) {
      display: none;
    }
  }
}

.link {
  text-decoration: none;
  display: flex;
}

.defaultLogo {
  width: 27px;
  height: 27px;
  line-height: 27px;
  font-size: 20px;
  text-align: center;
}

.logo {
  border: solid 1px $logo-border;
  height: 27px;
  width: 27px;
}