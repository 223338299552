@import '../../variables';

.crmDetails {
    margin-top: 10px;

    .noObjects {
        margin: 0 3px 0 -33px;

        .text {
            margin-bottom: 10px;
        }

        .cloud {
            margin-right: 10px;
        }
    }
}

.iconsContainer {
    display: flex;
    flex-direction: row;
    gap: 4px;

    .icon {
        width: 35px;
        height: 39px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.presentContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.content {
    @include widgetDescriptionPanel;
}
