@mixin taskOwnerIcon {
    border-radius: 20px;
    width: 20px;
    height: 20px;
}

.customerIcon {
    @include taskOwnerIcon();
}

.noOrganizationIcon {
    @include taskOwnerIcon();
}

.organizationIcon {
    @include taskOwnerIcon();
}

.specificUserIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-left: 5px;

    span {
        width: 100%;
        display: inline-flex;
        justify-content: center;
    }
}
