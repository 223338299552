@import '../../../../variables';

.dialog {
    width: 425px;
    font-size: 16px;
    height: 100px;

    .context {
        display: flex;
        align-items: center;
        height: 100px;
        margin: 0 22px;
        font-size: 14px;
        line-height: 30px;
    }
}
