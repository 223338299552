@import '../../../../../../variables';

.container {
    display: grid;
    grid-template-columns: fit-content(40%) auto;
    grid-template-rows: auto;
    column-gap: 30px;
    row-gap: 30px;

    @media (max-width: $mobile) {
        grid-template-columns: unset;
        row-gap: 10px;
    }

    &.editMode {
        grid-template-columns: unset;
    }

    .titleBox {
        text-align: left;
        word-break: break-word;
        display: flex;
        align-items: center;

        .field {
            font-weight: 700;
            font-size: 14px;
        }
    }

    .kpiValue {
        @media (max-width: $mobile) {
            margin-bottom: 15px;
        }
    }
}
