@import '../../variables';

.closeIcon {
    align-self: end;
    margin-top: 2px;
    margin-right: 10px;
}

.titleText {
    @include textStyle();
    font-family: $secondary-font;
    font-size: 30px;
}

.dialogTitle {
    display: flex;
    padding: 0 30px;
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
