.toggle {
    display: flex;
}

.toggleOn {
    background-image: url('../../assets/icons/toggle-on.svg');
    width: 40px;
    height: 22px;
}

.toggleOff {
    width: 40px;
    height: 22px;
    background-image: url('../../assets/icons/toggle-off.svg');
}

.actionContainer hr {
    border: none;
    border-top: 1px dotted #979797;
    height: 1px;
    width: 40%;
    margin-top: 10px;
}

.titleWrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-basis: 53%;
}

.container .textField {
    margin-top: 13px;
    width: inherit;
    height: 40px;
}

.actionContainer {
    display: flex;
    justify-content: flex-start;
}

.actionContainer hr {
    margin: 10px 15px;
    width: 100%;
}

.container {
    width: 100%;
}

.subTitle {
    opacity: 0.7;
}

.input {
    padding-top: 10px;
    color: #000000;
}

.title {
    margin-left: 10px;
}

.titleTextStyle {
    min-width: fit-content;
}

.children {
    margin-top: 10px;
}
