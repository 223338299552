@import '../../../../../../variables';

.headerContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(90vw - 80px);
    max-width: 1440px;
    height: 100%;

    [class*='editBox'] {
        max-width: 1340px;
    }

    &.templateView {
        width: 100%;
    }

    transition: background-color 0.2s ease-in;

    &.hiddenFromCustomer {
        background-color: $internal-data-background-color;
        transition: background-color 0.2s ease-in;
    }

    .headerActions {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 10px;
        margin-right: 20px;
        .titleAction {
            @include taskActionButton();

            &.delete {
                @include deleteButton();
            }

            height: 14px;
            width: 14px;
        }

        .separator {
            height: 16px;
            border-left: 1px $separator-color solid;
        }
    }

    .titleEditWrapper {
        display: flex;
        gap: 10px;
        height: 27px;
        align-items: baseline;
        margin-left: 30px;
        width: 100%;
        [class*='displayValue'] {
            span {
                max-width: calc(#{$task-edit-dialog-max-width} - 130px);
                @include ellipsis();
            }
        }

        .input {
            width: 100%;
            margin-right: 20px;
            background: $widget-container-background-color;
            max-height: unset;

            fieldset {
                border-width: 0;
            }

            &:hover,
            &:active,
            &:focus {
                fieldset {
                    border-width: 1px;
                }
            }
        }
    }
}

.additionalActionsButton {
    cursor: pointer;
}

.deleteTaskAction {
    color: $delete-or-remove-color;
}

.confirmTitle {
    @include confirmDialogTitle();
}
