@import '../../variables';
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.closeIcon {
    text-align: right;
}

.sourceText {
    font-size: 12px;
    text-align: center;
    color: #5c5a5a;
}

.sourceText a {
    text-decoration: none;
    color: $primary-color;
}

.gradient {
    height: 30px;
    margin-top: 8px;
    z-index: 10;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.editIcon {
    border-radius: 50%;
    display: flex;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: -moz-fit-content;
    width: 13px;
    height: 19px;
    margin-bottom: -15px;
    z-index: 5;
    background-color: white;
    padding: 0 3px;
    cursor: pointer;
}

.latestImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.editContainer {
    width: 90px;
    display: flex;
    justify-content: flex-end;
    z-index: 6;
}

.imagesList {
    display: flex;
    flex-flow: row wrap;
    gap: 30px;
    width: 100%;
    margin: 30px 0;
    height: calc(55vh - 100px);
    overflow: auto;
}

.description {
    @include description();
    padding: 10px 0;
}

.uploadButton {
    margin-bottom: 8px;
}

.recommendedImgSize {
    margin-bottom: 9px;
    color: $subtext-grey;
}

.search {
    width: 25vw;
}

.loader {
    position: absolute;
    @include center();
    height: 100%;
    width: 100%;
}
