@import '../../../variables';

$overdue-due-date-color: #ee3543;

.tabs {
    border-bottom: 1px solid $separator-color;

    [class*='singleTabHeader'] {
        padding: 0 0 7px;
        margin: 0 20px 0 0;
    }

    .selectedTab {
        font-weight: 500;
        color: $main-color;
        border-bottom: $main-color 2px solid;
    }
}

.noOpenTasksContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .championIcon > svg {
        width: 38px;
        height: 38px;
    }

    .noOpenTasksTitle {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 600;
    }

    .noOpenTasksSubtitle {
        margin-top: 5px;
        font-size: 12px;
    }
}

.table {
    height: 90%;
}

.headerRow > span {
    font-family: $main-Font;
}

.skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeletonRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skeletonGroup {
    width: 30%;
    display: flex;
    gap: 20px;
}

.overdueDueDate {
    color: $overdue-due-date-color;
}

.taskOwner {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 20px;
    text-align: center;
}

.upcomingTable,
.overdueDueDateTable {
    height: 95%;

    div {
        height: 100%;
    }
}
