@import '../../../../../variables';

.eventsContainer {
    .notificationIcon:hover {
        background-color: $gray-action-button-menu-hover-color;
    }

    .hubNotificationDot::before {
        content: ' \25CF';
        font-size: 20px;
        color: #fe5e5b;
        z-index: 1;
        position: absolute;
        left: 17px;
    }

    position: relative;
    .events {
        width: 405px;
        left: -185px;
        z-index: 100;
        top: 37px;
        border-radius: 8px;

        &:before {
            right: 50%;
        }

        [class*='main'] {
            padding-left: 13px;
            padding-right: 13px;
        }
    }
}
