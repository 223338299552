@import '../timelineVariables';

%completedLine {
  flex: unset;
}

%firstCompletedLine {
  @extend %completedLine;

  &:nth-child(1) {
    width: 50%;
  }

  &:nth-child(2) {
    width: calc(50% + 40px);
  }
}

%finalCompletedLine {
  @extend %completedLine;

  &:nth-child(1) {
    width: calc(50% + 40px);
  }

  &:nth-child(2) {
    width: 50%;
  }
}

@mixin marginRight($margin) {
  margin-right: $margin;
  margin-left: unset;
}

@mixin marginLeft($margin) {
  margin-left: $margin;
  margin-right: unset;
}

.snakeLayoutContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[class*='highlightWidget'] {
  .snakeLayoutContainer {
    padding-top: 10px;
  }
}

.milestonesRow {
  position: relative;
  overflow: unset;

  [class*='viewModeTextContainer'] {
    padding: 0 5px;
  }

  &:nth-child(odd) {
    [class*='finalCompletedLineInRow'] {
      @include marginLeft($SNAKE_LAYOUT_COMPLETED_LINE_MARGIN);

      & > div {
        @extend %finalCompletedLine;
      }
    }

    [class*='firstCompletedLineInRow'] {
      @include marginRight($SNAKE_LAYOUT_COMPLETED_LINE_MARGIN);

      & > div {
        @extend %firstCompletedLine;
      }
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    [class*='completeLineContainer'] {
      flex-direction: row-reverse;
    }

    .lineBetweenRows {
      left: 0;
      right: unset;
      border-left: 2px solid transparent;
      border-right: 0;
      border-radius: 20px 0 0 20px;
    }

    [class*='finalCompletedLineInRow'] {
      @include marginRight($SNAKE_LAYOUT_COMPLETED_LINE_MARGIN);

      & > div {
        @extend %finalCompletedLine;
      }
    }

    [class*='firstCompletedLineInRow'] {
      @include marginLeft($SNAKE_LAYOUT_COMPLETED_LINE_MARGIN);

      & > div {
        @extend %firstCompletedLine;
      }
    }
  }
}

.lineBetweenRows {
  position: absolute;
  top: 39px;
  right: 0;
  border: 2px solid transparent;
  border-left: 0;
  border-radius: 0 20px 20px 0;
  height: calc(100% - 2px);
  width: 20px;
}