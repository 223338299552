@import '../../../../variables';

.zapierContainer {
    margin: 20px 0;

    .row {
        display: flex;
        justify-content: space-between;
    }

    .zapierTitle {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
    }

    .zapierSubtitle {
        font-size: 12px;
        color: #000000;
        opacity: 0.7;
        margin-bottom: 10px;
    }

    .integrationInstructions {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 600;
        color: $primary-color;
        text-decoration: none;
    }
}
