@import '../../../../../variables';

.headerRow > span {
    font-family: $main-Font;
}

.bodyRow [class*='label'] {
    word-break: break-word;
}

.accessListDialog {
    width: 750px;
    padding: 26px;
    max-height: 65vh;
    overflow: scroll;
}

.longTextColumn {
    @include ellipsis();
    font-size: 14px;
    cursor: default;
}

.contactName {
    font-weight: bold;
}

.noResultsMessage {
    font-size: 16px;
    color: $subtext-grey;
}
