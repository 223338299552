.button {
    transition: transform 0.2s ease-in;
    padding: 5px;
    cursor: pointer;

    &.collapsed {
        transition: transform 0.2s ease-in;
        transform: rotate(-90deg);
    }
}
