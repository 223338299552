@import '../../../../variables';
@import '../../../../aiVariables';

.dialog {
    width: 724px;
    height: 485px;
    box-sizing: border-box;
    position: relative;
}

.header {
    height: fit-content;
}

.headerContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 43px 18px 35px;
    position: relative;
}

.title {
    @extend %AI_TEXT;
    position: relative;
    width: fit-content;
}

.titleIcon {
    position: absolute;
    left: -22px;
    top: 6px;
}

.subtitle {
    font-size: 14px;
    color: $subtext-light-grey;
    white-space: break-spaces;
}

.closeIcon {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    padding: 3px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 313px;
    padding: 24px 32px 41px 31px;
    box-sizing: border-box;
}

.fileUploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.fileUpload {
    display: flex;
    justify-content: center;
}

.fileUploadInput {
    [role="button"] {
        padding: 0 15px;
    }

    &:hover {
        background-color: unset
    }
}

.supportedFormats {
    font-size: 14px;
    color: #707383;
    margin-top: 8px;

    span:first-of-type {
        font-weight: bold;
    }
}

.aiContentGeneration, .textArea, .textAreaContainer {
    width: 100%;
    height: 100%;
}

.textAreaContainer {
    overflow: auto;
    padding-bottom: 10px;
}

.submitButton {
    padding: 0 22px;
}