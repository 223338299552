.dialog {
    .MuiDialog-paper {
        border-radius: 10px;
    }
}

.closeIcon {
    text-align: right;
    margin-top: 2px;
    margin-right: 10px;
}
