@import '../../../../../../variables';

.title {
    font-size: 14px;
    color: $text-color;
    font-family: $main-Font;
}

.uploadIcon {
    padding: 45px 0 0;
    background: url(../../../../../../assets/icons/icon-upload.svg) center top/auto 35px no-repeat;
    margin: 26px 0 0;
}

.bottomMargin {
    margin-bottom: 0;
    font-size: 10px;
    font-family: $main-Font;
}

.accountViewBottom {
    margin-bottom: 20px;
}

.boxFoot {
    background-color: $share-material-empty-state-background;
    padding: 5px 15px;
    border-radius: 0 0 6px 6px;
    margin: 30px -18px -2px;
    box-sizing: border-box;
    font-family: $main-Font;
    @include smallTitle;
}
