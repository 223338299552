.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 26px 0 0 26px;
}

.title {
    font-weight: bold;
}
