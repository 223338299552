@import '../../../variables';

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 105px);
    border-bottom: 1px solid $widget-container-border-color;
    margin-right: 27px;
    gap: 20px;
    position: fixed;
    z-index: 10;

    .subHeader {
        display: flex;
        align-items: center;
        height: $header-height;
        justify-content: flex-end;
        padding-right: 25px;

        .middleContent {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .undoIcon {
                border-radius: 20px;
                width: 37px;
                height: 37px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color: #ebebeb;
                }
            }
        }
    }

    .subHeader {
        display: flex;
        width: 100%;
        align-items: center;
        height: 67px;
        gap: 10px;

        .shareMenu {
            @include shareMenu();
            top: $header-height;
        }

        .shareMenuWithTrialBanner {
            @include shareMenu();
            top: $header-height-with-trial-banner;
        }
    }

    .companyName {
        margin-left: 10px;
        line-height: 1.7;
        flex: 30%;
    }
    .leftHeader:not(:last-child) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        flex-shrink: 1;
        flex-basis: 80%;
        min-width: 0;

        .accountAndAssetContainer {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            min-width: 0;

            .assetDetails {
                display: flex;
                align-items: center;
                gap: 10px;
                @media (min-width: $small-laptop) and (max-width: $medium-laptop) {
                    display: none;
                }
            }

            .logo {
                width: 24px;
                height: 24px;
                min-width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #e9e8ed;
                border-radius: 28px;
            }
        }

        .allAccounts {
            @include textFieldInput;
            margin-left: 15px;
            cursor: pointer;
            &:hover {
                background-color: $all-accounts-hover-color;
            }
        }
    }

    .allAssets {
        @include textFieldInput;
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
        padding-left: 7px;
        border-radius: 3px;
        font-weight: bold;
        &:hover {
            background-color: #ebebeb;
        }
        vertical-align: middle;
        gap: 5px;
    }
}

.accountDropDownContainer {
    display: flex;
    height: 37px;
    border-radius: 20px;
    border: solid 1px #dedede;
    align-items: center;
    margin-right: 20px;
}

.arrow {
    margin-left: 60px;
}

.action {
    padding: 15px;
}

.space {
    flex: 5%;
}

.menuLabel {
    padding: 8px 22px;
}

.middleContent {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .undoIcon {
        border-radius: 20px;
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background-color: #ebebeb;
        }
    }

    .events {
        width: 405px;
        left: calc((309px - 37px) / -2);
        z-index: 100;
        top: 37px;
        border-radius: 8px;

        &:before {
            right: calc(50% + 48px);
        }

        [class*='main'] {
            padding-left: 13px;
            padding-right: 13px;
        }
    }
}

.eventsContainer {
    position: relative;
}

.presentationMenuContainer {
    width: 331px;
    z-index: 100;
    top: $header-height;

    .presentationMenu {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        font-size: 14px;
        .title {
            font-weight: bold;
        }

        .subTitle {
            margin-bottom: 30px;
        }

        .presentationModeBtn {
            margin-bottom: 20px;
        }
    }
}

.presentationMenuTopWithTrialBanner {
    top: $header-height-with-trial-banner;
}

.hubNotificationDot::before {
    content: ' \25CF';
    font-size: 20px;
    color: #fe5e5b;
    z-index: 1;
    position: absolute;
    left: 17px;
}

.ellipsisExpansion {
    @include ellipsis();
}

.accountNameOnFocus {
    outline: 1px solid #3a3ff2;
    padding: 0 10px;
    border-radius: 5px;
}

.accountNameUnfocused {
    cursor: pointer;
    outline: 0;
}

.assetNameHeader {
    display: inline-grid;
}

.accountActionsMenuButton {
    margin-left: auto;
}

.addAsset {
    @include flexRow();
    gap: 10px;
}

.iconStyle {
    min-width: 15px;
    min-height: 15px;
    display: flex;
    align-items: center;
}

.happyGuy {
    position: relative;
    top: 242px;
    z-index: 100;
    right: 398px;
}

.defaultValueClassName {
    line-height: 24px;
    font-size: 27px;
    text-align: center;
}

.archivedDialogContainer {
    position: absolute;
    right: 0;
}

.shareLink {
    color: $primary-branded-color;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
}

.unsharedLinkTitle {
    font-size: 14px;
}

.unsharedLinkText {
    font-size: 12px;
    color: $subtext-grey;
    margin-top: 5px;
}

.divider {
    height: 18px;
    border-left: 1px solid $divider-color;
}
