@import '../../../variables';

.container {
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: $widget-container-background-color;
    border-radius: 20px;
    border: 1px solid $separator-color;
}
