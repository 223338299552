.collaboration {
    position: absolute;
    right: -60px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55px;
    padding-left: 10px;
}

.collaborationSlides {
    right: 15px;
    top: 10px;
}