@import '../../variables';

$notify-via-email-left-padding: 6px;

.container {
    font-size: 14px;
    color: #000;
}

.noMargin {
    margin: 0;

    > div:first-child {
        margin: 0;
    }
}

.notifyViaEmailTitle {
    font-family: $main-Font;
    font-size: 14px;
    padding-left: $notify-via-email-left-padding;
    display: flex;
    gap: 10px;
    align-items: center;
}

.notifyViaEmailSubtitle {
    color: $subtext-color;
    font-size: 12px;
    margin-top: 5px;
}

.notifyViaEmailSubtitle,
.notifyViaEmailContacts,
.followersCheckbox {
    padding-left: calc(23px + #{$notify-via-email-left-padding});
}

.options {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.notificationsToAccountFollowers {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
}

.slackNotificationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.slackNotificationTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.slackNotificationTitle {
    margin-top: 15px;
}
