@import '../../variables';

.presentationMode {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    z-index: 1000 !important;
    overflow: hidden !important;

    .navigationContainer {
        @include presentationNavigationMenu();
        z-index: 9000;
        opacity: 0;
        transition: opacity 0.2s ease-in;

        &.visible {
            transition: opacity 0.1s ease-in;
            opacity: 1;
        }

        &:hover {
            transition: opacity 0.1s ease-in;
            opacity: 1;
        }
    }
}
