@import '../../../variables';

.container {
    @include actionButton();
    width: 37px;
    height: 32px;
    padding-bottom: 5px;
    border-radius: 50%;
    &:hover {
        background: $all-accounts-hover-color;
    }
}
