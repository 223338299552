@import '../../../../../../variables';

.container {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    gap: 10px;

    .txtLink {
        width: 100%;
        height: 37px;
        box-sizing: border-box;
        border: solid 1px $widget-container-border-color;
        float: left;
        padding: 5px 10px 5px 15px;
        border-radius: 5px;
        flex: 1 0;
    }

    .font {
        font-family: $main-Font;
    }
}

.dialog {
    width: 517px;
    padding: 26px 26px 20px;

    @media (max-width: $mobile) {
        width: auto;
    }

    .textField {
        margin-bottom: 10px;
    }
}
