@import '../../../../variables';

.container{
    padding-top: 20px;
    text-align: left;
    margin: 0px 26px;

    .accountsFilter{
        font-size: 14px;
        border-bottom: 1px $separator-color solid;

        .filters{
            padding: 20px 0px 30px 0px;

            .filter{
                padding-top: 10px;
            }
            
        }

        .bold{
            font-weight: bold;
        }
    }

    .additionalFiltersContainer{
        padding-top: 30px;
        padding-bottom: 30px;
        max-height: 50vh;
        overflow: auto;
        overflow-x: hidden;

        .description {
            @include description(14px);
        }
    }
}

.buttonContainer {
    display: flex;
    align-items: center;
}

.button {
    margin-left: 20px;
}

.saveNewFilter {
    margin-top: 15px;
}

.filtersClassName {
    margin-top: 20px;
    align-items: flex-start;
    [class*='multiDropDownSection'] {
        margin: 0;
    }

    [class*='addFilterButton']{
        margin-left: 10px;
    }
}

.divider {
    @extend %divider;
}