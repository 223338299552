@import '../../../../variables';

.wrapper {
    height: fit-content;
    background-color: $main-background-color;
    .container {
        width: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 15px 15px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
        position: relative;

        .actions {
            display: none;
            position: absolute;
            right: 7px;
            top: 5px;
        }

        &:hover {
            .actions {
                display: block;
            }
        }

        .tileLabel {
            font-family: $main-Font;
        }

        .seperator {
            border: dashed 1px $steps-line-color;
        }

        .description {
            padding: 8px 20px;
            min-height: 40px;
            font-family: $main-Font;
            font-weight: normal;

            :global(div.DraftEditor-root) {
                min-height: 22px;
            }
        }

        .colorsContainer {
            display: flex;
            flex-direction: row;
            gap: 9px;
            align-items: center;

            .tileColorDescription {
                font-family: $main-Font;
                color: $placeholder-color;
                font-size: 14px;
                align-self: baseline;
                margin-top: 4px;
            }
        }
    }
}