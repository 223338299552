@import '../../variables';

$light-border-color: #d9d9d9;

.textArea {
  position: relative;
}

.container {
  border-radius: 5px;
  border: solid 1px $light-border-color;
  display: flex;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #ffffff;

  &.large {
    height: 49px;
  }
  &.small {
    height: 24px;
    padding: 0 5px;
  }
  &.errorBorder {
    border: solid 1px $error-message-color;
  }
  &.disabled {
    opacity: 0.2;
    border: solid 1px $textbox-border-color;
    pointer-events: none;
  }
  &:hover {
    border: solid 1px $textbox-hover-border-color;
  }
}

.internal{
  border: solid 1px $internal-border-color;
  background: $textbox-backgrount-color;
  &:hover {
    border: solid 1px $internal-border-color;
  }
}

.label {
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 11px) scale(1);
  transition: all 0.1s ease-in-out;
  color: $placeholder-color;
  padding: 0 20px;

  &.cornerLarge {
    font-size: 12px;
    color: #000000;
  }

  &.cornerRegular {
    font-size: 10px;
    color: #000000;
  }

  &.large {
    font-size: 20px;
  }

  &.regular {
    font-size: 14px;
  }
}

.large {
  .active {
    transform: translate(0, 4px);
    opacity: 0.7;
    font-size: 12px;
  }
}

.active {
  transform: translate(0, 4px);
  opacity: 0.7;
  font-size: 10px;
}

.container:active {
  border: solid 1px $textbox-hover-border-color;
}

.input {
  border: none;
  outline: 0;
  width: 100%;
  padding: 0 6px;
  overflow-y: scroll;
  border: unset;
  font-weight: normal;

  &.input {
    z-index: 1;
    background: none;
  }
}

.rightIcon,
.loader {
  display: flex;
  align-items: center;
}

.error {
  font-size: 10px;
  font-weight: 600;
  color: $error-message-color;
}

.helperText {
  font-size: 12px;
  color: $subtext-grey;
  font-family: $main-Font;
  line-height: 1.67;
}

.noteContainer {
  display: flex;
  width: 100%;
  position: absolute;

  .noteText {
    width: fit-content;
    display: flex;
    height: 21px;
    z-index: 10;
    align-items: center;
    font-family: $main-Font;
    background-color: $internal-data-background-color;
    font-size: 12px;
    border: solid 1px $internal-border-color;
    border-radius: 25px;
    margin: -10px auto;
    padding: 0 5.5px;
  }
}