@import '../../../../variables';

.dialogHeader {
  width: 100%;
}

.dialogTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.dialogTitle {
  @include title();
}

.closeIconArea {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.dialogBody {
  width: 1053px;
  padding: 30px;
  font-size: 14px;
  color: #000;
}