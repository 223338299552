@import '../../../variables';

$TEXT_COLOR: #221437;
$SELECTED_TAG_BG_COLOR: #e1edf1;
$SELECTED_TAG_BG_COLOR_DARK: #2a1244;

%THUMBNAIL_IMAGE {
  width: 100%;
  //height: 167px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  aspect-ratio: 294 / 167;
}

%TAGS {
  display: flex;
  gap: 5px;
  padding-top: 15px;
}