@import '../../../variables';

.tabs {
    border-bottom: 1px solid $separator-color;

    [class*='singleTabHeader'] {
        padding: 0 0 7px;
        margin: 0 20px 0 0;
    }

    .selectedTab {
        font-weight: 500;
        color: $main-color;
        border-bottom: $main-color 2px solid;
    }
}

.meetingsContainer {
    margin-top: 10px;
    overflow-x: scroll;
}

.noMeetingsContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .championIcon > svg {
        width: 38px;
        height: 38px;
    }

    .noMeetingsTitle {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 600;
    }

    .noMeetingsSubtitle {
        margin-top: 5px;
        font-size: 12px;
    }
}

.skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeletonRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skeletonGroup {
    width: 30%;
    display: flex;
    gap: 20px;
}
