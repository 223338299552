@import '../../../../../_variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content {
        display: flex;
        margin: 30px auto;
        text-align: center;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 18px;
            font-weight: bold;
            color: $text-color;
            margin-top: 18px;
        }

        .midSection {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            width: 400px;
        }

        .imageLogo {
            margin: 20px 0;
        }
        .description {
            color: $text-color;
            font-size: 14px;
            margin: 20px 0 40px;
            max-width: 500px;
        }
    }
}
