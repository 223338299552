@import '../../variables';

$fourth-contact-container-min-width: 400px;
$contacts-gap: 20px;

.widgetContainer {
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
    display: flex;
    gap: $contacts-gap;
    padding-bottom: 20px;
}

.viewModeReducedMargin {
    margin-top: 10px;
}

.flexWrapWidget {
    flex-wrap: wrap;
    justify-content: center;
}

.overflowWidget {
    overflow-x: auto;
}

.messageInput {
    margin: 10px 0 55px;
    height: 100px;

    :global(.DraftEditor-root) {
        min-height: 70px;
        height: 100px;
    }
}

.contactContainer {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    min-width: 270px;

    @media only screen and (min-width: calc(#{$fourth-contact-container-min-width} * 2 + #{$contacts-gap})) and (max-width: $desktop) {
        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ div {
            min-width: calc(max(35%, #{$fourth-contact-container-min-width}));
        }
    }
}

.addContactButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-width: 100px;
    max-width: 100px;

    .addIcon {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}

.removeContact {
    position: absolute;
    top: 0;
    right: 8px;
    z-index: 1;
    cursor: pointer;
}

.infoIcon {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 15px;
    top: 27px;
}

.contactCard {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    box-sizing: border-box;
    background: #fff;
    padding: 15px;
    font-size: 13px;
    line-height: 1.4;

    .content {
        height: 100%;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:not(.hiddenExtendedData) {
            min-height: 180px;
        }
    }
}

.additionalPaddingBottom {
    padding-bottom: 25px;
}

.topRow {
    padding-bottom: 10px;
    display: flex;
}

.detailsRow {
    min-height: 35px;
    height: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;

    & div p {
        margin: 0;
        padding: 0 0 10px 0;
        font-size: 14px;
    }
}

.noPointerEvents {
    pointer-events: none;
}

.overlayText {
    position: absolute;
    z-index: 1;
    opacity: 0.7;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: calc(100% - 20px);
    padding: 0 10px;
    font-size: 12px;
}

.overlayTextEditMode {
    margin-top: 50px;
}

.overlayTextShortCard {
    margin-top: 65px;
}

.blurOverlay {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    opacity: 0.4;
}

.cursorPointer {
    cursor: pointer;
}

.formFieldsContainer {
    flex: 1;
    padding-left: 15px;

    & input {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        width: 100%;
        background: #fff;
        box-sizing: border-box;
        margin: 0 0 5px 0;
        padding: 5px;
        font-size: 13px;
        line-height: 1.3;
    }
}

.fieldSetContainer {
    padding: 0;
    margin: 0;
    border: none;
}

.nameInput {
    height: 26px;
}

.jobInput {
    height: 22px;
}

.nameText,
.jobText {
    margin: 0;
    padding: 0;
    word-break: break-word;
}

.nameText {
    font-size: 14px;
    font-weight: 900;
}

.jobText {
    font-size: 12px;
}

.contactFooter {
    border-top: 1px solid #dfdfdf;
    padding: 10px 0 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    & > a {
        min-width: 30px;
        display: flex;
        justify-content: center;
        border-radius: 100%;
    }
}

.openDetailsDialog {
    cursor: pointer;
    font-size: 13px;
    color: $primary-branded-color;
    position: absolute;
    bottom: 7px;
}

@media (max-width: $mobile) {
    .contactFooter > a {
        margin: 0 0 0 20px;
        width: 28px;
        border-radius: 100%;
    }
}

.linkedToAccountOwner {
    font-family: $main-Font;
    font-size: 14px;
    vertical-align: middle;
}

.linkedToAccountOwnerDescription {
    font-family: $main-Font;
    font-size: 12px;
    color: $description-grey;
    margin: 18px;
}

.vIcon {
    vertical-align: middle;
    padding-right: 10px;
}

.linkedField {
    top: 50%;
}

.contactWrapper {
    position: relative;
    display: flex;
    flex: 1 1;
    flex-flow: column nowrap;
    min-width: 270px;
    .actionsContainer {
        position: absolute;
        top: -5px;
        right: 10px;
    }
}
.deleteAction {
    @include deleteButton();
    color: $error-message-color;
}

.dropdownMenuContainer {
    padding: 0 8px;
}

.loader {
    padding-right: 100px;
    margin-right: 100px;
    > div {
        text-align: end;
    }
}