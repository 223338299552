.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 25px;

    .subTitle {
        font-size: 14px;
    }

    .imageRadioButtonContainerClassName {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .imageRadioButtonBulletsClassName {
        margin-bottom: unset;
    }
}
