@import '../../../../../variables';

.noteIcon {
  width: 18px;
  height: 15px;
}

.notesIcon {
  padding: 6px 2px 0 2px;
  box-shadow: none !important;
  margin-top: 6px !important;
  cursor: pointer;
  text-align: center;
}

.csmNotesIcon {
  margin-bottom: 0 !important;
  border: 2px solid transparent !important;

  &:hover {
    border: solid 2px $primary-branded-color !important;
  }
}

.customerNotesIcon {
  &:hover {
    box-shadow: 0 1px 5px 0 rgba(162, 162, 162, 0.5) !important;
  }
}


.noNotes {
  opacity: 0;
}