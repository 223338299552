@import '../../../../../../variables';

.container {
    display: flex;
    align-items: center;
    gap: 2px;
    .selectInput {
        min-width: 288px;
        max-width: 378px;
        [class*='MuiOutlinedInput-input'] {
            padding: 0 10px;
            height: fit-content;
            font-size: 13px;
            min-height: 30px;
        }

        [class*='MuiInputBase-root'] {
            height: fit-content;
            min-height: 30px;
        }

        [class*='MuiChip-root'] {
            height: 24px;
            font-size: 13px;
            svg {
                height: 18px;
                width: 18px;
            }
        }
    }

    .settingsButton {
        cursor: pointer;
        width: 14px;
        height: 14px;
        padding: 8px;

        @include taskActionButton();

        g {
            fill: black;
        }
    }
}
