@import '../../../variables';

.container {
    margin-top: 20px;
}

.manageTagsLink {
    color: $primary-color;
    cursor: pointer;
    font-weight: 600;
}

.rightButton {
    background-color: $main-color;
}

.deleteMenuItem {
    color: $error-message-color;
}

.separator {
    height: 1px;
    margin: 0 0 10px 20px;
    border-bottom: 1px solid $separator-color;
}

.addKeyLink {
    color: $primary-color;
    cursor: pointer;
    font-size: 12px;
}

.errorMessage {
    color: $error-message-color;
}

.subTitle {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.apiDocLink {
    color: $primary-color;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
}

.dialog {
    width: 440px;
}

.content {
    max-height: 65vh;
    overflow: auto;
    padding: 20px;
}

.description {
    font-size: 14px;
}
