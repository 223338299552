@import '../../../../variables';

.wrapper {
    height: fit-content;
    background-color: $main-background-color;
    display: flex;
    .container {
        height: 130px;
        width: 150px;
        padding: 20px 15px 15px 20px;
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);

        &.clickable {
            cursor: pointer;
        }

        .tileEmpty {
            color: $placeholder-content-color;
        }

        @media (max-width: $mobile) {
            width: auto;
        }

        .actions {
            display: none;
        }

        &:hover {
            .actions {
                display: block;
                position: absolute;
                right: 7px;
                top: 5px;
            }
        }

        .title {
            font-size: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
        }

        .details {
            display: flex;
            flex-direction: row;
            flex: 1 1;
            color: $subtext-color;

            .description {
                font-size: 12px;
                flex: 1 1;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                height: fit-content;
            }
        }

        .iconContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            cursor: pointer;
            height: fit-content;
            align-self: flex-end;

            .arrowIcon {
                width: 12px;
                height: 10px;
                padding: 9px 8px;
                fill: $font-color-in-main-color;
            }
        }
    }
}

.tileActionButton {
    height: 28px;
}

.loader {
    top: -3px;
    left: -3px;
}
