$notifications-bg-color: #ffffff;
$notifications-bg-hover-color: #ebebeb;

%NOTIFICATIONS_ICON {
    width: 37px;
    height: 37px;
    border-radius: 50%;
}

.notificationsIconBackground {
    @extend %NOTIFICATIONS_ICON;

    &.opened {
        background-color: $notifications-bg-color;
    }

    &:not(.opened) {
        &:hover {
            circle {
                stroke: #000;
            }

            rect, path {
                fill: #000;
            }
        }
    }
}

.notificationsIcon {
    @extend %NOTIFICATIONS_ICON;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
        background-color: $notifications-bg-hover-color;
    }
}

.hubNotificationDot::before {
    content: ' \25CF';
    font-size: 20px;
    color: #fe5e5b;
    z-index: 1;
    position: absolute;
    left: 19px;
    top: -2px;
}

.events {
    width: 305px;
    right: -12px;
    z-index: 100;
    top: 37px;
}

.events::before {
    margin-right: -132px !important;
}

.childrenClassNameEvents {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
}

.container {
    position: relative;
}
