@import '../../variables';
.selected {
    svg {
        fill: white;
    }
}

.spacecraftUpPosition {
    bottom: 20px;
}

.spacecraftDownPosition {
    bottom: -5px;
}

.spacecraft {
    z-index: 10;
    position: absolute;
    transition: bottom 0ms ease-in-out;

    &.transition {
        transition: bottom 1500ms ease-in-out;
    }
}

.label {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0px 5px;
}

.main {
    font-family: $main-Font;
}
