@import '../../../variables';

.container {
    margin-top: 20px;
}

.manageTagsLink {
    color: $primary-color;
    cursor: pointer;
    font-weight: 600;
}

.rightButton {
    background-color: $main-color;
}
