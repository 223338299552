@import '../../../../../variables';

.contactObject {
    width: 400px;
    margin: 20px;
}

.addField {
    margin-left: 15px;
    font-size: 12px;
    color: $primary-color;
    height: 20px;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600;
}

.contactField {
    width: 380px;
    max-width: 380px;
}
