.continer {
    position: relative;
}

.addWidgetBtn {
    position: absolute;
    top: -2px;
    left: -78px;
    z-index: 12;
}

.addWidgetBtnWithoutPublish {
    left: 0px;
}
