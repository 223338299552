@import '../../../../../variables';

$error-color: #fe5e5b;

.fieldsCountText {
    display: flex;
    gap: 9px;
    align-items: flex-start;
}

.noFieldSelectedError {
    font-weight: 600;
    color: $error-color;
    text-decoration: underline;
}

.settingsIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
}