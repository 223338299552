@import '../../variables';

.container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .key {
        margin-right: 10px;
    }
}

.multipleKeyValueContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .inputPairContainer {
        margin-bottom: 15px;
        display: flex;
        width: calc(100% + 15px);
        &:hover {
            .deletePair {
                visibility: visible;
            }
        }

        .deletePair {
            visibility: hidden;
            position: relative;
            left: 10px;
            align-self: center;
            cursor: pointer;
        }
    }

    .borderBottom {
        border-bottom: 1px solid $separator-color;
    }

    .addPair {
        font-size: 12px;
        margin-top: 5px;
        text-align: left;
        cursor: pointer;
        color: $primary-color;
    }
}
