@import '../../variables';

$hover-purple: #5a3ac2;

.insights {
  position: relative;
  pointer-events: auto;
}

.hotspot {
  position: absolute;
  top: -13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px dashed $main-color;
  border-radius: 50%;
  transition: border 0.4s ease-in-out;
  z-index: 100;
  cursor: pointer;

  &:not(.visited) {
    @include rotatingAnimation(8s);
  }

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: $main-color;
    border-radius: 50%;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  &:hover, &.active {
    border: 1px dashed transparent;

    &:after {
      width: 20px;
      height: 20px;
      background-color: $hover-purple;
    }
  }
}

.visited {
  border: 1px dashed transparent;

  &:after {
    width: 10px;
    height: 10px;
    background-color: $hover-purple;
  }
}

.popover {
  background-color: $hover-purple;
  padding: 10px 10px 20px 20px;
  border-radius: 5px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.15);
  width: 330px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 10px;

  svg {
    cursor: pointer;
    position: absolute;
    padding: 8px;
    top: 0;
    right: 0;
  }
}

.insightsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: calc(400px - 40px); // 40px = top/bottom padding and header height of popover
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2) !important;

    &:hover, &:active {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.insightRow {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  color: white;
  font-size: 12px;

  span {
    margin-top: 3px;
  }
}