@import "../../variables";

%IDENT_BORDER {
    border-left: 2px solid #e9e8ed;
    padding-left: 8px;
}

.singleEventMain {
    @include notificationEvent();
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: $widget-title-color;
}

.clickableEvent {
    cursor: pointer;
    gap: 3px;
}

.avatarContainer {
    margin-right: 18px;
    width: 21px;
}

.dateContainer {
    color: $user-name-text-color;
    white-space: nowrap;
}

.messageContainer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.engagementIcon {
    width: 16px;
    height: 16px;
    padding-right: 5px;
    vertical-align: sub;
}

.messageText {
    width: fit-content;
}

.icon {
    font-family: AppleColorEmoji;
    font-size: 35px;
}

.highlightedText {
    font-weight: bold;
}

.semiHighlightedText {
    font-weight: 500;
}

.italicText {
    font-style: italic;
}

.eventFooter {
    color: $user-name-text-color;
    font-size: 10px;
}

.commentMessage {
    @extend %IDENT_BORDER;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.surveyAnswerMessage {
    @extend %IDENT_BORDER;
}

.eventMessage {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 5px
}
