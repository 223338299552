@import '../../../../../variables';

.calloutDialogAvatarOverlay {
    font-size: 10px;
    width: 51px;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
}

.calloutDialog {
    position: absolute !important;
    top: 180px !important;
}

.body {
    width: 550px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .subtitle {
        font-size: 14px;
        color: #000;
    }

    .textContainer {
        display: flex;
        padding-top: 10px;
        position: relative;
        gap: 15px;

        .textInput {
            flex: 1 1;

            [class*='DraftEditor-root'] {
                min-height: unset;
                height: 118px;
            }
        }
    }

    .errorText {
        color: $notification-icon-color;
        font-size: 12px;
        font-weight: 600;
    }

    .emptyError {
        margin-top: -10px;
    }

    .collapsibleAreaHeaderText {
        font-size: 14px;
        color: $subtext-color;
        padding-bottom: 20px;
        border-bottom: 1px $subtext-color dotted;
    }

    .collapsibleAreaAction {
        justify-content: unset;
        align-items: center;
        gap: 10px;
    }

    .collapsibleAreaHeader {
        width: unset;
    }

    .collapsibleAreaArrow {
        width: 10px;
        stroke: $subtext-color;
    }

    .contactInputsHeader {
        font-weight: 600;
        font-size: 12px;
        padding: 20px 0 10px;
    }

    .manualInputs {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.kitExplanation {
    padding-top: 5px;
    font-size: 12px;
    color: #000;
}

.confirmDeleteDialogBody {
    font-size: 14px;
    color: #000;
    white-space: break-spaces;
    padding: 15px 32px 19px 26px;
    line-height: 3;
}
