@import '../../../../../variables';

.kpiValue {
    word-break: break-word;
}

.kpiField {
    @include formEditKpiField();
    padding: 4px 0 0 15px;
}

.richText {
    padding: 0 0 0 8px;
}

.kpiLabel {
    font-size: 16px;

    :global(.DraftEditor-root) {
        min-height: 40px;
        max-height: 40px;

        :global(.public-DraftEditorPlaceholder-root) {
            margin: 8px 8px 8px 10px;
        }

        :global(.DraftEditor-editorContainer) {
            margin: 8px 0 0 9px;
        }
    }
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
