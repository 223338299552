@import '../../../src/variables';

.container {
    width: 96%;
    padding: 0 30px;
    position: relative;
}

.header {
    border-bottom: 1px solid $separator-color;
}

.divider {
    border-left: 1px solid $separator-color;
    height: 19px;
}
.taskTitleContainer {
    display: flex;
}
.taskCommentsButton {
    position: relative;
    opacity: 1;
    right: -10px;
    top: 4px;
    height: 8px;
    padding: 8px 3px 3px 8px;
    &:hover {
        border-radius: 3px;
    }
}

.taskTitleColumnContainer {
    margin-top: 5px;
}
.tabsInToolBar {
    position: absolute;
    top: 67px;
    left: 10px;
    z-index: 10;
}

.tabs {
    border-bottom: 1px solid $separator-color;
}

.tabsPageSelectedTab {
    font-weight: bold;
    color: $main-color;
    border-bottom: $main-color 3px solid;
}

.accountFilterAbsolute {
    position: absolute;
    top: 25px;
}

.grouping {
    position: absolute;
    right: 220px;
    top: 17px;
}

.addTask {
    position: absolute;
    right: 30px;
    top: 17px;
}

.editIcon {
    margin-left: auto;
    height: 27px;
    min-width: 27px;
}

.ownerIcon {
    display: flex;
    max-width: 50px;
    justify-content: center;
}

.hourglassContainer {
    display: flex;
    margin-left: 2px;
    height: 17px;
}
