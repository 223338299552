@import '../../../../variables';

.actionButton {
    width: 13px;
    height: 16px;
    cursor: pointer;
    padding: 3px 6px;
    margin: 0 4px;
    @include taskActionButton();
}

.deleteTaskAction {
    color: $error-message-color;
}

.warningText {
    width: 410px;
}
