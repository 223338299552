@import '../../variables';

$warning-color: #fe5e5b;

.content {
    display: flex;
    margin: 30px auto 0;
    text-align: center;
    flex-direction: column;
    align-items: center;

    &.narrow {
        width: 400px;
    }

    &.mid {
        width: 700px;
    }

    &.wide {
        width: 1000px;
    }

    &.max {
        max-width: 100%;
        margin-right: 40px;
        margin-left: 40px;
    }

    &.center {
        height: 100%;
        justify-content: center;
    }

    .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    .connectImg {
        margin-top: 20px;
    }

    .widgets {
        margin-bottom: 40px;

        .checkedIcon {
            margin-left: 10px;
            display: flex;
            align-items: flex-end;

            svg {
                fill: white;
            }

            .icon {
                margin-left: 3px;
            }
        }
    }
    h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    p {
        font-size: 14px;

        &.info {
            margin-bottom: 20px;
        }
        &.intro,
        &.disclaimer {
            font-size: 12px;
            opacity: 0.7;
            margin-bottom: 20px;
        }

        &.subTitle {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }
    }

    .dropDownSection {
        display: flex;
        text-align: start;
        margin-bottom: 30px;

        .dropDown {
            .dropDownLabel {
                font-size: 12px;
            }
        }

        .explain {
            margin-right: 55px;

            .explainTitle {
                font-size: 14px;
                margin-bottom: 10px;
            }

            .explainText {
                font-size: 12px;
                width: 250px;
                opacity: 0.7;
            }
        }
    }

    .multiDropDownSection {
        text-align: left;
        margin: 0 11px;
    }

    .option {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .label {
            font-size: 10px;
            color: $subtext-grey;
            margin-bottom: 10px;
        }
    }

    .dropDown {
        .dropDownLabel {
            font-size: 10px;
            color: $subtext-grey;
            margin-bottom: 10px;
        }

        .dropDownSubLabel {
            font-size: 10px;
            color: $subtext-grey;
            margin: 2px 0 3px;
        }

        .dropDownTitle {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: start;
            padding-bottom: 10px;
        }
    }

    .operators,
    .values {
        .dropDown {
            margin-bottom: 22px;
        }
    }

    .tagsWithSmallFont {
        :global div {
            font-size: 14px;
        }
    }
    .checkedTag {
        background-color: $checked-tag-color;
        color: white;
        box-shadow: none;
    }

    .allEditable {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        margin: 22px 0;
        color: #0b3ff2;
        text-decoration: underline;
        cursor: pointer;

        span {
            margin-right: 10px;
        }
    }

    .checkboxContainer {
        display: flex;
        margin-bottom: 10px;

        .text {
            font-size: 12px;
            line-height: 1.67;
            color: #000000;
            margin: 0 0 0 24px;
            text-align: left;
        }
    }
}

.objectTypeInputs {
    gap: 50px;
}

.newLine {
    display: block;
}

.accountManagerSyncContainer {
    display : flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.error {
    font-size: 12px;
    font-weight: 600;
    color: $warning-color;
    margin-top: 2px;
}