$bulletMargin: 13px;

.inputHidden {
    position: absolute;
    left: -9999px;
}

.container {
    display: flex;
    flex-direction: column;
}

.bulletWrapper {
    display: flex;
    .bulletLabel {
        cursor: pointer;
    }

    .disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.htmlLabel {
    display: flex;
    cursor: pointer;
}

.disabled {
    cursor: default;
}

.imgDisabled {
    cursor: default;
    opacity: 0.7;
}

.img {
    margin-right: $bulletMargin;
    min-height: 20px;
    min-width: 20px;
}

.text {
    font-size: 14px;
}

.bulletOn {
    // --primary-color is a variable set in the elements style
    g {
        circle:nth-child(3) {
            stroke: var(--primary-color);
        }

        circle:nth-child(4) {
            fill: var(--primary-color);
        }
    }
}

.bulletInfo {
    margin-left: 5px;
    width: 12px;
    height: 20px;
    flex-shrink: 0;
}

.tooltipInfo {
    min-width: unset;
}

.additionalElementWrapper {
    margin-top: 10px;
    margin-left: calc(20px + #{$bulletMargin})
}

.disabledAdditionalElement {
    pointer-events: none;
    opacity: 0.7;
}

input[type='radio']:checked + label > img {
    content: url(./../../assets/icons/radio-bullet.svg);
}

/* Stuff after this is only to make things more pretty */

input[type='radio'] + label > img {
    content: url(./../../assets/icons/radio-bullet-unselected.svg);
}

.element {
    margin-bottom: 24px;
    // &:last-child {
    //     margin-bottom: none;
    // }
}
