@import '../../variables';

.container {
    display: flex;
    gap: 10px;
    font-size: 14px;
    align-items: center;
    flex-wrap: wrap;
    font-family: $main-Font;
}

.editModeContainer {
    width: 100%;
}

.editModeCollaboratorsDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.hide {
    font-size: 12px;
    cursor: pointer;
    color: $collaborators-info-color;
}

.icons {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.collaboratorsInputContainer {
    width: 100%;
    padding-bottom: 10px;

    .input {
        background-color: white;
    }
}

.infoText {
    font-size: 12px;
    color: $collaborators-info-color;
}
