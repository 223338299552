@import '../../../variables';

.error {
    position: absolute;
    bottom: 68px;
    right: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;
}

.customLeftButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.customLeftButtonBorder {
    margin: 0 20px;
    border-left: 1px solid $border-color;
    height: 20px;
}

.mandatoryLabel {
    font-size: 12px;
}

.deleteRecordDialog {
    margin: 15px 30px 30px;
}

.fieldInput {
    margin-bottom: 25px;
}

.emptyState {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.dialog {
    width: 500px;
    max-height: 650px;
    overflow: scroll;
    padding: 20px 30px 20px 18px;

    @media (max-width: $mobile) {
        width: unset;
    }
}