@import '../../variables';

.container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 400px;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.mandatoryLabel {
    font-size: 14px;
}

.error {
    color: $error-message-color;
    font-size: 14px;
}
