@import '../../../../variables';

.title {
    @include title();
}
.container {
    display: flex;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    align-items: center;
    padding: 20px;
}

.textContainer {
    margin-left: 20px;
    width: 100%;
}
.subTitle {
    font-size: 12px;
    color: $subtitle-color;
}

.check {
    position: relative;
    bottom: 32px;
    right: 12px;
}

.imageContainer {
    position: relative;

    &.noIcon {
        margin-left: 14px;
    }

    img {
        max-width: unset;
    }

    .betaTag {
        color: $font-color-in-main-color;
        font-size: 9px;
        padding: 0 3px;
        background-color: $primary-branded-color;
        border: 1px solid $font-color-in-main-color;
        position: absolute;
        top: -6px;
        left: 22px;
    }
}
