.existingAssetDialog {
    width: 518px;
    margin: 26px;
}

.subTitle {
    margin-bottom: 15px;
    height: 10px;
}

.createNewAssetLabel {
    font-weight: 600;
    font-size: 12px;
}

.addAssetToAccountDialog {
    position: absolute;
}

.addAssetToAccountContent {
    width: 526px;
    margin: 26px;

    .addAssetTypeOptions {
        margin-top: 10px;

        .addAssetTypeOption {
            text-align: center;
            font-size: 14px;
            padding: 12px 17px;
        }
    }
}