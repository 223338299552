@import '../../../../variables';

.container{
    padding-top: 20px;
    text-align: left;
    margin: 0px 26px;
    padding-bottom: 30px;

    .description {
        @include description(14px);
    }

    .submitButton {
        width: 400px;
        padding: 0 10px;
    }
    
    .saveNewFilter {
        margin-top: 15px;
    }
    
    .filters{
        margin-top: 20px;
        
        [class*='multiDropDownSection'] {
            margin: 0;
        }
        
        [class*='addFilterButton']{
            margin-left: 10px;
        }
    }
}

.deleteDialog {
    width: 452px;
    padding: 25px 26px 15px;

    p {
      margin-bottom: 25px;
    }
}

.editDialogHeader {
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
    color: #000;
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialogTitle {
    display: flex;
    gap: 5px;
    align-items: center;
    width: 80%;

    span {
        width: 23%;
    }
}

.labelEditInput {
    width: 100%;
}

.viewMode {
    pointer-events: none;
}

.rightSideActions  {
    align-self: baseline;
    display: flex;
    gap: 20px;
    width: 70px;
}

.closeIcon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.errorContainer {
    position: relative;
}

.error {
    color: $error-message-color;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    right: 25px;
    bottom: 0;
}