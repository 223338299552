@import '../../variables';

.actionsContainer {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    background-color: $dialog-footer-background-color;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: flex-end;
}

.actionsFlexEnd {
    justify-content: flex-end;
}

.actionsSpaceBetween {
    justify-content: space-between;
}

.notificationContainer {
    flex: 0 1 auto;
}
.subTitle {
    flex: 0 1 auto;
    color: $subtext-grey;
    font-size: 12px;
}

.notificationTitle {
    font-size: 15px;
    margin-bottom: 7px;
    font-weight: bold;
}

.noComment {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;

    &.resolvedComment {
        margin-bottom: 5px;
    }

    .resolvedText {
        color: $subtext-grey;
        font-size: 14px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .commentHeader {
        margin-bottom: 20px;
    }

    .titleContainer {
        display: flex;
        border-bottom: 1px solid $separator-color;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 25px 10px;

        .title {
            font-size: 16px;
            font-weight: bold;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    // Used in DiscussionWidget.module.scss for override
    .noCommentsContainer,
    .noCommentsTitleContainer,
    .customerNoCommentsContainer {
        margin-top: 0px;
    }
    .commentsContainer {
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        &:not(.commentsResolvedBody) {
            padding: 0 10px;
        }
    }
    .newComment {
        padding: 0 25px;
        font-size: 12px;

        .actionsContainer {
            margin: 20px 0 0 -30px;
            padding: 13px 30px;
        }
    }
    .newReply {
        $new-reply-container-width: 78%;

        background: $placeholder-content-background;
        width: $new-reply-container-width;
        border-radius: 9px;

        padding: 10px 20px;
        margin-bottom: 10px;
        margin-left: calc(calc(100% - #{$new-reply-container-width}) / 2 - 10px);

        &.internal {
            background: $internal-data-background-color;
            .actionsContainer {
                background: $internal-data-background-color;
            }
        }
    }
}

.emptyFollowers {
    margin-top: 25px;
}
.resolvedAvatar {
    filter: grayscale(100%);
}

.commentWithReplies {
    height: fit-content;
    padding-bottom: 20px;
}
.commentContainer {
    margin-bottom: 5px;
    .internalLabel {
        position: absolute;
        top: 5px;
        right: 20px;
    }

    &:hover {
        .internalLabel {
            opacity: 0;
        }
    }

    border-radius: 4px;
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $placeholder-content-background;
        border-radius: 4px;
        .iconsContainer {
            z-index: 5;
            transition: all 0.2s ease-in;
            opacity: 1;
        }

        [class*='internalLabel'] {
            display: none;
        }
    }
    transition: all 0.2s ease-in;

    &.internal {
        transition: all 0.2s ease-in;
        background-color: $internal-data-background-color;
    }
}

.iconsContainer {
    opacity: 0;
    position: absolute;
    right: 10px;
}

.actionIcon {
    height: 13px;
    width: 13px;
    margin-right: 5px;
    cursor: pointer;
    margin-top: 5px;
}

.commentsBody {
    max-height: $comments-dialog-height;
}

.commentsResolvedBody {
    padding: 0 10px 10px;
}

.newCommentContainer {
    .commentEditorStyled {
        @include typeAComment();
        :global(.DraftEditor-root) {
            min-height: 41px;
            max-height: 141px;
        }

        &:not(.commentEditorStyledDisabled):not(.commentMinimized) {
            min-height: 90px;

            :global(.DraftEditor-root) {
                min-height: 90px;
            }
        }

        &.withConfiguration {
            border-radius: 0;
            border-bottom: none;
            border-right: none;
            border-left: none;
            :global(.DraftEditor-root) {
                padding-top: 5px;
            }
        }

        transition: all 0.2s ease-in;
        &.internal {
            transition: all 0.2s ease-in;
            border-color: $internal-data-border-color;
            background-color: $internal-data-background-color;
        }
    }
}

.commentMinimized,
.commentEditorStyledDisabled {
    [role='toolbar'] {
        display: none;
    }
}

.commentEditorStyledDisabled {
    opacity: 0.2;
    pointer-events: none;
}

.leftButtonNewComment {
    margin-right: 11px;
    font-size: 14px;
    cursor: pointer;
}

.inputContainerWrapper {
    margin-top: 15px;
    padding-bottom: 2px;
    [class*='toolbar'] {
        margin-bottom: 0;
    }
    .internalSettings {
        font-family: $main-Font;
        [class*='singleTabHeader'] {
            padding: 0;
            margin: 5px 10px 0;
            > div {
                font-size: 12px;
                font-weight: normal;
            }

            &[class*='selected'] {
                > div {
                    font-weight: 600;
                }
            }
        }
    }

    border: 1px solid $widget-container-border-color;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: all 0.2s ease-in;

    &.internal {
        border: 1px solid $internal-data-border-color;
        background: $internal-data-background-color;
        transition: all 0.2s ease-in;
    }
}

.internalCommentTooltip {
    text-align: center;
}

.emailInput {
    padding-top: 5px;
    height: 55px;
}

.errorMessage {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .errorIcon {
        flex-shrink: 0;
        width: 18px;
        height: 20px;
        padding-top: 6px;
        margin-left: 5px;
    }
}

.commentDialog {
    padding-top: 2px;
    width: 600px;
}

.closeIcon {
    float: right;
    margin-right: 10px;
}
