@import '../../_variables.scss';
.container {
    width: inherit;
    height: inherit;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    cursor: text;
    background: #fefefe;
    position: relative;

    [class*='DraftEditor-root'] {
        overflow-x: hidden;
    }
}

.label {
    font-size: 10px;
    color: black;
    opacity: 0.7;
    padding-left: 4px;
}

a:hover {
    cursor: pointer;
}

.textSnippetIcon,
.magicContentIcon {
    cursor: pointer;
    position: relative;
    z-index: 10;
    float: right;
    margin: 3px 5px 0 5px;
}

.textSnippetIcon {
    &:hover {
        path {
            transition: stroke 0.2 ease-in;
            stroke: $snippet-button-color;
        }
    }
}

.magicContentIcon {
    &:hover {
        transition: fill 0.2 ease-in;
        path {
            fill: $snippet-button-color;
        }
        rect {
            stroke: $snippet-button-color;
        }
    }
}

.input {
    height: 34px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: transparent;
    border: none;
    color: #000000;
}

.input:focus {
    outline: none;
}

.input::placeholder {
    color: $placeholder-editor-color;
}

.inputInvalid {
    color: #e65757;
}

.link {
    color: #545454;
    text-decoration: underline;
}

.focusedToolbar {
    position: absolute;
    z-index: 1;
}

.bluredToolbar {
    display: none;
}

.focusedToolbarContainer {
    position: relative;
}

.bluredToolbarContainer {
    position: initial;
}

.mentionSuggestions {
    max-height: 200px;
    overflow: auto;
    box-shadow: none;
}

.mentionSuggestionsEntry {
    padding: 12px 7px 8px;
    display: flex;
}

.toolbar {
    margin: 0;

    .toolbarButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .defaultButton {
        flex: 1 1;
    }

    .uploadFileButton {
        padding: 9px;
        border-radius: 0;
        vertical-align: super;
        display: inline-block;
    }
}
