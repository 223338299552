@import '../../../../../variables';

$edit-dialog-width: 600px;

.contentLayout {
    width: $edit-dialog-width;
}

.editDialogBody {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 20px;

    [class*='DraftEditor-root'] {
        min-height: 60px;
        max-height: unset;
    }
}

.internalNotesDialogContainer {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 5px;
}

.internalNotesDialogEditor {
    border: 1px solid $help-icon-background-color;
}

.internalNotesText {
    position: absolute;
    top: -8px;
    padding: 3px 25px;
    font-size: 11px;
    font-weight: 500;
    border: 1px solid $help-icon-background-color;
    border-radius: 12px;
    background-color: $internal-data-background-color;
    z-index: 1;
}

.dialogHeader {
    display: flex;
    width: 100%;
    height: 20px;
    padding: 15px 20px;
    align-items: center;
    max-width: calc(#{$edit-dialog-width} - 40px);
    @include ellipsis();
}

.checkboxLine {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        cursor: default;
        font-size: 14px;
    }
}

.fieldLine {
    display: flex;
    gap: 10px;
    min-height: 54px;
    align-items: center;
}

.fieldsDropDownInput {
    width: 40%;
}

.fieldsDropDownMenu {
    [class*='MenuList'] {
        max-height: 260px;
    }
}

.inputTitle {
    display: none;
}

.input {
    width: 40%;
}

.inputContent {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    width: 100%;
    gap: unset;
}

.refreshHub {
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
    font-size: 14px;

    .icon {
        vertical-align: middle;
        margin-left: 5px;
    }
}
