.dialogTitleContainer {
    display: flex;
    gap: 10px;
    align-items: center;

    .title {
        font-size: 16px;
    }
}

.dialogBody {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 14px;
    margin: 26px;
    width: 450px;

    .enableDetails {
        font-weight: bold;
    }
}
