@import '../../../../../../variables';

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50px;

    .files {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 50px;
    }

    .addIcon {
        height: 24px;
        width: 24px;
        padding: 5px;
        background: $widget-container-background-color;
        border-radius: 5px;
        border: 1px dashed $separator-color;
        fill: $empty-state-text-color;
        @include taskActionButton();

        &.uploadingFile {
            cursor: default;
            &:hover {
                background: none;
            }
        }
    }

    .file:last-of-type {
        margin-right: 50px;
    }

    .loader {
        margin-left: -7px;
        margin-top: -7px;
    }

    .uploadedFileContainer {
        > div {
            height: 30px;
        }
    }
}
