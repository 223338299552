@import '../../../../../variables';

// CUSTOMER CALLOUT STYLES

.wrapper {
  position: relative;
  height: 52px;
  margin-top: -52px;
}

.calloutFloatingWindow {
  background-color: white;
  z-index: 99;
  position: absolute;
  right: 72px;
  padding: 12px 20px 10px;
  min-height: 31px;
  max-width: calc(32vw - 20px);
  width: max-content;
  cursor: default;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.2);

  & > div {
    max-width: calc(31vw - 20px);
  }

  animation: openCalloutDesktop 300ms;
}

.calloutFloatingWindowWithEdit {
  padding-right: 60px;
}

.closeAnimation {
  animation: closeCalloutDesktop 300ms;
  opacity: 0;
}

.hideFirstRender {
  visibility: hidden;
}

.calloutPopperActions {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.editCalloutIcon,
.closeTextCallout {
  cursor: pointer;
}

.closeTextCallout {
  padding-left: 10px;
}

.editCalloutIcon {
  padding-right: 10px;
  border-right: 1px solid $divider-color;
}

.calloutText {
  overflow-wrap: anywhere;
  font-size: 14px;
}

.bottomScreenTextPosition {
  bottom: 0;
}

.topScreenTextPosition {
  top: 10px;
}

.calloutFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  width: calc(100% + 10px);
}

.calloutContactDetails {
  color: $subtext-color;
  font-size: 12px;
}

.reply {
  font-size: 14px;
  color: $primary-color;
  cursor: pointer;
}

@media (max-width: $mobile) {
  .calloutContainer {
    margin-top: 24px;
  }

  .calloutAvatar {
    height: 34px;
    width: 34px;
    margin-top: -34px;
  }

  .calloutFloatingWindow {
    right: -12px;
    min-height: 31px;
    width: max-content;
    max-width: 75vw;

    & > div {
      max-width: unset;
    }

    // Moves callout to the right to compensate for the avatar bubble being more left due to comments
    &.calloutPopperWithComments {
      right: -60px;
    }
  }

  .topScreenTextPosition {
    animation: openCalloutAnimationTopMobile 300ms;
  }

  .bottomScreenTextPosition {
    animation: openCalloutAnimationBottomMobile 300ms;
  }

  .closeAnimation {
    animation: closeCalloutAnimationMobile 300ms;
  }

  // In mobile, text is shown on top on below callout avatar

  .topScreenTextPosition {
    bottom: unset;
  }

  .bottomScreenTextPosition{
    top: unset;
  }

  .topScreenTextPosition {
    top: 55px;
  }

  .bottomScreenTextPosition {
    bottom: 45px;
  }
}

@keyframes openCalloutAnimationTopMobile {
  0% {
    opacity: 0;
    margin-top: -50px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes openCalloutAnimationBottomMobile {
  0% {
    opacity: 0;
    margin-bottom: -50px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

@keyframes closeCalloutAnimationMobile {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


@keyframes openCalloutDesktop {
  0% {
    opacity: 0;
    margin-right: -50px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes closeCalloutDesktop {
  0% {
    opacity: 1;
    margin-right: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    margin-right: -50px;
  }
}