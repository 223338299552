@import '../../variables';

.improveTextBtn {
    display: inline-flex;
    width: 15px;
    height: 22px;
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-right: 2px;
    padding: 4px 8px;
    border-radius: 5px;
    background-color: #f4f1ff;
    font-weight: 500;
    color: $main-color;
    transition: width 400ms 500ms linear;
    cursor: pointer;

    .label {
        white-space: nowrap;
        background-color: unset;
        height: unset;
        opacity: 0;
        font-size: 12px;
        font-family: $main-Font;
    }

    &.content {
        width: 95px;

        .label {
            opacity: 1;
            font-size: 14px;
            transition: opacity 0.5s 1s ease-in;
            background-color: unset;
            font-size: 12px;
            font-family: $main-Font;
        }
    }

    .magicContentIcon {
        path {
            fill: $main-color;
        }

        rect {
            stroke: $main-color;
        }
    }
}
