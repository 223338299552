@import '../../../variables';

.main {
    @extend %widgetsModuleHeader;
    background-color: $widgets-header-color;
    justify-content: space-between;

    .text {
        color: $widget-browser-text;
    }

    .leftSideHeader {
        display: flex;
        flex-direction: row;

        .ovalGreen {
            @include ovalWidgetHeader();
            margin: 5px 13px 5px 9px;
            background-color: $widgets-header-green-oval;
        }
        .ovalOrange {
            @include ovalWidgetHeader();
            margin: 5px 0 3px;
            background-color: $widgets-header-orange-oval;
        }

        .ovalRed {
            @include ovalWidgetHeader();
            margin: 5px 9px 3px 7px;
            background-color: $widgets-header-red-oval;
        }
    }

    .rightSideHeader {
        display: flex;
        margin-top: -1px;
    }

    .section {
        margin: 0px 10px;
        border-right: 1px solid #dfdfdf;
    }
}
