@import '../../../../../../../../variables';

.title {
    font-size: 18px;
    font-weight: bold;
    color: $text-color;
}

.midSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin: 20px 0;
}

.description {
    color: $text-color;
    font-size: 14px;
    margin: 20px 0 40px;
    max-width: 500px;
}

.imageLogo {
    margin: 20px 0;
}
