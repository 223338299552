@import '../../../variables';
@import './KitGalleryVariables';

$START-FROM-SCRATCH-BORDER-COLOR: #cccccc;
$START-FROM-SCRATCH-THUMBNAIL-BORDER-COLOR: #eaecee;

.templateWrapper {
  position: relative;

  &.templateHover {
    transition: opacity ease-in-out 200ms;
  }
}

.templateWrapper:hover .templateHover, .forceTemplateHover .templateHover {
  opacity: 1;
}

.blockPointerEvents {
  pointer-events: none;
}

.template {
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  aspect-ratio: 1 / 1;
  border-radius: 11px;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.templateHover {
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 1;
  background-color: rgba(230, 218, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 11px;
  transition: opacity ease-in-out 200ms;
}

.thumbnailHeader {
  align-self: stretch;
}
.thumbnailImage {
  @extend %THUMBNAIL_IMAGE;
}

.tags {
  @extend %TAGS;
}

.templateText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
}

.templateTitle {
  color: $TEXT_COLOR;
  font-size: 18px;
  font-weight: 500;
}

.templateSubtitle {
  color: $subtext-grey;
  font-size: 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.badges {
  position: absolute;
  top: 19px;
  right: -7px;
  display: flex;
  gap: 5px;
  z-index: 2;
  cursor: default;
}

.badge {
  border-radius: 5px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 12px;
}

.darkModeTemplate {
  .templateTitle, .templateSubtitle {
    color: #ffffff;
  }

  .templateSubtitle {
    opacity: 0.9;
  }

  .tag {
    background-color: $SELECTED_TAG_BG_COLOR_DARK;
    color: #ffffff;
  }
}

.getThisKitButton {
  transition: opacity ease-in-out 200ms;
  &:hover {
    opacity: 0.8;
  }
}

.readMore {
  border: none;
  cursor: pointer
}


.startFromScratch {
  background-color: #ffffff;
  border: 1px dashed $START-FROM-SCRATCH-BORDER-COLOR;
  cursor: pointer;

  &:hover .startFromScratchThumbnail {
    opacity: 0.6;
  }
}

.startFromScratchThumbnail{
  @extend %THUMBNAIL_IMAGE;
  border: 1px solid $START-FROM-SCRATCH-THUMBNAIL-BORDER-COLOR;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease-in-out 200ms;
}