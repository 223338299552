@import '../../../variables';

.field {
    margin-bottom: 15px;
}

.isShownByDefaultCheckbox {
    display: flex;
    margin-bottom: 5px;

    .label {
        margin-left: 8px;
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .objectSelectContainer {
        display: flex;
    }

    .accountFieldSelector {
        display: flex;
    }
}

.infoIcon {
    margin-left: 10px;
    margin-top: 36px;
}

.dialogContainer {
    position: absolute;
}

.dialogContentContainer {
    width: 526px;
    padding: 40px 26px 20px;
    overflow-y: scroll;
    font-size: 14px;
    max-height: 65vh;

    :global(.MuiInputBase-input) {
        font-size: 14px;
    }

    :global(.MuiFormLabel-root) {
        font-size: 14px;
    }

    [class*='label'] {
        color: $textbox-hover-border-color;
    }

    :global(.MuiFormLabel-root.Mui-error) {
        &[class*='label'] {
            color: $textbox-hover-border-color;
        }
    }

    [class*='label'] {
        color: $textbox-hover-border-color;
    }

    :global(.MuiInputBase-root) {
        color: $text-color;
        outline: none;
        font-size: 14px;

        fieldset {
            border-color: $textbox-border-color;
        }

        &:hover {
            fieldset {
                border-color: $textbox-hover-border-color;
            }
        }
    }
}

.settingsToggle {
    font-size: 13px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 0;
}

.followersToggle {
    display: flex;
    align-items: center;
}

.syncUrlContainer {
    padding-top: 10px;

    .infoText {
        font-size: 14px;
        padding-bottom: 10px;
    }
}

.syncUrlOptionsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;

    &.collapsed {
        height: 0;
        overflow: hidden;
    }
}

.deleteButton {
    position: absolute;
    left: 28px;
    bottom: 21px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 16px;
        width: 16px;
    }

    transition: fill 0.2s ease-in-out;

    &:hover {
        transition: fill 0.2s ease-in-out;
        fill: $error-message-color;
    }
}

.hidden {
    display: none;
    height: 0;
    width: 0;
}

.settingsWithConfiguration {
    .configurationButton {
        margin-left: 5px;
    }
}

.deleteDialog {
    width: 526px;
    margin: 26px;

    p {
        margin-top: 20px;
    }
}

.deleteConfirmationButton {
    padding: 0 30px;
}

.newRecordMenuItem {
    color: $primary-color !important;
    font-weight: 600;
}