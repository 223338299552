@import '../../../variables';

h3 {
    font-size: 18px;
    margin-bottom: 4px;
}

.intro {
    font-size: 12px;
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.inlineDropDowns {
    display: flex;
    align-items: center;
    font-size: 14px;

    > span {
        margin: 0 15px;
    }
}
