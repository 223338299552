@import '../../../../variables';
@import '../TemplateModal.module';

.container {
    display: flex;
    flex-direction: column;
    gap: 25px;

    :global(.MuiPaper-root) {
        margin-left: 28px;
    }

    .row {
        display: flex;

        .checkboxLabel {
            @include description(14px);
            margin-left: 10px;
            display: flex;
            align-items: center;

            .infoIcon {
                margin-left: $info-icon-margin-left;
            }
        }
    }

    .objectFilter {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: space-between;

        .additionalObjectsCombo {
            flex: 0 0 375px;
            &:global(.MuiFormControl-root) {
                margin: 12px 0 0 28px;
            }
        }
    }

    .additionalObjectsFilterRow {
        @include flexRow();
        margin-top: 10px;

        .title {
            @include widgetTitle();
            @include flexRow();
            white-space: nowrap;

            .infoIcon {
                margin: 0 15px 0 7px;
            }
        }

        .pen {
            cursor: pointer;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: flex-end;
        gap: 5px;
    }
}

.addLinkObject {
    font-size: 12px;
    color: $primary-branded-color;
    font-weight: bold;
    cursor: pointer;
}
