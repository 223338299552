@import '../../variables';

.saving {
    @include widgetPanelConfigurationName;
}

.savingLogo {
    vertical-align: middle;
    height: 30px;
    width: 60px;
}

.savedText {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkmark {
    height: 20px;

    circle {
        fill: transparent;
    }
}

.savingAnimation {
    text-align: left;
    display: flex;
    width: 60px;
    color: $saving-text-color;
}

.savingAnimation:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0;
}

@keyframes ellipsis {
    to {
        width: 1em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1em;
    }
}
