@import '../../../../../../variables';

.robotIconContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    @include taskActionButton();
    align-items: flex-start;
    padding: 5px;
    position: relative;

    .automate {
        font-size: 12px;
    }
}

.oval::after {
    @include robotIconOval;
}

.tooltipContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .tooltipEmails {
        display: flex;
        flex-direction: column;
    }
}
