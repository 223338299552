@import '../ResourceViewMode/ResourceViewMode.module';

.container {
    grid-template-columns: 70px calc(100% - 200px) 130px;
    min-width: 90%;
    flex: auto;

    .details {
        height: auto;
        width: 100%;

        .textArea {
            overflow-y: scroll;
            font-size: 12px;

            @media (max-width: $mobile) {
                width: 100%;
            }
        }
    }

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 90%;
    }
}

.resourceAction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 5px;

    .actionButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        margin-left: 20px;

        &.linkUrl {
            text-decoration: none;
            @include sharedWidgetBtn;
        }

        &.downloadFile {
            cursor: pointer;
            @include sharedWidgetBtn;
        }
    }

    @media (max-width: $mobile) {
        position: absolute;
        top: 13px;
        right: 17px;
        padding: 3px 0 3px 8px;
        justify-content: flex-end;
    }

    .menu {
        cursor: pointer;
    }
}
