@import '../../../../../variables';

.dialogWrapper {
    position: relative;
}

.contentLayout {
    display: flex;
    width: calc(90vw - 80px);
    height: calc(100vh - 206px);
    max-width: 1440px;
    max-height: 1440px;
    justify-content: space-between;

    .taskEditSection {
        flex-basis: 65%;
        width: 100%;
    }

    .commentsSection {
        border-left: 1px solid $separator-color;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 35%;
        width: 35%;
        flex-shrink: 0;
        overflow: auto;
        height: 100%;
        > div {
            display: flex;
            align-items: center;
            width: 100%;
        }
    }
}

.contentLayoutTemplateView {
    max-width: 1000px;
}

.internalTaskLabel {
    opacity: 0;
    transition: opacity 0.2s ease-in;
    position: absolute;
    background: $widget-container-background-color;
    width: 224px;
    height: 30px;
    padding: 5px 30px;
    border-radius: 40px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 18px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.visible {
        opacity: 1;
        transition: opacity 0.2s ease-in;
    }
}

.confirmTitle {
    @include confirmDialogTitle();
}
