@import '../../variables';

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.container .newToSiteText {
    margin-top: 20px;
}

.forgotPasswordLink,
.ssoLink {
    align-items: flex-start;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $primary-color;
    display: flex;
    margin-top: 20px;
}

.sso {
    display: flex;
    align-items: center;
    margin: 0 20px 0 30px;

    span {
        margin-right: 5px;
    }

    .ssoLink {
        margin: 0;
        cursor: pointer;
        text-decoration: none;
    }
}

.googleButton {
    margin-top: 31px;
    margin-bottom: 23px;
}

.container .loginButton {
    width: 310px;
    margin-bottom: 23px;
    display: flex;
    height: 50px;
    align-items: center;
    margin-top: 10px;
    border-radius: 25.5px;
    justify-content: center;
}

.buttonLogo {
    width: 20px;
    margin-right: 16px;
    height: 21px;
}

.formContainer,
.signupFormContainer {
    display: flex;
    flex-direction: column;
    width: 310px;
    align-items: center;
}

.formContainer {
    margin-top: 22px;

    .textField {
        width: 270px;
        margin-bottom: 22px;
    }
}

.mfaFormContainer {
    margin-top: 70px;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 310px;
    color: #a2a2a2;

    @media (max-width: $mobile) {
        width: unset;
    }
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: solid 1px #cfcfcf;
}

.separator::before {
    margin-right: 15px;
}

.separator::after {
    margin-left: 15px;
}

.complianceContainer {
    display: flex;
}

.complianceContainer .complianceText {
    font-size: 10px;
    line-height: 1.7;
    color: #666464;
    margin-left: 20px;
}

.complianceContainer .complianceText a {
    font-weight: 600;
    text-decoration: none;
    color: $primary-color;
}

.complianceCheckBox input {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
}

.inputValidatorsConatainer {
    width: inherit;
}

.inputValidatorsConatainer .completed {
    text-decoration: line-through;
}

.inputValidatorsConatainer .completed .validationText {
    opacity: 0.2;
}

.inputValidatorsConatainer .validationText {
    opacity: 0.7;

    &.error {
        color: #ee3543;
        opacity: 1;
    }
}

ul {
    padding: 0 0 0 20px;
    margin: 0 0 10px 0;
}

ul .completed {
    list-style-image: url('../../assets/icons/condition-done.svg');
}

ul li {
    margin: 0 0 5px 0;
}

input[type='checkbox'] {
    width: 35px;
    height: 25px;
    margin: 0;
}

.errorMessage {
    width: 310px;
    font-size: 10px;
    font-weight: 600;
    color: #ee3543;
}

.errorPosition {
    margin-top: 15px;
    text-align: center;
    font-size: 12px;
}

.submitButton {
    margin-bottom: 40px;
    margin-top: 20px;
    @include mobileButton();
    @media (max-width: $mobile) {
        z-index: 101;
        margin-bottom: 0;
    }
}

.mfaSubmitButton {
    margin-bottom: 20px;
    margin-top: 20px;
    @include mobileButton();
    @media (max-width: $mobile) {
        z-index: 101;
        margin-bottom: 0;
    }
}

.complianceContainer {
    @media (max-width: $mobile) {
        @include centeredStickyElement();
        height: $mobile-button-bottom-offset + $mobile-button-height + 80px;
        bottom: 0;
        width: 100%;
        box-shadow: -3px 0px 11px 0 rgba(0, 0, 0, 0.2);
        padding-top: 10px;
        background: $widget-container-background-color;
        display: flex;
        justify-content: center;
        z-index: 100;
        .complianceText {
            width: 290px;
        }
    }
}

.welcome {
    @include onboardingTitle();
    font-family: $secondary-font;
}

.linkUsers {
    color: $text-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 40%;
    gap: 5px;

    .infoLinkUser {
        width: 65%;
    }

    .textToMark {
        font-weight: bold;
    }
}

.loginOptions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0 30px 0;
    align-items: center;
}

.emailField {
    margin-top: 30px;
}

.mfaCodeInput {
    width: 270px;
    margin-bottom: 22px;
    font-size: 18px;
    color: #000000;
}

.codeSent {
    font-size: 14px;
    margin: 30px 0 10px 0;
    display: flex;
    align-items: end;
}

.checkIcon {
    margin-right: 3px;
}
