@import '../../variables';

.avatarCircleContainer {
    border-radius: 100%;
    width: 55px;
    height: 55px;
    padding: 2px;
    box-sizing: border-box;
    position: relative;
}

.editableAvatar {
    cursor: pointer;
}

.avatarWithOverlayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gravatarContainer {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & img {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
}

.overlayText {
    position: absolute;
    z-index: 2;
    font-size: 12px;
    font-family: $main-Font;
    cursor: default;
}

.avatarTooltip {
    white-space: break-spaces;
    text-align: center;
}

.hideTooltip {
    display: none;
}

.imageContainer {
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
    position: relative;
}

.blurImage {
    filter: blur(2px);
}

.editPhotoIcon {
    z-index: 10;
    position: absolute;
    bottom: -15px;
    right: -16px;
    cursor: pointer;
}

.errorIcon {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: $notification-icon-color;
    color: white;
    position: absolute;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: calc(50% - 7px);
    left: calc(50% - 8px);
    font-family: $main-Font;

    .errorIconSymbol {
        padding-left: 1px;
    }
}
