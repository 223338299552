@import '../../../variables';
.manageSnippets {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    font-weight: normal;
    margin-left: 332px;
}
.container {
    width: 625px;
    height: 371px;
    display: flex;
    padding-bottom: 0px;
    .titlesContainer {
        border-right: 1px solid $divider-color;
        display: flex;
        flex-direction: column;
        width: 253px;
        overflow-y: scroll;
        flex: 0 0 253px;
        overflow-x: hidden;
        .search {
            padding-left: 30px;
            padding-right: 34px;
            margin-bottom: 16px;
        }
        .titleWrapper {
            font-size: 14px;
            min-height: fit-content;
            margin-left: 25px;
            padding: 5px 5px 5px 25px;
            width: 185px;
            margin-bottom: 10px;
            &:hover {
                border-radius: 5px;
                width: 185px;
                cursor: pointer;
                background-color: $text-snippet-hover-background-color;
            }
        }
        .chosenTitle {
            padding: 5px 5px 5px 25px;
            border-radius: 5px;
            width: 185px;
            background-color: $chosen-text-snippet-background-color;
            color: $chosen-text-snippet-color;
            font-weight: bold;
        }
    }
    .content {
        font-size: 14px;
        padding-right: 30px;
        margin-left: 30px;
        overflow-y: scroll;
        overflow-x: hidden;
        white-space: pre-line;
        margin-top: 15px;
    }
}

.searchInput {
    width: 193px;
}
