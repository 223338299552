@import '../../../../variables';

.subTasks {
    margin-top: 5px;
    border-top: 1px solid $separator-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        min-height: 37px;

        span {
            font-weight: 600;
            margin-right: 30px;
        }
    }

    .tasksContainer {
        margin-top: 20px;
        width: 100%;
    }

    .addItem {
        cursor: pointer;
        margin-top: 20px;
        font-size: 14px;
    }
}

.subtasksButton {
    @include taskActionButton();
    padding: 1px 5px;
    .count {
        margin-right: 5px;
    }
}
