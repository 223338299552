.content {
    padding: 0 20px;
}

.dialogHeader {
    border-bottom: unset;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.closeIcon {
    height: 12px;
    width: 12px;
}
