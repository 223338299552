@import '../../../variables';

.row {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.autocompleteDropdownRTL {
    @include autocompleteDropdownRTL;
}