.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.title {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.subtitle {
    font-size: 12px;
    color: #000;
}

.input {
    margin: 0;
}

// Align detail row '...' to field label
.fieldDetailRow {
    margin-top: 10px;
}
.detailRowEditButton {
    margin-top: -10px;
}

.verifyDateDialogError {
    font-size: 12px;
    margin-left: 35px;
    color: red;
    font-weight: 600;
}
