@import '../../variables';

.container {
    width: 27px;
    height: 35px;
    background: $main-color;
    border-radius: 0 30px 30px 0;
    position: fixed;
    bottom: 35px;
    left: $sidebar-main-width;
    transition: width 250ms linear, left 500ms linear;
    z-index: 10;
    cursor: pointer;

    &.close {
        width: 65px;
        left: calc(#{$sidebar-main-width} + #{$product-feed-menu-width});
        transition: all 500ms linear;
        gap: 15px;
    }

    &:focus {
        transition: none;
    }

    &:hover:not(.close) {
        width: 118px;

        .label {
            display: block;
            font-size: 14px;
            color: white;
            opacity: 1;
            transition: opacity 0.75ms ease-in;
            background-color: unset;
        }
    }

    .content {
        display: inline-flex;
        align-items: center;
        gap: 5px;

        .giftIcon {
            height: 35px;
            padding-left: 2.5px;
        }

        .label {
            white-space: nowrap;
            background-color: unset;
            height: unset;
            opacity: 0;
            transition: opacity 0.75ms ease-in;
            display: none;
        }
    }

    .closeContent {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        margin-left: 5px;

        .backIcon {
            height: 35px;
            fill: white;
        }

        .openGiftIcon {
            height: 35px;
        }
    }
}
