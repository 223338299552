@import '../../../variables';

.buttons {
    width: 200px;
}

.wizardTitleContainer {
    width: fit-content;
    padding-top: 9px;
    .title {
        padding: 20px 0;
        width: 100%;
        text-align: center;
        @include title();
        font-weight: bold;
        font-size: 18px;
    }

    .description {
        text-align: center;
        font-size: 14px;
        padding-bottom: 10px;
    }
}

.dialogContent {
    @include description();
    display: flex;
    flex-direction: column;
    width: 500px;
    white-space: break-spaces;
    overflow-x: visible;
    margin: 25px;
    font-size: 14px;
    .imageWrapper {
        display: flex;
        width: 430px;
        justify-content: flex-end;
        align-items: flex-end;
        img {
            width: 100px;
            height: 90px;
        }
    }
}

.verification {
    margin: 25px;
    width: 440px !important;
}

.contentPlaceholder {
    display: flex;
    height: 53px;
    border-radius: 4px;
    width: 100%;
    font-size: 14px;
    background: $placeholder-content-background;
    color: $configuration-text-color;
    border: 1px $configuration-text-color dashed;
    justify-content: center;
    align-items: center;
}

.ctaButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 21px;
    height: 37px;
    > * {
        height: 100%;
        display: flex;
        width: 100%;
        label {
            line-height: 0;
        }
    }
}

.dialogContentContainer {
    border: 1px solid $placeholder-content-color;
    border-radius: 3px;
    padding: 17px;
}

.placeholder {
    @include inputPlaceholder();
}


.widgetsSelect {
    height: fit-content;
    max-height: 104px;
    overflow-y: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: height 0.2s ease-in, max-height 0.2s ease-in;
    min-height: 50px;

    .widgetsSelectContainer {
        max-height: 104px;
        overflow: auto;
    }

    .placeholder {
        padding-left: 15px;
        padding-top: 15px;
    }

    .menuItem {
        height: 28px;
        width: 100%;
        border: 1px solid $widget-container-border-color;
        padding: 10px;
        margin-bottom: 5px;

        &.disabled {
            cursor: not-allowed;
            @include internalWidgetBackground();
        }

        &.selected {
            background: $selected-item-background;
        }

        [class*='internalIcon'] {
            top: 8px;
            left: 58px;
        }
    }

    .displayMode {
        width: 100%;
        height: fit-content;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .menuItem {
            max-width: 100%;
            width: fit-content;
            min-width: 100px;
            margin: 5px;
            transition: opacity 0.1s ease-in, width 0.1s ease-in;
            padding: 0 5px 0 0;

            height: 40px;

            img {
                padding: 0 4px;
            }

            &.hidden {
                opacity: 0;
                width: 0;
                display: none;
                min-width: 0;
                padding: 0;
                margin: 0;
                transition: opacity 0.1s ease-in, width 0.1s ease-in;
            }
        }
    }
}

.inlineEditButton {
    width: calc(100% - 50px);
    > div {
        transition: all 0.2s ease-in;
        width: fit-content;
    }
    padding: 10px;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in;
    .input {
        width: fit-content;
        input {
            color: $widget-container-background-color;
            text-align: center;
            width: 400px;
            min-width: 100%;
        }
    }

    .button {
        transition: all 0.2s ease-in;
        width: fit-content;
        padding: 0 15px;
    }
}

.separator {
    width: 100%;
    height: 1px;
    background: $separator-color;
}
