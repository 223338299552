@import '../../variables';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .titleContainer {
        display: flex;
        margin-top: 16px;
        justify-content: center;

        .stars {
            margin-top: 20px;
        }

        .title {
            @include pageTitle();
            display: flex;
            align-items: flex-end;
            margin-top: 40px;
        }

        .subtitle {
            @include pageSubtitle();
        }
    }

    .embedContainer {
        box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.2);
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        margin-bottom: 60px;

        .loaderContainer {
            height: 500px;
        }

        .embed {
            width: 1026px;
            height: 60vh;
            border-width: 0px;
            border-radius: inherit;
        }

        .browserHeader {
            width: 100%;
            border-radius: 10px 10px 0 0;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        text-decoration: none;
        position: fixed;
        bottom: 0px;
        width: 100vw;
        padding-bottom: 70px;
        padding-top: 20px;
        align-items: baseline;
        gap: 15px;

        .optionalButton {
            margin-top: 22px;
            margin-bottom: 5px;
            width: fit-content;
            height: fit-content;
            min-width: 134px;
            min-height: 37px;
            padding: 9px 35px;
            text-align: center;
            border: unset;
            font-family: $main-Font;
            font-size: 14px;
            color: #ffffff;
        }

        .buttonLabel {
            font-size: 20px;
        }
    }
}

.container {
    @media (max-width: $mobile) {
        .titleContainer {
            height: fit-content;
            margin-top: 0;
            .title {
                margin-top: 0;
            }
        }

        .subtitle {
            width: 240px;
            text-align: center;
        }
        .embedContainer {
            width: calc(100% - 40px);

            .embed {
                width: 100%;
            }
        }
        .stars {
            margin-top: 5px;
        }
    }
}
