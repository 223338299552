.assets {
    width: 100%;
    padding-bottom: 29px;
}

.emptyBox {
    min-height: 148px;
}

.linesDesign {
    display: flex;
    flex-direction: column;
}

.twoColumnsDesign {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
