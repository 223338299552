@import '../../../variables';
@import '../../../aiVariables';

.aiButtonWrapper {
    border-left: 1px solid $separator-color;
    height: 37px;
    display: flex;
    align-items: center;
    margin: 0 20px;
    padding: 0 10px;
}

.aiButton {
    &:hover {
        @extend %AI_COLOR_BACKGROUND;

        .aiButtonLabel {
            -webkit-text-fill-color: white;

            svg path {
                fill: white;
            }
        }
    }
}

.aiButtonLabel {
    @extend %AI_TEXT;
    font-size: 12px;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 5px;
}

.aiDialog {
    width: 641px;
}

.aiDialogHeader {
    height: auto;
    margin: 5px 0 0;
    padding: 0 5px 19px;
    align-items: baseline;
}

.aiDialogTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 5px -23px;
}

.aiDialogSubTitle {
    font-size: 14px;
    font-weight: normal;
    color: $ai-text-color;
    -webkit-text-fill-color: $ai-text-color;
    background: white;
}

.aiDialogContent {
    margin: 20px 30px 30px;
}

.aiDialogSuggestions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 30px;
}

.aiDialogSuggestion {
    width: 180px;
    height: 80px;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    border-radius: 11px;
    background-color: $ai-suggestion-background-color;
    cursor: pointer;

    &:hover {
        background-color: $ai-suggestion-hover-background-color;
    }
}

.aiContentGeneration {
    height: 160px;

    .textArea, .textAreaContainer {
        width: 100%;
        height: 100%;
    }

    .textAreaInput {
        padding: 0 20px;
    }
}