@import '../../../variables';

.text {
    @include description();
}

.textContainer {
    max-width: fit-content;
    padding-bottom: 10px;
}

.defaultViewsDialog {
    padding: 15px 20px 30px 25px;
}

.resetFieldsText {
    font-size: 12px;
    color: $placeholder-color;
    padding-left: 330px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.resetLoader {
    width: 20px;
    height: 20px;
    margin-left: 20px;
}

.resetFieldsClickableText {
    cursor: pointer;
    color: $primary-branded-color;
}

.selectAllFields {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: $primary-branded-color;
}
