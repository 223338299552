@import '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.slackChannelTypesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.slackChannelTypes {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: unset;
}

.privateSlackChannel {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}

.vIcon {
    vertical-align: middle;
}
