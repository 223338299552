%TASK_FILE_BUTTON {
    padding: 0 20px !important;
}

.customerUploadButton {
    border: unset;
}

.onlyCustomersDialog {
    width: 450px;
    height: 45px;
    margin: 10px 30px;
    font-size: 14px;

    div {
        margin: 5px 0;
    }
}

.button {
    @extend %TASK_FILE_BUTTON;
}

// Used to override UploadButton styles
.uploadButton {
    @extend %TASK_FILE_BUTTON;
}