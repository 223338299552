@import '../../variables';

.slidesBackground {
    z-index: 0;
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.regularSlidesBackground {
    @media (max-width: $mobile) {
        display: none;
    }
}
