.container {
    max-width: 820px;
    font-size: 14px;
    .grayContainer {
        padding: 10px 10px;
        border-radius: 5px;
        border: solid 1px #d9d9d9;
        .readOnlyText {
            color: #757575;
        }
    }
}
