@import '../../../../variables';

.colRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & .editIcon {
        padding: 3px;
        cursor: pointer;
    }

    .addColumnRowContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.menuRow {
    display: flex;
    align-items: center;
    .oval {
        @include ovalMenuItemIcon;
        &.none {
            background-color: #fff;
            border: solid 1px #d9d9d9;
        }
        &.yellow {
            border: solid 1px #d9d9d9;
            background-color: #fbf3db;
        }
        &.red {
            border: solid 1px #d9d9d9;
            background-color: #f8d6de;
        }
        &.green {
            border: solid 1px #d9d9d9;
            background-color: #ddedea;
        }
        &.blue {
            border: solid 1px #d9d9d9;
            background-color: #ddebf1;
        }
        &.grey {
            border: solid 1px #d9d9d9;
            background-color: #ebeced;
        }
    }
}

.tableColumnEdit {
    width: 150px;
    min-width: 150px;
}
.fullWidth {
    width: 100%;
}

.autocompleteDropdownRTL {
    @include autocompleteDropdownRTL;
}
