@import './overviewVariables';

$FOOTER-MARGIN-BOTTOM: $MARGIN-MAIN;

.container {
    width: 100%;
    height: 100%;
}

.header {
    height: $HEADER-HEIGHT;
}

.content {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - #{$HEADER-HEIGHT} - #{$FOOTER-HEIGHT} - #{$MARGIN-MAIN} - #{$MARGIN-MAIN} - #{$FOOTER-MARGIN-BOTTOM});
    margin: $MARGIN-MAIN 31px $MARGIN-MAIN;
}

.footer {
    height: $FOOTER-HEIGHT;
}

.emptyStateContainer {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.accountPageMain {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 1px 0 0 1px;
    overflow-y: auto;
    overflow-x: hidden;
}