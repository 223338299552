@import '../../../../variables';

.container {
    display: flex;
    font-family: $main-Font;
    width: 100%;

    .ellipsisClassName {
        white-space: unset;
        font-size: 14px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .title {
        text-align: left;
        flex: 0 1 270px;
        max-width: 270px;
        align-items: center;
        display: flex;
        height: 100%;

        @media (max-width: $mobile) {
            flex: unset;
            max-width: fit-content;
        }
    }

    &:has([class*='ChoiceInput'], [class*='TextArea'], [class*='TextEditor']) {
        .title {
            align-items: flex-start;
        }
    }
}

.detailsBoxContainer {
    width: 100%;
    flex: 1 1;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: unset;

    @media screen and (min-width: $laptop) {
        align-items: center;
    }

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.richText {
    width: auto;
    text-align: left;
}

.inputEditModeClassName {
    flex: 1 1;
}
