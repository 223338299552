@import 'variables';

.taskActionOption {
  display: flex;
  align-items: center;
}

.warningText {
  width: 410px;
}

.confirmTitle {
  @include confirmDialogTitle();
}

.editActionButton {
  @include taskActionButton();
  width: 13px;
  height: 16px;
  cursor: pointer;
  margin: 0 4px;
  transition: opacity 0.2s ease-in;
}

.menuIcon {
  width: 30px;
  height: 22px;
  max-width: 30px;
  min-width: 30px;
  margin-left: 5px;
  border-radius: 3px !important;
}

.deleteTaskAction {
  width: 100%;
  color: $error-message-color;
}

.confirmDeleteTaskButton {
  [class*='purple'] {
    background-color: $error-message-color !important;
    border-color: $error-message-color !important;
    color: white !important;
  }
}