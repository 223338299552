@import '../../../variables';

.container {
    margin-top: 20px;
    padding: 0 15px;

    [class*='inputContainer'] {
        max-width: calc(100% - 40px);
    }

    .readonly {
        pointer-events: none;
    }
}

.content {
    margin-top: 20px;
    height: inherit;

    [class*='Input_contentContainer'] {
        width: 195px;
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        gap: unset;
    }

    .accountDomainField {
        margin-top: 19px;

        .accountDomainFieldTitle {
            display: flex;
            flex-direction: row;
        }
    }
}

.detailBox {
    margin-top: 19px;
}

.editButton {
    margin-left: -10px !important;
}

.contactContainer {
    font-weight: bold;
    width: calc(100% - 20px);
}

.noteContainer {
    background-color: $internal-data-background-color;
    height: 21px;
    font-size: 12px;
    margin-bottom: 13px;
    padding-top: 3px;
    display: flex;
    justify-content: center;
}

.noteText {
    padding: 0 5.5px;
    font-size: 11px;
}

/*****************/

.colflbox {
    margin: 0 0 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 5px 5px;
}

.colflbox label {
    color: #bbb;
    font-size: 11px;
    padding: 0 0 0 5px;
}

.colselect {
    width: 100%;
    border: none;
    padding: 5px 0 5px 0;
    font-size: 15px;
    height: 32px;
}
.selectBox {
    padding: 0 30px 20px 0;
    border-bottom: 1px solid $widget-container-border-color;

    .title {
        @include title();
        font-size: 16px;
        padding-bottom: 10px;
    }
}
