.addAsFollowers {
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;

    .label {
        margin-left: 7px;
        margin-right: 9px;
    }
}
