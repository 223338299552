.crmDetails {
    margin-top: 10px;
    .noObjects {
        margin: 0 3px 0 -33px;
        .text {
            margin-bottom: 10px;
        }
        .cloud {
            margin-right: 10px;
        }
    }
}
