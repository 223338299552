.main {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    margin: 0 8px -32px 0;
    align-items: center;

    .menuIcon {
        width: 15px;
        height: 15px;
        cursor: pointer;
        padding: 5px 20px 5px 10px;
    }

    .dragIcon {
        width: 15px;
        height: 15px;
        cursor: pointer;
        border-left: 1px solid #e9e8ed;
        padding: 5px 5px 5px 20px;
    }

    .editModeIcon {
        width: 15px;
        height: 15px;
        cursor: pointer;
        padding: 5px 10px;
    }

    .detachedWidget {
        width: 15px;
        height: 15px;
        padding: 5px 10px;
    }

    .lockIcon {
        width: 15px;
        height: 15px;
        cursor: pointer;
        padding: 5px 10px;
    }

    .configurationButton {
        width: 15px;
        height: 15px;
        padding: 0 10px;
        inset: 0;
    }
}

.actionIconWrapper {
    display: flex;
}

.emailIcon {
    padding: 0 10px;
}
