.container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 5px;

    .intro {
        opacity: 0.7;
        margin-bottom: 15px;
    }

    .section {
        font-weight: bold;
    }

    .checkboxRow {
        display: flex;
        font-size: 12px;

        .text {
            margin-left: 12px;
            cursor: pointer;
        }
    }
}