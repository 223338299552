@import '../../variables';
.notificationTitle {
    font-size: 24px;
    margin-bottom: 7px;
}

.notifyCheckbox {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.notificationViaEmail {
    @include notificationsViaEmail();
    display: flex;
    flex-direction: column;
    gap: unset;
    margin-bottom: 7px;
}

.notificationViaEmailStyled {
    @include notificationsViaEmail();
    gap: 0;
    flex-wrap: wrap;
}

.contactsClassName {
    max-height: 70px;
    overflow: auto;
    background-color: $blocker-dialog-bg-color;
    margin-top: 5px;
}
