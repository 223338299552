@import '../../variables';

.container {
    display: flex;
    align-items: center;

    .star {
        margin-left: 5px;
        cursor: default;
        font-size: 18px;
    }
}

.noPointer {
    cursor: default;
    pointer-events: none;
}

.colwidget {
    text-align: left;
    font-size: 13px;
    min-width: fit-content;
    margin: 10px 6px 12px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding: 5px 5px 5px 7px;
    display: flex;
    align-items: center;
    z-index: 0;
    position: relative;
}

.title {
    min-width: 115px;
}

.fileLabel {
    background-color: #b3523d;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    height: 20px;
    padding: 0 5px;
    border-radius: 2px;
    text-decoration: none;
    display: block;
    z-index: 1;
    margin-right: 10px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.noDownload {
    opacity: 0.2;

    .fileLabel {
        cursor: default;
    }
}

.colwidget .boxTitle {
    padding: 0 5px;
    font-weight: 500;
    margin: 0;
    width: fit-content;
    line-height: 1.25;
}

.boxTitle {
    cursor: pointer;
    @media (max-width: $mobile) {
        max-width: calc(100vw - 250px);
        @include ellipsis();
    }
}

.disabled {
    cursor: default;
}

.colwidget {
    @media (max-width: $mobile) {
        max-width: calc(100vw - 200px);
    }
}

@media only screen and (max-width: 1200px) {
    .colwidget {
        max-width: 30%;
    }
}

@media only screen and (max-width: 1500px) {
    .colwidget {
        max-width: 47%;
    }
}

@media only screen and (max-width: 600px) {
    .colwidget {
        max-width: 100%;
    }
}
