@import '../../../../variables';

$white: #ffffff;
$black: #000000;
$hoverColor: #ebebeb;

.additionalAssetsContainer {
  cursor: pointer;
  border-radius: 100px;

  @media (max-width: $mobile) {
      position: relative;
      top: 12px;
      left: 35px;
  }

  &.isOpen {
    background-color: $white;
  }

  &:hover {
    background-color: $hoverColor;

    @media (max-width: $mobile) {
      background-color: $white;
    }
  }
}

@mixin MenuTextColor($color) {
  .label, .dropDownArrow svg g {
    color: $color;
    fill: $color;
  }
}

.additionalAssets {
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  height: 37px;
  padding-left: 10px;
  min-width: 36px;
  justify-content: center;

  &:hover {
    background-color: $hoverColor;
  }

  .menuLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    display: block;

    @media (max-width: $small-laptop){
      display: none;
    }
  }

  &.miniMode {
    white-space: nowrap;

    @media (max-width: $small-laptop){
      padding-left: 0;
    }
  }

  &.whiteMenu {
    @include MenuTextColor($white);

    &:hover {
      @include MenuTextColor($black);
    }
  }

  &.blackMenu {
    @include MenuTextColor($black);
  }

  .label {
    font-size: 14px;
  }

  .dropDownArrow {
    margin: 0 10px;
  }
}

.additionalHubsMenu {
  min-width: 260px;

  [class*='divider'] {
    border-bottom: 1px solid $black;
    margin-bottom: 4px;
  }

  [class*='gutters'] {
    padding-left: 3px;
  }

  ul {
    padding: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 0;
      list-style: disc;
    }
  }
}

.currentAccountMenuItem {
  margin-left: 10px;
}
