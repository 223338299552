@import '../../../variables';

.widgetWithinSection {
    display: none;
}

.container {
    width: 100%;
    .linkItem {
        height: 40px;
        position: relative;
    }
}

.indentedItem {
    margin-left: 25px;
    width: 200px;
    position: relative;

    &:not(.dragging):before {
        content: '';
        height: 40px;
        width: 6px;
        border-left: 1px solid $widget-list-hierarchy-line;
        border-bottom: 1px solid $widget-list-hierarchy-line;
        margin: -19px 0 0 -5px;
        position: absolute;
        z-index: 1;
    }
}

.dragging {
    outline: solid 3px $primary-color;
    border-radius: 1px;
    z-index: 1;
}

.additionalSectionBorder {
    outline: solid 3px $primary-color;
    height: 40px;
    width: 100%;
    margin-left: 6px;
    margin-top: -34px;
    background-color: white;
    border-radius: 1px;
}
.sectionHighlight {
    .menuItem {
        background: $placeholder-content-background;
    }
}

.menuItem {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    background: white;
    cursor: pointer;
    transition: background 0.1s ease-in;
    border-radius: 4px;
    z-index: 2;

    &:not(.disableHoverState) {
        &:hover {
            background: $placeholder-content-background;
        }

        &.selected {
            background: $selected-item-background;
        }
    }

    &.editable:hover {
        .editIcon {
            opacity: 1;
        }

        .widgetRightIcons {
            opacity: 0;
        }
    }

    .title {
        @include ellipsis();
        @include description();
        margin-right: auto;

        &:not(.titleWithoutMaxWidth) {
            max-width: calc(100% - 65px);
        }
    }

    .icon {
        height: 30px;
        min-width: 28px;
        width: 28px;
        padding: 5px 9px;
    }
}

.boldTitle {
    font-weight: bold;
}

.titleInput {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    input {
        font-size: 12px;
    }
    &:hover {
        background-color: white;
    }
}

.nonEditableItem {
    cursor: default;
}

.emptyState {
    @include emptyStateText();
    padding-top: 30px;
}

.loader {
    @include center();
    width: 100%;
    height: 50vh;
}

.topIcons {
    position: absolute;
    top: -5px;
    left: 46px;
    display: flex;
    gap: 5px;
    align-items: center;
    height: 25px;
}

.verification {
    width: 12px;
    height: 12px;
}

.widgetRightIcons {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 5px;
}

.engagedIcon {
    padding-top: 2px;
    width: 16px;
    height: 16px;
}
.robotIcon {
    margin-left: auto;
    padding-bottom: 2px;
}

.reorderDialogBody {
    padding: 15px 30px;
    max-width: 480px;

    .lastSentence {
        margin-top: 20px;
    }
}

.editIcon {
    position: absolute;
    right: 5px;
    opacity: 0;
    transition: opacity 0.2s linear;
}

.test {
    display: none;
}

.notesIcon {
    display: flex;
    width: 15px;
    height: 15px;
    margin-top: -3px;
}

.commentsIcon{
    display: flex;
}