@import '../../variables.scss';

.container {
  max-height: 30px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out, padding-bottom 0.2s ease-in-out;
  position: relative;
  padding-bottom: 0;
}

.expanded {
  max-height: 100vh;
  padding-bottom: 20px;

  .childrenWrapper {
    -webkit-mask-image: none;
  }
}

.childrenWrapper {
  mask-image: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 93%);

  @media (max-width: $medium-laptop) {
    mask-image: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 80%);
  }
}

.expandText {
  cursor: pointer;
  font-size: 12px;
  font-family: $main-Font;
  color: #000;
  position: absolute;
  bottom: 2px;
  right: 0;
  z-index: 1;
  background-color: inherit;
  display: flex;
  gap: 5px;
  align-items: center;
  transition: color 0.2s ease-in;
  width: max-content;

  // Arrow icon
  g {
    transition: fill 0.2s ease-in;
  }

  &:hover {
    color: $main-color;
    text-decoration: underline;

    // Arrow icon
    g {
      fill: $main-color;
    }
  }
}

.expandTextAllLine {
  padding-left: 90%;
}

.arrowIcon {
  width: 8px;
  transition: transform 0.2s ease-in-out;
}

.upArrow {
  transform: rotate(180deg);
}

.downArrow {
  transform: rotate(0);
}