@import '../../../variables';

$blue-background: #e1f1fa;
$skeleton-border: #eff0f6;

%container {
  height: 100%;
  margin: 0 31px;
  border-radius: 20px;
  padding-left: 20px;
}

.contentContainer {
  @extend %container;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $blue-background;
}

.content {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  min-width: 850px;
}

.title {
  font-weight: bold;
  margin-right: 10px;
}

.metricContainer {
  display: flex;
  gap: 20px;
}

.metric {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.metricTitle {
  color: $subtext-color;
}

.metricValue {
  font-weight: bold;
}

.divider {
  border-left: 1px solid $separator-color;
  height: 22px;
}

.confetti {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/bottom-panel-confetti-big-resolution.svg');
  margin-right: 20px;
}

.skeletonContainer {
  @extend %container;
  border: solid 1px $skeleton-border;
}
