@import '../../variables';

%NO-COMMENTS-CONTAINER {
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 130px;
}

.container {
  width: 96%;
  position: relative;
}

.header {
  border-bottom: 1px solid $separator-color;
}

.loader {
  height: 90vh;
}

.tableToolbar {
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: flex-end;
}

.accountFilter {
  margin-right: 20px;
}

.threadName {
  display: flex;
  align-items: center;
  gap: 3px;
  text-decoration: underline;
  cursor: pointer;
}

.threadTypeIcon {
  display: flex;
  align-self: baseline;
  margin-top: 2px;
}

.widgetIcon {
  padding: 3px 2px 0 0;
}

.commentIcon {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  height: 26px;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
  background-color: $main-color;
  color: #ffffff;
  width: 48px;

  svg path {
    fill: #ffffff;
  }
}

.collaboratorsSkeleton {
  display: flex;

  div:not(:first-child) {
    margin-left: -5px;
  }
}

.collaborators {
  margin-left: 5px;
}

.emptyStateContainer {
  @extend %NO-COMMENTS-CONTAINER;
  gap: 20px;
}

.completeStateContainer {
  @extend %NO-COMMENTS-CONTAINER;
  gap: 10px;
}

.noCommentsTitle {
  font-size: 22px;
  margin-top: 10px;
}

.noCommentsDescription {
  font-size: 14px;
}