@import '../../../variables';

$popover-title-height: 35px;
$search-total-height: 60px;

.searchContainer {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 10px;
}

.popoverSearch {
    margin-top: 15px;
}

.searchInput {
    padding-right: 15px;
}

.searchIcon {
    position: absolute;
    right: 15px;
    top: 27px;
    width: 15px;
    height: 15px;
}

.sections {
    max-height: calc(40vh - #{$search-total-height} - #{$popover-title-height});
    overflow-y: scroll;
    overflow-x: hidden;
}

.sectionHeader {
    align-items: center;
    display: flex;
    color: $subtext-light-grey;
    font-size: 12px;
    padding-bottom: 5px;
}

.sectionToggle {
    margin-right: 0px;
    align-items: center;
}

.fieldRow {
    align-items: center;
    display: flex;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 3px 10px;

    &:hover {
        background-color: $menu-item-hover-color;
    }
}

.field {
    @include ellipsis();
    font-size: 12px;
}

.fieldsContainer {
    margin-top: 10px;
}

.noResults {
    padding: 0 10px;
    margin: 20px 0 26px;
    font-size: 14px;
    color: $subtext-grey;
}