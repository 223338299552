.legendContainer {
  display: flex;
  gap: 40px;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 10px 40px;
  border-radius: 20px;
  width: fit-content;
  align-items: center;
}

.legendItemContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  .title {
    color: #5d5d5d;
    font-size: 12px;
  }

  .helpIcon {
    margin: 0;
  }
}

.colorIcon {
  width: 13px;
  height: 13px;
  border: 1px solid transparent;
  border-radius: 4px;

  &.colorIconBorder {
    border: 1px solid #d4d4d4;
  }
}