.dialog {
  width: 535px;
  max-width: 535px;
  box-sizing: border-box;
}

.dialogHeader {
  [class*='dialogTitle'] {
    font-size: 16px;
  }
  [class*='closeIconArea'] {
    width: 35px;
  }
}

.container {
  padding: 26px 30px 30px 26px;
}

.submitButton {
  padding: 0 30px
}
