@import '../../variables';

.dialogSubText {
    display: block;
    margin-top: 20px;

    .dialogLink {
        color: $primary-color;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
    }
}

.archiveMessage {
    display: flex;
    margin-top: 20px;
    .archiveMessageText {
        margin-left: 10px;
    }
}

.contextContainer {
    height: inherit;
}

.testAccountDesign {
    border-top: $test-account-border-color 3px solid;
}

.rectangle {
    background-color: $test-account-border-color;
    font-size: 10px;
    color: white;
    padding: 0px 5px 0px 5px;
    border-radius: 0px 0px 2px 2px;
}

.widgetsContentPanel {
    @include widgetPanel();
    border-right: 1px solid $separator-color;
    width: 255px;
    height: 100%;
}

.widgetListContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.headerContainer {
    padding-right: 27px;
}

.pageContent {
    width: 100%;
    display: flex;
    overflow: hidden;
}

.splitAccountPage {
    width: calc(100% - #{$sidebar-main-width});
    display: flex;
    position: fixed;
}

.noTrialPage > div {
    height: $widget-content-height;
    top: $header-height;
}

.withTrial > div {
    height: calc(#{$widget-content-height} - #{$trial-banner-height});
    top: $header-height + $trial-banner-height;
}

.leftPanel {
    min-width: $configuration-panel-width;
    max-width: $configuration-panel-width;
    z-index: 100;
    height: inherit;
    border-right: 1px solid $widget-container-border-color;
}

.rightPanel {
    flex-shrink: 0;
    overflow-y: auto;
    max-height: $widget-content-height;
    border-left: 1px solid $widget-container-border-color;
}

.widgetsContent {
    height: inherit;
    position: relative;
    width: 100%;
    display: inline-grid;
    > div {
        @include widgetsModuleContainer();
        margin: 30px;
        min-height: inherit;
        overflow: auto;
    }
}

.internalAssetContent {
    background-color: $internal-data-background-color;
}

.internalAssetBorder {
    outline: 4px solid $internal-border-color;
}

.leftPanelContainer {
    @include widgetConfigurationPanel();
}

.insightsModeContent {
    @include insightsModeContent();
}

.insightsModeBorder {
    @include insightsModeBorder();
}