@import '../../../../../../variables';

.container {
    display: flex;
    gap: 10px;
    .layoutOption {
        @include taskActionButton();
        @include taskActionSelectionBorder();
        padding: 5px;
        width: 81px;
        height: 40px;
    }
}
