@import '../../../../variables';
.oval {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 1px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
    border: 1px solid;

    &.outerCirlce {
        box-shadow: 0 0 0 9px rgba(0, 200, 169, 0.4), 0 0 0 18px rgba(0, 200, 169, 0.2);
        margin-bottom: 18px;
    }
}

.regularStep {
    width: 11px;
    height: 11px;
    border-radius: 50%;

    margin-right: 1px;
    margin-top: -6px;
    margin-bottom: 3px;

    display: flex;
    justify-content: center;
}

.currentStep {
    margin-top: -25px;
    inset: 0;
    z-index: 2;
    animation: fadeInWithDelay 0.3s linear;
}

@media (max-width: $mobile) {
    .regularStep {
        margin-left: -6px;
    }

    .currentStep {
        margin-left: -25px;
    }
}

.clickable {
    cursor: pointer;
}
