$button-hover-background-color: #ebebeb;

.assetsPopoverButton {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: $button-hover-background-color;
  }
}

.container {
  padding: 22px 10px 20px 26px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 370px;
  max-height: 50vh;
  box-sizing: border-box;
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
}

.popoverWrapper {
  margin-left: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.listWrapper {
  overflow: overlay;
  padding-right: 10px;
}

.assetsList {
  margin-left: 0;
}

.pinnedAssetsList {
  margin-left: -5px;
}

.groupedAssetsContainer {
  margin: 10px 0 0 0;
}

.groupedAssetsList {
  margin-left: 0;
}

.pinnedAssetsList, .assetsList, .groupedAssetsList {
  [class*='assetDetails'] {
    width: calc(100% - 55px);
  }
}