.checkboxWithConfigContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
}

.checkboxRow {
  display: flex;
  gap: 10px;
}

.additionalDetailsConfigRow {
  margin-left: 28px;
}