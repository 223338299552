@import '../../../../variables';

$subtext-color: #8b8d91;

.checkboxContainer {
    display: flex;
    align-items: center;
    margin: -5px 0 22px;
    font-size: 12px;
}

.checkbox {
    margin-right: 10px;
}

.checkboxLabel {
    cursor: pointer;
}

.settingsIcon {
    margin-left: 5px;
    cursor: pointer;
    @include actionButton();
    padding: 5px;
}

.settingsDialogContainer {
    padding: 26px 30px;
}

.closeIconArea {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.closeIcon {
    cursor: pointer;
    margin-left: 10px;
}

.settingsDialogTitle {
    @include title();
    padding-left: 30px;
}

.settingsDialogSubtitle {
    margin-bottom: 20px;
    font-size: 14px;
}

.queryHelpText {
    color: $subtext-grey;
    font-size: 12px;
    margin: 5px 0 20px;
    display: flex;
    align-items: center;
}

.divider {
    height: 1px;
    background-color: $separator-color;
    width: 100%;
    margin: 30px 0;
}

.testAreaTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}

.testAreaControls {
    display: flex;
    gap: 10px;
}

.openMappingDialogText {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.mandatoryLabel {
    font-size: 14px;
}

.mappingDialogContainer {
    padding: 26px 30px;
    width: 796px;
    max-height: 65vh;
    overflow: scroll;
}

.mappingArea {
    margin-bottom: 20px;
}

.mappingAreaTitle {
    font-weight: bold;
    font-size: 14px;
}

.mappingAreaSubtitle {
    font-size: 12px;
    color: $subtext-grey;
    margin: 5px 0 20px;
}

.mappingAreaSection {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.mappingAreaSectionMapTo {
    width: 20%;
    font-size: 14px;
}

.mappingAreaPersonaSection, .mappingAreaPersonaSectionColumnTitles {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;

    & > div {
        width: 50%;
    }
}

.mappingAreaPersonaSection {
    margin-bottom: 20px;
}

.personaMatchingColumnTitle {
    font-size: 10px;
    color: $subtext-grey;
}

.addEntryLink {
    cursor: pointer;
    color: $primary-color;
    font-size: 12px;
}