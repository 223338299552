@import '../../variables';

.syncIcon {
    width: 18px;
    height: 18px;
}

.pointer {
    cursor: pointer;
}

.syncLoading {
    @include rotatingAnimation();
}