@import '../../variables';

.container {
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 5px;
    border: solid 1px $textbox-border-color;

    &:hover {
        border: solid 1px $textbox-hover-border-color;
    }
}
