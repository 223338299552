@import '../../../variables';

.selectInputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.selectInput {
  background-color: #fff;
  font-family: $main-Font;
  position: relative;
}