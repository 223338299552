@import '../../../variables';
.buttonContainer {
    display: flex;
    justify-content: center;
    text-decoration: none;

    .labelContainer {
        position: relative;

        .label {
            opacity: 0;
        }

        .checkIcon {
            fill: white;
            position: absolute;
            left: calc(50% - 5px);
        }
    }

    .optionalButton {
        margin-top: 22px;
        margin-bottom: 5px;
        width: fit-content;
        height: fit-content;
        min-width: 134px;
        min-height: 37px;
        padding: 9px 35px;
        text-align: center;
        border: unset;
        font-family: $main-Font;
        font-size: 14px;
        color: #ffffff;
    }
    .buttonLabel {
        width: fit-content;
    }
}

.disabled {
    pointer-events: none;
}