@import '../../variables';

.dialogContentWrapper {
    height: fit-content;
    display: flex;
    width: fit-content;
    flex-direction: column;
    background: transparent;
    align-items: center;

    .overlayImage {
        height: 92px;
        margin-bottom: -11.5px;
        z-index: 10;
    }

    .content {
        min-width: 428px;
        width: 50vw;
        max-width: 628px;
        border-radius: 5px;
        background: $widget-container-background-color;
    }

    .footerText {
        font-size: 14px;
        color: $widget-container-background-color;
        margin-top: 10px;
    }
}
