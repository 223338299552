@import '../../../variables';
@import '../timelineVariables';

.container,
.adjacentLayoutContainer {
    width: inherit;
    flex: 1;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adjacentLayoutContainer {
    margin-top: 20px;
    position: relative;

    &.reducedMarginTop {
        margin-top: 12px;
    }
}

.completeLineContainer {
    display: flex;
    width: 100%;
    height: 41px;
}

.completeLine {
    flex: 1;
}

// Snake layout related styles
.finalCompletedLineInRow {
    margin-left: $SNAKE_LAYOUT_COMPLETED_LINE_MARGIN;
}

.firstCompletedLineInRow {
    margin-right: $SNAKE_LAYOUT_COMPLETED_LINE_MARGIN;
}

.onlyCompletedLineInRow {
    width: 94%;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .line {
        margin-left: auto;
        margin-right: auto;
        width: 1px;
        opacity: 0.5;
        margin-bottom: 10px;
        height: 50px;
        &.dashed {
            border-left: dashed 1px #979797;
        }
        &.solid {
            border-left: solid 1px $text-color;
        }
    }
    & .longLine {
        height: 100px;
    }
}

.viewModeTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    & .title {
        font-size: 14px;
        &.lastCompleted {
            color: $last-completed-milestone-title;
            font-weight: bold;
        }
        color: $text-color;
    }
    & .subtitle {
        margin-top: 3px;
        font-size: 12px;
        color: $placeholder-color;
    }
}

.row {
    display: flex;
    margin-top: 10px;
    align-items: center;
    & .checkboxLabel {
        opacity: 0.7;
        margin-left: 5px;
    }
}

@media (max-width: $mobile) {
    .container:not(.pdf) {
        border-left: 2px solid rgb(205, 210, 218);
    }
}

.nextMilestoneArrow {
    z-index: 1;
    position: absolute;
    top: 0;
    right: -13px;

    .arrow {
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
    }

    & .lastCompletedArrowIcon {
        position: absolute;
        z-index: 2;
        top: 21px;
        left: 0;
        width: 17px;
    }
}

.whiteFlag {
    path {
        fill: #ffffff;
        stroke: #ffffff;
    }
}

.doubleTextEditor {
    width: 120px;
    padding: 2px;
    border: solid 1px #d9d9d9;
    border-radius: 5px;
    background-color: #ffffff;
}

.datePickerText {
    padding-right: 5px;
}

.rightCurvedBorders {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.leftCurvedBorders {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.adjacentLayoutViewModeTextContainer {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    padding: 22px 10px 11px 20px;
    box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    height: 100%;
    box-sizing: border-box;

    & .adjacentLayoutTitle {
        font-size: 14px;
        height: 60px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;

        & > *:not(h3) {
            font-size: 14px;
            font-weight: 500;
        }
    }
    & .adjacentLayoutSubtitle {
        margin-top: 10px;
    }
    & .adjacentLayoutSubtitle,
    & .adjacentLayoutSubtitle > * {
        font-size: 11px;
    }

    & .adjacentLayoutDate {
        font-size: 11px;
        position: absolute;
        left: 20px;
        bottom: 16px;
    }

    & .calendarIcon {
        height: 12px;
        width: 12px;
        padding-right: 5px;
        margin-top: 2px;
        vertical-align: text-top;
    }
}

.disabledContainer {
    pointer-events: none;
    opacity: 0.2;
}

.actionsMenu {
    opacity: 0;
    margin-bottom: 10px;
}

.milestoneContainer {
    &:hover {
        [class*='actionsMenu'] {
            opacity: 1;
        }
    }
}

.afterDaysContainer {
    margin-top: 10px;
}

.majorStepIcon {
    border-radius: 20px;
    border: 1px solid white;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -12px;
    left: 13px;
    z-index: 10;

    .majorStepFlag {
        width: 26px;
        height: 26px;
    }
}

.majorStepBorder {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.adjacentOval {
    align-items: center;
    z-index: 1;

    & .adjacentOvalCheck {
        width: 11px;
        height: 9px;
        margin-top: 4px;
    }
}

.adjacentLayoutLine {
    padding: 30px 0;
    height: 1px;
    display: flex;
}

.dashedLine {
    &:first-of-type {
        margin-left: 2px;
    }

    width: 50%;
    height: 1px;
    margin-top: -2px;
    background-image: linear-gradient(to right, $widget-container-border-color 60%, transparent 40%);
    background-position: top;
    background-size: 34% 1px;
    background-repeat: repeat-x;
}
.invisibleDashedLine {
    width: 50%;
    height: 1px;
}

@media (max-width: $mobile) {
    .viewModeTextContainer {
        align-items: unset;
        text-align: unset;
        position: relative;
        top: -8px;
        right: 6px;
        max-width: 230px;
        & .title {
            margin-left: 13px;
        }
        & .subtitle {
            margin-left: 13px;
            color: $navigation-buttons-prefix-color;
            font-weight: normal;
            margin-top: 7px;
        }
    }
}
