@import '../../../../variables';
.tooltip {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 280px;
    gap: 10px;
}

.accountEmailButton:hover {
    background-color: $gray-action-button-menu-hover-color;
}

.button {
    position: relative;
}
.button::before {
    font-size: 9px;
    top: -13px;
    right: -11px;
}

.errorIcon {
    flex-shrink: 0;
    width: 18px;
    height: 20px;
    padding-top: 6px;
}

.errorMessage {
    display: flex;
    gap: 6px;
}
