@import '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.widgetTitle {
    @include widgetTitle;
    margin-top: 27px;
}

.content {
    @include widgetDescriptionPanel;
    font-size: 12px;
}

.dropDownTextField {
    height: 40px;
    border: solid 1px #d9d9d9;
    border-radius: 5px;
    position: relative;

    &:hover {
        border: solid 1px #8b8d91;
    }
    .oval {
        margin-left: 20px;
    }
    .text {
        font-size: 14px;
    }
    .arrow {
        cursor: pointer;
        position: absolute;
        right: 20px;
    }
}

.menuRow {
    @include flexRow;
    .oval {
        @include ovalMenuItemIcon;
        border: solid 1px $widget-container-border-color;
    }
    .Alert {
        background-color: $widget-alert-background;
    }
    .Warning {
        background-color: $widget-warning-background;
    }
    .Success {
        background-color: $widget-success-background;
    }
    .General {
        background-color: $widget-general-background;
    }
}
