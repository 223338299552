@import '../../variables';

.container {
    display: flex;
    flex: 100%;
    flex-direction: column;
    height: 100%;

    .content,
    .footer {
        display: flex;
        margin: 30px auto 0;
        text-align: center;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 18px;
            margin-bottom: 4px;
        }

        p {
            font-size: 14px;

            &.intro {
                font-size: 12px;
                opacity: 0.7;
            }
        }

        .inlineDropDowns {
            display: flex;
            align-items: center;
            font-size: 14px;

            > span {
                margin: 0 15px;
            }

            .selectedItem {
                font-size: 14px;
                font-weight: 600;
                color: $primary-color;
                border-bottom: 1px solid $primary-color;

                span {
                    margin-right: 23px;
                }
            }
        }

        .error {
            font-size: 10px;
            font-weight: 600;
            color: #ee3543;
            margin-bottom: 15px;
        }

        .addButton {
            margin-bottom: 20px;
        }
    }
}
