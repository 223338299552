@import '../../../variables';

$DIALOG-HEADER-HEIGHT: 80px;
$DIALOG-HEADER-WITH-BORDER-HEIGHT: calc(#{$DIALOG-HEADER-HEIGHT} + 1px);

// 66px = content padding, 70px = dialog footer height
$DIALOG-UNUSED-HEIGHT: calc(66px + #{$DIALOG-HEADER-WITH-BORDER-HEIGHT} + 70px);
$DIALOG-TEXT-COLOR: $text-color;

.dialogContainer, .dialogContainerWithIntercom {
  height: 90vh;
}

.dialogContainerWithIntercom {
  margin-top: -30px !important;
}

.content {
  padding: 36px 26px 30px;
  height: calc(90vh - #{$DIALOG-UNUSED-HEIGHT});
  overflow: auto;
}

.warningDialogContent {
  width: 510px;
}

.warningDialogText {
  padding: 26px 26px 30px;
  font-size: 14px;
  color: $DIALOG-TEXT-COLOR;
}

.dialogHeader {
  height: $DIALOG-HEADER-HEIGHT;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.titles {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 30px;
}

.dialogTitle {
  @include title();
}

.dialogSubtitle {
  font-size: 14px;
  color: $description-grey;
}

.closeIcon {
  cursor: pointer;
  padding: 0 10px 10px 30px;
  align-self: baseline;
}