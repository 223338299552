@import '../../../variables';

.contentDialog {
    padding: 26px 30px 30px 26px;
    overflow: auto;
    max-height: 70vh;
    margin-bottom: 10px;
}

.contentWithSection {
    padding: 25px;
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 35px;

    .title {
        display: flex;
        flex-direction: row;
    }

    .ellipsis,
    .inputView {
        font-size: 12px;
    }
}

.detailRowContainer {
    width: 400px;

    @media (max-width: $mobile) {
        max-width: 100%;
        [class*='contentContainer'] {
            margin-left: 0;
        }
    }
}

.detailRow {
    margin-top: 20px;
}

.sectionTitle {
    font-size: 14px;
    border-bottom: 0.5px solid $section-kpi-seperator;
    text-align: left;
    padding-bottom: 3px;
    color: $subtext-light-grey;
    font-family: $section-title-kpi-font-family;
    font-weight: bold;
}

.input {
    margin-left: unset;
}
