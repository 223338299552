.labelContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
        flex: 1 1;
    }

    .label {
        position: absolute;
        right: -15px;
    }
}
