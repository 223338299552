@import '../../../../variables';

.optionsTitle,
.customerOptionsTitle {
    @include accountsDetailsTitle;
}

.configurationTitle {
    @include widgetPanelConfigurationName;

    :global(.WidgetTitle) {
        font-weight: normal;
    }
}

.notifyCostumer {
    @include widgetPanelConfigurationName;

    & span:first-of-type {
        font-weight: normal;
    }
}

.layoutOptionsContainer {
    margin-top: 10px;
}

.layoutOptions {
    @include flexRow;
    margin-top: 5px;

    .layoutIcon {
        margin: auto;
        height: inherit;
    }
}

.presentationStyleContainer,
.actionButtonContainer {
    margin-top: 20px;
}

.customerPermissionsContainer {
    margin-top: 5px;
}

.actionButtonContainer {
    padding-bottom: 20px;
}

.taskDescriptionContainer,
.customerFilterTaskContainer,
.startDateContainer {
    margin-top: 15px;
}

.widgetDurationCheckbox {
    display: flex;
    font-size: 14px;

    .text {
        margin-left: 12px;
    }

    padding-top: 20px;
}

.relativeDatesInput {
    margin-top: -20px;

    :global(.WidgetTitle) {
        font-weight: normal;
    }
}

.relativeDateRecalculate {
    margin-top: -25px;
    padding-bottom: 20px;
}

.customerOptionsSeparator {
    border-top: 1px solid $widget-container-border-color;
    margin-top: 10px;
    margin-left: -25px;
}

.customerOptionsTitle {
    margin-top: 25px;
}

.taskSectionOption {
    @include ellipsis;
}

.taskDurationMeasurementType {
    margin: 10px 0 0 30px;
}

.startDateInput {
    margin-left: 0;
}

.tooltipLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confetti {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 15px;

    .text {
        font-size: 12px;
    }
}

.tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoIcon {
    margin-left: 5px;
    vertical-align: middle;
}
