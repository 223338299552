@import '../../../../../variables';

$DIALOG-WIDTH: 586px;

%TEXT_COLOR_AND_SIZE {
    font-size: 14px;
    color: #000;
}

.updateIcon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin-top: 5px !important;

    opacity: 0;

    &:hover {
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }
}

.notificationTitle {
    font-size: 15px;
    margin-bottom: 7px;
}

.dialogHeader {
    // Override dialog header fixed height
    height: auto !important;
    max-width: $DIALOG-WIDTH;
    padding: 10px 0;
}

.dialogContent {
    width: $DIALOG-WIDTH;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.explainer {
    @extend %TEXT_COLOR_AND_SIZE;
    white-space: break-spaces;
}

.messageTextEditor {
    @include typeAComment();
    :global(.DraftEditor-root) {
        @extend %TEXT_COLOR_AND_SIZE;
        min-height: 136px;
        max-height: 136px;
    }
}
