@import '../../../../../variables';
.container {
    border-radius: 6px;
    border: dashed 2px $primary-color;
    background-color: #fafafa;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
    transition: height 0.5s ease;
    max-width: $widget-max-width - $widget-inline-menu-border-width;
}
.close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
}

.title {
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-family: $main-Font;
}

.widgetsList {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.listContainer {
    height: 100%;
}

.widgetCard {
    font-family: $main-Font;
}

.widgetCardText {
    font-size: 10px;
}

.widgetCardTitle {
    font-size: 12px;
}
