@import '../../../../../variables';

.majorStep {
    width: 42px;
    height: 42px;
    border-radius: 50%;

    margin-top: -23px;
    margin-bottom: 3px;

    display: flex;
    justify-content: center;
    border: 1px solid;
}

@media (max-width: $mobile) {
    .majorStep {
        width: 22px;
        height: 22px;
        margin-top: -10px;
        margin-left: -12px;
    }

    .flag {
        margin-top: -10px;
    }
}
