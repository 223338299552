@import '../../../variables';

.signatureLayoutContainer {
    margin-top: 25px;

    @media (max-width: $mobile) {
        margin-top: 5px;
    }
}

.signatureNextToDescription {
    display: flex;
    gap: 20%;
}
.signatureUnderDescription {
    .contactsAndSignatureArea {
        margin-top: 25px;
    }
}

.contactsAndSignatureArea {
    display: flex;
    flex-direction: column;
}

.message {
    width: 50%;
}

.avatarsContainer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    max-width: 270px;
}

.closingStatement {
    font-size: 14px;
    padding-top: 10px;
}

@media (max-width: $mobile) {
    .signatureNextToDescription,
    .signatureUnderDescription {
        display: flex;
        flex-direction: column;
    }

    .contactsAndSignatureArea {
        margin-top: 25px;
    }

    .message {
        width: 100%;
    }
}
