@import '../../../variables';

.supportRequestContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content {
  @include widgetDescriptionPanel;
}

.manageColumnsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.editFiltersContainer {
  display: flex;
  flex-direction: column;
}

.filterCount {
  font-size: 14px;
  font-weight: 500;
  color: $primary-branded-color;
}

.ticketsContainer,
.reporterContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.widgetTitle {
  @include widgetTitle;
}