@import '../../variables';

.buttonsContainer {
    display: flex;
    align-items: center;
}

.button {
    padding: 11px 15px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
}

.selected {
    background-color: $linked-button-background-color;
    color: $main-color;
    font-weight: 600;
    border-radius: 6px;
    cursor: default;
}
.selectedWithIcon {
    background-color: $linked-button-background-color;
    color: $main-color;
    font-weight: 600;
    border-radius: 6px;
    cursor: default;
    padding-right: 1px;
}
.commentsIcon {
    position: relative;
    top: -10px;
}

.separator {
    height: 19px;
    width: 1px;
    margin: 0 20px;
    background: $separator-color;


    @media (min-width: $medium-laptop) {
        margin: 0 10px;
    }
}

.notification {
    background-color: $notification-icon-color;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    margin: -3px 5px 0 1px;
}