@import '../../variables';

.suggestionsContainer {
    display: flex;
    height: 14px;
    margin-left: 10px;
    margin-top: 5px;
    font-family: $main-Font;
    font-size: 10px;
    line-height: 1.71;
    color: $suggestions-color;
}

.suggestionsArray {
    overflow: hidden;
    flex-wrap: wrap;
    display: flex;
}

.suggestion {
    border-bottom: 1px solid $suggestions-color;
    cursor: pointer;
    height: 13px;
    margin-left: 5px;
}
.andMoreOption {
    width: 56px;
    white-space: pre;
}
