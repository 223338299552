.taskPlanContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 25px 0;

    .title {
        font-size: 14px;
        font-weight: bold;
    }

    .imageRadioButton {
        margin-bottom: 10px;
    }
}

.textContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}
