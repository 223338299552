@import '../../variables';

.radioIconWrapper {
    height: 13px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid $steps-empty-color;
    background: $widget-container-background-color;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    .radioIcon {
        transition: all 0.2s ease-in-out;
        opacity: 0;
        background: $primary-branded-color;
        border-radius: 50%;
        height: 9px;
        aspect-ratio: 1;
    }

    &.selected {
        transition: all 0.2s ease-in-out;
        border: 1px solid $primary-branded-color;
        .radioIcon {
            transition: all 0.2s ease-in-out;
            opacity: 1;
        }
    }
}

.radioGroupContainer {
    display: flex;
    width: 100%;

    gap: 10px;

    .optionWrapper {
        @include actionButton();
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid $textbox-border-color;
        padding: 20px 0;
        margin-bottom: 10px;
        text-align: center;

        &.selected {
            border: 2px solid $primary-branded-color;
        }

        .radioIconWrapper {
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: auto;
            outline: white 2px solid;
        }
    }
}
