@import '../../../../../variables';

.textEditor {
    border: 'solid 1px #d9d9d9';
    box-sizing: 'border-box';
    background-color: 'white';
    margin-top: '33px';
    margin-bottom: unset;

    :global(.DraftEditor-root) {
        height: 85px;
        overflow-y: auto;
        max-height: 85px;
    }
}
