@import '../../../../../variables';

.dialogWrapper {
    position: relative;
}

.contentLayout {
    display: flex;
    max-width: 900px;
    min-height: 450px;
    max-height: 1440px;
    justify-content: space-between;
}
