.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .customerAction {
        display: flex;
        flex-direction: row;
        gap: 3px;

        .text {
            margin-left: 10px;
        }
    }
}
