.title {
    justify-content: space-between;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .buttonContainer {
        width: 100%;
        display: flex;
        justify-content: center;

        &.buttonContainerRight {
            justify-content: flex-end;
        }
    }

    .resources {
        clear: both;
        padding: 0 0 0;
        text-align: center;
    }

    a {
        text-decoration: none;
    }
}
