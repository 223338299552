@import '../../../variables';
.welcomeContainer {
    display: flex;
    margin: 70px auto;
    flex-direction: column;
    align-items: center;

    .title {
        font-family: $title-Font;
        margin-bottom: 22px;
    }

    .subTitle {
        font-size: 24px;
    }

    .cards {
        display: flex;

        .cardContentContainer {
            height: 238px;
            width: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 22px 10px 30px;
            margin: 40px 15px;
            border-radius: 10px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);

            .cardTitle {
                font-size: 18px;
                font-weight: bold;
                margin: 12px 0 5px;
            }

            .cardContent {
                opacity: 0.7;
                font-size: 12px;
                height: 100px;

                div {
                    margin-bottom: 16px;
                }

                .cardDownloadCsv {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .footer {
        font-size: 16px;

        .demoData {
            font-weight: 600;
            color: $primary-color;
            margin-top: 4px;
            cursor: pointer;
        }
    }
}
