@import '../../../variables';

.widgetWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;

    &:not(.slidesModeWidgetWrapper) {
        margin-top: 10px;
    }
}

.slidesModeWidgetWrapper {
    overflow: hidden auto;

    @media (min-width: $mobile) {
        max-height: 72vh;
    }

    @media (max-width: $mobile) {
        margin: auto;
        overflow: hidden overlay;
    }
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    max-width: $widget-max-width;
}

.widgetHeaderLeftSide {
    display: flex;
    align-items: center;
    position: absolute;
    top: 32px;
    left: 12px;
    gap: 3px;

    &:not(.engagementIconPosition) {
        left: 25px;
        top: 35px;
    }

    & > svg {
        margin-top: -5px;
    }
}

.revealOnHover {
    opacity: 0;
}

.main:hover {
    .revealOnHover {
        opacity: 1;
    }

    .highlight:not(.insightsModeOverlay) {
        border: solid 2px $primary-branded-color;
        border-radius: 6px;
    }

    .note {
        visibility: visible;
    }

    [class*='emptyCallout'],
    [class*='noNotes'],
    [class*='noComments'] {
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }

    [class*='updateIcon'] {
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }
}

.addWidgetMenu:hover {
    .revealOnHover {
        opacity: 1;
    }

    .highlight:not(.insightsModeOverlay) {
        border: solid 2px $primary-branded-color;
        border-radius: 6px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    pointer-events: none;
}

.widgetContentWrapper {
    width: 100%;
    position: relative;
}

.topLeftActions {
    display: flex;
    gap: 10px;
    margin-top: -5px;
    align-items: center;
}

.actionsMenuContainer {
    width: 100%;
    z-index: 10;
    position: relative;
    height: 20px;
    display: flex;
}
.actionsMenuContainerNone {
    display: none;
}

.actionsMenuWithWidgets {
    width: 100%;
    z-index: 10;
    position: relative;
    height: 20px;
    display: flex;
    top: 20px;
}

.dropIndicator {
    display: flex;
    align-items: center;
    z-index: 10;
    top: -15px;
    position: absolute;
}

.divider {
    height: 0;
    width: 80px;
    margin: 0 5px;
    border: 1px solid black;
}

.note {
    font-family: $main-Font;
    width: fit-content;
    height: 21px;
    background-color: $internal-data-background-color;
    font-size: 12px;
    z-index: 10;
    position: relative;
    padding: 0 5px;
    margin: auto;
    display: flex;
    align-items: center;
    visibility: hidden;
}

.contact {
    margin: 0;
    align-self: end;
    flex: 1;
    min-height: 300px;
    height: 100%;
}

.extraSmallLayout {
    top: -13px;
}

.smallLayout {
    top: -28px;
}

.mediumLayout {
    top: -58px;
}

.presentationSectionSlides {
    z-index: 1;
    margin: auto;
    width: $widget-content-max-width;

    @media (max-width: $mobile) {
        width: $mobile-widget-content-max-width;
    }
}

.dropHereImg {
    width: 26px;
    height: 26px;
}
