.disabledContainer {
    pointer-events: none;
    opacity: 0.2;
}

.textEditor {
    padding: 2px;
    border: solid 1px #d9d9d9;
    border-radius: 5px;
    background-color: #ffffff;
}
