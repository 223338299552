.wrapper {
    overflow: hidden;
    .container {
        height: fit-content;
        transition: margin 0.5s ease-out, max-height 0.5s ease-in;
        margin-top: 0;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        max-height: 100%;

        &.collapsed {
            transition: margin 0.5s ease-in, max-height 0.5s ease-in;
            margin-top: -100%;
            max-height: 0;
            overflow: hidden;
        }
    }
}
