.password {
    margin-top: 22px;
    margin-bottom: 5px;
}

.openRecording {
    font-size: 14px;
    text-decoration: underline;
}

.integrationSource {
    display: flex;
}

.content {
    padding: 26px 30px 0px 26px;
    width: 510px;
}

.input{
    color: black;
}

.manualInputsContainer{
    margin-top: 10px;
}