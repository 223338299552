@import "../../variables";

@mixin commentHeaderContainer {
  margin-top: -65px;
}

.widgetContainer {
  overflow: visible;
  max-height: unset !important; // override inline style to enforce the overflow on comments container (instead of widget container) to keep the status filter stickiness relevant

  .container {
     padding-top: 10px;
    &.stickyCommentsFilter {
      overflow: visible;

      @media (max-width: $mobile) {
        padding: 0;
      }

        > div {
        overflow: visible;
      }


      [class*='newComment'] {
        [class*='actionsContainer'] {
          padding: 5px 0 0;
          margin: 0;
          background: none;
        }
      }

      [class*='newCommentContainer'] {
        padding-top: 15px;
      }

      [class*='emailInput'] {
        padding-top: 12px;
      }

      [class*='titleContainer'] {
        @media (min-width: $mobile) {
          border: none;
          padding: 0;
        }

        @media (max-width: $mobile) {
          padding: 0 0 10px;
        }

      }
      [class*='noCommentsTitleContainer']{
        margin-top: -50px;
      }
      [class*='commentHeader'] {
        @media (min-width: $mobile) {
          position: sticky;
          @include commentHeaderContainer;
          margin-bottom: 0;
          top: 8px;
          padding-right: 15px;
          right: 5px;
          border: none;
          opacity: 0;
          z-index: 10;
          transition: opacity 0.3s ease-in-out;
        }
      }
      [class*='customerNoCommentsContainer']{
        margin-top: 15px;
      }
      .noTitleContainer{
        margin-top: -15px;
        padding-bottom: 0px;
      }
      .noTitleCommentContainer{
        padding-top: 15px;
      }
    }
    [class*='commentsContainer']{
      padding-top: 20px;
    }
    [class*='commentsContainer'], [class*='newComment'] {
      @media (max-width: $mobile) {
        padding: 0;
      }
    }
  }


  &:hover {
    .container {
      &.stickyCommentsFilter {
        [class*='commentHeader'] {
          opacity: 1;
          transition: opacity 0.1s ease-in-out;
        }
      }
    }
  }
}

.emptyStateContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .emptyStateTitle {
    font-size: 14px;
    font-weight: 600;
  }
  .emptyStateDescription {
    font-size: 12px;
    font-weight: normal;
  }

  .emptyStateTextContainer {
    .emptyStateTitle {
      padding-bottom: 5px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.commentsIconContainer {
  position: relative;
  height: 40px;
  width: 60px;
  margin-top: 10px;
  .commentsIconWithPlus {
    position: absolute;
    top: 0;
    left: 0;
    height: 22.5px;
    width: 24px;
    transform: rotateY(180deg);
  }

  .commentsIcon {
    position: absolute;
    top: 19px;
    left: 13px;
    width: 19px;
    height: 18px;
  }
}

.chatEntryIcon {
  display: flex;
  gap: 5px;
  margin: 8px;
  align-items: center;
  .icon {
    display: flex;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
  }

  .textBubble {
    padding: 6px 8px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  }
}

.conversationContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.textPlaceholder {
  height: 4px;
  border-radius: 2px;
  background: $divider-color;
}

.kitEmptyState {
  font-family: $main-Font;
}

.kitEmptyStateImage {
  height: 97px;
  width: 110px;
}