.widgetPresentationConfigurationContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 25px;
}

.description {
  font-size: 14px;
  color: black;
  padding-bottom: 25px;
}

.bulletText {
  color: black;
}