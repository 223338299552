@import '../../variables';

.container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.title {
    @include description();
    font-size: 60px;
    text-align: center;
    margin-top: 70px;
    font-family: $title-Font;

    @media only screen and (max-width: 600px) {
        font-size: 34px;
        letter-spacing: normal;
        text-align: center;
        margin-top: 60px;
        padding: 0 20px;
    }
}

.actionButton {
}
