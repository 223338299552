@import './variables';

.App {
    /* text-align: center; */
    display: flex;
}

.columnFlex {
    flex-direction: column;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.sideBarContainer {
    height: 100vh;
    margin-right: 73px;
}

.sideBarContainerWithTrial {
    height: $screen-height-with-trial-banner;
    margin-right: unset;
    background-color: $sidebar-color;
    z-index: 1000;
}

.container {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.appMainContent {
    /* overflow: auto; */
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
    max-width: 100%;

    &.viewportHeight {
        height: 100%;
        overflow-x: hidden;
    }
}

.viewportHeightWithTrial {
    height: $screen-height-with-trial-banner;
    > * {
        max-height: $screen-height-with-trial-banner;
    }
    overflow-x: hidden;
}
