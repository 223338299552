@import '../../../variables';

$connect-button-color: $white-color;
$connect-button-background-color-hover: $all-accounts-hover-color;
$connect-button-text-color: $black-color;

.connectBtn {
    background-color: $connect-button-color;
    color: $connect-button-text-color;
    padding: 12px;
    width: fit-content;

    &:hover {
        background-color: $connect-button-background-color-hover;
    }
}

.icon {
    vertical-align: middle;
}

.tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slack {
    font-weight: bold;
}

.slackConnected {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
