.tag {
    height: 36px;
    line-height: 1;
    border-radius: 18px;
    box-shadow: 0 0 0 1px #4a4a4a;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    /* margin-right: 25px;
    margin-top: 25px; */
    padding-left: 20px;
    padding-right: 20px;
    transition: box-shadow 0.6s linear;
    font-size: 20px;
    color: #000000;
}
.tag:hover {
    box-shadow: 0 0 0 2px#4a4a4a;
    cursor: pointer;
}

.label {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 20px;
}
.icon {
    margin-left: 10px;
    width: 13px;
    height: 10px;
}

.checked {
    composes: tag;
    background-color: #fa3263;
    box-shadow: 0 0 0 1px #fa3263;
    color: #ffffff;

    svg {
        fill: #ffffff;
    }
}
