@import '../../../../../../../variables';

.container {
    padding: 16px 26px 40px;
    font-size: 12px;

    .title {
        margin-bottom: 10px;
    }

    .buttonSettingsContainer {
        margin-top: 29px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid $separator-color;
        padding-top: 15px;
        width: 100%;
        > div {
            width: 100%;
        }

        .boldTitle {
            align-self: flex-start;
            font-weight: bold;
        }
    }
}

.dialogContent {
    overflow-y: auto;
}
