@import '../../../../variables';

.menuLink {
    display: flex;
    font-size: 14px;
    padding-top: 20px;
    padding-left: 32px;
    height: 25px;
    align-items: flex-end;
    .menuIndex {
        @include center;
        margin-right: 16px;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        font-weight: 600;
        color: $asset-menu-index;
    }
}

.twoColumnsDesign {
    width: 45%;
}
