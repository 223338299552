.coldataform {
    border: 1px solid #000;
    border-radius: 12px;
    padding: 15px;
    font-size: 14px;
    margin: 20px 0;
}

.descriptionText {
    font-size: 12px;
    margin-bottom: 15px;
}

.dropdownWrapper {
    justify-content: center;
}

.emojiContainer {
    position: absolute;
    bottom: 45px;
    left: 5px;
    visibility: hidden;
    z-index: 100;
}

.emojiPickerIcon {
    margin-right: 20px;
    align-self: center;
    padding-bottom: 3px;
    cursor: pointer;
    font-size: 20px;
}

.addFieldButtonContainer {
    margin-top: 30px;
    display: flex;
    border-top: 1px solid #e9e8ed;
}

.fcolend {
    border: none;
    padding: 10px 0;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
