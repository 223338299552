@import '../../../variables';

.tableToolbar {
    @include flexCenterJustifyContent(flex-end);
}

.accountsTableContainer {
    display: contents;
}

.toolbarActions {
    margin-right: -35px;
    display: flex;
    align-items: center;
    z-index: 10;
}

.accountOwnerAvatar {
    border-radius: 20px;
    margin-right: 12px;
}

.textWithTooltip {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.accountColumnContainer {
    display: flex;
    align-items: center;

    .defaultLogo {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 12px;
        line-height: 25px;
        font-size: 20px;
        text-align: center;
    }

    .accountName {
        cursor: pointer;
        align-items: center;
        overflow: hidden;
    }
}

.accountLogo {
    flex-shrink: 0;
    border-radius: 20px;
    margin-right: 12px;
    border: solid 1px #e9e8ed;
    height: 25px;
    width: 25px;

    &.error {
        display: none;
    }
}

.accountData {
    @include accountsDetailsTitle();
}
