@import '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;

    .description {
        font-size: 14px;
        color: $widget-description-color;
    }
}
