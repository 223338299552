@import '../../../variables';

.widgetHeader {
    position: absolute;
    top: 8vh;
    left: 12vw;
    max-width: calc(70vw - 30px);
    word-break: break-word;

    @media (max-width: $mobile) {
        position: unset;
        margin-right: 0;
        width: 80vw;
        max-width: initial;
    }
}

.title {
    font-size: 30px;
}

.subtitle {
    color: $placeholder-color;
    font-size: 20px;
}
