@import '../../variables';

.container {
    position: relative;
    width: fit-content;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

.popover {
    width: 368px;
    right: 40px;
    z-index: 100;
    top: 3px;
    position: absolute !important;
    margin-top: 0px;
    height: max-content;
    border-radius: 10px;

    &::before {
        border-bottom: 10px solid white;
    }
}

.popoverChildren {
    padding: 15px 0 0;
    overflow: hidden;
    height: 100%;
    max-height: 40vh;
}

.popoverContainer {
    min-height: 100px;
    font-family: $main-Font;
    padding: 0 10px;
}

.popoverTitle {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
}

.flex {
    display: flex;
    align-items: center;
}

.popoverTitleContainer {
    justify-content: space-between;
}

.backArrow {
    cursor: pointer;
}

.footer {
    min-height: 60px;
}

.copyToButtonContainer {
    font-family: $main-Font;
    
    &:hover {
        background-color: $purple-action-button-menu-hover-color;
    }
}

.copiedContainer {
    padding: 5px 10px;
    display: flex;
    width: max-content;
    align-items: center;

    .text {
        padding-left: 7px;
    }
}

.copyToButton {
    padding: 5px 30px;
}
