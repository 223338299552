@import '../../variables';

.dialog {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.popup {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 30px 30px 20px;
    gap: 30px;
    transition: all 0.2s ease-in;
    font-size: 14px;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: bold;

    .title {
        color: #000;
    }

    .closeButton {
        cursor: pointer;
        align-self: flex-start;
        height: fit-content;
        display: flex;
        margin-top: -15px;
        margin-right: -15px;
        padding: 5px;
    }
}

.header, .footer {
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    box-sizing: border-box;
    margin-right: -10px; /* Push content 10px to the right */
    //padding-right: 10px; /* Add spacing between content and scrollbar */
}

.description, .footer .text {
    color: $subtext-color;
}

.imageWrapper {
    display: flex;
    justify-content: center;

    img {
        height: fit-content;
        max-height: 70vh;
        max-width: 100%;
        transition: height 0.2s ease-in;

        &.hidden {
            opacity: 0;
            height: 0;
        }
    }

    .imageLoader {
        img {
            margin-top: 25px;
        }
        height: 200px;
        width: 400px;
        margin-top: 25px;
    }
}

.hidden {
    height: 0;
}

.footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 30px;

    .text {
        margin-top: 10px;
    }
}

.ctaWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-self: center;
    font-size: 14px;

    .buttonLabel {
        text-wrap: none;
        padding: 0 15px;
    }
}

.divider {
    border-bottom: 1px solid $divider-color;
}