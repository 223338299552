@import '../../../../../variables';

.calloutContainer {
  // Fixes avatar image not being rounded on hover with animation still showing
  [class*='imageContainer'] {
    position: unset;
  }
}

.avatarOverlay {
  cursor: pointer;
  margin-top: -7px;
  white-space: break-spaces;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
}

.emptyCallout {
  opacity: 0;

  .calloutAvatarEmptyState {
    width: 37px;
    height: 37px;
  }

  .calloutAvatarOverlayEmptyState {
    font-size: 9px;
  }

  &:hover {
    opacity: 1;
    transition: opacity 100ms ease-in-out;
  }
}

.calloutAvatar {
  cursor: pointer;
  position: relative;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.15);
}

.animateAvatar {
  border-radius: 100%;
  animation: pulseCalloutAvatar 1.3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  width: calc(100% + 29px);
  height: calc(100% + 29px);
  position: absolute;
  top: -14px;
  left: -14px;
  z-index: 0;
}

.avatarTip {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid $callout-opened-border-color;
  transform: rotate(64deg);
  border-radius: 50px;
  position: absolute;
  top: 41px;
  left: 2px;
  transition: border 300ms ease;
}

.removeAvatarHighlight {
  // Avatar highlight is set as inline-style (for branding color), so need to use !important to override it.
  border: 2px solid $callout-opened-border-color !important;
  transition: border 300ms ease;
}

.avatarOverlayHighlight {
  opacity: 0;
  width: 47px;
  height: 47px;
  position: absolute;
  z-index: 10;
  border-radius: 100%;
  transition: opacity 100ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}

.slidesCallout {
  position: absolute;
  right: 13vw;
  top: 7vh;
  z-index: 1001; // Presentation mode has hubs z-index set to 1000
}

@keyframes pulseCalloutAvatar {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@media (max-width: $mobile) {
  .calloutAvatar {
    width: 37px;
    height: 37px;
  }

  .avatarOverlayHighlight {
    width: 29px;
    height: 29px;
  }

  .slidesCallout {
    right: 3vw;
    top: $slides-mode-content-top;

    [class*='calloutFloatingWindow'],
    [class*='calloutPopperWithComments'] {
      right: 0;

      [class*='anchor'] {
        right: 8px;
      }
    }
  }

  .avatarTip {
    border: 0;
  }

  .arrowUp {
    top: -6px;
    left: 4px;
    transform: rotate(170deg);
  }
}