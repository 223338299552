@import '../../../taskVariables';

.dialog {
    max-width: 452px;
    margin: 20px 36px;
}

.button {
    @extend %TASK_CTA;

    > div {
        padding: 0 10px !important;
    }
}