@import '../../variables';

.presentationSlidesModeTitle {
    font-size: 7vh;
    word-break: break-word;

    &:not(.titleWithoutPadding) {
        padding-bottom: 13px;
        padding-top: 10vh;
    }

    @media (max-width: $mobile) {
        font-size: 6vw;
    }
}

.sectionHeader {
    [class*='titleContainer'] {
        padding-left: 0;
    }
}

.thinSectionSlides {
    padding-top: inherit;
    text-align: inherit;

    @media (min-width: $mobile) {
        max-width: calc(50vw - 20px);
    }
}

.presentationSlidesModeSubtitle,
.presentationSlidesModeFooter {
    p {
        font-size: 21px;

        @media (max-width: $mobile) {
            font-size: 16px;
        }
    }
}
