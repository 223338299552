@import '../../../../../variables';

.container {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: $mobile) {
        width: calc(100% - 70px);
    }

    .image {
        width: 100%;
        img {
            max-height: 500px;
            object-fit: contain;
            padding: 0 5px;

            @media (max-width: $mobile) {
                width: 100%;
                padding: 0;
                max-width: calc(100% - 80px);
            }
        }
    }

    &.horizontal {
        flex-direction: row;
        justify-content: flex-end;
        > * {
            display: flex;
            width: 50%;
        }
        .image {
            display: flex;
            width: 50%;
            img {
                max-width: 100%;
            }
        }

        @media (max-width: $mobile) {
            width: calc(100% - 100px);
            justify-content: center;
            .image {
                width: 100%;
            }
        }
    }

    &.vertical {
        flex-direction: column;
        .image {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .description {
        padding: 5px 0;
        width: 100%;
        min-width: 50%;
        flex-direction: column;
        font-size: 14px;
    }
}

.descriptionWrapper {
    padding: 0 5px 5px 5px;
}
