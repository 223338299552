@import '../../../../../variables';

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.styledContainer {
    .styledButton {
        border: unset;

        .styledButtonLabel {
            display: flex;
            align-items: center;
        }

        .iconStyle {
            margin-left: 10px;
        }
    }
}