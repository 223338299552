@import '../../../../variables';

.container {
    margin: 20px 0 10px;

    .title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .description {
        @include descriptionWithOpacity();
        margin-bottom: 10px;
    }

    .robotIcon {
        margin-right: 10px;
    }

    .oval {
        width: 12px;
        height: 12px;
        position: relative;
        bottom: 5px;
        background-color: $primary-branded-color;
        border-radius: 50%;
        text-align: center;
        font-size: 8px;
        color: $font-color-in-main-color;
    }
}
.dialogContainer {
    padding: 20px 30px;
    max-width: 625px;

    .dialogDescription {
        color: $subtitle-info-color;
        margin-bottom: 20px;
    }
}