@import '../../../variables';

.title {
    @include widgetTitle;
}

.description {
    @include widgetInformationPanel;
    padding-bottom: 15px;
}

.contactsInput {
    caret-color: transparent;
}

.selectableIconAreas {
    display: flex;
    padding-bottom: 10px;
}

.secondaryTitle {
    @include widgetSecondaryTitle;
}

.signatureTextInputs {
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 10px 0 15px;
}

.fontsContainer {
    display: flex;
    flex-wrap: wrap;
}
