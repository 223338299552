@import '../../../../variables';

.setExpectedDurationText {
    font-size: 12px;
    font-weight: 600;
    color: $primary-branded-color;
    font-family: $main-Font;
    cursor: pointer;
    z-index: 2;
}

.disabled {
    pointer-events: none;
}

.dialogBody {
    padding: 30px;
}

.text {
    p {
        font-size: 12px;
        font-family: $main-Font;
    }
}
.statusTrackerText {
    margin-left: 5px;
    color: $set-duration-highlight-text;
    font-weight: 600;
    text-decoration: none;
}

.inputLine {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding-top: 10px;
}

.dontSetExpectationButton {
    width: 120px;
    color: $primary-branded-color;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    position: absolute;
    left: 55px;
}

.healthTooltip {
    white-space: break-spaces;
    line-height: 0.8;
    margin: -5px 0 -15px -20px;
}

.taskHealth {
    font-size: 12px;
    font-family: $main-Font;
    font-weight: 600;
    padding: 1px 10px;
    border-radius: 4px;
    width: fit-content;
    min-width: fit-content;
    cursor: default;
}

.clickableTaskHealth {
    cursor: pointer;
}

.notStarted,
.completed {
    color: $tasks-duration-not-started-color;
    background-color: $tasks-duration-not-started-background;
}

.potentialDelay {
    color: $tasks-duration-potential-delay-color;
    background-color: $tasks-duration-potential-delay-background;
}

.delayed {
    color: $tasks-duration-delayed-color;
    background-color: $tasks-duration-delayed-background;
}

.onTrack {
    color: $tasks-duration-on-track-color;
    background-color: $tasks-duration-on-track-background;
}
