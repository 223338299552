@import '../../../_variables.scss';

.editModalInput {
    width: 424px;
}

.editModalInputTitle {
    height: 20px;
    margin: 24px 257px 1px 0;
    font-family: $main-Font;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.editModal {
    width: 424px;
    padding: 25px;
}