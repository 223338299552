@import '../../../../variables';

@mixin displayValueAlignment {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 4px;
    min-height: 30px;
    margin: auto;
    align-items: center;
    span {
        display: inline-flex;
        align-items: center;
    }

    > :first-child {
        min-height: 21px;
        padding-right: 5px;
        @media (max-width: $mobile) {
            margin: 0;
        }
    }
}

.placeholder {
    @include inputPlaceholder();
    @include displayValueAlignment();
    line-height: 30px;
}

.displayValue {
    @include displayValueAlignment();
}

.editBoxContainer {
    width: 100%;
    min-width: 30px;
    [class*='editButton'] {
        width: 30px;
        height: 30px;
    }
    > div {
        height: 100%;
        display: flex;
    }

    overflow: hidden;
}

.input {
    border-radius: 0;
    width: 100%;

    [class*='MuiOutlinedInput'] {
        border-color: $separator-color !important;
    }
}

.inlineEditButton {
    @include taskActionButton();
    height: 13px;
    width: 13px;
    padding: 4px;
    padding-right: 5px;
}
