@import '../../variables';

.trialBanner {
    min-height: $trial-banner-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $trial-banner-color;
    gap: 33px;
    width: 100%;

    .trialText {
        display: flex;
        align-items: center;
        color: $trial-action-button-color;
        font-weight: bold;
        font-size: 14px;
    }

    .contactButton {
        padding: 9px 35px;
        text-align: center;
        border: unset;
        background-color: $contact-us-trial-banner-color;
        height: $trial-action-button-height;
    }

    .labelClassName {
        color: $trial-banner-color;
        font-size: 12px;
    }
}
