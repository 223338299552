@import '../../../variables';

.container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    background-color: $individual-mode-label-color;
    align-items: center;
    padding: 2px 5px;
    border-radius: 3px;

    .label {
        font-size: 10px;
        font-family: $main-Font;
        font-weight: normal;
        height: 15px;
        color: #000;
    }
}
