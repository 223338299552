@import '../../../../variables';

.container {
    display: flex;
    gap: 20px;
    align-items: baseline;
}

.thumbsIcons {
    padding: 10px 12px;
    width: 50px;
}

.scores {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    align-self: flex-end;
}

.score {
    @include center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 7px;

    &:not(:hover) {
        border-color: transparent !important;
    }
}

.disabled {
    opacity: 0.7;
    cursor: default;
    pointer-events: none;
}

.starScores {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

.starScore {
    margin: 0 1px;
    opacity: 0.2;

    &:not(.highlighted) {
        opacity: 1;
    }
}

.star {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}