@import '../../variables';
.bar {
    height: 6px;
    border: solid 1px #eaeaea;
    border-radius: 3px;
    position: relative;
}

.completedPart {
    height: 8px;
    margin-top: -1px;
    border-radius: 5px;
}

.percentTextContainer {
    min-width: 20px;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #545454;
    align-items: flex-end;
    transition: width 0.2s ease-in-out;
}

.animatedGradient {
    background: repeating-linear-gradient(to left, #00d392 0%, #0cb083 19%, #48ffd5 37%, #0cb083 55%, #00dfab 74%);
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 1s 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    height: 8px;
    margin-top: -1px;
    border-radius: 5px;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.confetti {
    position: absolute !important;
    // top: 50%;
    // left: 50%;
    right: 0px;
    & div {
        z-index: 999999;
    }
}

@media (max-width: $mobile) {
    .percentTextContainer {
        justify-content: flex-end;
    }
}

.container {
    margin-top: -15px;
    margin-right: 25px;
    width: 100%;
    padding: 5px 0;
}
