@import '../../variables';

%tableHeaderHover {
    :global(.MuiTableSortLabel-root) {
        &:hover {
            color: inherit;
        }
        &:focus {
            color: inherit;
        }
    }
    :global(.MuiTableSortLabel-active) {
        color: inherit !important;
    }
}

.container {
    padding: 0 15px;
    border-radius: 6px;
    margin: 10px 0 0;

    &.dashed {
        border: 2px dashed #d9d9d9;
    }

    &.customer {
        padding: 0;
    }

    .bottomRow {
        margin-top: 5px;
        min-height: 46px;
        height: 46px;
        flex-direction: row-reverse;
        display: flex;
    }

    @extend %tableHeaderHover;
}

.paper {
    height: 100%;
    @extend %tableHeaderHover;
}

div.paperWithIntercom {
    height: calc(100% - #{$intercom-height});
    position: fixed;
    top: 0;

    @extend %tableHeaderHover;
}

.addRecordButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.addRecordButton {
    margin-top: 36px;
    border-color: transparent;
    max-width: 500px;
    min-width: 120px;
}

.addRecordButtonRulesIcon {
    margin-top: 36px;
    margin-left: 20px;
}

.addRecordButtonLabel {
    padding: 0 35px;
}

.skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeletonGroup {
    display: flex;
    gap: 20px;
}

.addRecordDisabledDialog {
    padding: 26px 30px;
    width: 400px;
}

.actionsContainer {
    div {
        align-items: start;
    }
}