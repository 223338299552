@import '../../../../variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    font-size: 12px;

    .txtlink {
        height: 80px;
    }
}

.rightButton {
    background-color: $main-color;
}
