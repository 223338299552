@import '../../../../variables';

.container{
    width: 465px;
    padding: 26px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.propertyTitle {
    margin-bottom: 15px;
}

.hyperlinkContainer {
    display: flex;
    gap: 18px;
}

.hyperlink {
    width: 100%;
}

.hyperlinkTitle {
    margin-bottom: 10px;
    cursor: pointer;
}