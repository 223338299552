@import '../../../variables';

.text {
    font-size: 12px;
    color: $subtext-grey;
    margin-bottom: 10px;
    margin-top: -8px;
}

.comboBox {
    font-family: $main-Font;
    margin-bottom: 15px;
}

.comboBoxInfoContainer,
.comboBoxInfoContainerWithoutInfoIcon {
    display: inline-block;
}

.comboBoxInfoContainer {
    width: calc(100% - #{$info-size});
}

.comboBoxInfoContainerWithoutInfoIcon {
    width: 100%;
}

.infoIcon {
    margin-left: 10px;
}
