@import '../../../../variables';

.container {
    width: 413px;
    padding: 20px;
}

.inputLabel {
    margin: 10px 0 5px;
}

.inputDescription {

}

.error {
    color: $error-message-color;
    font-weight: bold;
    font-size: 14px;
}

.checkboxContainer {
    display: flex;
    font-size: 12px;
    gap: 10px;
}
