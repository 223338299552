@import '../../../variables';

$dialogWidth: 440px;

.error {
    position: absolute;
    bottom: 68px;
    right: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;
}

.mandatoryDiv {
    font-size: 13px;
}

.fieldsContainer {
    margin-top: 30px;
    width: $dialogWidth;
    max-height: 65vh;
    padding: 0 26px 0 14px;
    overflow-y: scroll;

    @media (max-width: $mobile) {
        width: unset;
    }
}

.fieldInput {
    margin-bottom: 25px;
}

.emptyState {
    display: flex;
    justify-content: center;
    margin: 30px 0 25px;
    width: $dialogWidth;
    font-size: 14px;
}
