.tooltip {
    background: #eeeeee;
    font-size: 8px;
    position: absolute;
    opacity: 0;
    padding: 4px;
    top: -26px;
    left: -3px;
    transition: opacity 0.3s linear;
    &.visible {
        opacity: 1;
        transition: opacity 0.1s linear;
    }
}

.copyButton {
    position: relative;
}
