@import '../../../variables';
@import '../../../aiVariables';

.widget {
    @extend %widget;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0;

    & > div:first-of-type {
        display: flex;
    }

    @media (max-width: $mobile) {
        padding: 25px 20px 0;
    }
}

.widgetContent {
    padding: 0 25px 25px;
    @media (max-width: $mobile) {
        padding: 0 20px 25px;
    }
}

.headlessWidget {
    padding-top: 25px;
}

.dotBackground {
    @include internalWidgetBackground();
}

.widgetSlidesMode {
    padding: 25px !important;
    border: none !important;

    @media (max-width: $mobile) {
        padding: 25px 10px !important;
        margin-bottom: 0 !important;
    }
}

.buttonContainer {
    text-decoration: none;
    padding-top: 20px;

    .editButton {
        padding: 9px 35px;
        text-align: center;
        border: unset;
    }
}

.divider {
    border-top: 1px solid $separator-color;
    height: 10px;
    margin-top: 20px;
}

.subtitle {
    color: $placeholder-color;
    font-size: 14px;
    padding-bottom: 10px;

    @media (max-width: $mobile) {
        font-size: 12px;
    }
}

.subtitle,
.footer {
    word-break: break-word;
}

.rightPositionButton {
    display: flex;
    justify-content: flex-end;
}

.descriptionBtn {
    margin-left: 5px;
    margin-top: 5px;
}

.footer {
    color: $placeholder-color;
    position: relative;
    bottom: 0;
}
.footerEdit {
    margin-top: 10px;
}

.centerPositionButton {
    display: flex;
    justify-content: center;
}

.collapsedWidgetTitleContainer {
    padding-right: 0;
    padding-bottom: 25px;
}

.noBackgroundWidget:not(.highlightWidget):not(.widgetSlidesMode) {
    .widgetContent {
        padding: 0 0 25px;
    }
    .titleContainer {
        padding: 25px 0 0;
    }
}

[class*='sharedHub']:not([class*='sharedHubCustomerHub']) {
    .noBackgroundWidget:not(.highlightWidget) {
        .titleContainer,
        .widgetContent {
            padding: 0;
        }
    }
}

.blurred {
    filter: blur(4px);
    pointer-events: none;
}

.slides {
    .titleContainer,
    .widgetContent {
        padding: 0;
    }
}

.container:not(.slides) {
    @media (max-width: $mobile) {
        padding-bottom: 25px;
    }
}

.aiBorder {
    @include AI_CONTENT_WRAPPER(#fff, 5px, 2px);
}

.aiTooltip {
    max-width: 550px;
    box-sizing: border-box;
    bottom: calc(100% - 80px);
}