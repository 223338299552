@import '../../../../../variables';

.container {
    @include horizontalCenter();
    height: 100%;
}

.content {
    width: 291px;
    margin-top: 40px;
    > * {
        margin-bottom: 22px;
    }
}

.loader {
    position: fixed;
    top: 0;
    height: 100vh;
}

.error {
    color: $error-message-color;
}
