.itemTextContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 220px;

    span {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.detailBoxContainer {
    display: grid;
    grid-template-columns: auto 25px;
    width: 100%;
}

.boxIcon {
    width: 24px;
    height: 24px;
}

.boxDetails {
    flex-direction: column;
    margin-left: 12px;
}

.accountDetailsTitle {
    font-size: 14px;
    font-weight: bold;
}

.accountDetails {
    font-size: 14px;
}

.accountDetailsError {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;

    .cancel {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 10px;
    }
}

.editBtn {
    display: flex;
    margin-left: -36px;
}

.detailBoxContainer {
    width: 240px;
    display: flex;
    justify-content: space-between;
}
