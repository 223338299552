.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 25px 0;

    .title {
        font-size: 14px;
        font-weight: bold;
    }

    .description {
        font-size: 14px;
    }
}

.activationOptionsText {
    font-size: 12px;
}

.automaticTaskPlanInfoIcon {
    margin-left: 5px;
}