@import '../../../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calculateButton {
  margin-left: 10px;
  margin-right: 20px;
}

.separator {
  padding-top: 20px;
  border-bottom: 1px solid $separator-color;
  width: 100%;
}

.notifyTitle {
  margin: 0;
}

.activateTasksWidgetButton {
  margin-right: 20px;
}

.activateTasksDialogContent {
  padding-bottom: 15px;
}