@import '../../variables';

.container {
    padding: 0 25px 25px;

    .title {
        display: flex;
        flex-direction: row;
    }

    .ellipsis,
    .inputView {
        font-size: 12px;
    }
}

.detailRowContainer {
    width: 400px;
    @media (max-width: $mobile) {
        max-width: 100%;
        [class*='contentContainer'] {
            margin-left: 0;
        }
    }
}

.inputEditMode {
    border-radius: 5px;
    border: solid 1px $error-message-color;
}

.errorMessage {
    color: $error-message-color;
    font-size: 12px;
    font-weight: 600;
}

.input {
    margin-left: unset;
}

.content {
    overflow: auto;
}
