@import '../../../../../variables';

.presentationModeBtn {
    margin-left: auto;
    height: 33px;
    padding: 15px;

    @media (max-width: $small-laptop) {
        padding: 10px;
    }

    .presentationMenuContainer {
        width: 331px;
        z-index: 100;
        top: $header-height;

        .presentationMenu {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            font-size: 14px;
            .title {
                font-weight: bold;
            }

            .subTitle {
                margin-bottom: 30px;
            }

            .presentationModeBtn {
                margin-bottom: 20px;
            }
        }
    }
}

.presentationModeButtonLabel {
    @include textualButtonLabel;

    @media (max-width: $small-laptop) {
        .label {
            display: none;
        }
    }
}
