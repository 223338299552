@import '../../../../variables';

.container {
    &.collapsed {
        width: 0;
    }

    .header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    height: 100%;
}

.content {
    .navItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 10px;
        font-size: 14px;
        padding: 5px 15px;
        border-radius: 5px;
        width: calc(100% - 50px);

        &.activeNavItem {
            border-bottom: none;
            border-left: 3px solid;
            border-bottom: none;
        }
    }

    &.mobileViewContent {
        .navItem {
            width: calc(100% - 60px);
            margin: 0;
        }
    }
}

.menuButtonContent {
    aspect-ratio: 1;
    height: 4em;
    cursor: pointer;
}

.collapseContainer {
    display: flex;
    flex-direction: row;
    background: $widget-container-background-color;
    height: 100%;

    .menu {
        -webkit-box-shadow: 4px 2px 14px -1px rgba(0, 0, 0, 0.36);
        box-shadow: 4px 2px 14px -1px rgba(0, 0, 0, 0.36);
        z-index: 99;
        flex-shrink: 0;
        opacity: 1;
        width: 15vw;
        min-width: 250px;
        overflow: auto;
        padding-bottom: 10px;
        transition: min-width 0.2s ease-in, width 0.2s ease-in, opacity 0.2s linear, transform 0.2s ease-in;
        &.collapsed {
            width: 0;
            min-width: 0;
            transform: translateX(-200px);
            opacity: 0;
            transition: min-width 0.2s ease-in, width 0.2s ease-in, opacity 0.2s linear, transform 0.2s ease-in;
        }

        .actionBar {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.menuButtonContent {
    position: relative;
    top: 10px;
    left: 30px;

    z-index: 99;
    background: $widget-container-background-color;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    padding: 15px;
    opacity: 1;
    transition: width 0.2s ease-in, opacity 0.2s linear;
    border: 1px $separator-color solid;
    &.collapsed {
        padding: 0;
        width: 0;
        opacity: 0;
        transition: padding 0.2s linear, width 0.2s ease-in, opacity 0.2s linear;
    }
}

.menuButtonContent {
    left: 30px;
    padding: 10px;
    height: 20px;
    width: 20px;
}

.navigationItemWrapper {
    position: relative;
    height: fit-content;
    .addSectionIcon {
        opacity: 0;
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        height: 20px;
        margin: auto;
    }

    &:hover {
        .addSectionIcon {
            transition: opacity 0.1s linear;
            opacity: 1;
        }
    }
}
