@import '../../../../../variables';

.manageContactsBtnContainer {
    position: relative;
}

.manageContactsBtn {
    height: 33px;
    padding: 15px;

    @media (max-width: $small-laptop) {
        padding: 10px;
    }
}

.manageContactsnLabel {
    @include textualButtonLabel;

    @media (max-width: $small-laptop) {
        .label {
            display: none;
        }
    }
}
