@import '../../../../variables';

.contentTilesWidgetEditMode {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin: 20px 37px 15px;

    .iconContainer {
        display: flex;
        align-items: center;
        .addIcon {
            cursor: pointer;
        }
    }

    @media (max-width: $laptop) {
        margin: 20px 0;
    }
}
