@import '../../../../variables';

.container {
    display: flex;
    gap: 5px;

    @media (max-width: $small-laptop) {
        flex-direction: column;
        gap: 0;
    }
}
.name {
    color: $main-color;
}