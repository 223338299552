@import '../../variables';

$header-height: 67px;
$footer-height: 45px;
$column-width: 260px;

.container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - (2 * 27px));
  gap: 10px;
  padding: 0 27px;
}

.header {
  height: $header-height;
  border-bottom: 1px solid $widget-container-border-color;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  .leftSideNavigation {
    font-size: 18px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    left: 0;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .rightSide {
    display: flex;
    gap: 20px;
    align-items: center;
    position: absolute;
    right: 0;
  }
}

.body {
  // 61px = 1px header border + 40px gaps between header/body/footer + 20px footer paddings
  height: calc(100vh - #{$header-height} - #{$footer-height} - 61px);
  padding-top: 20px;
}

.kitGraphContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  position: relative;

  .kitName {
    min-width: calc(#{$column-width} - 40px);
    text-align: center;
    padding: 7px 20px;
    border-radius: 4px;
    background-color: $template-cell-background-color;
    color: white;
    z-index: 1;
    position: sticky;
    right: 0;
  }
  .sectionColumns {
    height: calc(100% - 58px);
    max-width: 100%;
    position: relative;

    &.withTopBorder:before {
      content: '';
      width: calc(100% - 4px);
      height: 20px;
      top: -40px;
      position: absolute;
      border: 2px solid $template-cell-background-color;
      border-bottom: 0;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    .columns {
      display: flex;
      gap: 20px;
      overflow: auto;
      max-height: 100%;
      padding: 0 3px;

      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
      }
    }

    .sectionWidgetsList {
      display: flex;
      flex-direction: column;
      width: $column-width;
      min-width: $column-width;

      .addButton {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      .addTabButton {
        margin-bottom: 15px;
        flex-direction: row-reverse;
      }

      .addWidgetButtonIdent {
        margin-left: 40px;
      }

      &:hover {
        .addButton {
          opacity: 1;
        }
      }
    }

    .additionalPadding {
      padding: 33px 0 15px;
    }
  }
}

.addButton {
  display: flex;
  color: $primary-color;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.widgetMenuItemContainer:last-of-type {
  .indentedMenuItem:before {
    border-bottom-left-radius: 10px;
  }
}

.itemDrag {
  outline: 0;
}

// Tab sections
.sectionMenuItem {
  .widgetMenuItem {
    background-color: $minimap-tab-background-color;
    border: 1px solid transparent;

    &:hover {
      background: $minimap-tab-background-color;
    }
  }
}

// Non tab sections
.nonSectionMenuItem {
  border: 1px solid $minimap-widget-background-color;

  &:hover {
    background: unset;
  }
}

// All widgets
.widgetMenuItem {
  border-radius: 4px;
  margin-bottom: 15px;
}

// All widgets within a section (excluded tab + widgets before first tab)
.indentedMenuItem {
  margin-top: 15px;
  margin-left: 40px;
  width: 220px;

  &::before {
    height: 56px !important;
    margin: -35px 0 0 -19px !important;
    width: 19px !important;
  }
}

.emptyState {
  font-size: 12px;
  color: $subtext-grey;

  .emptyStateActions {
    display: flex;
    gap: 20px;
    margin-top: 15px;

    .separator {
      height: 18px;
      border-left: 1px solid $textbox-border-color;
    }
  }
}

.emptyTab {
  margin-left: 40px;
  padding-bottom: 15px;
}

.removePointerCursor {
  pointer-events: none;
}

.footer {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  height: $footer-height;
}