@import '../../../../../../variables';

.linkContainer {
    display: flex;
    align-items: center;
}

.internalLinkContainer {
    background-color: $internal-data-background-color;
    outline: 1px solid $internal-border-color;
    border-radius: 50px;
}

.link {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: $text-color;

    .text {
        margin-left: 5px;
        cursor: pointer;
    }
}

.dialogContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 75px);
    position: absolute;
    width: 100%;
    margin: 0 -30px;
}

%icon {
    width: 13px;
    height: 13px;
}

.playIcon {
    @extend %icon;
}

.seeIcon {
    @extend %icon;
    margin-left: 5px;
    cursor: pointer;
}

.copyPassword {
    font-size: 12px;
    cursor: pointer;
    align-self: flex-end;

    &:hover {
        font-weight: 600;
    }

    @media (max-width: $mobile) {
        margin-left: 18px;
    }
}

.recordingLinkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    border-right: 1px solid $divider-color;

    @media (max-width: $mobile) {
        align-items: unset;
        padding-right: unset;
        border-right: unset;
    }
}
