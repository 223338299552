@import '../../../variables';

.container {
    width: $configuration-panel-width;
    height: calc(100% - #{$test-account-height});
    border-right: 1px solid $widget-container-border-color;

    .accountDetailsContainer {
        height: calc(100% - #{$nav-left-panel-height});
        overflow-y: auto;
        overflow-x: hidden;
        [class*='main'] {
            padding-left: 0;
        }

        [class*='header'] {
            font-size: 12px;
        }
    }

    .accountDetailsWithTrial {
        height: calc(100% - #{$header-height} - #{$trial-banner-height} - 4px);
    }

    .widgetMenu {
        height: max-content;

        [class*='container'] {
            height: unset;
        }
    }
}

.widgetsMenuContainer {
    overflow: hidden auto;
}

.widgetsList {
    > [class*='container'] {
        height: calc(100% - #{$nav-left-panel-height} - 10px);
        padding-right: 20px;
        overflow-y: auto;
    }
    [class*='actionItem'] {
        [class*='floatingWidgetsChildrenContainer'] {
            max-height: calc(#{$widget-content-height} - 320px);
        }
    }
}

.tabs {
    > div {
        margin-left: 25px;
        border-bottom: 1px solid $widget-container-border-color;
    }

    [class*='singleTabHeader'] {
        height: 46px;
        padding: 0;
        display: inline-flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}
