@import '../../variables';

$GANTT-CELL-BORDER-COLOR: #ebebeb;
$GANTT-BORDER-COLOR: #c1c1c1;

.gantt_container {
  border: 1px solid $GANTT-BORDER-COLOR !important;

  & div::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
}

// Right-side grid
.gantt_grid_data, .gantt_row {
  background-color: #fff !important;
}

.gantt_row, .gantt_row_odd {
  border-top: 1px solid $GANTT-CELL-BORDER-COLOR !important;
  border-bottom: 1px solid $GANTT-CELL-BORDER-COLOR !important;
  box-sizing: content-box;
}

.gantt_grid_scale {
  border: 0;
  height: unset !important;

  .gantt_grid_head_cell {
    background-color: #fff !important;
    border: 0 !important;
    color: #c1c1c1 !important;
  }
}

.gantt_cell {
  padding-left: 17px;
}

.gantt_tree_indent {
  width: 25px;
}

.gantt_layout_cell_border_right {
  border-color: $GANTT-CELL-BORDER-COLOR;
}

.gantt_tree_icon:not(.gantt_close, .gantt_open) {
  display: none;
}

.gantt_close, {
  background-image: url('../../assets/icons/arrow-drop-down.svg') !important;
}

.gantt_open {
  background-image: url('../../assets/icons/arrow-drop-down.svg') !important;
  transform: rotate(-90deg);
}

.gantt_close, .gantt_open {
  margin-left: -10px;
  margin-right: 5px;
}

// Dates row
.gantt_task_scale {
  background-color: white !important;
  border-bottom: 1px solid $GANTT-CELL-BORDER-COLOR !important;
}

.gantt_scale_cell {
  border-left: none !important;
  border-right: 1px solid $GANTT-CELL-BORDER-COLOR !important;
  color: $GANTT-BORDER-COLOR !important;
  font-size: 12px !important;
  font-weight: normal;

  &:last-of-type {
    border-right: none !important;
  }
}

// Timeline items
.gantt_task_progress {
  border: none;
  box-shadow: unset;
  background: unset;
}

.gantt_task_line {
  background-image: none;
}

.gantt_tooltip {
  @extend %mui_tooltip;

  // dialog has z-index: 1300
  z-index: 1301;
}

.gantt_task_vscroll {
  background-color: unset !important;
}

.gantt_hor_scroll, .gantt_task_vscroll {
  overflow: auto;
}

.gantt_layout_cell_border_top {
  border-top: none;
}

// Timeline arrows
.gantt_line_wrapper div {
  background-color: #333;
}

.gantt_link_arrow_right {
  border-left-color: #333;
}

.gantt_link_arrow_left {
  border-right-color: #333;
}

.gantt_link_arrow {
  margin-top: -2px !important;
}