@import '../../variables';

.colorsContainer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    flex: 1;

    .colorOption {
        border-radius: 100px;
        border: 1px solid $widget-container-border-color;
        height: 28px;
        width: 28px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover,
        &.selected {
            border: 2px solid $widget-container-border-color;
            height: 26px;
            width: 26px;
        }

        .selectedIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            border-radius: 100px;
            width: 18px;
            height: 18px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}
