@import '../../../../variables';

$meetingDetailsContentPadding: 26px;

.meetingDetails {
    font-size: 14px;
    color: black;
    font-weight: bold;
}

.notificationTitle {
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
}

.meetingDetailsInput {
    margin-top: 24px;
    margin-bottom: 20px;
}

.meetingDetailsDialog {
    width: 50%;
    height: auto;
}

.meetingDetailsContent {
    padding: $meetingDetailsContentPadding;
    overflow-y: auto;
    height: fit-content;
    max-height: calc(100vh - (#{$meetingDetailsContentPadding} * 2) - #{$dialog-v2-non-content-height});
}

.subject {
    margin-top: 26px;
}

.dialogComment {
    flex: 1;
    font-size: 14px;
    margin-top: 30px;
}

.starLogo {
    padding-right: 5px;
}
