@import '../../variables';

.menuContainer {
    display: flex;
    height: 100%;
    flex: 1;
    left: calc(#{$sidebar-main-width} - #{$product-feed-menu-width + $slide-panel-border-width});
    border-right: $slide-panel-border-width solid $main-color;
    box-shadow: 15px $slide-panel-border-width 12px 0 rgba(0, 0, 0, 0.07);

    .iframeContaienr {
        overflow: hidden;
        height: inherit;
        flex: 1;
        position: relative;
    }

    .iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
