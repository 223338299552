@import '../../variables';

.row {
    width: 100%;
    display: flex;
    .editSharedPasswordIcon {
        margin-left: auto;
        cursor: pointer;
    }
}

.csmLinkInfo {
    margin-left: 17px;
    position: relative;
    top: 2px;
}
.copyUniversalLink {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.42;
    color: $primary-color;
    cursor: pointer;
}

.editPasswordText {
    font-size: 12px;
    font-weight: 600;
    color: $primary-color;
    cursor: pointer;
    margin: 5px 0px 20px 55px;
}
.universalText {
    font-size: 12px;
    color: $subtext-grey;
    margin-bottom: 13px;
}
.iconWrapper {
    border-radius: 50%;
    border: solid 1px #000000;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.sharedWithPassword {
    margin-bottom: 20px;
}

.row {
    display: flex;
    align-items: center;
    .urlLinkContainer {
        background-color: #f2f2f2;
        padding: 10px;
        display: flex;
        border-radius: 5px;
        justify-content: space-between;
        width: 100%;
    }
    .emailIcon {
        cursor: pointer;
        margin-left: 19px;
        min-width: 16px;
    }
}

.pdfPreviewLink {
    cursor: pointer;
    .iconWrapper {
        border: none;
        position: relative;
    }
    .arrowRight {
        align-self: center;
        margin-left: 50px;
    }
}

.lineBreak {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #00000033;
}

.betaTag::after {
    @include betaTag();
    right: -8px;
    top: 4px;
}

.shareUrlToggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
