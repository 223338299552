@import '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-top: 20px;
}

.subtitle {
    font-size: 12px;
    color: #000000;
    opacity: 0.7;
    margin: 5px 0;
    white-space: break-spaces;
}
