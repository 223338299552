@import '../../../variables';
.row {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.circle {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: solid 1px #d9d9d9;

    &.toggled {
        border: solid 2px $primary-color;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .listContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .togglesContainer {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
}

.toggleAreaBox {
    padding-bottom: 60px;
}
