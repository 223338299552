.content {
    margin: 20px 0;
    width: 820px;
    font-size: 14px;
    .grayContainer {
        padding: 10px 10px;
        border-radius: 5px;
        border: solid 1px #d9d9d9;
        .readOnlyText {
            color: #757575;
        }
    }
    .explain {
        margin: 30px 0 40px 0;
        white-space: break-spaces;
    }
    .buttons {
        margin: 40px 0 0 0;
    }
}

.poper {
    width: 170px;
}
