@import '../../variables';

.generatedOption {
    padding: 10px 20px 20px;
    background-color: $generated-content-options-background;
    border-radius: 4px;
    width: auto;
    cursor: pointer;
    position: relative;
    border: 2px solid transparent;
    font-size: 12px;
    white-space: break-spaces;
    height: auto;

    .copyToHighlight {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        text-align: center;

        .text {
            font-size: 14px;
            vertical-align: text-bottom;
            margin-left: 6px;
            font-family: $main-Font;
        }
    }

    .copyToContainer {
        visibility: hidden;

        position: absolute;
        top: 7px;
        right: 5px;

        .copyTo {
            padding: 2px 8px;
            border-radius: 10px;
            border: solid 1px black;
            background-color: white;
            cursor: pointer;
        }
    }

    .useGeneratedOptionButton {
        font-family: $main-Font;
        visibility: hidden;
        padding: 0 17px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .generatedOptionText {
        font-size: 12px;
        font-family: $main-Font;

        &.cover {
            opacity: 0.1;
        }
    }

    &:hover {
        border: 2px solid $primary-branded-color;

        .generatedOptionText {
            opacity: 0.6;

            &.cover {
                opacity: 0.1;
            }
        }

        .useGeneratedOptionButton {
            visibility: visible;
        }

        .copyToContainer {
            visibility: visible;
        }
    }
}
