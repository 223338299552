@import '../../variables';

.megaphoneIcon {
    padding: 0 5px;
    width: 21px;
    height: 14px;
}

.megaphoneIconWithColor {
    stroke: $megaphone-icon-color;
}

.editModeMegaphoneIcon {
    cursor: pointer;
}
