@import '../../../variables';

.view {
    height: 100%;
    width: 100%;
}
.container {
    @include horizontalCenter();
    height: fit-content;
    padding-top: 118px;
}
