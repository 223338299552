@import '../../../variables';

.defaultKitsContainer {
    margin-top: 50px;
    height: 100%;
    margin-bottom: 40px;
    > div {
        display: flex;
        gap: 40px;
    }
}

.container {
    background-image: url('../../../assets/images/bg-get-started-image.png');
    background-repeat: repeat-y;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-size: cover;
    min-height: 100%;

    .title {
        @include onboardingTitle();
        display: flex;
        font-family: $secondary-font;
    }

    .semiTitle {
        font-weight: bold;
        font-family: $main-Font;
    }

    .description {
        @include description();
        font-size: 24px;
        margin-top: 10px;
        text-align: center;
    }

    @media (max-width: $mobile) {
        padding: 0 44px;
        align-items: center;
        .description {
            font-size: 16px;
        }
        .defaultKitsContainer {
            margin-top: 22px;
            > div {
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: space-between;
                flex-direction: column;
                > div {
                    box-shadow: 0 10px 15px 0 $intro-highlight-box-shadow;
                }
            }
        }

        background-size: inherit;

        .title {
            font-size: 30px;
            flex-direction: column;
            line-height: 30px;
            padding: 20px;
        }
        .img {
            width: 200px;
        }
    }
}
