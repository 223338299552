@import '../../../variables';

.title {
    font-size: 16px;
}

.dialog {
    width: 425px;
    font-size: 16px;
    height: fit-content;

    .container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 0 30px;
        padding: 26px 0px 30px 0px;

        .fieldName {
            color: $field-color;
            font-size: 12px;
        }

        .helpText {
            .label {
                font-size: 12px;
            }

            .content {
                height: 80px;
                font-weight: unset;
                padding: 2px 0 2px 18px;
            }
        }

        .fieldType {
            margin-top: 7px;
        }

        .description {
            @include smallTitle;
        }

        .label {
            font-size: 14px;
        }
    }
}

.required {
    display: flex;
    align-items: center;
    gap: 10px;
}

.disabled {
    opacity: 0.3;
}

.fieldRequiredOptions {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.optionsHeaderText {
    font-size: 12px;
}

.options:last-child {
    margin-bottom: 0;
}

.optionsText {
    font-size: 12px;
}

.hiddenValueInput {
    display: flex;
    // PersonalizationToken has about 9px of right/left "padding"
    gap: 11px;
    align-items: center;
    margin-left: -13px;
}

.personalizationPopup {
    right: 20px;
}

.defineValuesText {
    display: flex;
    gap: 10px;
    align-items: center;
    color: $text-color;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: -12px;
}