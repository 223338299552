.navItem {
    margin: 0 20px;
    font-size: 16px;
    padding-bottom: 5px;
    height: 37px;
    align-items: center;
    text-decoration: none;
    z-index: 12;
    white-space: nowrap;
    width: fit-content;

    &.selected {
        font-weight: bold;
        border-bottom: 3px solid;
        border-radius: 1.5px;
    }

    &:not(.selected) {
        margin: 0 21px;
    }
}