@import '../../../variables';
@import '../../../aiVariables';

.header {
    display: flex;
    height: 63px;
    border-bottom: 1px $separator-color solid;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .closeIconArea {
        align-self: baseline;
        display: flex;
        gap: 20px;
        margin-right: 20px;
    }

    .dialogTitle {
        @include title();
        padding-left: 30px;
    }
}

.headerActionsContainer {
    min-width: 50px;
    min-height: 30px;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

.footer {
    min-height: 70px;
    display: flex;
    align-items: center;
    background: $dialog-footer-background-color;
    gap: 10px;
    padding: 0 30px;
    justify-content: flex-end;

    .customLeftButton {
        margin-right: auto;
    }
}

.aiTitle {
    @extend %AI_TEXT;
}

.submitAiButton {
    border: none;
    &:hover {
        @extend %AI_COLOR_BACKGROUND;
    }
}