@import '../../../../../../variables';

.container {
    width: 100%;
    display: flex;
    align-items: center;

    .actions {
        width: calc(100% - 56px);
        flex-direction: row;
        display: flex;
        gap: 5px;
        font-size: 13px;
        flex-wrap: wrap;

        > div {
            padding: 5px;
            cursor: pointer;
            margin-bottom: 0;
            width: 48%
        }

        label {
            cursor: pointer;
            padding: 5px 3px 5px 5px;

            > div {
                font-size: 13px;
            }
        }
    }

    .configurationButton {
        opacity: 0;
        margin-left: 20px;
        @include taskActionButton();
        height: 16px;
        width: 16px;
        padding: 5px;
        cursor: pointer;
    }

    &:hover {
        .configurationButton {
            transition: opacity 0.2s ease-in;
            opacity: 1;
        }
    }
}

.actionButtonDialog {
    [class*='ToggleButton'],
    hr {
        display: none;
    }
}

.actionLabel {
    display: flex;
    height: 20px;
    align-items: center;
    width: fit-content;
    max-width: 500px;
}

.actionSubtitle {
    font-size: 12px;
    color: $subtitle-info-color;
    margin-left: 5px;
}

.updateFieldsConfigurationSuffix {
    display: flex;
    align-items: center;

    .fieldsCountLabel {
        display: flex;
        align-items: center;

        .fieldsCountDot {
            font-size: 27px;
            margin: 0 20px;
        }
    }
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .informationRequestSubtitle {
        display: flex;
        align-items: flex-start;
        gap: 5px;

        .infoIcon {
            vertical-align: middle;
            margin-left: 5px;
        }
    }
}

.description {
    opacity: 0.7;
}

.subTitleContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

div.badge {
    font-size: 8px;
    padding: 0 3px;
    border-radius: 20px;
    margin: 0 0 0 13px;
}
