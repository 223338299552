@import '../../../../../variables';

$chart-size: 36px;
$chart-background: #ddd;
$progress-color: #218a8a;
$radius: 18px;
$strokeWidth: 4px;
$normalizedRadius: calc(#{$radius} - calc(#{$strokeWidth} * 0.5));
$circumference: calc(#{$normalizedRadius} * 2 * pi);

%CHART {
  width: $chart-size;
  height: $chart-size;
  border-radius: 50%;
}

.container {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  margin-right: 10px;
}

.chartContainer {
  display: flex;
  align-items: center;
  width: $chart-size;
  height: $chart-size;
  position: relative;

  &:not(.disabled) {
    cursor: pointer;
  }
}

.chartSvg {
  // To start the circle from the top
  transform: rotate(-90deg);
}

.chartBackground {
  stroke: $chart-background;
  fill: transparent;
  stroke-width: $strokeWidth;
}

.chartProgress {
  stroke: $progress-color;
  fill: transparent;
  stroke-width: $strokeWidth;
  stroke-linecap: round;
}

.confirmDialogBody {
  padding: 30px;
  font-size: 14px;
}

.confirmButton {
  [class*='purple'] {
    background-color: $main-color;
    border-color: $main-color;
  }
}

.chartCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  font-weight: 600;
  color: $progress-color;
}

.checkmark {
  width: 16px;
  height: 14px;
  margin-top: 4px;

  * {
    fill: $progress-color;
  }
}

@container kit-actions (width < 802px) {
  .label {
    display: none;
  }
}