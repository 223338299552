@import '../../../variables';

.dialogContainer {
    display: flex;
    flex-direction: column;
    padding: 26px 36px 31px;
    gap: 17px;
    width: 420px;
    max-height: 65vh;
    overflow-y: scroll;
}

.questionEditor {
    background-color: $widget-container-background-color;
    margin-bottom: 10px;

    [class*='TextEditor_label'] {
        padding: 5px 0 0 20px;
    }

    [class*='DraftEditor-root'] {
        min-height: 60px;
        max-height: 60px;
        padding: 0 9px 20px 14px;
    }
}

.questionTypeIcon {
    margin-right: 10px;
}

.answerChoiceLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    color: #000;
}

.rangeDropDown {
    width: 84px;
}

.layoutOption {
    width: 65px !important;
    height: 70px;
    box-sizing: border-box;
    font-size: 25px;
}

.followUpQuestionToggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 9px;
}

.followUpQuestionsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 50px;
    margin-top: -15px;
}

.followUpQuestionText, .followUpQuestionsContainer {
    font-size: 14px;
    color: #000;
}

.followUpQuestionWarning {
    color: $widget-container-font-color;
}

.subtitle {
    font-weight: 600;
    padding-bottom: 10px;
    color: #000;
}

.layoutText {
    color: $widget-container-font-color;
    padding-bottom: 7px;
}

.subtitle, .layoutText {
    font-size: 14px;
}