@import '../../variables';
.tabHeader {
    display: flex;
    /* border-bottom   : 1px solid #e9e8ed; */
}

.title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.selected {
    font-weight: bold;
    color: $primary-branded-color;
    border-bottom: $primary-branded-color 3px solid;
}

.singleTabHeader {
    padding: 25px 20px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover .actionIconContainer {
        display: block;
    }
}
.singleTabHeaderIcon {
    padding: 23px 20px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover .actionIconContainer {
        display: block;
    }
}
.headerIcon {
    margin-right: 12px;
}

.tabHeader {
    display: flex;
}

.iconContainer {
    margin-left: 10px;
}

.actionIconContainer {
    display: none;
    cursor: pointer;
    margin-left: 10px;
    margin-top: -10px;
}

.divider {
    height: 1px;
    background-color: #e9e8ed;
}

.disabled {
    opacity: 0.2;
    cursor: default;
}
