@import '../../../variables';

.label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.circle {
    @include colorCircle();
}
