.options {
    font-size: 12px;
}

.fieldSelector {
    opacity: 0.2;
    pointer-events: none;
}

.configsContainer, .configOptionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}