@import '../../../../variables';

.divider {
    border-left: 1px solid $separator-color;
    height: 19px;
}

.robotIconWrapper {
    cursor: pointer;
}

.newRecordMenuItem {
    color: $primary-color;
    font-weight: 600;
}

.taskPlanDialogContent {
    width: 650px;
    padding: 20px 30px;
    font-size: 14px;
}

.dialogContent {
    margin-bottom: 10px;
}

.dialogContentHeader {
    @include title();
    font-size: 16px;
    margin: 20px 0 10px;
}
