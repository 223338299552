@import '../../../variables';

$menu-color: #4f4f4f;

%highlightTitle {
    font-weight: 800;
    color: #ffffff !important;
}

.container {
    width: 100%;

    &:hover {
        .profileTitle {
            @extend %highlightTitle;
        }
    }
}

.userInfoContainer {
    width: fit-content;
    min-width: 250px;
    display: flex;
    padding: 15px 10px;
}

.picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.userDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 14px;
}
.userName, .userEmail {
    font-family: $main-Font;
    color: white;
}

.userName {
    font-size: 16px;
    font-weight: 500;
}

.userEmail {
    font-size: 12px;
    opacity: 0.6;
}

.calendarTitle {
    font-weight: bold;
}
.calendarText {
    font-weight: normal;
}
.calendarAlert {
    position: absolute;
    right: 10px;
    cursor: pointer;
}


.subMenuFloatingWindow {
    // FloatingWindow has wrapper with position: absolute !important;
    position: fixed !important;
    width: fit-content;
    z-index: 100;
    left: 87px;
    height: fit-content;
    margin-top: 0;
    border-radius: 10px;
    bottom: 35px;

    &::before {
        bottom: 14px;
        left: -15px;
        border-bottom: 10px solid $menu-color;
        transform: rotate(270deg);
    }
}

.contentWrapper {
    height: fit-content;
    background-color: $menu-color;
    padding: 0 5px 5px;
    border-radius: 6px;
}

.divider {
    height: 1px;
    background-color: #fff;
    opacity: 0.1;
    margin: 0 15px;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.action {
    font-size: 12px;
    color: #fff;
    height: 24px;
    padding: 5px 15px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;

    &:hover:not(.selected) {
        background-color: #696969;
    }
}

.selected {
    background-color: $main-color;
}

.avatarContainer {
    cursor: pointer;
}

.avatar {
    width: 33px;
    height: 33px;
    border: solid 1px #909090;
    border-radius: 50%;
}

.profileTitle {
    font-size: 12px;
    color: #b7b7b7;
    text-align: center;
    margin-top: -4px;
}

.highlight {
    .avatar {
        border: solid 2px #ffffff;
    }

    .profileTitle {
        @extend %highlightTitle;
    }
}

.highlightTitle {
    @extend %highlightTitle;
}