@import '../../../../variables';

.container {
    display: flex;

    &.progress {
        align-items: center;
        padding-top: 10px;
    }

    &.steps {
        flex-direction: row;
        padding-top: 15px;
        justify-content: flex-end;
    }

    &.noProgress {
        align-items: center;
        padding-top: 10px;
        justify-content: flex-end;
    }
}

.uiFilter {
    input {
        pointer-events: none;
    }
}

.ganttChartButton {
    padding: 10px 10px 6px;
    margin-left: 5px;
    border: 1px solid $widget-container-border-color;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;

    &:hover {
        border: 1px solid $textbox-hover-border-color;
    }
}

@media (max-width: $mobile) {
    .ganttChartContainer {
        display: none;
    }
}
