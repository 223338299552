@import '../../variables';

.loginButton {
    background-color: $login-button-color;
}

.label {
    font-family: 'Roboto';
    flex: 1 1 80%;
}

.children {
    flex: 1 1 10%;
    margin-left: 20px;
    display: flex;
}
