@import '../../../../variables';

:export {
    widgetDefaultBackgroundColor: $widget-container-background-color;
}

.widgetStyleContainer {
    display: flex;
    flex-direction: column;
    width: 450px;
    padding: 25px;

    .description {
        font-size: 14px;
        color: black;
        padding-bottom: 25px;
    }

    .categoryName {
        font-size: 12px;
        padding-bottom: 10px;
        color: $field-color;
    }

    .categoryOptions {
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding-bottom: 25px;
    }

    .componentContainer,
    .collapseContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 12px;
        color: black;
    }

    .maxHeightContainer {
        display: flex;
        flex-direction: column;
        gap: 7px;

        .maxHeightInput {
            margin-left: 25px;
        }
    }
}

// Widget Highlight styling

.widgetHighlightTooltip {
    width: 135px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    align-items: center;
}

.widgetHighlightColors {
    display: flex;
    gap: 20px;
    padding-top: 17px;
    padding-left: 28px;
    align-items: center;
}

.widgetHighlightColorsLabel {
    font-size: 14px;
    color: $subtitle-info-color;
    align-self: baseline;
    margin-top: 4px;
}


.addRulesContainer {
    width: 100%;
    font-size: 12px;
}

.addRulesRow {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin: 5px 0 0 28px;
}

.addRuleClickableArea {
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
}

.addRulesSelection {
    font-weight: bold;
}

.editPen {
    width: 16px;
    text-align: center;
    padding: 5px;
    border-radius: 100%;

    &:hover {
        background-color: $gray-action-button-menu-hover-color;
    }
}