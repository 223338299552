.uploadBtnWrapper {
    position: relative;
    /*overflow: hidden;*/
    display: inline-block;
}

.fileInput {
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    height: inherit;
}
