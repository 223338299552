@import '../../../variables';

$asset-background-color: #fff;
$asset-border-color: #dedede;
$asset-hover-background-color: #ebebeb;

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0 16px;

    :global(.MuiInputBase-root) {
        border: none;
        outline: none;
        font-family: $main-Font;
    }

    :global(.MuiInput-underline:before),
    :global(.MuiInput-underline:after),
    :global(.MuiInput-underline:hover) {
        content: none;
        border-bottom: none;
    }

    .searchLine {
        display: flex;
        align-items: center;
        width: calc(100% - 72px);
        gap: 20px;
        padding: 10px 36px;
        height: 36px;
        flex-shrink: 0;
    }

    .resultsContainer {
        border-top: 1px solid $widget-container-border-color;
        padding-top: 16px;
        width: 100%;
        display: inline-block;
        max-height: calc(65vh - 185px);
        overflow: auto;
    }
    .noResults {
        height: 112px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $empty-state-text-color;
    }
}

.loader {
    margin-top: 20px;
    width: 120px;
    [class*='.text'] {
        display: none;
        margin-top: 0;
    }
}

.suggestionsWrapper {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-self: center;
    cursor: pointer;
    border-radius: 5px;
    margin: 2px auto;
    align-items: flex-start;
    padding-bottom: 18px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: $select-options-hover-highlight;
    }
}

.selected {
     background: $select-options-keyboard-navigation-highlight;
 }

.suggestionContainer {
    display: flex;
    padding: 8px 10px;
    transition: all 0.2s ease-in;
    gap: 10px;

    .suggestionTitle {
        display: inline-block;
        @include ellipsis();
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
    }

    .accountOwnerName {
        font-size: 14px;
        display: inline-block;
        @include ellipsis();
        &:before {
            content: '-';
            padding-right: 10px;
        }
    }

    .accountLogo {
        width: 25px;
        height: 23px;
        text-align: center;
        line-height: 27px;
        font-size: 24px;

        > div {
            text-align: center;
            line-height: 24px;
            font-size: 22px;
        }
    }
}

.recentAccountsTitle {
    font-size: 14px;
    color: $configuration-text-color;
    padding: 0 36px 18px;
}

.assetsList {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0 45px;
    flex-wrap: wrap;
}

.asset {
    background-color: $asset-background-color;
    border: 1px solid $asset-border-color;
    border-radius: 10px;
    padding: 0 15px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    gap: 5px;
    align-items: center;

    &:hover {
        background-color: $asset-hover-background-color;
    }
}