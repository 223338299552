@import '../../../variables';

.container {
    overflow-x: hidden;
    background-image: url('../../../assets/images/4.png');
    background-repeat: repeat-y;
    background-size: cover;
    min-height: 100%;
    min-width: 100%;
    .tracker {
        margin-top: 30px;
    }
    .content {
        display: flex;
        flex-direction: column;
    }
    .title {
        @include pageTitle();
        font-family: $main-Font;
        font-weight: bold;
    }
    .mainTitle {
        @include pageTitle();
        font-family: $secondary-font;
        @media (max-width: $mobile) {
            line-height: 40px;
        }
    }
    .colorTitles {
        @include onBoardingColorsTitle();
        margin-top: 34px;
    }

    .title:first-of-type {
        margin-top: 70px;
    }
    .mainTitle:first-of-type {
        margin-top: 70px;
    }
    .footer {
        @include horizontalCenter();
    }

    @media (max-width: $mobile) {
        height: 100%;
        padding: 0 20px;
        .content {
            padding-bottom: 160px;
        }
        .description {
            font-size: 16px;
            text-align: center;
            padding: 0 20px;
            line-height: 20px;
        }
        .colorsSelect {
            justify-content: flex-start;
            > div {
                margin: 0 0 20px 0;
            }
        }
        .title:first-of-type {
            margin-top: 40px;
        }

        .mainTitle:first-of-type {
            margin-top: 40px;
        }

        .nextButton {
            @include mobileButton();
            @media (max-width: $mobile) {
                bottom: 30px;
                height: 40px;
            }
        }

        .footer {
            position: absolute;
            bottom: 0;
            @include centeredStickyElement();
            box-shadow: -3px 0px 11px 0 rgba(0, 0, 0, 0.2);
            width: 100%;
            background: $widget-container-background-color;
            height: 100px;

            &.hasError {
                height: 120px;
            }

            .errorContainer {
                max-width: 90%;
            }
        }

        justify-content: space-between;
    }
}
