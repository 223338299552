@import '../../variables';

.txtlink {
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    border: 2px solid $widget-container-border-color;
    float: left;
    padding: 5px 10px 5px 15px;
    font-family: $main-Font;
    font-size: 12px;
    overflow-y: auto;
}
