@import '../../variables';

.inputContainer {
    display: flex;
    width: 100%;
}

.title {
    font-size: 12px;
    color: $field-color;
    white-space: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.titleFlex {
    display: flex;
}

.boxIcon {
    width: 24px;
    height: 24px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    width: 100%;
    gap: 5px;
}
