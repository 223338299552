@import '../../../variables';

.container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .errorMessage {
        color: $error-message-color;
    }

    .emptyTaskPlanContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &.opacity {
            opacity: 0.2;
        }
    }

    .description {
        font-size: 14px;
        font-weight: bold;
    }

    .startPlanBtn {
        min-width: 155px;
        padding: 10px;
    }
}
