@import '../../variables.scss';

$search-bg-color: #ffffff;
$search-bg-hover-color: #ebebeb;
$search-result-hover-color: #f5f5f5;
$internal-indicator-color: #f8f0cb;

.container {
    position: relative;
    @media (max-width: $mobile) {
        position: unset;
    }
}

@mixin searchIcon {
    width: 37px;
    height: 37px;
    border-radius: 50%;
}

.searchIconBackground {
    @include searchIcon();
    margin-right: 10px;

    &.active {
        background-color: $search-bg-color;
    }

    &:not(.active) {
        &:hover {
            circle {
                stroke: #000;
            }

            rect,
            path {
                fill: #000;
            }
        }
    }
}

.searchIcon {
    @include searchIcon();
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
        background-color: $search-bg-hover-color;
    }

    svg {
        background-color: unset !important;
        padding: unset !important;

        &.notActive {
            circle {
                stroke: black !important;
            }
            rect,
            path {
                fill: black !important;
            }
        }
    }
}

.internal {
    position: absolute;
    top: 28px;
    padding: 1px 3px 0;
    background-color: $internal-indicator-color;
    width: 32px;
    height: 12px;
    font-size: 8px;
    border-radius: 20px;
    text-align: center;
}

.searchWindow {
    position: absolute;
    top: 58px;
    right: 10px;
    z-index: 15;
    width: 478px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #0000004c;

    @media (max-width: $mobile) {
        width: 90%;
        z-index: 100;
        right: 5%;
    }

    .searchWindowContent {
        display: flex;
        flex-direction: column;
        box-shadow: unset;
        border-radius: unset;
        max-height: unset;
        padding: 16px 18px 17px;

        @media (max-width: $mobile) {
            width: unset !important;
        }
    }
}

.searchInput {
    height: 17px;
    width: 428px;
    padding: 0 5px;

    @media (max-width: $mobile) {
        width: inherit;
    }

    & > div,
    & > div:hover {
        border: none;
    }
}

.searchResults {
    border-top: 1px solid $textbox-border-color;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;

    @media (max-width: $mobile) {
        width: 100%;
    }

    .showMore {
        font-size: 12px;
        color: $primary-branded-color;
        cursor: pointer;
        align-self: center;
    }
}

.noResults {
    border-top: 1px solid $textbox-border-color;
    padding: 20px 0 3px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: $subtext-grey;
}

.searchResult {
    font-size: 14px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 7.5px 5px;
    width: 100%;

    &:hover {
        background-color: $search-result-hover-color;
    }

    @media (max-width: $mobile) {
        width: 90%;
        justify-content: flex-start;
    }

    .resultIcon {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    .resultText {
        max-width: 400px;
        @include ellipsis();
    }
}
