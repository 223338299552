@import '../../../../variables';
@import '../TemplateModal.module';

.enableCommentsContainer {
    @include flexRow();

    .enableComments {
        @include description(14px);
        margin-left: 10px;
        display: flex;
        align-items: center;

        .infoIcon {
            margin-left: $info-icon-margin-left;
        }
    }
}
