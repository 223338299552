.closeButton {
    margin-bottom: -5px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0 25px 60px;
    width: 100%;
    box-sizing: border-box;
}

.title {
    margin-top: -15px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
}

.closeBtn {
    margin-left: auto;
    margin-right: -16px;
}
