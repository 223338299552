.actions {
    display: flex;
    position: relative;
}

.editIconContainer {
    padding-left: 15px;
    margin-top: -10px;
}

.editableTag {
    background-color: #d3e0fa;
    color: #000;
    font-size: 8px;
    padding: 1px 4px 0;
    border-radius: 20px;
    margin-top: -7px;
}

.editIconContainer, .editableTag {
    position: absolute;
    top: 0;
    right: 0;
}