@import '../../variables';

.reconnectMessage {
    display: flex;
    font-size: 16px;
    color: $font-color-in-main-color;
    align-items: center;
    font-weight: normal;
    .calendarIcon {
        margin-left: 15px;
        margin-right: 20px;
    }
    .headline {
        font-weight: bold;
    }
    .text {
        font-weight: normal;
    }
}
