@import '../../variables';

$border-color: #757575;

.wrapper {
  position: relative;

  * {
    cursor: row-resize;
  }

  &:hover {
    .arrow {
      opacity: 1;
    }

    .resizer {
      border-bottom: 1px solid $border-color;
    }
  }
}

.resizer {
  border-bottom: 1px solid $separator-color;
  padding-bottom: 5px;
  transition: border 0.2s ease-in-out;

  &:active {
    border-bottom: 1px solid $border-color;
  }
}

.arrow {
  position: absolute;
  right: calc(50% - 6px);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  svg {
    height: 5px;
  }

  &:first-of-type {
    top: -10px;
    transform: rotate(180deg);
  }

  &:last-of-type {
    bottom: -15px;
  }
}