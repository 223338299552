.container {
    padding: 0 10px;
    margin-bottom: 30px;
}

.fallbackContainer {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.info {
    margin-left: 10px;
}

.fieldLabel {
    font-size: 14px;
    margin-top: 15px;
}
