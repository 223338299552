@import '../../variables';
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin: 2px 12px 0 8px;
}

.name {
    font-size: 10px;
    font-weight: bold;
}

.message {
    font-size: 14px;
    width: 97%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.commentTime {
    font-size: 10px;
    color: #9d9d9d;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.comment {
    padding: 5px;
}

.commentContainer {
    position: relative;
    .internalLabel {
        position: absolute;
        top: 4px;
        right: 8px;
    }
    &:hover {
        .internalLabel {
            display: none;
        }
    }
}

.resolved {
    opacity: 0.4;
}
