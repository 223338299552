.dateBox {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-top: 2px;
    border-radius: 10px;

    .textContainer {
        position: relative;

        .digits {
            position: absolute;
            top: 6px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
        }

        .month {
            position: absolute;
            top: 21px;
            width: 100%;
            text-align: center;
            font-size: 9px;
        }
    }
}
