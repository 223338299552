@import '../../../../variables';

.title {
    font-size: 14px;
    color: $subtext-color;
    display: flex;
    align-items: center;

    .titleText {
        margin: 5px 171px 5px 0px;
        font-size: 14px;
        font-weight: bold;
        color: $text-color;
    }
}

.showMoreContainer {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 10px;
}

.showMoreBtn {
    color: $text-color;
    border: solid 1px $show-more-button-border-color;
    background-color: $show-more-button-background-color;

    &:hover {
        background-color: $show-more-button-background-color;
    }
}

@media (max-width: $mobile) {
    .meetingsDataContainer {
        padding-left: 5px;
    }
}

.space {
    padding-top: 10px;
}
