.joinLink {
    display: flex;
    place-items: center;
    position: relative;
    text-decoration: none;
    font-size: 12px;
    color: #4a4a4a;

    .linkIcon {
        width: 18px;
    }

    .linkTest {
        padding-left: 8px;
    }
}
