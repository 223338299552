@import '../../../../variables';

.settingsButton {
    padding: 3px;
    height: 15px;
    @include actionButton();
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 36px;

    [class*='element'] {
        margin-bottom: 10px;
    }

    .infoText {
        font-size: 14px;
        padding-bottom: 20px;
    }
}

.validationError {
    @include errorMessageContainer();
    font-size: 12px;
    padding-top: 0;
}

.filtersContainer {
    font-size: 14px;

    .filtersContent {
        margin-top: 14px;
        > div {
            max-height: 340px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    label {
        font-size: 14px;
    }
}

.errorInput {
    :global(.MuiInputBase-root) {
        fieldset {
            border-color: $error-message-color;
            outline-color: $error-message-color;
        }
    }
}

.optionWrapper {
    &.selectable {
        cursor: pointer;
    }
}
.personasConfigurationContainer {
    padding-top: 14px;
    width: 50%;

    :global(.MuiInputBase-root) {
        font-size: 14px;
    }
}

.active {
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.inactive {
    opacity: 0.2;
    transition: opacity 0.2s ease-in;
}
