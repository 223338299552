@import '../../../variables';

.commentsTableContainer {
  overflow-x: scroll;
  height: 95%;

    div {
        height: 100%;
    }

  [class*="MuiTableSortLabel-icon"] {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.headerRow > span {
  font-family: $main-Font;
}

.noCommentsContainer, .emptyStateContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon > svg {
    width: 38px;
    height: 38px;
  }

  .title {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
  }

  .subtitle {
    margin-top: 5px;
    font-size: 12px;
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeletonRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeletonGroup {
  width: 30%;
  display: flex;
  gap: 20px;
}
