@import '../../../variables';
.mainContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.imageUploaderContainer {
    margin-top: 10px;
    flex-grow: 1;
    min-width: 48%;
}

.imageUploader {
    min-height: 155px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    position: relative;

    &:hover {
        .upload {
            opacity: 1;
        }
    }
}

.imageViewer {
    display: flex;
    justify-content: center;
}

.selected {
    border: solid 2px $primary-color;
    border-radius: 3px;
}

.selected:hover {
    border: solid 2px transparent;
}

.border {
    border-radius: 3px;
    border: dashed 1px #d9d9d9;
}

textarea {
    border-radius: 5px;
    box-sizing: border-box;
    border: solid 1px #d9d9d9;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 0 2px 3px;
}

.editElemensContainer {
    display: flex;
    height: 100%;
    margin-top: 10px;
}

.publishButton:hover {
    opacity: unset;
    cursor: pointer;
}

.publishButton {
    width: 78px;
    height: 30px;
    border-radius: 15px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: $primary-color;
    margin-left: 30px;
    margin-bottom: -15px;
    position: relative;
    z-index: 10;
    color: #ffffff;
}

.unpublishButton {
    width: 78px;
    height: 30px;
    border-radius: 15px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    margin-left: 30px;
    margin-bottom: -15px;
    position: relative;
    z-index: 10;
    &:hover {
        opacity: unset;
        cursor: pointer;
    }
}

img {
    max-width: 100%;
    max-height: 100%;
}

.contentEditor {
    color: $freestyle-texteditor-color;
    width: 100%;
    margin-top: 10px;
}

.contentViewer {
    color: $freestyle-texteditor-color;
    width: 100%;
    font-size: 14px;
}

.textEditorContainer {
    height: inherit;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 48%;
}

.overlay {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

p {
    margin: 0;
}

a {
    text-decoration: underline;
    color: inherit;
}

.note {
    width: 519px;
    height: 21px;
    background-color: $internal-data-background-color;
    font-size: 12px;
    margin-bottom: -10px;
    z-index: 10;
    position: relative;
    padding-left: 5px;
    margin-left: 120px;
}

.row {
    flex-direction: row;
}

.rowReverse {
    flex-direction: row-reverse;
}

@media only screen and (max-width: $mobile) {
    .editElemensContainer:not(.pdf) {
        flex-direction: column;

        .row {
            flex-direction: column;
        }
        .rowReverse {
            flex-direction: column;
        }
        .contentViewer {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
        }
    }
}

.embedContainer {
    margin-top: 10px;
    flex-grow: 1;
    min-width: 48%;
}

.viewModeMarginTop {
    margin-top: 0;

    div {
        margin-top: 0;
    }
}
