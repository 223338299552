@import '../../variables.scss';

.tag {
    height: 36px;
    line-height: 1;
    border-radius: 18px;
    box-shadow: 0 0 0 1px $tag-shadow-color;
    background-color: $tag-background-color;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    transition: box-shadow 0.6s linear;
    font-size: 20px;
    color: $tag-color;
}
.tag:hover {
    box-shadow: 0 0 0 2px $tag-shadow-color;
    cursor: pointer;
}

.label {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 20px;
}
