@import '../../../../variables';

.activateWidgetListContainer {
    width: 100%;
    display: flex;
    margin-bottom: 30px;

    .activateWidgetListButton {
        padding: 10px 20px;
        width: fit-content;
        margin: 0 auto;
    }
}

.changeContextRecordLink {
    margin-left: 5px;

    span {
        margin-left: 3px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.contextRecordName {
    margin-left: 5px;

    span {
        margin-left: 3px;
    }
}

.dialogContent {
    width: 450px;
    padding: 20px 30px;
}

.recordLineContainer {
    display: flex;
    flex-direction: column;
}
