@import '../../../../variables';

.customerCheckBoxContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.widgetTitle {
    @include widgetTitle;
}

.customerOptions {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.checkboxRow {
    display: flex;
    gap: 15px;
    font-size: 12px;
}