.setUpTaskDependenciesDialog {
    width: 429px;
    margin: 26px;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    gap: 25px;
}

.description {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.hourglass {
    min-width: 45px;
}

.dependenciesDialog {
    width: 400px;
}
