@import '../../variables';

.container {
    width: 100%;
    height: 100%;
}

.dialogContainer {
    border-radius: 10px;
    background-color: $main-color;
    background-size: contain;
    height: 580px;
    min-height: 580px;
    min-width: 700px;
    width: 700px;
    justify-content: center;
    position: relative;

    [class*='dialogTitle'] {
        display: block;
    }

    .dialogHeader {
        color: $trial-action-button-color;
        .dialogTitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-size: 34px;
            font-family: $secondary-font;
            text-align: center;
        }

        .dialogTitleDescription {
            padding: 0 11%;
            font-size: 18px;
            font-weight: 300;
            text-align: center;
            font-family: $main-Font;
        }
    }

    [class*='closeIcon'] {
        svg {
            fill: $trial-action-button-color;
            opacity: 1;
        }
    }

    .starImage {
        position: absolute;
        left: 36px;
        bottom: 2px;
    }
}

.dialogPaper {
    background-color: $main-color !important; // force override Material UI css
}

.content {
    background-color: $blocker-dialog-bg-color;
    background-image: url('../../assets/images/blocker-dialog-bg.png');
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 38px;

    .contentTitle {
        font-weight: 600;
        font-size: 17px;
        @include title();
    }

    .contentEntry {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include description();
        font-size: 14px;
        .bold {
            font-weight: 600;
        }
    }
}

.button {
    text-decoration: none;
    width: 10%;
    min-width: 170px;
    display: inline-block;
}
