.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading {
  pointer-events: none;
  opacity: 0.5;
}

.checkboxOption {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  text-align: initial;
  font-size: 14px;
}

.radioButtonOptionContainer {
  margin-bottom: 10px;
}

.radioButtonOptionText {
  font-size: 14px;
}

.tooltip {
  min-width: unset;
}

.infoIconWrapper {
  height: 19px;
}