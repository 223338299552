@import '../../variables';

.menu {
    position: fixed;
    height: 100%;
    background: white;
    left: calc(#{$product-feed-menu-width} * -1);
    transition: all 500ms linear;
    z-index: 100;
    overflow-x: hidden;

    &.active {
        left: $sidebar-main-width;
    }
}
