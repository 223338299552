.infoIcon {
    margin-left: 5px;
}

.templateBorder {
    margin: 0 5px;
}

.automationField {
    margin: 0 5px;
    font-weight: normal;
}
