@import '../../../variables';

$off-indicator-color: #edeced;
$off-hover-color: #f5f5f5;

// All !important uses are to override the default styles of the ToggleButton component (some nested styles are not overridable)

.container {
    border-radius: 25px !important;
    border: 1px solid $divider-color;

    &:hover {
        background-color: $off-hover-color !important;
    }

    @media (max-width: $small-laptop) {
        .indicator {
            left: 2px !important;
            width: 88%;
            height: 88%;
        }
    }

    &[data-value='false'] {
        .indicator {
            background-color: $off-indicator-color !important;
            left: 2px !important;
        }
    }

    &[data-value='true'] {
        border: 1px solid $primary-branded-color;

        .indicator {
            top: 2px;
            left: 44%;
        }
    }
}

.indicator {
    border-radius: 25px !important;
    height: 89%;
    width: 53%;

    &:hover {
        opacity: 1;
    }
}
