@import '../../../../../variables';

.title {
    @include title();
    font-size: 18px;
}

.container {
    @include center();
}

.image {
    width: 160px;
    height: 59px;
    margin: 0 53px 19px;
}
