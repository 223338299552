@import '../FieldsSettings.module.scss';

.fieldName {
    width: fit-content;
}

.optionContaier {
    display: flex;
    .optionEditIcon {
        position: relative;
        top: 20px;
        margin-right: 24px;
        cursor: pointer;
    }
}

.fieldType {
    margin-top: 16px;
    margin-bottom: 30px;
}

.dialogContent {
    width: 328px;
    overflow-x: hidden;
    padding: 26px 30px 30px;
}

.confirmDialogContent {
    width: 480px;
    height: 137px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 16px;
    .confirmText {
        font-size: 16px;
    }
}

.warningButton {
    border: none;
}

.deleteIcon {
    color: black;
    position: relative;
    top: 49px;
    left: 30px;
    cursor: pointer;
}

.optionEdit {
    line-height: 2.8;
    margin-left: 10px;
    margin-top: 10px;
    width: 100%;
    padding-left: 10px;
    :global(.DraftEditor-root) {
        min-height: 38px;
        max-height: 130px;
    }
}

.deleteOptionContainer {
    width: 612px;
    margin-top: 16px;
    margin-left: 33px;
}
.replaceOption {
    display: flex;
}
.optionMenu {
    margin-bottom: 14px;
}

.addValue {
    margin-bottom: 25px;
}

.limitContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.limitToggle {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.limitLabel {
    font-size: 14px;
    cursor: pointer;
}

.limitInput {
    width: auto !important; // Overrides width set as inline-style
    margin-left: 50px;
}