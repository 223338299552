@import '../../../../../variables';

.columnContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 40px;
    cursor: pointer;

    &::after {
        content: '';
        display: block;
        width: 2px;
        height: 90%;
        position: absolute;
        z-index: 0;
        top: 5%;
        right: 0;
        background: #e9e8ed;
    }
    &:last-child::after {
        display: none;
    }
    :global([aria-controls='simple-menu']) {
        background-color: white;
        font-family: $main-Font;
    }
}

.kpiLabel {
    font-size: 14px;
    color: $subtext-color;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        justify-content: center;
        align-items: center;
    }
    div {
        margin: 0;
        padding: 0;
        font-size: inherit;
    }
    a {
        text-decoration: underline;
    }
}

.kpiField {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $widget-container-border-color;
    border-radius: 8px;
    vertical-align: middle;
    &:focus {
        outline: none;
    }
}

.columnContainer .kpiField {
    text-align: center;
}

.columnContainerLast .kpiField {
    text-align: center;
}

.checkboxRow {
    display: flex;
    font-size: 12px;
    margin: 10px 0 12px 0;

    .text {
        margin-left: 12px;
        text-align: initial;
        font-family: $main-Font;
        text-align: center;
    }
}

.kpiValue {
    padding: 20px 0 20px;
}

.input {
    height: 50px;
    border-radius: 8px;
    text-align: center;
}
