@import '../../variables';
@import '../../aiVariables';

.content {
    display: flex;
    margin: 13px auto 0;
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 960px;

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    .filterRowsContainer {
        padding: 14px 14px 9px 14px;
        position: relative;
        width: 95%;
        border: 2px solid transparent;

        &.aiConfirmation {
            @include AI_CONTENT_WRAPPER($textbox-backgrount-color, 10px, 2px);
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .dropDownSection {
        display: flex;
        text-align: left;
        margin-bottom: 30px;

        .dropDown {
            .dropDownLabel {
                font-size: 12px;
            }
        }

        .explain {
            margin-right: 55px;

            .explainTitle {
                font-size: 14px;
                margin-bottom: 10px;
            }

            .explainText {
                font-size: 12px;
                width: 250px;
                opacity: 0.7;
            }
        }
    }

    .multiDropDownSection {
        text-align: left;
        margin: 0 11px;
    }

    .dropDown {
        position: relative;

        .dropDownLabel {
            font-size: 10px;
            color: #5c5a5a;
            margin-bottom: 10px;
        }

        .dropDownSubLabel {
            font-size: 10px;
            color: #5c5a5a;
            margin: 0 0 6px;
            position: absolute;
            top: -20px;
        }
    }

    .operators,
    .values {
        .dropDown {
            margin-bottom: 22px;
        }
    }
}

.container {
    position: relative;

    .aiTooltip {
        width: 433px;
    }
}

.responsiveInput {
    // 33% since there are 3 inputs in a row, 7px to account for the delete button (20px total)
    width: calc(33% - 7px);

    &:first-of-type {
        margin-left: 0;
    }
}

.delete {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 36px;

    &.firstRow {
        // first row acting strange, adding workaround TODO: fix rows styles
        margin-bottom: 0;
        margin-top: 22px;
    }

    &:hover {
        cursor: pointer;
        fill: red;
        transition: fill 0.1s ease-in;
    }

    svg {
        height: 18px;
        width: 20px;
    }
}

.addButtonWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.addFilterButton {
    height: 17px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
}

.aiButtonWrapper {
    border-left: 1px solid $separator-color;
    height: 37px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    padding: 0 10px;
}

.aiButton {
    &:hover {
        @extend %AI_COLOR_BACKGROUND;

        .aiButtonLabel {
            -webkit-text-fill-color: white;

            svg path {
                fill: white;
            }
        }
    }
}

.aiButtonLabel {
    @extend %AI_TEXT;
    font-size: 12px;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 5px;
}
