@import '../../../../../variables';

.headerContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(90vw - 80px);
    max-width: 900px;
    height: 100%;

    .titleWrapper {
        display: flex;
        gap: 10px;
        height: 27px;
        align-items: center;
        margin-left: 30px;
        width: 100%;
    }
}
