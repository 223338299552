.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form {
        max-width: 310px;
        .textField {
            width: 270px;
            margin-bottom: 22px;
        }
    }
}
