@import '../../variables';

.pageContainer {
    width: 96%;
    position: relative;
}

.header {
    border-bottom: 1px solid $separator-color;
}

.accountFilterDivider {
    margin: 0 30px 0 20px;
}

.copyPassword {
    font-size: 12px;
    margin-left: 18px;
    cursor: pointer;
}

.accountFilter {
    flex-grow: 0;
}

.actionButtonsContainer {
    display: flex;
    justify-content: flex-end;
}

.tableToolbar {
    @include flexCenterJustifyContent(flex-end);
}

.tableToolbarWithConnectButton {
    padding-top: 50px;

    .connectToCalendar {
        top: 0;
    }

    @media (min-width: $laptop) {
        padding-top: 0;

        .connectToCalendar {
            top: unset;
        }
    }
}

.toolbarPadding {
    padding-right: 40px;
}

.grouping {
    display: flex;
    align-items: center;
    gap: 10px;
}

.groupingHeadline {
    @include description();
}

.tableContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 10px;
}

.loader {
    height: 90vh;
}

.hiddenMeetingAction {
    background-color: $internal-data-background-color;

    &:hover {
        background-color: $internal-data-background-color-hover;
    }
}

.toStart {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 200px auto 0;
    width: 450px;
    text-align: center;

    .emptyState {
        @include loading();
        font-weight: 'normal';
        margin-top: 50px;

        .smallerText {
            font-size: 16px;
        }
    }
}

.connectToCalendar {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    font-size: 14px;
    gap: 10px;

    .textSpan {
        margin-top: 8px;
    }
}

.noCalendar {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content {
    max-width: 692px;
    text-align: center;
}

.calendarImage {
    margin-bottom: 16px;
}

.description {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    text-align: center;
}

.meetingsPreviewImg {
    margin-top: 34px;
    margin-bottom: 35px;
    background-color: white;
}

.connectButton {
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
}
.readMoreText {
    margin-top: 34px;
    margin-bottom: 22px;
}

.icons {
    height: 27px;
    min-width: 27px;
}

.longTextColumn {
    white-space: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.pointer {
    cursor: pointer;
}
