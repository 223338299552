@import '../../../../../variables';

$block-task-completed-color: #cccccc;

.toggleStatusButton {
    cursor: pointer;
    @include taskActionButton();
    border: none;
    font-family: $main-Font;
    font-size: 12px;
    min-width: 128px;

    background: $task-status-not-started-background;
    color: $task-status-not-started-color;
    transition: background-color 0.2s ease-in;
    height: 32px;

    .label::before {
        content: 'Not started';
    }

    &:hover {
        .label.enabled::before {
            content: '✔ Mark completed';
        }
    }

    &.completed {
        background: $task-status-completed-background;
        transition: background-color 0.2s ease-in;
        color: $task-status-completed-color;

        .label::before {
            content: 'Completed';
        }

        &:hover {
            .label.enabled::before {
                content: '✖ Mark not started';
            }
        }
    }
}

.checkbox {
    margin-right: 7px;
    height: 14px;
    width: 14px;
    font-size: 10px;
    margin-left: 5px;

    label {
        height: 14px;
        aspect-ratio: 1;
    }

    input[type='checkbox'] {
        height: 14px;
        width: 14px;
    }

    span {
        height: 14px;
        max-height: 14px;
        max-width: 14px;
        aspect-ratio: 1;
    }

    [class*='checkbox']::after {
        left: 5px;
        top: 0;
        width: 2px;
        height: 10px;
    }
}

.multiSelect {
    height: 22px;
}

.reduceSizeToPreventOverflow {
    input[type='checkbox'] {
        height: 14px;
        width: 14px;
    }
}

.taskStatusTooltip {
    display: flex;
    flex-direction: column;
    line-height: 1.67;
}

@mixin blockTask($color, $background-color) {
    background: repeating-linear-gradient(-35deg, $color, $color 1px, $background-color 0px, $background-color 5px);
}

.blockUpdateTask {
    $block-update-task-background: #ffffff;
    @include blockTask($block-task-completed-color, $block-update-task-background);
}

.blockCompletedTask {
    $block-task-completed-background: #000000;
    @include blockTask($block-task-completed-color, $block-task-completed-background);
}
