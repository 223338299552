@import '../../variables';
.box {
    background-color: white;
    border-radius: 3px;
    border: dashed 1px #d9d9d9;
    padding: 0 20px;
    // margin: 5px 10px 30px 10px;
}

.divider {
    height: 1px;
    background-color: #e4e4e4;
    width: -webkit-fill-available;
    margin: 20px 10px 5px 10px;
}

.buttonContainer {
    width: 100%;
    display: flex;
    padding-left: 10px;
}

.tasksContainer {
    font-family: $main-Font;

    .tasksContent {
        padding-left: 20px;
        margin-left: -20px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .sectionsWrapper {
            margin-top: 20px;
        }

        @media (max-width: $mobile) {
            overflow-x: hidden;
        }
    }
}

.viewModeMarginTop {
    div:first-of-type .sectionsWrapper {
        margin-top: 10px;
    }
}

.hr {
    border: none;
    border-right: solid 1px #e4e4e4;
    margin: 10px 20px;
}

.verifyDateDialogReminder {
    margin-top: 20px;
}

.header {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
    flex-shrink: 0;
    gap: 20px;
    padding-top: 0;

    .uiFilter {
        &.pullUpToSaveSpace {
            margin-top: -10px;
        }
    }
    .statusBar {
        display: flex;
        width: 100%;
    }

    &.sameLine {
        margin-top: 0;
        padding: 15px 0;
        flex-direction: row;
        align-items: center;

        .uiFilter {
            margin-top: 0;
        }

        .statusBar {
            align-items: center;
            padding-top: 0;
        }
    }
}

.tasksCompleted {
    color: $checked-tag-color;

    .vIcon {
        vertical-align: middle;
        padding-right: 10px;
    }
}
