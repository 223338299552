@import '../../../../variables';

.button {
    display: inline-flex;
    justify-content: flex-start;
    margin-left: 10px;
}

.body {
    width: 430px;
    padding: 30px;
}

.description {
    @include description();
    padding-bottom: 15px;
}

.input {
    width: 430px;
    height: 40px;
    input {
        padding: 15px;
    }
    label {
        margin-top: -7px;
    }
}

.error {
    color: $error-message-color;
    font-weight: 600;
    font-size: 12px;
}

.buttonContainer {
    display: flex;

    .info{
        margin-left: 20px;
    }
}

.button {
    height: auto;
    justify-content: flex-start;
}
