@import '../../../variables';

.container {
    padding: 15px 0px;
    text-align: center;

    .title {
        font-family: $main-Font;
        font-size: 14px;
        vertical-align: middle;
    }

    .subTitle {
        font-family: $main-Font;
        font-size: 12px;
        color: $description-grey;
        margin: 18px;
    }
}
