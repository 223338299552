@import '../../../../../variables';

.newRecordMenuItem {
    color: $primary-color !important;
    font-weight: 600;
}

.recordsDropDown {
    margin-top: 20px;

    &:global(.MuiFormControl-root) {
        margin-top: 30px;
    }
}
