@import '../../../../../../variables';

.addAgendaButton {
    height: 26px;
    max-height: 26px;
    padding: 0px 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: $font-color-in-main-color;
    cursor: pointer;
    margin: 0 10px;
}
